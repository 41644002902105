import React, { useEffect, useState } from "react";
import { useMainSectionStyles } from "../../../css/admin/main-section.styles";
import { FormInput } from "../../../components/input-forms";
import { useDispatch, useSelector } from "react-redux";
import {
  getReasonsStart,
  clearErrors,
  editReasonStart,
  addReasonStart,
  deleteReasonStart,
} from "../../redux/reasons/reasons.actions";
import { ReactComponent as Trash } from "../../../assets/trash.svg";
import ConfirmDeleteModal from "../../../components/confirmActionModal";

const Reasons = () => {
  const classes = useMainSectionStyles();
  const dispatch = useDispatch();
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const reasons = useSelector((state) => state.reasons.reasons);
  const isDeleting = useSelector((state) => state.reasons.isDeleting);
  const errorMessage = useSelector((state) => state.reasons.error);
  const isAdding = useSelector((state) => state.reasons.isAdding);

  const [reasonName, setReasonName] = useState("");
  const [reasonToEdit, setReasonToEdit] = useState();
  const [confirmDelete, setConfirmDelete] = useState();

  useEffect(() => {
    if (!reasons) {
      dispatch(getReasonsStart(currentAdmin.adminId));
    }
  }, [reasons, currentAdmin.adminId, dispatch]);

  useEffect(() => {
    if (reasonToEdit) {
      setReasonName(reasonToEdit.name);
    }
  }, [reasonToEdit]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [confirmDelete, dispatch]);

  const callback = () => {
    setReasonName("");
    document.getElementById("name").focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (reasonToEdit) {
      const callback = () => {
        setReasonToEdit(null);
        setReasonName("");
      };

      return dispatch(
        editReasonStart(
          reasonName,
          currentAdmin.adminId,
          reasonToEdit.id,
          callback
        )
      );
    }

    if (reasonName) {
      dispatch(addReasonStart(reasonName, currentAdmin.adminId, callback));
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const callback = () => setConfirmDelete(false);

    dispatch(
      deleteReasonStart(confirmDelete.id, currentAdmin.adminId, callback)
    );
    if (reasonToEdit && reasonToEdit.id === confirmDelete.id) {
      setReasonToEdit(null);
      setReasonName("");
    }
  };

  const cancelEdit = () => {
    setReasonToEdit(null);
    setReasonName("");
    document.getElementById("name").focus();
  };

  return (
    <div className={classes.main}>
      <div className={classes.list}>
        <h1>Виды оснований</h1>
        {reasons &&
          reasons.map((reason) => (
            <div key={reason.id} className={classes.listItem}>
              <span
                onClick={() => setReasonToEdit(reason)}
                className={
                  reasonToEdit && reasonToEdit.id === reason.id
                    ? classes.selectedItem
                    : ""
                }
              >
                {`${reason.name}`}
              </span>
              <Trash onClick={() => setConfirmDelete(reason)} />
            </div>
          ))}
      </div>

      <form className={classes.addSection} onSubmit={handleSubmit}>
        <div className={classes.inputSection}>
          <FormInput
            label="Название"
            id="name"
            value={reasonName}
            onChange={(e) => setReasonName(e.target.value)}
          />
        </div>
        <div className="buttons">
          <button
            className="blue"
            submitstyle="true"
            disabled={!reasonName || isAdding}
          >
            {reasonToEdit ? "Сохранить" : "Добавить"}
          </button>
          {reasonToEdit && (
            <button
              className="cancel"
              disabled={isAdding}
              type="button"
              onClick={cancelEdit}
            >
              Отмена
            </button>
          )}
        </div>
      </form>

      {confirmDelete && (
        <ConfirmDeleteModal
          message={`Удалить основание "${confirmDelete.name}"?`}
          confirm={handleDelete}
          cancel={() => setConfirmDelete(null)}
          processing={isDeleting}
          errorMsg={errorMessage}
        />
      )}
    </div>
  );
};

export default Reasons;
