const RemarksActionTypes = {
  ADD_REMARK_START: "ADD_REMARK_START",
  ADD_REMARK_SUCCESS: "ADD_REMARK_SUCCESS",
  ADD_REMARK_FAILURE: "ADD_REMARK_FAILURE",
  GET_REMARKS_START: "GET_REMARKS_START",
  GET_REMARKS_SUCCESS: "GET_REMARKS_SUCCESS",
  GET_REMARKS_FAILURE: "GET_REMARKS_FAILURE",
  GET_REMARKS_BY_SUB_START: "GET_REMARKS_BY_SUB_START",
  GET_REMARKS_BY_SUB_SUCCESS: "GET_REMARKS_BY_SUB_SUCCESS",
  GET_REMARKS_BY_SUB_FAILURE: "GET_REMARKS_BY_SUB__FAILURE",
  GET_REMARKS_BY_USER_START: "GET_REMARKS_BY_USER_START",
  GET_REMARKS_BY_USER_SUCCESS: "GET_REMARKS_BY_USER_SUCCESS",
  GET_REMARKS_BY_USER_FAILURE: "GET_REMARKS_BY_USER_FAILURE",
  DELETE_REMARK_START: "DELETE_REMARK_START",
  DELETE_REMARK_SUCCESS: "DELETE_REMARK_SUCCESS",
  DELETE_REMARK_FAILURE: "DELETE_REMARK_FAILURE",
  EDIT_REMARK_START: "EDIT_REMARK_START",
  EDIT_REMARK_SUCCESS: "EDIT_REMARK_SUCCESS",
  EDIT_REMARK_FAILURE: "EDIT_REMARK_FAILURE",
  UPDATE_REMARK_STATUS_START: "UPDATE_REMARK_STATUS_START",
  UPDATE_REMARK_STATUS_SUCCESS: "UPDATE_REMARK_STATUS_SUCCESS",
  UPDATE_REMARK_STATUS_FAILURE: "UPDATE_REMARK_STATUS_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default RemarksActionTypes;
