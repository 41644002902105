import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { InputField } from "../../components/input-forms";
import { useDispatch, useSelector } from "react-redux";
import { validate } from "../../utils/normalizeData";

import {
  recoverPasswordStart,
  clearErrors,
} from "../../redux/user/user.actions";

const Recover = () => {
  const dispatch = useDispatch();

  useEffect(() => dispatch(clearErrors()), [dispatch]);

  const [credentials, setCredentials] = useState({ identifier: "", email: "" });
  const isFetching = useSelector((state) => state.user.isFetching);
  const recoverSuccess = useSelector((state) => state.user.recover);
  const errorMessage = useSelector((state) => state.user.error);

  const [errors, setErrors] = useState({
    identifier: { type: "required", error: null },
    email: { type: "required", error: null },
  });

  const handleChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      validate(
        e.target.value,
        () => {},
        () =>
          setErrors({
            ...errors,
            [e.target.name]: { ...errors[e.target.name], error: null },
          }),
        errors[e.target.name].type
      );
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    if (!credentials.identifier) {
      setErrors((errors) => ({
        ...errors,
        identifier: {
          ...errors.identifier,
          error: "Укажите идентификатор организации",
        },
      }));
      valid = false;
    }
    if (!credentials.email) {
      setErrors((errors) => ({
        ...errors,
        email: {
          ...errors.email,
          error: "Укажите e-mail",
        },
      }));
      valid = false;
    }
    if (!valid) return;

    dispatch(recoverPasswordStart(credentials));
  };

  return (
    <>
      <div className="header_login">
        <h1>Под Контролем</h1>
        <h2>Забыли пароль?</h2>
      </div>

      <div className="content_login">
        <form onSubmit={handleSubmit}>
          {recoverSuccess && (
            <div className="info success">
              На почту отправлено сообщение, все будет
            </div>
          )}
          <div className="info err">{errorMessage}</div>

          <InputField
            type="text"
            name="identifier"
            label="Идентификатор компании"
            value={credentials.identifier}
            validationType="required"
            onChange={handleChange}
            err={errors.identifier.error}
          />
          <InputField
            name="email"
            label="Электронная почта"
            type="email"
            value={credentials.email}
            validationType="required"
            onChange={handleChange}
            err={errors.email.error}
          />

          <div className="">
            {!recoverSuccess && (
              <>
                <div className="button">
                  <button disabled={isFetching}>
                    {isFetching ? "Загрузка..." : "Готово"}
                  </button>
                </div>
                <div className="info">
                  Для восстановления пароля, укажите идентификатор компании и
                  электронную почту, указанную при регистрации. Если что-то из
                  этого не помните, свяжитесь с вашим администратором.
                </div>
              </>
            )}

            <div className="link">
              <Link to="/login">Вход</Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Recover;
