const MaterialsActionTypes = {
  ADD_MATERIAL_START: "ADD_MATERIAL_START",
  ADD_MATERIAL_SUCCESS: "ADD_MATERIAL_SUCCESS",
  ADD_MATERIAL_FAILURE: "ADD_MATERIAL_FAILURE",
  GET_MATERIALS_START: "GET_MATERIALS_START",
  GET_MATERIALS_SUCCESS: "GET_MATERIALS_SUCCESS",
  GET_MATERIALS_FAILURE: "GET_MATERIALS_FAILURE",
  DELETE_MATERIAL_START: "DELETE_MATERIAL_START",
  DELETE_MATERIAL_SUCCESS: "DELETE_MATERIAL_SUCCESS",
  DELETE_MATERIAL_FAILURE: "DELETE_MATERIAL_FAILURE",
  EDIT_MATERIAL_START: "EDIT_MATERIAL_START",
  EDIT_MATERIAL_SUCCESS: "EDIT_MATERIAL_SUCCESS",
  EDIT_MATERIAL_FAILURE: "EDIT_MATERIAL_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default MaterialsActionTypes;
