import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";

import ActsActionTypes from "./acts.types";
import {
  addActSuccess,
  addActFailure,
  getActsSuccess,
  getActsFailure,
  editActSuccess,
  editActFailure,
  deleteActSuccess,
  deleteActFailure,
  getActsStart,
} from "./acts.actions";
import { getRevisionsStart } from "../revisions/revisions.actions";
import { getClaimsStart } from "../claims/claims.actions";
import { getSingleSubsectionStart } from "../subsections/subsections.actions";

export function* addDocument(document, actId, subsectionId, currentUserId) {
  let formData = new FormData();
  let uniqueDocName =
    Math.floor(Math.random() * 1000) + document.name.slice(-35);
  formData.append("actDocument", document, uniqueDocName);
  yield axios.post(
    `/api/acts/document/${currentUserId}/${actId}/${subsectionId}`,
    formData
  );
}

export function* addPhoto(photo, actId, subsectionId, currentUserId) {
  let formData = new FormData();
  let uniqueImgName = Math.floor(Math.random() * 1000) + photo.name.slice(-35);
  formData.append("actImg", photo, uniqueImgName);
  yield axios.post(
    `/api/acts/img/${currentUserId}/${actId}/${subsectionId}`,
    formData
  );
}

export function* addAct({ payload, callback }) {
  const { newActData, markerCoords, photos, currentUser, site_id } = payload;

  const postActData = {
    row: newActData,
    who: currentUser.user,
    subsection: newActData.subsection_id,
  };
  try {
    const response = yield axios.post("/api/acts/add", postActData);
    if (markerCoords.length) {
      const pointsData = {
        actPlanPointsValues: [],
        who: currentUser.user,
      };
      for (const i in markerCoords) {
        markerCoords[i].act_id = response.data.resultForRes.insertId;
        pointsData.actPlanPointsValues.push(Object.values(markerCoords[i]));
      }
      pointsData.actPlanPointsColumns = Object.keys(markerCoords[0]);
      yield axios.post("/api/act_plan_points/add", pointsData);
    }

    if (photos && photos.length) {
      yield all(
        photos.map((photo) =>
          call(
            addPhoto,
            photo,
            response.data.resultForRes.insertId,
            newActData.subsection_id,
            currentUser.user
          )
        )
      );
    }
    axios
      .post("/api/acts/mail_to_contractor", {
        who: currentUser.user,
        act: response.data.resultForRes.insertId,
      })
      .catch((e) => console.log(e.response));

    yield put(addActSuccess());
    yield put(getActsStart(currentUser, site_id));
    if (newActData.revision_id) {
      yield put(getRevisionsStart(currentUser, site_id));
    }
    if (newActData.claim_id) {
      yield put(getClaimsStart(currentUser, site_id));
    }
    yield put(
      getSingleSubsectionStart(
        currentUser.user,
        site_id,
        newActData.subsection_id
      )
    );
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addActFailure(error));
  }
}

export function* getActs({ payload: { currentUser, site_id } }) {
  try {
    const response = yield axios.get(
      `/api/act_form/${currentUser.user}/${site_id}`
    );
    yield put(getActsSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getActsFailure(error));
  }
}

export function* editAct(editDetails) {
  try {
    yield console.log("axios.put(", editDetails);
    yield put(editActSuccess());
  } catch (e) {
    yield put(
      editActFailure(e.response ? e.response.data.message : "Сеть недоступна")
    );
  }
}

export function* deleteAct(ActDetails) {
  try {
    yield console.log("axios.delete(", ActDetails);
    yield put(deleteActSuccess());
  } catch (e) {
    yield put(
      deleteActFailure(e.response ? e.response.data : "Сеть недоступна")
    );
  }
}

export function* onGetActsStart() {
  yield takeLeading(ActsActionTypes.GET_ACTS_START, getActs);
}

export function* onAddActStart() {
  yield takeLatest(ActsActionTypes.ADD_ACT_START, addAct);
}

export function* onEditActStart() {
  yield takeLatest(ActsActionTypes.EDIT_ACT_START, editAct);
}

export function* onDeleteActStart() {
  yield takeLeading(ActsActionTypes.DELETE_ACT_START, deleteAct);
}

export function* actsSagas(args) {
  yield all([
    call(onAddActStart),
    call(onGetActsStart),
    call(onEditActStart),
    call(onDeleteActStart),
  ]);
}
