import React, { useState, useEffect } from "react";
import {
  postponeRevisionStart,
  clearErrors,
} from "../../../redux/revisions/revisions.actions";
import { useHistory, useParams } from "react-router-dom";
import DatePicker from "react-date-picker";
import {
  concatDateTime,
  formatDate,
  getTime,
  validate,
} from "../../../utils/normalizeData";
import { useSelector, useDispatch } from "react-redux";
import InlineModal from "../../inlineModal";
import AddAct from "../acts/add-act";
import AddChecklist from "../checklists/add-checklist";
import AddClaim from "../claims/add-claim";
import { TextAreaInput } from "../../input-forms";

const RevisionResult = ({ close, revision }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const statuses = useSelector((state) => state.statuses.revisions);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetching = useSelector((state) => state.revisions.isFetching);
  const errorMessage = useSelector((state) => state.revisions.error);

  const [postpone, setPostpone] = useState(false);
  const [reason, setReason] = useState("");
  const [newDate, setNewDate] = useState(new Date(revision.date));
  const [newTime, setNewTime] = useState(getTime(revision.date));

  const [errors, setErrors] = useState({
    reason: { type: "required", error: null },
  });

  const { info, site_id, sub_id, action2 } = useParams();

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  const handleReasonChange = (e) => {
    console.log("check");
    if (errors.reason.error) {
      validate(
        e.target.value,
        () => {},
        () => {
          setErrors({ ...errors, reason: { type: "required", error: null } });
        },
        "required"
      );
    }
    setReason(e.target.value);
  };

  const handleDateChange = (date) => {
    const currentDate = new Date();
    if (date < currentDate) return setNewDate(currentDate);
    setNewDate(date);
  };

  const handleDateSubmit = () => {
    if (!reason) {
      return setErrors((errors) => ({
        ...errors,
        reason: { type: "required", error: "Обязательное поле" },
      }));
    }

    if (revision) {
      dispatch(
        postponeRevisionStart(
          {
            revision_id: revision.id,
            old_date: formatDate(revision.date, "withTime"),
            new_date: concatDateTime(newDate, newTime),
            reason,
            user_id: currentUser.user,
          },
          currentUser,
          site_id,
          close
        )
      );
    }
  };

  // const completeRevision = () => {
  //   if (revision) {
  //     let status_id;

  //     for (let id in statuses) {
  //       if (statuses[id] === "Пройдена") {
  //         status_id = Number(id);
  //         break;
  //       }
  //     }

  //     if (status_id) {
  //       dispatch(
  //         editRevisionStart(
  //           { status_id },
  //           {},
  //           currentUser,
  //           site_id,
  //           close,
  //           revision.id
  //         )
  //       );
  //     }
  //   }
  // };

  const path = `/main/sites/${site_id}`;
  const subPath = sub_id ? `/subsections/${sub_id}` : "";

  const closeActionModal = () =>
    history.push(`${path}${subPath}/revisions/${info}/revision_result`);

  const closeResult = () => history.push(`${path}${subPath}/revisions`);

  return (
    <div className="main">
      <div className="under_popup"></div>
      <div className="popup_small">
        <button className="close" onClick={close} />

        {action2 === "checklist" && (
          <InlineModal close={closeActionModal}>
            <AddChecklist closeResult={closeResult} rev_id={+info} />
          </InlineModal>
        )}

        {action2 === "act" && (
          <InlineModal close={closeActionModal}>
            <AddAct closeResult={closeResult} rev_id={+info} />
          </InlineModal>
        )}

        {action2 === "claim" && (
          <InlineModal close={closeActionModal}>
            <AddClaim closeResult={closeResult} rev_id={+info} />
          </InlineModal>
        )}

        {postpone ? (
          <>
            <div className="message">Перенести проверку</div>
            <TextAreaInput
              validationType="required"
              value={reason}
              onChange={handleReasonChange}
              err={errors.reason.error}
              label="Основание"
            />
            <div className="two_col">
              <div className="col">
                <div className="field">
                  <label>На дату</label>
                  <DatePicker
                    label="На дату"
                    onChange={handleDateChange}
                    value={newDate}
                    clearIcon={null}
                    format="dd-MM-yyyy"
                    calendarClassName="date-picker-calendar"
                    minDate={new Date(revision.date)}
                    locale="ru-RU"
                  />
                </div>
              </div>
              <div className="col">
                <div className="field">
                  <label>На время</label>
                  <input
                    type="time"
                    value={newTime}
                    onChange={(e) => setNewTime(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div>{errorMessage}</div>
            <div className="buttons">
              <button
                className="green"
                onClick={handleDateSubmit}
                disabled={isFetching}
              >
                Да
              </button>
              <button
                className="cancel"
                onClick={() => setPostpone(!postpone)}
                disabled={isFetching}
              >
                Нет
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="message">Результаты проверки</div>
            <div className="buttons sized">
              <button
                className="green"
                onClick={() =>
                  history.push(
                    `${path}${subPath}/revisions/${info}/revision_result/checklist`
                  )
                }
                disabled={
                  revision &&
                  statuses &&
                  statuses[revision.status_id] === "Пройдена"
                }
              >
                Чеклист
              </button>

              <button
                className="red"
                onClick={() =>
                  history.push(
                    `${path}${subPath}/revisions/${info}/revision_result/claim`
                  )
                }
                disabled={
                  revision &&
                  statuses &&
                  (statuses[revision.status_id] === "Пройдена" ||
                    statuses[revision.status_id] ===
                      "Не пройдена - создано предписание")
                }
              >
                Предписание
              </button>
            </div>

            <div className="buttons sized">
              <button
                className="blue"
                onClick={() => setPostpone(!postpone)}
                disabled={
                  revision &&
                  statuses &&
                  (statuses[revision.status_id] === "Пройдена" ||
                    statuses[revision.status_id] ===
                      "Не пройдена - создано предписание")
                }
              >
                Перенос
              </button>

              <button
                className="green"
                onClick={() =>
                  history.push(
                    `${path}${subPath}/revisions/${info}/revision_result/act`
                  )
                }
                disabled={
                  revision &&
                  statuses &&
                  statuses[revision.status_id] === "Пройдена"
                }
              >
                АОСР
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RevisionResult;
