import UserActionTypes from "./user.types";

export const signInStart = (userCredentials) => ({
  type: UserActionTypes.SIGN_IN_START,
  payload: userCredentials,
});

export const signInSuccess = (user) => {
  console.log("USER DATA: ", user);
  localStorage.setItem(
    "user",
    JSON.stringify({
      user: user.user,
      organization: user.organization,
      identifier: user.identifier,
      token: user.token,
      admin: user.admin,
      firstname: user.firstname,
      lastname: user.lastname,
      avatar: user.avatar,
    })
  );
  return {
    type: UserActionTypes.SIGN_IN_SUCCESS,
    payload: user,
  };
};

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const signOut = () => {
  localStorage.removeItem("user");
  return {
    type: UserActionTypes.SIGN_OUT_SUCCESS,
  };
};

export const getUsersStart = (currentUser) => ({
  type: UserActionTypes.GET_USERS_START,
  payload: currentUser,
});

export const getUsersSuccess = (usersArray) => ({
  type: UserActionTypes.GET_USERS_SUCCESS,
  payload: usersArray,
});

export const getUsersFailure = (error) => ({
  type: UserActionTypes.GET_USERS_FAILURE,
  payload: error,
});

export const addUserStart = (newUser, photo, admin, callback) => ({
  type: UserActionTypes.ADD_USER_START,
  payload: { newUser, photo, admin },
  callback,
});

export const addUserSuccess = () => ({
  type: UserActionTypes.ADD_USER_SUCCESS,
});

export const addUserFailure = (error) => ({
  type: UserActionTypes.ADD_USER_FAILURE,
  payload: error,
});

export const editUserStart = (
  userNewDetails,
  photo,
  userId,
  admin,
  callback
) => ({
  type: UserActionTypes.EDIT_USER_START,
  payload: { userNewDetails, photo, admin, userId },
  callback,
});

export const editUserSuccess = ({ admin, userId, userNewDetails }) => {
  let currentUser;
  if (admin.user === userId) {
    currentUser = {
      user: admin.user,
      organization: admin.organization,
      identifier: admin.identifier,
      token: admin.token,
      admin: admin.admin,
      firstname: userNewDetails.firstname,
      lastname: userNewDetails.lastname,
    };
    localStorage.setItem("user", JSON.stringify(currentUser));
  }
  return {
    type: UserActionTypes.EDIT_USER_SUCCESS,
    payload: { currentUser },
  };
};

export const editUserFailure = () => ({
  type: UserActionTypes.GET_USERS_FAILURE,
});

export const deleteUserStart = (userDeleteData, admin, callback) => ({
  type: UserActionTypes.DELETE_USER_START,
  payload: { userDeleteData, admin },
  callback,
});

export const deleteUserSuccess = () => ({
  type: UserActionTypes.DELETE_USER_SUCCESS,
});

export const deleteUserFailure = (error) => ({
  type: UserActionTypes.DELETE_USER_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: UserActionTypes.CLEAR_ERRORS,
});

export const recoverPasswordStart = (credentials) => ({
  type: UserActionTypes.RECOVER_PASSWORD_START,
  payload: credentials,
});

export const recoverPasswordSuccess = () => ({
  type: UserActionTypes.RECOVER_PASSWORD_SUCCESS,
});

export const recoverPasswordFailure = (error) => ({
  type: UserActionTypes.RECOVER_PASSWORD_FAILURE,
  payload: error,
});

export const resetPasswordStart = (passwordDetails, callback) => ({
  type: UserActionTypes.RESET_PASSWORD_START,
  payload: passwordDetails,
  callback,
});

export const resetPasswordSuccess = () => ({
  type: UserActionTypes.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (error) => ({
  type: UserActionTypes.RESET_PASSWORD_FAILURE,
  payload: error,
});

export const changePasswordStart = (oldPassword, newPassword, callback) => ({
  type: UserActionTypes.CHANGE_PASSWORD_START,
  payload: { oldPassword, newPassword },
  callback,
});

export const changePasswordSuccess = (newToken) => {
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const currentUser = {
    token: newToken,
    user: storedUser.user,
    organization: storedUser.organization,
    admin: storedUser.admin,
    firstname: storedUser.firstname,
    lastname: storedUser.lastname,
    identifier: storedUser.identifier,
  };
  localStorage.setItem("user", JSON.stringify(currentUser));

  return {
    type: UserActionTypes.CHANGE_PASSWORD_SUCCESS,
    payload: currentUser,
  };
};

export const changePasswordFailure = (error) => ({
  type: UserActionTypes.CHANGE_PASSWORD_FAILURE,
  payload: error,
});
