import MaterialsActionTypes from "./materials.types";

const INITIAL_STATE = {
  isAdding: false,
  isFetching: false,
  isDeleting: false,
  error: null,
  materials: null,
};

const materialsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MaterialsActionTypes.ADD_MATERIAL_START:
    case MaterialsActionTypes.EDIT_MATERIAL_START:
      return { ...state, isAdding: true, error: null };
    case MaterialsActionTypes.GET_MATERIALS_START:
      return { ...state, isFetching: true, error: null };
    case MaterialsActionTypes.DELETE_MATERIAL_START:
      return {
        ...state,
        isDeleting: true,
        error: null,
      };

    case MaterialsActionTypes.ADD_MATERIAL_SUCCESS:
    case MaterialsActionTypes.EDIT_MATERIAL_SUCCESS:
      return { ...state, isAdding: false, error: null };
    case MaterialsActionTypes.DELETE_MATERIAL_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        error: null,
      };

    case MaterialsActionTypes.GET_MATERIALS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        materials: action.payload,
      };

    case MaterialsActionTypes.ADD_MATERIAL_FAILURE:
    case MaterialsActionTypes.EDIT_MATERIAL_FAILURE:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      };

    case MaterialsActionTypes.GET_MATERIALS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case MaterialsActionTypes.DELETE_MATERIAL_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };

    case MaterialsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default materialsReducer;
