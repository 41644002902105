import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError, isEmpty } from "../../utils/normalizeData";

import WorksActionTypes from "./works.types";

import {
  addGroupSuccess,
  addGroupFailure,
  addWorkSuccess,
  addWorkFailure,
  addWorkResultSuccess,
  addWorkResultFailure,
  getWorksStart,
  getWorksSuccess,
  getWorksFailure,
  getWorksBySubSuccess,
  getWorksBySubFailure,
  getWorksByUserSuccess,
  getWorksByUserFailure,
  editWorkSuccess,
  editWorkFailure,
  deleteWorkSuccess,
  deleteWorkFailure,
  deleteGroupSuccess,
  deleteGroupFailure,
  getGroupsSuccess,
  getGroupsFailure,
  getGroupsStart,
  getSingleWorkSuccess,
  getSingleWorkFailure,
  editGroupSuccess,
  editGroupFailure,
  getWorkClaimsSuccess,
  getWorkClaimsFailure,
  getWorkRemarksSuccess,
  getWorkRemarksFailure,
  getWorksTreeSuccess,
  getWorksTreeFailure,
} from "./works.actions";
// import { getSubsectionsStart } from "../subsections/subsections.actions";

export function* addGroup({ payload, callback }) {
  const { groupName, currentUser, parentGroupId, site_id } = payload;
  const groupData = {
    row: {
      name: groupName,
      obj_id: site_id,
    },
    who: currentUser.user,
  };
  if (parentGroupId) {
    groupData.row.job_group_id = parentGroupId;
  }
  try {
    yield axios.post("/api/job_groups/add", groupData);
    yield put(addGroupSuccess());
    yield put(getGroupsStart(currentUser, site_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addGroupFailure(error));
  }
}

export function* getGroups({ payload }) {
  const { currentUser, site_id } = payload;
  try {
    const response = yield axios.get(
      `/api/job_groups/${currentUser.user}/${site_id}`
    );
    yield put(getGroupsSuccess(Object.values(response.data)));
  } catch (e) {
    yield put(getGroupsFailure());
  }
}

export function* addWork({ payload, callback }) {
  const {
    newWorkData,
    variables,
    resources,
    currentUser,
    site_id,
    markerCoords,
  } = payload;
  const postWorkData = {
    jobsColumns: Object.keys(newWorkData),
    jobsValues: [Object.values(newWorkData)],
    who: currentUser.user,
  };

  if (newWorkData.card_id) {
    postWorkData.jobVariablesColumns = ["variable_id", "value"];
    postWorkData.jobVariablesValues = variables;
  }

  if (resources.specialties.length) {
    postWorkData.employeeColumns = ["employee_id", "qty"];
    postWorkData.employeeValues = resources.specialties;
  }
  if (resources.equipment.length) {
    postWorkData.equipmentColumns = ["equipment_id", "qty"];
    postWorkData.equipmentValues = resources.equipment;
  }
  if (resources.materials.length) {
    postWorkData.materialColumns = ["material_id", "qty"];
    postWorkData.materialValues = resources.materials;
  }

  try {
    const response = yield axios.post("/api/jobs/add", postWorkData);
    // yield put(getSubsectionsStart(currentUser, site_id));

    if (markerCoords.length) {
      const pointsData = {
        jobPlanPointsValues: [],
        who: currentUser.user,
      };
      for (const i in markerCoords) {
        markerCoords[i].job_id = response.data.resSend.insertId;
        pointsData.jobPlanPointsValues.push(Object.values(markerCoords[i]));
      }
      pointsData.jobPlanPointsColumns = Object.keys(markerCoords[0]);
      yield axios.post("/api/job_plan_points/add", pointsData);
    }

    yield put(addWorkSuccess());
    yield put(getWorksStart(newWorkData.job_group_id, currentUser, site_id));

    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addWorkFailure(error));
  }
}

export function* addPhoto(photo, resultId, workId, currentUserId) {
  let formData = new FormData();
  let uniqueImgName = Math.floor(Math.random() * 1000) + photo.name.slice(-35);
  formData.append("jobRevisionImg", photo, uniqueImgName);
  yield axios.post(
    `/api/job_revisions/img/${currentUserId}/${resultId}/${workId}`,
    formData
  );
}

export function* addWorkResult({ payload, callback }) {
  const { resultData, currentUser, photos } = payload;
  try {
    const response = yield axios.post("/api/job_revisions/add", {
      row: resultData,
      who: currentUser.user,
    });
    if (photos && photos.length) {
      yield all(
        photos.map((photo) =>
          call(
            addPhoto,
            photo,
            response.data.insertId,
            resultData.job_id,
            currentUser.user
          )
        )
      );
    }
    yield put(addWorkResultSuccess());
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addWorkResultFailure(error));
  }
}

export function* getWorks({ payload }) {
  const { job_group_id, currentUser, site_id } = payload;
  try {
    let response;
    if (!job_group_id) {
      response = yield axios.get(
        `/api/only_obj_jobs/${currentUser.user}/${site_id}`
      );
    } else {
      response = yield axios.get(
        `/api/group_jobs/${currentUser.user}/${job_group_id}`
      );
    }
    yield put(getWorksSuccess(job_group_id, Object.values(response.data)));
  } catch (e) {
    console.log(e);
    yield put(getWorksFailure(job_group_id, "Ошибка сети"));
  }
}

export function* getWorksTree({ payload }) {
  const { user_id, site_id } = payload;

  try {
    const response = yield axios.get(
      `/api/job_tree_by_obj/${user_id}/${site_id}`
    );
    yield put(getWorksTreeSuccess(response.data));
  } catch (e) {
    console.log(e);
    const error = handleError(e);
    yield put(getWorksTreeFailure(error));
  }
}

export function* getWorksBySub({ payload }) {
  const { sub_id, currentUserId } = payload;
  try {
    const response = yield axios.get(
      `/api/sub_jobs/${currentUserId}/${sub_id}`
    );
    yield put(getWorksBySubSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getWorksBySubFailure(error));
  }
}

export function* getWorksByUser({ payload }) {
  const { currentUserId, site_id } = payload;

  try {
    const response = yield axios.get(
      `/api/user_jobs/${currentUserId}/${currentUserId}/${site_id}`
    );
    yield put(getWorksByUserSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getWorksByUserFailure(error));
  }
}

export function* getSingleWork({ payload }) {
  const { work_id, currentUserId } = payload;
  try {
    const response = yield axios.get(`/api/jobs/${currentUserId}/${work_id}/`);
    if (isEmpty(response.data)) {
      return yield put(getSingleWorkFailure("Не найден"));
    }
    yield put(getSingleWorkSuccess(...Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getSingleWorkFailure(error));
  }
}

export function* getWorkClaims({ payload }) {
  try {
    const response = yield axios.get(
      `/api/claim_by_job/${payload.currentUserId}/${payload.workId}`
    );
    yield put(getWorkClaimsSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getWorkClaimsFailure(error));
  }
}

export function* getWorkRemarks({ payload }) {
  try {
    const response = yield axios.get(
      `/api/job_note_by_job/${payload.currentUserId}/${payload.workId}`
    );
    yield put(getWorkRemarksSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getWorkRemarksFailure(error));
  }
}

export function* editGroup({ payload, callback }) {
  const { groupId, groupName, currentUser, site_id } = payload;

  const editGroupData = {
    row: {
      name: groupName,
    },
    who: currentUser.user,
    job_group: groupId,
  };
  try {
    yield axios.put("/api/job_groups/edit", editGroupData);
    yield put(editGroupSuccess());
    yield put(getGroupsStart(currentUser, site_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editGroupFailure(error));
  }
}

export function* editWork({ payload }) {
  const {
    newWorkData,
    variables,
    resources,
    currentUser,
    site_id,
    callback,
    work_id,
    markerCoords,
    coordsToDelete,
  } = payload;

  const editWorkData = {
    job: newWorkData,
    who: currentUser.user,
    job_id: work_id,
  };

  editWorkData.employeeColumns = ["employee_id", "qty"];
  editWorkData.employeeValues = resources.specialties;

  editWorkData.equipmentColumns = ["equipment_id", "qty"];
  editWorkData.equipmentValues = resources.equipment;

  editWorkData.materialColumns = ["material_id", "qty"];
  editWorkData.materialValues = resources.materials;

  if (newWorkData.card_id) {
    editWorkData.jobVariablesColumns = ["variable_id", "value"];
    editWorkData.jobVariablesValues = variables;
  }

  try {
    yield axios.put("/api/jobs/edit", editWorkData);

    const newPointsToSubmit = [];
    const pointsToEdit = [];

    for (const i in markerCoords) {
      if (markerCoords[i].id) {
        if (markerCoords[i].changed) {
          pointsToEdit.push({
            row: {
              dot_x: markerCoords[i].dot_x,
              dot_y: markerCoords[i].dot_y,
            },
            job_plan_point: markerCoords[i].id,
          });
        }
      } else {
        newPointsToSubmit.push(markerCoords[i]);
      }
    }

    if (newPointsToSubmit.length) {
      const pointsData = {
        jobPlanPointsValues: [],
        who: currentUser.user,
      };
      for (const i in newPointsToSubmit) {
        newPointsToSubmit[i].job_id = work_id;
        pointsData.jobPlanPointsValues.push(
          Object.values(newPointsToSubmit[i])
        );
      }
      pointsData.jobPlanPointsColumns = Object.keys(newPointsToSubmit[0]);
      yield axios.post("/api/job_plan_points/add", pointsData);
    }

    if (pointsToEdit.length) {
      yield axios.put("/api/job_plan_points/edit", {
        rows: pointsToEdit,
        who: currentUser.user,
      });
    }

    if (coordsToDelete && coordsToDelete.length) {
      const idsToDelete = [];
      for (const i in coordsToDelete) {
        idsToDelete.push(coordsToDelete[i].id);
      }
      axios.delete("/api/job_plan_points/delete", {
        data: { who: currentUser.user, id: idsToDelete },
      });
    }

    yield put(editWorkSuccess());
    yield put(getWorksStart(editWorkData.job_group_id, currentUser, site_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editWorkFailure(error));
  }
}

export function* deleteGroup({ payload, callback }) {
  const { groupId, currentUser, site_id } = payload;

  const deleteData = {
    who: currentUser.user,
    id: groupId,
  };
  try {
    yield axios.delete("/api/job_groups/delete", { data: deleteData });
    yield put(deleteGroupSuccess());
    yield put(getGroupsStart(currentUser, site_id));
    yield callback();
  } catch (e) {
    let error = "Ошибка сети";
    try {
      error = e.response.data.message || "Ошибка сети";
    } catch {}
    yield put(deleteGroupFailure(error));
  }
}

export function* deleteWork({ payload, callback }) {
  const { work_id, currentUser, site_id, group_id } = payload;

  const deleteData = {
    who: currentUser.user,
    id: Number(work_id),
  };

  try {
    yield axios.delete("/api/jobs/delete", { data: deleteData });
    yield put(deleteWorkSuccess());
    yield put(getWorksStart(group_id, currentUser, site_id));
    yield callback();
  } catch (e) {
    let error = "Ошибка сети";
    try {
      error = e.response.data.message || "Ошибка сети";
    } catch {}
    yield put(deleteWorkFailure(error));
  }
}

export function* onAddGroupStart() {
  yield takeLatest(WorksActionTypes.ADD_GROUP_START, addGroup);
}

export function* onGetGroupsStart() {
  yield takeLatest(WorksActionTypes.GET_GROUPS_START, getGroups);
}

export function* onAddWorkStart() {
  yield takeLatest(WorksActionTypes.ADD_WORK_START, addWork);
}

export function* onAddWorkResultStart() {
  yield takeLatest(WorksActionTypes.ADD_WORK_RESULT_START, addWorkResult);
}

export function* onGetWorksStart() {
  yield takeLeading(WorksActionTypes.GET_WORKS_START, getWorks);
}

export function* onGetWorksTreeStart() {
  yield takeLeading(WorksActionTypes.GET_WORKS_TREE_START, getWorksTree);
}

export function* onGetWorksBySub() {
  yield takeLeading(WorksActionTypes.GET_WORKS_BY_SUB_START, getWorksBySub);
}

export function* onGetWorksByUser() {
  yield takeLeading(WorksActionTypes.GET_WORKS_BY_USER_START, getWorksByUser);
}

export function* onGetSingleWorkStart() {
  yield takeLeading(WorksActionTypes.GET_SINGLE_WORK_START, getSingleWork);
}

export function* onEditWorkStart() {
  yield takeLatest(WorksActionTypes.EDIT_WORK_START, editWork);
}

export function* onEditGroupStart() {
  yield takeLatest(WorksActionTypes.EDIT_GROUP_START, editGroup);
}

export function* onDeleteWorkStart() {
  yield takeLeading(WorksActionTypes.DELETE_WORK_START, deleteWork);
}

export function* onDeleteGroupStart() {
  yield takeLeading(WorksActionTypes.DELETE_GROUP_START, deleteGroup);
}

export function* onGetWorkClaimsStart() {
  yield takeLeading(WorksActionTypes.GET_WORK_CLAIMS_START, getWorkClaims);
}

export function* onGetWorkRemarksStart() {
  yield takeLeading(WorksActionTypes.GET_WORK_REMARKS_START, getWorkRemarks);
}

export function* worksSagas(args) {
  yield all([
    call(onAddGroupStart),
    call(onGetGroupsStart),
    call(onAddWorkStart),
    call(onAddWorkResultStart),
    call(onGetWorksStart),
    call(onGetWorksTreeStart),
    call(onGetWorksBySub),
    call(onGetWorksByUser),
    call(onGetSingleWorkStart),
    call(onGetWorkClaimsStart),
    call(onGetWorkRemarksStart),
    call(onEditWorkStart),
    call(onEditGroupStart),
    call(onDeleteWorkStart),
    call(onDeleteGroupStart),
    call(onGetGroupsStart),
  ]);
}
