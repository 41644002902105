import React from "react";
import { Route, Switch } from "react-router";
import ClaimContractor from "./claim-contractor";

const ContractorRoutes = ({ match }) => {
  return (
    <div className="contractor">
      <div className="header_contractor">
        <h1>Под Контролем</h1>
        <h2>Предписание</h2>
      </div>
      <Switch>
        <Route
          exact
          path={`${match.path}/claims/:token`}
          component={ClaimContractor}
        />
      </Switch>
    </div>
  );
};

export default ContractorRoutes;
