import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleWorkStart,
  getWorkClaimsStart,
  getWorkRemarksStart,
} from "../../../redux/works/works.actions";
import { useHistory, useParams } from "react-router";
import { formatDate, isEmpty } from "../../../utils/normalizeData";
import { Link } from "react-router-dom";
import InlineModal from "../../inlineModal";
import ConfirmDeleteModal from "../../confirmActionModal";
import CheckWork from "./check-work";
import AddRemark from "../remarks/add-remark";
import AddWorkClaim from "./add-work-claim";
import AddWork from "./add-work";
import { deleteWorkStart } from "../../../redux/works/works.actions";
import GetPointOnPlan from "../pointsOnPlan/get-points";

const WorkInfo = ({ work_id }) => {
  const { site_id, sub_id, action } = useParams();
  const dispatch = useDispatch();
  const currentWork = useSelector((state) => state.works.currentWork);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetching = useSelector((state) => state.works.isFetching);
  const errorMessage = useSelector((state) => state.works.error);
  const subsections = useSelector((state) => state.subsections.subsections);
  const contractors = useSelector((state) => state.contractors.contractors);
  const workClaims = useSelector((state) => state.works.workClaims);
  const workRemarks = useSelector((state) => state.works.workRemarks);
  const statuses = useSelector((state) => state.statuses);
  const isFetchingWorkActions = useSelector(
    (state) => state.works.isFetchingWorkActions
  );
  const users = useSelector((state) => state.user.usersList);
  const [subsectionToRender, setSubsectionToRender] = useState();
  const [plan, togglePlan] = useState(false);
  const [contractorToRender, setContractorToRender] = useState();
  const [responsibleUser, setResponsibleUser] = useState();
  const [confirmDelete, setConfirmDelete] = useState();

  useEffect(() => {
    if (!currentWork || String(currentWork.id) !== work_id) {
      dispatch(getSingleWorkStart(currentUser.user, work_id));
    } else {
      const subsection =
        subsections &&
        subsections.find(
          (subsection) => subsection.id === currentWork.subsection_id
        );
      if (subsection) setSubsectionToRender(subsection);
      if (currentWork.contractor_id) {
        const contractor =
          contractors &&
          contractors.find(
            (contractor) => contractor.id === currentWork.contractor_id
          );
        if (contractor) setContractorToRender(contractor);
        if (
          !workClaims ||
          (workClaims.length && workClaims[0].job_id !== currentWork.id)
        ) {
          dispatch(getWorkClaimsStart(currentUser.user, currentWork.id));
        }
      } else if (
        !workRemarks
        // (workRemarks.length && workRemarks[0].job_id !== currentWork.id)
      ) {
        dispatch(getWorkRemarksStart(currentUser.user, currentWork.id));
      }
      const userToRender =
        users && users.find((user) => user.id === currentWork.user_id);
      if (userToRender) setResponsibleUser(userToRender);
    }
  }, [
    currentWork,
    currentUser.user,
    dispatch,
    work_id,
    subsections,
    contractors,
    users,
    workClaims,
    workRemarks,
  ]);

  const renderSpecialties = (specialties) => {
    let itemsToRender = [];

    for (const id in specialties) {
      itemsToRender.push(
        <span className="cols" key={id}>
          <span>{specialties[id].specialty}</span>
          <span>{`${specialties[id].qty} чел.`}</span>
        </span>
      );
    }
    return itemsToRender;
  };

  const renderEquipment = (equipment) => {
    let itemsToRender = [];

    for (const id in equipment) {
      itemsToRender.push(
        <span className="cols" key={id}>
          <span>{equipment[id].equipment}</span>
          <span>{`${equipment[id].qty} шт.`}</span>
        </span>
      );
    }
    return itemsToRender;
  };

  const renderMaterials = (materials) => {
    let itemsToRender = [];

    for (const id in materials) {
      itemsToRender.push(
        <span className="cols" key={id}>
          <span>{materials[id].material}</span>
          <span>{`${materials[id].qty} ${materials[id].unit}`}</span>
        </span>
      );
    }
    return itemsToRender;
  };

  const history = useHistory();

  let path = `/main/sites/${site_id}`;
  if (sub_id) path += `/subsections/${sub_id}`;

  const openClaim = () => {
    if (currentWork && currentWork.contractor_id) {
      return history.push(`${path}/works/${work_id}/add_claim`);
    } else history.push(`${path}/works/${work_id}/add_remark`);
  };

  const handleDelete = () => {
    const callback = () => history.push(`${path}/works`);
    if (currentWork) {
      dispatch(
        deleteWorkStart(
          { work_id, currentUser, site_id, group_id: currentWork.job_group_id },
          callback
        )
      );
    }
  };

  if (isFetching) return <div className="info">Загрузка данных...</div>;
  else if (currentWork && String(currentWork.id) === work_id)
    return (
      <>
        <div className="content">
          <div className="two_col">
            <div className="col">
              <h2>Работа</h2>

              <div className="field">
                <label>Название</label>
                <span className="data">{currentWork.name}</span>
              </div>

              <div className="two_col">
                <div className="col">
                  <div className="field">
                    <label>Дата начала</label>
                    <span className="data">
                      {formatDate(currentWork.date_start, true)}
                    </span>
                  </div>
                </div>
                <div className="col">
                  <div className="field">
                    <label>Дата завершения</label>
                    {formatDate(currentWork.date_end, true)}
                  </div>
                </div>
              </div>

              {!isEmpty(currentWork.prev_job) && (
                <div className="field">
                  <label>Идет после другой работы: </label>
                  <Link to={`${path}/works/${currentWork.prev_job.id}`}>
                    {currentWork.prev_job.name}
                  </Link>
                </div>
              )}

              {subsectionToRender && (
                <>
                  <div className="two_col_field">
                    <label>Подраздел</label>

                    <span className="cols">
                      <span>{subsectionToRender.name}</span>
                      <Link to="#" onClick={() => togglePlan(!plan)}>
                        {`${plan ? "Скрыть" : "Показать"} планы`}
                      </Link>
                    </span>
                  </div>

                  {plan && (
                    <GetPointOnPlan
                      subsection={subsectionToRender}
                      markerType="work"
                      work_id={currentWork.id}
                    />
                  )}
                </>
              )}

              {responsibleUser && (
                <div className="field">
                  <label>Ответственное лицо: </label>
                  <span className="data">
                    {responsibleUser
                      ? `${responsibleUser.firstname} ${responsibleUser.lastname} (${responsibleUser.email})`
                      : ""}
                  </span>
                </div>
              )}

              {contractorToRender && (
                <div className="field">
                  <label>Подрядчик: </label>
                  <span className="data">{contractorToRender.name}</span>
                </div>
              )}

              {(!isEmpty(currentWork.job_variables) ||
                currentWork.hours_in_day) && (
                <div className="two_col_field">
                  <label>Переменные: </label>

                  {currentWork.hours_in_day && (
                    <span className="cols">
                      <span>Рабочих часов в дне: </span>
                      <span>{currentWork.hours_in_day}</span>
                    </span>
                  )}

                  {Object.values(currentWork.job_variables).map(
                    (variable, i) => (
                      <span className="cols" key={i}>
                        <span>{variable.name_print}: </span>
                        <span>{variable.value}</span>
                      </span>
                    )
                  )}
                </div>
              )}

              {!isEmpty(currentWork.job_employees) && (
                <div className="two_col_field">
                  <label>Необходимые работники</label>

                  {renderSpecialties(currentWork.job_employees)}
                </div>
              )}

              {!isEmpty(currentWork.job_equipment) && (
                <div className="two_col_field">
                  <label>Необходимое оборудование</label>
                  <div>{renderEquipment(currentWork.job_equipment)}</div>
                </div>
              )}

              {!isEmpty(currentWork.job_materials) && (
                <div className="two_col_field">
                  <label>Необходимые материалы</label>
                  <div>{renderMaterials(currentWork.job_materials)}</div>
                </div>
              )}

              {currentWork.descriptions && (
                <>
                  <div>Описание:</div>
                  <div>{currentWork.descriptions}</div>
                </>
              )}
            </div>

            <div className="col">
              <div className="job_buttons">
                <button
                  className="green"
                  onClick={() =>
                    history.push(`${path}/works/${work_id}/check_work`)
                  }
                >
                  проверка по окончании
                </button>
                <button className="red" onClick={openClaim}>
                  {`создать ${
                    currentWork.contractor_id
                      ? "предписание"
                      : currentWork.user_id
                      ? "замечание"
                      : ""
                  } `}
                </button>
              </div>

              {currentWork && (
                <>
                  <h3>
                    {currentWork.contractor_id &&
                    workClaims &&
                    workClaims.length
                      ? "Предписания по работе"
                      : currentWork.user_id && workRemarks && workRemarks.length
                      ? "Замечания по работе"
                      : ""}
                  </h3>

                  {isFetchingWorkActions && (
                    <div className="info">"Загрузка..."</div>
                  )}

                  {currentWork.contractor_id &&
                    workClaims &&
                    workClaims.map((workClaim) => (
                      <div className="job_desc" key={workClaim.id}>
                        <span className="date">
                          {formatDate(workClaim.date_created, true)}
                        </span>
                        <span className="status">
                          {statuses.claims[workClaim.status_id]}
                        </span>
                        <span className="name">{workClaim.title}</span>
                        <span className="desc">{workClaim.descriptions}</span>
                      </div>
                    ))}

                  {currentWork.user_id &&
                    workRemarks &&
                    workRemarks.map((workRemark) => (
                      <div className="job_desc" key={workRemark.id}>
                        <span className="date">
                          {formatDate(workRemark.date_created, true)}
                        </span>
                        <span className="status">
                          {statuses.remarks &&
                            statuses.remarks[workRemark.status_id]}
                        </span>
                        <span className="desc">{workRemark.descriptions}</span>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>

          <div className="buttons">
            {/* <Link to={`/main/sites/${site_id}/works/edit_work/${work_id}`}> */}
            <button
              className="blue"
              onClick={() => history.push(`${path}/works/${work_id}/edit_work`)}
            >
              Редактировать
            </button>
            {/* </Link> */}
            {/* <button className="link">Переместить в другую группу</button> */}
            <button className="remove" onClick={() => setConfirmDelete(true)} />

            {confirmDelete && (
              <ConfirmDeleteModal
                message="Удалить работу?"
                confirm={handleDelete}
                cancel={() => setConfirmDelete(false)}
                processing={isFetching}
                errorMsg={errorMessage}
              />
            )}
          </div>
        </div>

        {action === "edit_work" &&
          currentWork &&
          String(currentWork.id) === work_id && (
            <InlineModal close={() => history.push(`${path}/works/${work_id}`)}>
              <AddWork
                close={() => history.push(`${path}/works/${work_id}`)}
                work_id={currentWork.id}
                group_id={currentWork.job_group_id}
              />
            </InlineModal>
          )}

        {currentWork && action === "check_work" && (
          <InlineModal close={() => history.push(`${path}/works/${work_id}`)}>
            <CheckWork
              close={() => history.push(`${path}/works/${work_id}`)}
              work_id={currentWork.id}
            />
          </InlineModal>
        )}

        {currentWork && action === "add_remark" && (
          <InlineModal close={() => history.push(`${path}/works/${work_id}`)}>
            <AddRemark
              close={() => history.push(`${path}/works/${work_id}`)}
              work_id={currentWork.id}
            />
          </InlineModal>
        )}

        {currentWork && action === "add_claim" && (
          <InlineModal close={() => history.push(`${path}/works/${work_id}`)}>
            <AddWorkClaim
              close={() => history.push(`${path}/works/${work_id}`)}
              work_id={currentWork.id}
            />
          </InlineModal>
        )}
      </>
    );
  else if (errorMessage) return <div className="info err">{errorMessage}</div>;
  else return "";
};

export default WorkInfo;
