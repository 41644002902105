const ContractorsActionTypes = {
  ADD_CONTRACTOR_START: "ADD_CONTRACTOR_START",
  ADD_CONTRACTOR_SUCCESS: "ADD_CONTRACTOR_SUCCESS",
  ADD_CONTRACTOR_FAILURE: "ADD_CONTRACTOR_FAILURE",
  GET_CURRENT_CONTRACTOR_START: "GET_CURRENT_CONTRACTOR_START",
  GET_CURRENT_CONTRACTOR_SUCCESS: "GET_CURRENT_CONTRACTOR_SUCCESS",
  GET_CURRENT_CONTRACTOR_FAILURE: "GET_CURRENT_CONTRACTOR_FAILURE",
  GET_CONTRACTORS_START: "GET_CONTRACTORS_START",
  GET_ALL_CONTRACTORS_START: "GET_ALL_CONTRACTORS_START",
  GET_ALL_CONTRACTORS_SUCCESS: "GET_ALL_CONTRACTORS_SUCCESS",
  GET_ALL_CONTRACTORS_FAILURE: "GET_ALL_CONTRACTORS_FAILURE",
  GET_CONTRACTORS_SUCCESS: "GET_CONTRACTORS_SUCCESS",
  GET_CONTRACTORS_FAILURE: "GET_CONTRACTORS_FAILURE",
  DELETE_CONTRACTOR_START: "DELETE_CONTRACTOR_START",
  DELETE_CONTRACTOR_SUCCESS: "DELETE_CONTRACTOR_SUCCESS",
  DELETE_CONTRACTOR_FAILURE: "DELETE_CONTRACTOR_FAILURE",
  EDIT_CONTRACTOR_START: "EDIT_CONTRACTOR_START",
  EDIT_CONTRACTOR_SUCCESS: "EDIT_CONTRACTOR_SUCCESS",
  EDIT_CONTRACTOR_FAILURE: "EDIT_CONTRACTOR_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default ContractorsActionTypes;
