import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  getCurrentTechCardStart,
} from "../../redux/techCards/techCards.actions";
import { useCardViewStyles } from "../../../css/admin/main-section.styles";
import LinkButton from "../../../components/buttons/link-button";
import DeletePrompt from "./DeletePrompt";

const ViewCard = ({
  cardIdToView,
  setCardIdToView,
  cardIdToEdit,
  setCardIdToEdit,
}) => {
  const classes = useCardViewStyles();

  const currentCard = useSelector((state) => state.techCards.currentCard);
  const isFetching = useSelector((state) => state.techCards.isFetchingACard);
  const errorMsg = useSelector((state) => state.techCards.error);
  console.log("CURRENT CARD: ", currentCard);
  const [confirmDelete, setConfirmDelete] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (!currentCard || currentCard.id !== cardIdToView) {
      dispatch(getCurrentTechCardStart(cardIdToView));
    }
  }, [currentCard, cardIdToView, dispatch]);

  if (errorMsg) return <div className={classes.errorMsg}>{errorMsg}</div>;
  if (!currentCard || isFetching) return <div>загрузка данных...</div>;

  const renderVariables = (variables) => {
    const renderArr = [];
    for (const i in variables) {
      renderArr.push(
        <li
          key={i}
        >{`${variables[i].variable} (${variables[i].name_print})`}</li>
      );
    }
    return (
      <div>
        <h2>Выбранные переменные: </h2>
        <ul>{renderArr}</ul>
      </div>
    );
  };

  const renderSpecialties = (specialties) => {
    const specArr = [];

    for (const i in specialties) {
      specArr.push(
        <tr key={i}>
          <td>{specialties[i].specialty}</td>
          <td>{specialties[i].man_hour}</td>
          <td>{specialties[i].min_qty}</td>
          <td>{specialties[i].variable_qty}</td>
        </tr>
      );
    }

    if (specArr.length) {
      return (
        <div>
          <h2>Работники: </h2>
          <table>
            <thead>
              <tr>
                <th>Специализации</th>
                <th>человекочасы</th>
                <th>мин кол-во человек</th>
                <th>переменная кол-ва работников</th>
              </tr>
            </thead>
            <tbody>{specArr}</tbody>
          </table>
        </div>
      );
    }
    return "";
  };

  const renderEquipment = (equipment) => {
    const equipmentArr = [];
    for (const i in equipment) {
      equipmentArr.push(
        <tr key={i}>
          <td>{equipment[i].equipment}</td>
          <td>{equipment[i].qty}</td>
        </tr>
      );
    }
    if (equipmentArr.length) {
      return (
        <div>
          <h2>Оборудование: </h2>
          <table>
            <thead>
              <tr>
                <th>Вид оборудования</th>
                <th>количество</th>
              </tr>
            </thead>
            <tbody>{equipmentArr}</tbody>
          </table>
        </div>
      );
    }
    return "";
  };

  const renderMaterials = (materials) => {
    const materialsArr = [];

    for (const i in materials) {
      materialsArr.push(
        <tr key={i}>
          <td>{materials[i].material}</td>
          <td>{materials[i].qty}</td>
        </tr>
      );
    }
    if (materialsArr.length) {
      return (
        <div>
          <h2>Материалы: </h2>
          <table>
            <thead>
              <tr>
                <th>Вид материалов</th>
                <th>количество</th>
              </tr>
            </thead>
            <tbody>{materialsArr}</tbody>
          </table>
        </div>
      );
    }
    return "";
  };

  const handleEdit = (cardId) => {
    setCardIdToView(null);
    setCardIdToEdit(cardId);
  };

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <h1>{currentCard.name}</h1>
        <div className={classes.actionBtns}>
          <LinkButton onClick={() => handleEdit(currentCard.id)}>
            Редактировать
          </LinkButton>
          <LinkButton
            deletestyle="true"
            onClick={() =>
              setConfirmDelete({ data: currentCard, type: "card" })
            }
          >
            Удалить
          </LinkButton>
        </div>
      </div>
      {renderVariables(currentCard.card_variables)}

      <h2>Обязательные переменные: </h2>

      <ul>
        <li>DH - Рабочих часов в дне</li>
      </ul>

      {renderSpecialties(currentCard.card_employees)}

      {renderEquipment(currentCard.card_equipment)}

      {renderMaterials(currentCard.card_materials)}

      {confirmDelete && (
        <DeletePrompt
          confirmDelete={confirmDelete}
          cardIdToView={cardIdToView}
          setCardIdToView={setCardIdToView}
          cardIdToEdit={cardIdToEdit}
          setCardIdToEdit={setCardIdToEdit}
          groupIdForCard={currentCard.card_group_id}
          close={() => setConfirmDelete(false)}
        />
      )}
    </div>
  );
};

export default ViewCard;
