import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { InputField } from "../../input-forms";
import {
  addSubsectionGroupStart,
  editSubsectionGroupStart,
} from "../../../redux/subsections/subsections.actions";
import { validate } from "../../../utils/normalizeData";

const AddSubsectionGroup = ({
  close,
  checkInputs,
  checkInputsAndClose,
  groupToEdit,
  groupId,
}) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetching = useSelector((state) => state.subsections.isFetching);
  const errorMessage = useSelector((state) => state.subsections.error);
  const dispatch = useDispatch();
  const { site_id } = useParams();

  const [errors, setErrors] = useState({
    groupName: { type: "required", error: null },
  });

  const [groupName, setGroupName] = useState("");

  checkInputs.current = () => {
    if (!groupToEdit) {
      if (groupName) return true;
      return false;
    }
  };

  useEffect(() => {
    if (groupToEdit) {
      setGroupName(groupToEdit.name);
    }
  }, [groupToEdit]);

  const handleChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      validate(
        e.target.value,
        () => {},
        () =>
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: { ...errors[e.target.name], error: null },
          })),
        errors[e.target.name].type
      );
    }
    return setGroupName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!groupName) {
      return setErrors((errors) => ({
        ...errors,
        groupName: { type: "required", error: "Обязательное поле" },
      }));
    }

    const action = groupToEdit
      ? editSubsectionGroupStart
      : addSubsectionGroupStart;

    dispatch(
      action(
        {
          name: groupName,
          obj_id: site_id,
          subsection_group_id: groupToEdit
            ? groupToEdit.subsection_group_id
            : groupId,
        },
        currentUser,
        close,
        groupToEdit ? groupToEdit.id : null
      )
    );
  };

  return (
    <>
      {groupToEdit && (
        <div className="caption">
          Группа
          <button className="close" onClick={close} />
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <InputField
          type="text"
          label="Название группы"
          name="groupName"
          value={groupName}
          onChange={handleChange}
          validationType="required"
          err={errors.groupName.error}
        />

        <div className="info err">{errorMessage}</div>

        <div className="buttons">
          <button className="green" disabled={isFetching}>
            {isFetching ? "Загрузка..." : "Сохранить"}
          </button>
          <button
            className="cancel"
            type="button"
            onClick={checkInputsAndClose}
            disabled={isFetching}
          >
            Отмена
          </button>
        </div>
      </form>
    </>
  );
};

export default AddSubsectionGroup;
