import VariablesActionTypes from "./variables.types";

const INITIAL_STATE = {
  isAdding: false,
  isFetching: false,
  isDeleting: false,
  error: null,
  variables: null,
};

const VariablesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VariablesActionTypes.ADD_VARIABLE_START:
    case VariablesActionTypes.EDIT_VARIABLE_START:
      return { ...state, isAdding: true, error: null };
    case VariablesActionTypes.GET_VARIABLES_START:
      return { ...state, isFetching: true, error: null };
    case VariablesActionTypes.DELETE_VARIABLE_START:
      return {
        ...state,
        isDeleting: true,
        error: null,
      };

    case VariablesActionTypes.ADD_VARIABLE_SUCCESS:
    case VariablesActionTypes.EDIT_VARIABLE_SUCCESS:
      return { ...state, isAdding: false, error: null };
    case VariablesActionTypes.DELETE_VARIABLE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        error: null,
      };

    case VariablesActionTypes.GET_VARIABLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        variables: action.payload,
      };

    case VariablesActionTypes.ADD_VARIABLE_FAILURE:
    case VariablesActionTypes.EDIT_VARIABLE_FAILURE:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      };

    case VariablesActionTypes.GET_VARIABLES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case VariablesActionTypes.DELETE_VARIABLE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };

    case VariablesActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default VariablesReducer;
