import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearSiteInputs } from "../../redux/sites/sites.actions";
import { clearCurrentSubsection } from "../../redux/subsections/subsections.actions";
import Menu from "../../components/manage-sites/menu";
import SubsectionsList from "../../components/manage-sites/subsections/subsections-list";
import RevisionsList from "../../components/manage-sites/revisions/revisions-list";
import ClaimsList from "../../components/manage-sites/claims/claims-list";
import ActsList from "../../components/manage-sites/acts/acts-list";
import ReportsList from "../../components/manage-sites/reports/reports-list";
import ContractorsList from "../../components/manage-sites/contractors/contractors-list";
import WorksAndGroups from "../../components/manage-sites/works/works-groups-section";
import Remarks from "../../components/manage-sites/remarks/remarks-list";
import InlineModal from "../../components/inlineModal";
import AddSite from "../../components/manage-sites/add-site";
import SiteDetails from "../../components/manage-sites/site-details";
import { normalizeSiteData } from "../../utils/normalizeData";
import { Link, useParams, useHistory } from "react-router-dom";

const SiteInfo = ({ currentSite }) => {
  const [section, setSection] = useState("");
  const history = useHistory();
  const { site_id, menu, info } = useParams();
  const site_status = useRef({
    актуальные: "В работе",
    завершенные: "Завершен",
  });

  const dispatch = useDispatch();

  const currentSub = useSelector(
    (state) => state.subsections.currentSubsection
  );
  const siteStatuses = useSelector((state) => state.statuses.sites);

  console.log("SITE STATUSES: ", siteStatuses);

  const [burgerMenu, toggleBurgerMenu] = useState();

  useEffect(() => {
    if (currentSub && menu !== "works") {
      dispatch(clearCurrentSubsection());
    }
  }, [currentSub, dispatch, menu]);

  const navigate = (e) => {
    if (e.target.name === "site_info") {
      history.push(`/main/sites/${site_id}/${menu}/site_info`);
    } else if (e.target.name === "contractors") {
      history.push(`/main/sites/${site_id}/contractors`);
    } else if (e.target.name === "edit_site") {
      dispatch(clearSiteInputs());
      history.push(`/main/sites/${site_id}/${menu}/edit_site`);
    }
    toggleBurgerMenu(false);
  };

  return (
    <div>
      {currentSite && (
        <>
          <div className="header2">
            <ul>
              <li className="hdr">
                {currentSite.name}
                {siteStatuses && (
                  <span className="status">
                    {site_status.current[siteStatuses[currentSite.status_id]]}
                  </span>
                )}
              </li>

              <li className="burger">
                <Link
                  to="#"
                  onClick={() => toggleBurgerMenu(!burgerMenu)}
                ></Link>
              </li>

              <div className={`menu ${burgerMenu ? "opened" : ""}`}>
                <li className="item">
                  <Link to="#" onClick={navigate} name="site_info">
                    Инфо
                  </Link>
                </li>
                <li className="item">
                  <Link to="#" onClick={navigate} name="contractors">
                    Подрядчики
                  </Link>
                </li>
                <li className="btn">
                  <button onClick={navigate} name="edit_site">
                    редактировать
                  </button>
                </li>
              </div>
            </ul>
          </div>

          <Menu menu={section} setMenu={setSection} />

          {menu === "subsections" && <SubsectionsList />}
          {menu === "revisions" && <RevisionsList menu={section} />}
          {menu === "claims" && <ClaimsList menu={section} />}
          {menu === "acts" && <ActsList menu={section} />}
          {menu === "reports" && <ReportsList menu={section} />}
          {menu === "works" && (
            <WorksAndGroups menu={section} currentSite={currentSite} />
          )}
          {menu === "remarks" && <Remarks menu={section} />}
          {menu === "contractors" && <ContractorsList menu={section} />}

          {info === "site_info" && (
            <InlineModal>
              <SiteDetails
                close={() => history.push(`/main/sites/${site_id}/${menu}`)}
                currentSite={currentSite}
              />
            </InlineModal>
          )}

          {info === "edit_site" && (
            <InlineModal
              close={() => history.push(`/main/sites/${site_id}/${menu}`)}
            >
              <AddSite
                close={() => history.push(`/main/sites/${site_id}/${menu}`)}
                siteToEdit={normalizeSiteData(currentSite)}
              />
            </InlineModal>
          )}
        </>
      )}
    </div>
  );
};

export default SiteInfo;
