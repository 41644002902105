import { createUseStyles } from "react-jss";

export const useAddRemarkStyles = createUseStyles({
  container: {
    width: "700px",
    maxWidth: "100%",
  },
  main: {
    margin: "0 2vw",
    fontSize: "12px",
    "& h1": {
      // margin: "0 0 2vh",
      fontSize: "20px",
      fontWeight: 400,
      textAlign: "center",
    },
    "& >*": {
      marginBottom: "4vh",
    },
  },
  submitBtns: {
    display: "flex",
    justifyContent: "space-around",
    margin: "0 6vw 3vh",
  },
});

export const useRemarksListStyles = createUseStyles({
  main: {
    padding: "2vh 0vw",
  },
  filterMenu: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2vh 25% 4vh 0",
    "& span": {
      padding: "1.5vh 1vw",
      cursor: "pointer",
    },
  },
  selectedMenu: {
    backgroundColor: "#ebebeb",
  },
  remarksGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr 1fr 1fr 0.5fr 1fr",
    gap: "4vh 2vw",
  },
  gridHeader: {
    color: "#a9a9a9",
  },
});

export const useRemarkInfoStyles = createUseStyles({
  container: {
    width: "700px",
    maxWidth: "100%",
  },
  mainSection: {
    margin: "0 2vw 2vh",
    fontSize: "12px",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      margin: 0,
      textAlign: "center",
    },
    "& >*": {
      marginBottom: "4vh",
    },
  },
  infoBlock: {
    "& >*": {
      marginBottom: "0.5vh",
    },
  },
  actionBtns: {
    display: "flex",
    justifyContent: "space-around",
  },
});
