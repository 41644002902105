import SpecialtiesActionTypes from "./specialties.types";

export const addSpecialtyStart = (newSpecialty, adminId, callback) => ({
  type: SpecialtiesActionTypes.ADD_SPECIALTY_START,
  payload: { newSpecialty, adminId },
  callback,
});

export const addSpecialtySuccess = () => ({
  type: SpecialtiesActionTypes.ADD_SPECIALTY_SUCCESS,
});

export const addSpecialtyFailure = (error) => ({
  type: SpecialtiesActionTypes.ADD_SPECIALTY_FAILURE,
  payload: error,
});

export const getSpecialtiesStart = (adminId) => ({
  type: SpecialtiesActionTypes.GET_SPECIALTIES_START,
  payload: adminId,
});

export const getSpecialtiesSuccess = (specialties) => ({
  type: SpecialtiesActionTypes.GET_SPECIALTIES_SUCCESS,
  payload: specialties,
});

export const getSpecialtiesFailure = (error) => ({
  type: SpecialtiesActionTypes.GET_SPECIALTIES_FAILURE,
  payload: error,
});

export const editSpecialtyStart = (
  specialtyName,
  adminId,
  specialtyId,
  callback
) => ({
  type: SpecialtiesActionTypes.EDIT_SPECIALTY_START,
  payload: { specialtyName, adminId, specialtyId },
  callback,
});

export const editSpecialtySuccess = () => ({
  type: SpecialtiesActionTypes.EDIT_SPECIALTY_SUCCESS,
});

export const editSpecialtyFailure = (error) => ({
  type: SpecialtiesActionTypes.EDIT_SPECIALTY_FAILURE,
  payload: error,
});

export const deleteSpecialtyStart = (specialtyId, adminId, callback) => ({
  type: SpecialtiesActionTypes.DELETE_SPECIALTY_START,
  payload: { specialtyId, adminId },
  callback,
});

export const deleteSpecialtySuccess = (deletedSpecialtyId) => ({
  type: SpecialtiesActionTypes.DELETE_SPECIALTY_SUCCESS,
  payload: deletedSpecialtyId,
});

export const deleteSpecialtyFailure = (error) => ({
  type: SpecialtiesActionTypes.DELETE_SPECIALTY_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: SpecialtiesActionTypes.CLEAR_ERRORS,
});
