import React, { useEffect, useRef, useState } from "react";
import { InputField, TextAreaInput } from "../../input-forms";
import DatePicker from "react-date-picker";
import SetPointsOnPlan from "../pointsOnPlan/set-points-on-plans";
import AddBackup from "../add-backup";
import { useDispatch, useSelector } from "react-redux";
import { getSingleWorkStart } from "../../../redux/works/works.actions";
import { getSingleSubsectionStart } from "../../../redux/subsections/subsections.actions";
import { addRemarkStart } from "../../../redux/remarks/remarks.actions";
import { useParams } from "react-router";
import { formatDate } from "../../../utils/normalizeData";

const AddRemark = ({ work_id, checkInputs, close, checkInputsAndClose }) => {
  const [markerCoords, setMarkerCoords] = useState([]);
  const currentWork = useSelector((state) => state.works.currentWork);
  const currentSub = useSelector(
    (state) => state.subsections.currentSubsection
  );
  const currentUser = useSelector((state) => state.user.currentUser);

  // const isFetchingWork = useSelector((state) => state.works.isFetching);
  const isFetchingSub = useSelector((state) => state.subsections.isFetching);
  const isAdding = useSelector((state) => state.remarks.isFetching);

  const dispatch = useDispatch();
  const { site_id } = useParams();

  useEffect(() => {
    if (!currentWork || currentWork.id !== work_id) {
      dispatch(getSingleWorkStart(currentUser.user, work_id));
    } else if (!currentSub || currentSub.id !== currentWork.subsection_id) {
      dispatch(
        getSingleSubsectionStart(
          currentUser.user,
          site_id,
          currentWork.subsection_id
        )
      );
    }
  }, [currentWork, dispatch, work_id, currentUser.user, currentSub, site_id]);

  const [remarkData, setRemarkData] = useState({
    reason: "",
    descriptions: "",
  });
  const [deadline, setDeadline] = useState(new Date());
  const backupPhotos = useRef();

  checkInputs.current = () => {
    if (
      remarkData.reason ||
      remarkData.descriptions ||
      markerCoords.length ||
      (backupPhotos.current && backupPhotos.current.length)
    )
      return true;
    return false;
  };

  const handleChange = (e) => {
    setRemarkData({ ...remarkData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addRemarkStart(
        { ...remarkData, date: formatDate(deadline), job_id: work_id },
        markerCoords,
        backupPhotos.current,
        currentUser,
        site_id,
        close
      )
    );
  };

  return (
    <>
      <div className="caption">
        Создать замечание
        <button className="close" onClick={checkInputsAndClose}></button>
      </div>
      <form onSubmit={handleSubmit}>
        <InputField
          type="text"
          label="Основание"
          name="reason"
          onChange={handleChange}
          value={remarkData.reason}
        />
        <TextAreaInput
          label="Описание"
          name="descriptions"
          onChange={handleChange}
          value={remarkData.descriptions}
        />
        <div>Дата устранения</div>
        <DatePicker
          onChange={setDeadline}
          value={deadline}
          clearIcon={null}
          format="dd-MM-yyyy"
          validationType="required"
          locale="ru-RU"
        />

        {isFetchingSub ? (
          <div>загрузка подраздела...</div>
        ) : currentSub ? (
          <SetPointsOnPlan
            markerCoords={markerCoords}
            setMarkerCoords={setMarkerCoords}
            subsection={currentSub}
            pinType="claim"
          />
        ) : (
          ""
        )}
        <AddBackup filesForSubmit={backupPhotos} />
        <div className="buttons">
          <button className="green" submitstyle="true" disabled={isAdding}>
            Сохранить
          </button>
          <button
            className="cancel"
            type="button"
            onClick={checkInputsAndClose}
          >
            Отмена
          </button>
        </div>
      </form>
    </>
  );
};

export default AddRemark;
