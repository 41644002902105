import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { user_photos } from "../../../config";
import { formatDate, isEmpty } from "../../../utils/normalizeData";
import InlineModal from "../../inlineModal";
import UserInfo from "../../manage-users/user-info";

const ReportInfo = ({ close, currentReport }) => {
  const { site_id, info, user_id } = useParams();

  const users = useSelector((state) => state.user.usersList);
  const currentUser = useSelector((state) => state.user.currentUser);

  const history = useHistory();

  const renderUser = (userId) => {
    if (users) {
      const user = users.find((user) => user.id === userId);

      if (user) {
        return (
          <Link
            to={`/main/sites/${site_id}/reports/${info}/user/${user.id}`} /*onClick={() => showUser(user.id)}*/
          >
            {user.img ? (
              <img
                className="avatar"
                src={`${user_photos}/${user.org_id}/${user.img}`}
                alt="avatar"
              />
            ) : (
              <span className="avatar">
                {`${user.firstname.charAt(0)}${user.lastname.charAt(0)}`}
              </span>
            )}
            {`${user.firstname} ${user.lastname} ${
              currentUser.user === user.id ? "(вы)" : ""
            }`}
          </Link>
        );
      }
      return "";
    }
  };

  console.log("ACTION: ", user_id);

  return (
    <>
      <div className="caption">
        Отчет
        <button className="close" onClick={close}></button>
      </div>

      <div className="field">
        <label>Пользователь</label>
        <span className="data">{renderUser(currentReport.user_id)}</span>
      </div>

      <div className="field">
        <label>Дата</label>
        <span className="data">{formatDate(currentReport.date, true)}</span>
      </div>

      <div className="field">
        <label>Отчет</label>
        <span className="data">{currentReport.descriptions}</span>
      </div>

      {!isEmpty(currentReport.paths) && (
        <div className="field">
          <label>Видео</label>

          {Object.values(currentReport.paths).map((filename, i) => (
            <span className="data" key={i}>
              <a
                href={`http://control.starkandbau.ru/user_reports/${currentReport.id}/${filename.video_path}`}
                target="_blank"
                rel="noreferrer"
              >
                {filename.video_path}
              </a>
            </span>
          ))}
          {/* <a href="/main">filefile.mp4</a> */}
        </div>
      )}

      {user_id && (
        <InlineModal>
          <UserInfo
            showUser={() =>
              history.push(`/main/sites/${site_id}/reports/${info}`)
            }
            basePath={`/main/sites/${site_id}/reports/${info}`}
            userId={+user_id}
          />
        </InlineModal>
      )}

      {/* <div className="buttons">
        <button className="blue">Редактировать</button>
      </div> */}
    </>
  );
};

export default ReportInfo;
