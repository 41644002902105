import React from "react";
import { Link, useParams } from "react-router-dom";

const Menu = ({ subsection }) => {
  const { site_id, sub_id, menu } = useParams();

  const path = `/main/sites/${site_id}`;
  const subPath = subsection ? `/subsections/${sub_id}` : "";

  return (
    <ul className="tabs">
      <li>
        {subsection ? (
          <Link
            to={`${path}${subPath}/plan`}
            className={menu === "plan" ? "sel" : ""}
          >
            План
          </Link>
        ) : (
          <Link
            to={`${path}/subsections`}
            className={menu === "subsections" ? "sel" : ""}
          >
            Подразделы
          </Link>
        )}
      </li>
      <li>
        <Link
          to={`${path}${subPath}/revisions`}
          className={menu === "revisions" ? "sel" : ""}
        >
          План проверок
        </Link>
      </li>
      <li>
        <Link
          to={`${path}${subPath}/claims`}
          className={menu === "claims" ? "sel" : ""}
        >
          Предписания
        </Link>
      </li>
      <li>
        <Link
          to={`${path}${subPath}/acts`}
          className={menu === "acts" ? "sel" : ""}
        >
          Акты
        </Link>
      </li>
      {subsection ? (
        <li>
          <Link
            to={`${path}${subPath}/checklists`}
            className={menu === "checklists" ? "sel" : ""}
          >
            Чеклисты
          </Link>
        </li>
      ) : (
        <li>
          <Link
            to={`${path}${subPath}/reports`}
            className={menu === "reports" ? "sel" : ""}
          >
            Отчеты
          </Link>
        </li>
      )}
      <li>
        <Link
          to={`${path}${subPath}/works`}
          className={menu === "works" ? "sel" : ""}
        >
          Работы
        </Link>
      </li>
      <li>
        <Link
          to={`${path}${subPath}/remarks`}
          className={menu === "remarks" ? "sel" : ""}
        >
          Замечания
        </Link>
      </li>
    </ul>
  );
};

export default Menu;
