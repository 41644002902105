import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";

import ReportsActionTypes from "./reports.types";
import {
  addReportSuccess,
  addReportFailure,
  getReportsStart,
  getReportsSuccess,
  getReportsFailure,
  // editReportSuccess,
  // editReportFailure,
  // deleteReportSuccess,
  // deleteReportFailure,
} from "./reports.actions";

export function* addReport({ payload, callback }) {
  const { newReportData, videos, currentUser, site_id } = payload;

  const postReportData = {
    row: newReportData,
    who: currentUser.user,
  };

  try {
    const response = yield axios.post("/api/user_reports/add", postReportData);

    if (videos && videos.length) {
      const fd = new FormData();
      for (const i in videos) {
        fd.append("videos", videos[i], videos[i].name);
      }
      yield axios.post(
        `/api/report_videos/add/${currentUser.user}/${response.data.insertId}`,
        fd
      );
    }

    yield put(addReportSuccess());
    yield put(getReportsStart(currentUser.user, site_id));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addReportFailure(error));
  }
}

export function* getReports({ payload: { user_id, site_id } }) {
  try {
    const response = yield axios.get(
      `/api/user_reports_by_object/${user_id}/${site_id}`
    );
    yield put(
      getReportsSuccess(
        Object.values(response.data).sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        )
      )
    );
  } catch (e) {
    const error = handleError(e);
    yield put(getReportsFailure(error));
  }
}

// export function* editReport(editDetails) {
//   try {
//     yield console.log("axios.put(", editDetails);
//     yield put(editReportSuccess());
//   } catch (e) {
//     yield put(
//       editReportFailure(e.response ? e.response.data.message : "Сеть недоступна")
//     );
//   }
// }

// export function* deleteReport(ReportDetails) {
//   try {
//     yield console.log("axios.delete(", ReportDetails);
//     yield put(deleteReportSuccess());
//   } catch (e) {
//     yield put(
//       deleteReportFailure(e.response ? e.response.data : "Сеть недоступна")
//     );
//   }
// }

export function* onGetReportsStart() {
  yield takeLeading(ReportsActionTypes.GET_REPORTS_START, getReports);
}

export function* onAddReportStart() {
  yield takeLatest(ReportsActionTypes.ADD_REPORT_START, addReport);
}

// export function* onEditReportStart() {
//   yield takeLatest(ReportsActionTypes.EDIT_ACT_START, editReport);
// }

// export function* onDeleteReportStart() {
//   yield takeLeading(ReportsActionTypes.DELETE_ACT_START, deleteReport);
// }

export function* reportsSagas(args) {
  yield all([call(onAddReportStart), call(onGetReportsStart)]);
}
