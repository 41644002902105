import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";

import ClaimsActionTypes from "./claims.types";
import {
  addClaimSuccess,
  addClaimFailure,
  getClaimsSuccess,
  getClaimsFailure,
  // editClaimSuccess,
  // editClaimFailure,
  // deleteClaimSuccess,
  // deleteClaimFailure,
  getClaimsStart,
  reclaimFailure,
  reclaimSuccess,
  markClaimCloseSuccess,
  markClaimCloseFailure,
} from "./claims.actions";
import { getRevisionsStart } from "../revisions/revisions.actions";
import { getSingleSubsectionStart } from "../subsections/subsections.actions";

export function* addPhoto(photo, claimId, subsectionId, currentUserId) {
  let formData = new FormData();
  let uniqueImgName = Math.floor(Math.random() * 1000) + photo.name.slice(-35);
  formData.append("claimImg", photo, uniqueImgName);
  yield axios.post(
    `/api/claims/img/${currentUserId}/${claimId}/${subsectionId}`,
    formData
  );
}

export function* addClaim({ payload, callback }) {
  const { claimData, photos, currentUser, site_id, markerCoords } = payload;
  const postClaimData = {
    row: claimData,
    who: currentUser.user,
    subsection: claimData.subsection_id,
    org_id: currentUser.organization,
  };

  console.log("POST CLAIM DATA: ", postClaimData);
  // delete postClaimData.row.subsection_id;
  try {
    const response = yield axios.post("/api/claims/add", postClaimData);
    if (photos && photos.length) {
      yield all(
        photos.map((photo) =>
          call(
            addPhoto,
            photo,
            response.data.resultForRes.insertId,
            claimData.subsection_id,
            currentUser.user
          )
        )
      );
    }
    if (markerCoords.length) {
      const pointsData = {
        claimPlanPointsValues: [],
        who: currentUser.user,
      };
      for (const i in markerCoords) {
        markerCoords[i].claim_id = response.data.resultForRes.insertId;
        pointsData.claimPlanPointsValues.push(Object.values(markerCoords[i]));
      }
      pointsData.claimPlanPointsColumns = Object.keys(markerCoords[0]);
      yield axios.post("/api/claim_plan_points/add", pointsData);
    }

    axios
      .post("/api/claims/mail_to_contractor", {
        who: currentUser.user,
        claim: response.data.resultForRes.insertId,
      })
      .catch((e) => console.log("ERROR SENDING MAIL: ", e.response));
    yield put(addClaimSuccess());
    yield put(getClaimsStart(currentUser, site_id));
    yield put(
      getSingleSubsectionStart(
        currentUser.user,
        site_id,
        claimData.subsection_id
      )
    );
    if (claimData.revision_id) {
      yield put(getRevisionsStart(currentUser, site_id));
    }
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addClaimFailure(error));
  }
}

export function* getClaims({ payload: { currentUser, site_id } }) {
  try {
    const response = yield axios.get(
      `/api/claim_form/${currentUser.user}/${site_id}`
    );
    yield put(getClaimsSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getClaimsFailure(error));
  }
}

// export function* editClaim(editDetails) {
//   try {
//     yield console.log("axios.put(", editDetails);
//     yield put(editClaimSuccess());
//   } catch (e) {
//     yield put(
//       editClaimFailure(e.response ? e.response.data.message : "Сеть недоступна")
//     );
//   }
// }

// export function* deleteClaim(claimDetails) {
//   try {
//     yield console.log("axios.delete(", claimDetails);
//     yield put(deleteClaimSuccess());
//   } catch (e) {
//     yield put(
//       deleteClaimFailure(e.response ? e.response.data : "Сеть недоступна")
//     );
//   }
// }

export function* reclaim({ payload, callback }) {
  const { claimId, currentUser, site_id } = payload;

  try {
    yield axios.patch("/api/claims/reclaim", {
      who: currentUser.user,
      claim: claimId,
    });
    yield put(reclaimSuccess());
    yield put(getClaimsStart(currentUser, site_id));
    return callback();
  } catch (e) {
    if (
      e.response &&
      e.response.data &&
      ((e.response.data.status === 500 &&
        e.response.data.message ===
          "Can't send mail - all recipients were rejected: 550 non-local recipient verification failed") ||
        (e.response.data.status === 404 &&
          e.response.data.message === "This contractor hasn't contacts"))
    ) {
      yield put(reclaimSuccess());
      yield put(getClaimsStart(currentUser, site_id));
      return callback();
    }
    const error = handleError(e);
    yield put(reclaimFailure(error));
  }
}

export function* markClaimClose({ payload, callback }) {
  const { claimId, descriptions, photos, currentUser, site_id } = payload;

  const fd = new FormData();
  if (descriptions) {
    fd.append("descriptions", descriptions);
  }
  for (const i in photos) {
    fd.append("images", photos[i], photos[i].name);
  }

  try {
    yield axios.post(`/api/claims/mark/${currentUser.user}/${claimId}`, fd);
    yield put(markClaimCloseSuccess());
    yield put(getClaimsStart(currentUser, site_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(markClaimCloseFailure(error));
  }
}

export function* onGetClaimsStart() {
  yield takeLeading(ClaimsActionTypes.GET_CLAIMS_START, getClaims);
}

export function* onAddClaimStart() {
  yield takeLatest(ClaimsActionTypes.ADD_CLAIM_START, addClaim);
}

// export function* onEditClaimStart() {
//   yield takeLatest(ClaimsActionTypes.EDIT_CLAIM_START, editClaim);
// }

// export function* onDeleteClaimStart() {
//   yield takeLeading(ClaimsActionTypes.DELETE_CLAIM_START, deleteClaim);
// }

export function* onReclaimStart() {
  yield takeLeading(ClaimsActionTypes.RECLAIM_START, reclaim);
}

export function* onMarkClaimCloseStart() {
  yield takeLeading(ClaimsActionTypes.MARK_CLAIM_CLOSE_START, markClaimClose);
}

export function* claimsSagas(args) {
  yield all([
    call(onAddClaimStart),
    call(onGetClaimsStart),
    call(onReclaimStart),
    call(onMarkClaimCloseStart),
    // call(onEditClaimStart),
    // call(onDeleteClaimStart),
  ]);
}
