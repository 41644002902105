import ChecklistsActionTypes from "./checklists.types";

const INITIAL_STATE = {
  isFetching: false,
  error: null,
  checklists: null,
  currentSubId: null,
};

const checklistsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ChecklistsActionTypes.ADD_CHECKLIST_START:
    case ChecklistsActionTypes.EDIT_CHECKLIST_START:
    case ChecklistsActionTypes.GET_CHECKLISTS_START:
    case ChecklistsActionTypes.DELETE_CHECKLIST_START:
      return { ...state, isFetching: true, error: null };

    case ChecklistsActionTypes.ADD_CHECKLIST_SUCCESS:
    case ChecklistsActionTypes.EDIT_CHECKLIST_SUCCESS:
    case ChecklistsActionTypes.DELETE_CHECKLIST_SUCCESS:
      return { ...state, isFetching: false, error: null };

    case ChecklistsActionTypes.GET_CHECKLISTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        checklists: action.payload.checklists,
        currentSubId: action.payload.currentSubId,
      };

    case ChecklistsActionTypes.ADD_CHECKLIST_FAILURE:
    case ChecklistsActionTypes.GET_CHECKLISTS_FAILURE:
    case ChecklistsActionTypes.DELETE_CHECKLIST_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case ChecklistsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default checklistsReducer;
