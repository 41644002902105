import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorksBySubStart,
  getWorksByUserStart,
} from "../../../redux/works/works.actions";
import { formatDate } from "../../../utils/normalizeData";
import { Link, useParams } from "react-router-dom";

const WorksListBySubAndUser = ({ sub_id, filterByUser, showOnlyMyWorks }) => {
  const { site_id } = useParams();
  const dispatch = useDispatch();
  const worksBySub = useSelector((state) => state.works.worksBySub);
  const worksByUser = useSelector((state) => state.works.worksByUser);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetching = useSelector((state) => state.works.isFetching);
  const [worksToRender, setWorksToRender] = useState([]);

  let path = `/main/sites/${site_id}`;
  if (sub_id) path += `/subsections/${sub_id}`;

  useEffect(() => {
    if (sub_id) {
      if (
        !worksBySub ||
        (worksBySub.length && String(worksBySub[0].subsection_id) !== sub_id)
      ) {
        dispatch(getWorksBySubStart(sub_id, currentUser.user));
      } else if (showOnlyMyWorks) {
        setWorksToRender(
          worksBySub.filter((work) => work.user_id === currentUser.user)
        );
      } else {
        setWorksToRender(worksBySub);
      }
    } else if (showOnlyMyWorks) {
      if (!worksByUser) {
        dispatch(getWorksByUserStart(site_id, currentUser.user));
      } else {
        setWorksToRender(worksByUser);
      }
    }
  }, [
    sub_id,
    worksBySub,
    currentUser,
    dispatch,
    showOnlyMyWorks,
    worksByUser,
    site_id,
  ]);
  return (
    <>
      {isFetching && (
        <>
          <div className="info">загрузка...</div>
          <span></span>
          <span></span>
        </>
      )}
      <table className="list">
        <thead>
          <th>Название</th>
          <th>Дата начала</th>
          <th>Дата окончания</th>
        </thead>
        <tbody>
          {worksToRender.map((work) => (
            <tr key={work.id}>
              <td>
                <Link to={`${path}/works/${work.id}`}>{work.name}</Link>
              </td>
              <td>{formatDate(work.date_start, true)}</td>
              <td>{formatDate(work.date_end, true)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default WorksListBySubAndUser;
