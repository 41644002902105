export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#969696",
  borderStyle: "dashed",
  color: "#33384B",
  outline: "none",
  transition: "border .24s ease-in-out",
  fontSize: "14px",
  textAlign: "center",
  cursor: "pointer",
};

export const activeStyle = {
  borderColor: "#A9D7F9",
};

export const acceptStyle = {
  borderColor: "#FCFFD7",
};

export const rejectStyle = {
  borderColor: "#FF9393",
};

export const plusStyle = {
  display: "block",
  fontSize: "100px",
  lineHeight: "100px",
};
