const ChecklistsActionTypes = {
  ADD_CHECKLIST_START: "ADD_CHECKLIST_START",
  ADD_CHECKLIST_SUCCESS: "ADD_CHECKLIST_SUCCESS",
  ADD_CHECKLIST_FAILURE: "ADD_CHECKLIST_FAILURE",
  GET_CHECKLISTS_START: "GET_CHECKLISTS_START",
  GET_CHECKLISTS_SUCCESS: "GET_CHECKLISTS_SUCCESS",
  GET_CHECKLISTS_FAILURE: "GET_CHECKLISTS_FAILURE",
  DELETE_CHECKLIST_START: "DELETE_CHECKLIST_START",
  DELETE_CHECKLIST_SUCCESS: "DELETE_CHECKLIST_SUCCESS",
  DELETE_CHECKLIST_FAILURE: "DELETE_CHECKLIST_FAILURE",
  EDIT_CHECKLIST_START: "EDIT_CHECKLIST_START",
  EDIT_CHECKLIST_SUCCESS: "EDIT_CHECKLIST_SUCCESS",
  EDIT_CHECKLIST_FAILURE: "EDIT_CHECKLIST_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default ChecklistsActionTypes;
