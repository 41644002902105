import { useRef, useState } from "react";
import { InputField, TextAreaInput } from "../../input-forms";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import AddBackup from "../add-backup";
import { validate } from "../../../utils/normalizeData";
import { addActStart } from "../../../redux/acts/acts.actions";
import { useParams } from "react-router";
import { markClaimCloseStart } from "../../../redux/claims/claims.actions";

const CloseClaim = ({
  checkInputs,
  checkInputsAndClose,
  close,
  claim,
  subsectionName,
  claimContractor,
}) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const isActProcessing = useSelector((state) => state.acts.isFetching);
  const isClaimProcessing = useSelector((state) => state.claims.isFetching);
  const claimError = useSelector((state) => state.claims.error);

  const [closingType, setClosingType] = useState("act");

  const [errors, setErrors] = useState({
    num: { type: "required", error: null },
  });
  const [isActDataValid, setIsActDataValid] = useState(true);

  const [actData, setActData] = useState({
    title: "",
    num: "",
    descriptions: "",
    contractor_id: claim.contractor_id,
    revision_id: null,
    subsection_id: claim.subsection_id,
    claim_id: claim.id,
    user_id: currentUser.user,
  });
  const [signedDate, setSignedDate] = useState(new Date());
  const actBackupPhotos = useRef();

  const [descriptions, setDescriptions] = useState("");
  const markBackupPhotos = useRef([]);
  const [markBackupPhotosError, setMarkBackupPhotosError] = useState();

  checkInputs.current = () => {
    if (
      actData.title ||
      actData.num ||
      actData.descriptions ||
      (actBackupPhotos.current && actBackupPhotos.current.length)
    )
      return true;
  };

  const handleActChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      validate(
        e.target.value,
        () => {},
        () =>
          setErrors({
            ...errors,
            [e.target.name]: { ...errors[e.target.name], error: null },
          }),
        errors[e.target.name].type
      );
    }

    setActData({ ...actData, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();
  const { site_id } = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (closingType === "act") {
      let valid = true;
      for (const i in errors) {
        if (!actData[i]) {
          setErrors((errors) => ({
            ...errors,
            [i]: { ...errors[i], error: "Обязательное поле" },
          }));
          valid = false;
          if (isActDataValid) setIsActDataValid(false);
        }
      }
      if (!valid) return;

      dispatch(
        addActStart(
          {
            ...actData,
            date: signedDate,
          },
          [],
          actBackupPhotos.current,
          currentUser,
          site_id,
          close
        )
      );
    } else if (closingType === "mark") {
      if (!markBackupPhotos.current.length) {
        return setMarkBackupPhotosError("Нужна минимум одна фотография");
      }

      dispatch(
        markClaimCloseStart(
          claim.id,
          descriptions,
          markBackupPhotos.current,
          currentUser,
          site_id,
          close
        )
      );
    }
  };

  return (
    <>
      <div className="caption">
        Закрыть предписание
        <button className="close" onClick={checkInputsAndClose} />
      </div>

      <div className="two_col">
        <div className="col">
          <div className="field">
            <label>Номер предписания</label>
            <span className="data">{claim.num}</span>
          </div>

          <div className="field">
            <label>Название предписания</label>
            <span className="data">{claim.title}</span>
          </div>

          <div className="field">
            <label>Основание</label>
            <span className="data">{claim.reason || claim.reason_name}</span>
          </div>
        </div>
        <div className="col">
          <div className="field">
            <label>Подраздел</label>
            <span className="data">{subsectionName}</span>
          </div>

          <div className="field">
            <label>Подрядчик</label>
            <span className="data">{claimContractor.name}</span>
          </div>
        </div>
      </div>

      <div className="field" onChange={(e) => setClosingType(e.target.value)}>
        <input
          name="closingType"
          id="act"
          value="act"
          type="radio"
          defaultChecked={closingType === "act"}
        />
        <label htmlFor="act">Актом </label>
        <input
          name="closingType"
          id="mark"
          value="mark"
          type="radio"
          defaultChecked={closingType === "mark"}
        />
        <label htmlFor="mark">Отметкой </label>
      </div>

      <form onSubmit={handleSubmit}>
        {closingType === "act" && (
          <>
            <InputField
              type="text"
              label="Номер акта"
              name="num"
              value={actData.num}
              onChange={handleActChange}
              autoComplete="off"
              validationType="required"
              placeholder="xxx"
              err={errors.num.error}
            />

            <div className="field">
              <label>Дата подписания</label>
              <DatePicker
                onChange={setSignedDate}
                value={signedDate}
                clearIcon={null}
                format="dd-MM-yyyy"
                validationType="required"
                locale="ru-RU"
              />
            </div>

            <InputField
              type="text"
              label="Краткое название"
              name="title"
              value={actData.title}
              onChange={handleActChange}
              autoComplete="off"
              placeholder="Заливка фундамента, побелка стены"
            />

            <TextAreaInput
              label="Текст"
              height="20vh"
              name="descriptions"
              value={actData.descriptions}
              onChange={handleActChange}
            />

            <div className="hdr">Фотографии</div>

            <AddBackup filesForSubmit={actBackupPhotos} />

            <div className="info">
              После создания акта, не будет возможности его редактировать.
              <br />
              Также, будет выслано уведомление исполнителю (подрядчику), при
              наличии у него указанного e-mail.
            </div>
          </>
        )}
        {closingType === "mark" && (
          <>
            <TextAreaInput
              label="Комментарий"
              height="20vh"
              value={descriptions}
              onChange={(e) => setDescriptions(e.target.value)}
            />

            <div className="hdr">Фотографии</div>

            <AddBackup
              filesForSubmit={markBackupPhotos}
              err={markBackupPhotosError}
              setError={setMarkBackupPhotosError}
            />
          </>
        )}

        {closingType === "act" && !isActDataValid && (
          <div className="info err">Не все поля заполнены корректно</div>
        )}

        {claimError && <div className="info err">{claimError}</div>}

        <div className="buttons">
          <button
            className="green"
            onClick={handleSubmit}
            disabled={isActProcessing || isClaimProcessing}
          >
            {isActProcessing || isClaimProcessing
              ? "Загрузка..."
              : "Закрыть предписание"}
          </button>
          <button
            type="button"
            className="cancel"
            onClick={checkInputsAndClose}
            disabled={isActProcessing || isClaimProcessing}
          >
            Отмена
          </button>
        </div>
      </form>
    </>
  );
};

export default CloseClaim;
