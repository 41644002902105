import ReasonsActionTypes from "./reasons.types";

const INITIAL_STATE = {
  isFetching: false,
  reasons: null,
  error: null,
};

const reasonsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReasonsActionTypes.GET_REASONS_START:
      return { ...state, isFetching: true, error: null };

    case ReasonsActionTypes.GET_REASONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        reasons: action.payload,
      };

    case ReasonsActionTypes.GET_REASONS_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

export default reasonsReducer;
