import React, { useState, useEffect } from "react";
import ImageUpload from "../image-upload";
import PopupForPhoto from "../popup-for-photo";
import { Link } from "react-router-dom";

const AddBackup = ({ video, filesForSubmit, label, err, setError }) => {
  const [backupFiles, setBackupFiles] = useState([]);

  useEffect(() => {
    filesForSubmit.current = [...backupFiles];
  }, [backupFiles, filesForSubmit]);

  // check for error and erase the error if at least one file is uploaded;
  useEffect(() => {
    if (err && backupFiles.length) {
      setError(null);
    }
  }, [err, setError, backupFiles.length]);

  const deleteFile = (filterCriteria) => {
    setBackupFiles(
      backupFiles.filter(
        (file, index) =>
          index !== filterCriteria && file.preview !== filterCriteria
      )
    );
  };

  const [zoomedImgSrc, setZoomedImgSrc] = useState();

  return (
    <div>
      {zoomedImgSrc && (
        <PopupForPhoto src={zoomedImgSrc} close={() => setZoomedImgSrc(null)} />
      )}
      <div className="hdr">{video ? "Видео" : "Фотографии"}</div>
      <div className={video ? "" : "photos"}>
        {backupFiles.length && video ? (
          <table className="list">
            <tbody>
              {backupFiles.map((file, index) => (
                <tr key={file.name + index}>
                  <td>{file.name}</td>
                  <td className="control">
                    <button
                      className="remove"
                      onClick={() => deleteFile(index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ""
        )}

        <div>
          <ImageUpload
            video={video}
            filesToUpload={backupFiles}
            setFilesToUpload={setBackupFiles}
            label={label}
          />
          <div className="info err">{err}</div>
        </div>

        {backupFiles.length && !video
          ? backupFiles.map((file) => (
              <span className="photo" key={file.preview}>
                <Link to="#" onClick={() => setZoomedImgSrc(file.preview)}>
                  <img
                    key={file.preview}
                    src={file.preview}
                    alt={file.name}
                    // onClick={() => setZoomedImgSrc(file.preview)}
                  />
                </Link>
                <button
                  className="remove"
                  type="button"
                  onClick={() => deleteFile(file.preview)}
                />
              </span>
            ))
          : ""}
      </div>
    </div>
  );
};

export default AddBackup;
