import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSideMenuStyles } from "../../css/admin/admin-panel.styles";

const SideMenu = () => {
  const classes = useSideMenuStyles();
  const { menu } = useParams();
  return (
    <div className={classes.sidemenu}>
      <div className={classes.menuItems}>
        <div
          className={`${classes.menuItem} ${
            menu === "specialties" && classes.selected
          }`}
        >
          <Link to="/admin/specialties">Профеcсии работников</Link>
        </div>
        <div
          className={`${classes.menuItem} ${
            menu === "materialUnits" && classes.selected
          }`}
        >
          <Link to="/admin/materialUnits">Единицы измерения материалов</Link>
        </div>
        <div
          className={`${classes.menuItem} ${
            menu === "materials" && classes.selected
          }`}
        >
          <Link to="/admin/materials">Виды материалов</Link>
        </div>
        <div
          className={`${classes.menuItem} ${
            menu === "equipment" && classes.selected
          }`}
        >
          <Link to="/admin/equipment">Виды оборудования</Link>
        </div>
        <div
          className={`${classes.menuItem} ${
            menu === "variables" && classes.selected
          }`}
        >
          <Link to="/admin/variables">Переменные (необходимая информация)</Link>
        </div>
        <div
          className={`${classes.menuItem} ${
            menu === "techCards" && classes.selected
          }`}
        >
          <Link to="/admin/techCards">Технологические карты</Link>
        </div>
        <div
          className={`${classes.menuItem} ${
            menu === "reasons" && classes.selected
          }`}
        >
          <Link to="/admin/reasons">Основания</Link>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
