import React from "react";
import { useSelector } from "react-redux";
import GetPointsOnPlan from "../pointsOnPlan/get-all-points-on-plan";
import { isEmpty } from "../../../utils/normalizeData";

const SubsectionInfo = () => {
  const currentSub = useSelector(
    (state) => state.subsections.currentSubsection
  );

  const renderPlans = (plans) => {
    const plansArray = [];
    for (const i in plans) {
      const plan = plans[i];
      plansArray.push(<GetPointsOnPlan key={plan.id} plan={plan} />);
    }
    return plansArray;
  };

  return (
    <div className="content">
      {isEmpty(currentSub.subsection_plans)
        ? "ПЛАН НЕ ДОБАВЛЕН"
        : renderPlans(currentSub.subsection_plans)}
      {currentSub.descriptions && (
        <div className="field">
          <label className="mobile">Дополнительное описание</label>
          <span className="data">{currentSub.descriptions}</span>
        </div>
      )}
      {currentSub && !isEmpty(currentSub.subsection_variables) && (
        <table className="list">
          <thead>
            <tr>
              <th>Параметр</th>
              <th>Значение</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(currentSub.subsection_variables).map((variable) => (
              <tr key={variable.id}>
                <td>
                  <label className="mobile">Параметр</label>
                  {variable.name_print}
                </td>
                <td>
                  <label className="mobile">Значение</label>
                  {variable.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SubsectionInfo;
