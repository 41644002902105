import SiteActionTypes from "./sites.types";

export const addSiteStart = (newSiteData, callback) => ({
  type: SiteActionTypes.ADD_SITE_START,
  payload: newSiteData,
  callback,
});

export const addSiteSuccess = () => ({
  type: SiteActionTypes.ADD_SITE_SUCCESS,
});

export const addSiteFailure = (error) => ({
  type: SiteActionTypes.ADD_SITE_FAILURE,
  payload: error,
});

export const getSitesStart = (currentUser) => ({
  type: SiteActionTypes.GET_SITES_START,
  payload: currentUser,
});

export const getSitesSuccess = (sites) => ({
  type: SiteActionTypes.GET_SITES_SUCCESS,
  payload: sites,
});

export const getSitesFailure = (error) => ({
  type: SiteActionTypes.GET_SITES_FAILURE,
  payload: error,
});

export const editSiteStart = (siteDetails, callback) => ({
  type: SiteActionTypes.EDIT_SITE_START,
  payload: siteDetails,
  callback,
});

export const editSiteSuccess = () => ({
  type: SiteActionTypes.EDIT_SITE_SUCCESS,
});

export const editSiteFailure = (error) => ({
  type: SiteActionTypes.EDIT_SITE_FAILURE,
  payload: error,
});

export const deleteSiteStart = (site) => ({
  type: SiteActionTypes.DELETE_SITE_START,
  payload: site,
});

export const deleteSiteSuccess = () => ({
  type: SiteActionTypes.DELETE_SITE_SUCCESS,
});

export const deleteSiteFailure = (error) => ({
  type: SiteActionTypes.DELETE_SITE_FAILURE,
  payload: error,
});

export const saveSiteInputs = (siteDetails) => ({
  type: SiteActionTypes.SAVE_SITE_DETAILS,
  payload: siteDetails,
});

export const clearSiteInputs = () => ({
  type: SiteActionTypes.SAVE_SITE_DETAILS,
});

export const clearErrors = () => ({
  type: SiteActionTypes.CLEAR_ERRORS,
});
