import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../../utils/normalizeData";

import MaterialsActionTypes from "./materials.types";

import {
  addMaterialSuccess,
  addMaterialFailure,
  getMaterialsStart,
  getMaterialsSuccess,
  getMaterialsFailure,
  editMaterialSuccess,
  editMaterialFailure,
  deleteMaterialSuccess,
  deleteMaterialFailure,
} from "./materials.actions";

export function* addMaterial({ payload, callback }) {
  const { newMaterialData, adminId } = payload;
  const postData = {
    row: newMaterialData,
    who: adminId,
  };
  try {
    yield axios.post(`/api/admin/materials/add`, postData);
    yield put(addMaterialSuccess());
    yield put(getMaterialsStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addMaterialFailure(error));
  }
}

export function* getMaterials({ payload }) {
  try {
    const response = yield axios.get(`/api/admin/materials/${payload}`);
    yield put(getMaterialsSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getMaterialsFailure(error));
  }
}

export function* editMaterial({ payload, callback }) {
  const { materialData, adminId, materialId } = payload;
  const putMaterialData = {
    row: materialData,
    who: adminId,
    material: materialId,
  };
  try {
    yield axios.put("/api/admin/materials/edit", putMaterialData);
    yield put(editMaterialSuccess());
    yield put(getMaterialsStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editMaterialFailure(error));
  }
}

export function* deleteMaterial({ payload, callback }) {
  const { materialId, adminId } = payload;
  try {
    yield axios.put("/api/admin/materials/mark_as_deleted", {
      who: adminId,
      material_id: materialId,
    });
    yield put(deleteMaterialSuccess(materialId));
    yield put(getMaterialsStart(adminId));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(deleteMaterialFailure(error));
  }
}

export function* onGetMaterialsStart() {
  yield takeLeading(MaterialsActionTypes.GET_MATERIALS_START, getMaterials);
}

export function* onAddMaterialStart() {
  yield takeLatest(MaterialsActionTypes.ADD_MATERIAL_START, addMaterial);
}

export function* onEditMaterialStart() {
  yield takeLatest(MaterialsActionTypes.EDIT_MATERIAL_START, editMaterial);
}

export function* onDeleteMaterialStart() {
  yield takeLeading(MaterialsActionTypes.DELETE_MATERIAL_START, deleteMaterial);
}

export function* materialsSagas(args) {
  yield all([
    call(onGetMaterialsStart),
    call(onAddMaterialStart),
    call(onEditMaterialStart),
    call(onDeleteMaterialStart),
  ]);
}
