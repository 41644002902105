import React, { useEffect, useState } from "react";
import { useMainSectionStyles } from "../../../css/admin/main-section.styles";
import { FormInput, ControlledSelect } from "../../../components/input-forms";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialsStart,
  clearErrors,
  editMaterialStart,
  addMaterialStart,
  deleteMaterialStart,
} from "../../redux/materials/materials.actions";
import { getMaterialUnitsStart } from "../../redux/materialUnits/materialUnits.actions";
import { ReactComponent as Trash } from "../../../assets/trash.svg";
import ConfirmDeleteModal from "../../../components/confirmActionModal";

const Materials = () => {
  const classes = useMainSectionStyles();
  const dispatch = useDispatch();
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const materials = useSelector((state) => state.materials.materials);
  const materialUnits = useSelector(
    (state) => state.materialUnits.materialUnits
  );
  const isDeleting = useSelector((state) => state.materials.isDeleting);
  const errorMessage = useSelector((state) => state.materials.error);
  const isAdding = useSelector((state) => state.materials.isAdding);

  const [materialName, setMaterialName] = useState("");
  const [materialUnitId, setMaterialUnidId] = useState("");
  const [materialToEdit, setMaterialToEdit] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const [unitsSelectOptions, setOptions] = useState({});

  useEffect(() => {
    if (!materials) {
      dispatch(getMaterialsStart(currentAdmin.adminId));
    }
    if (!materialUnits) {
      dispatch(getMaterialUnitsStart(currentAdmin.adminId));
    }
  }, [materials, materialUnits, currentAdmin.adminId, dispatch]);

  useEffect(() => {
    if (materialUnits && materialUnits.length) {
      const optionsToSet = {};
      materialUnits.forEach((unit) => (optionsToSet[unit.id] = unit.name));
      setOptions(optionsToSet);
      setMaterialUnidId(Object.keys(optionsToSet)[0]);
    }
  }, [materialUnits]);

  useEffect(() => {
    if (materialToEdit) {
      setMaterialName(materialToEdit.name);
    }
  }, [materialToEdit]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [confirmDelete, dispatch]);

  const callback = () => {
    setMaterialName("");
    document.getElementById("name").focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (materialToEdit) {
      const callback = () => {
        setMaterialToEdit(null);
        setMaterialName("");
      };

      return dispatch(
        editMaterialStart(
          { name: materialName, unit_id: materialUnitId },
          currentAdmin.adminId,
          materialToEdit.id,
          callback
        )
      );
    }

    if (materialName) {
      dispatch(
        addMaterialStart(
          { name: materialName, unit_id: materialUnitId },
          currentAdmin.adminId,
          callback
        )
      );
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const callback = () => setConfirmDelete(false);

    dispatch(
      deleteMaterialStart(confirmDelete.id, currentAdmin.adminId, callback)
    );
    if (materialToEdit && materialToEdit.id === confirmDelete.id) {
      setMaterialToEdit(null);
      setMaterialName("");
    }
  };

  const cancelEdit = () => {
    setMaterialToEdit(null);
    setMaterialName("");
    document.getElementById("name").focus();
  };

  const handleChange = (e) => {
    setMaterialUnidId(Number(e.target.value));
  };

  const renderMaterialUnit = (unit_id) => {
    if (materialUnits) {
      const unit = materialUnits.find((unit) => unit.id === unit_id);
      if (unit) return unit.name;
    }
    return "";
  };

  return (
    <div className={classes.main}>
      <div className={classes.list}>
        <h1>Виды материалов</h1>
        {materials &&
          materialUnits &&
          materials.map((material) => (
            <div key={material.id} className={classes.listItem}>
              <span
                onClick={() => setMaterialToEdit(material)}
                className={
                  materialToEdit && materialToEdit.id === material.id
                    ? classes.selectedItem
                    : ""
                }
              >
                {`${material.name} (${renderMaterialUnit(material.unit_id)}) `}
              </span>
              <Trash onClick={() => setConfirmDelete(material)} />
            </div>
          ))}
      </div>

      <form className={classes.addSection} onSubmit={handleSubmit}>
        <div className={classes.inputSection}>
          <FormInput
            label="Название"
            id="name"
            value={materialName}
            onChange={(e) => setMaterialName(e.target.value)}
          />

          <ControlledSelect
            options={unitsSelectOptions}
            onChange={handleChange}
            defaultOption={materialUnitId}
            label="Единица измерения"
          />
        </div>
        <div className="buttons">
          <button className="blue" disabled={!materialName || isAdding}>
            {materialToEdit ? "Сохранить" : "Добавить"}
          </button>
          {materialToEdit && (
            <button
              className="cancel"
              disabled={isAdding}
              type="button"
              onClick={cancelEdit}
            >
              Отмена
            </button>
          )}
        </div>
      </form>

      {confirmDelete && (
        <ConfirmDeleteModal
          message={`Удалить материал "${confirmDelete.name}"?`}
          confirm={handleDelete}
          cancel={() => setConfirmDelete(null)}
          processing={isDeleting}
          errorMsg={errorMessage}
        />
      )}
    </div>
  );
};

export default Materials;
