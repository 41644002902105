import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";

import ContractorsActionTypes from "./contractors.types";
import {
  addContractorSuccess,
  addContractorFailure,
  getContractorsStart,
  getContractorsSuccess,
  getContractorsFailure,
  editContractorSuccess,
  editContractorFailure,
  deleteContractorSuccess,
  deleteContractorFailure,
  getAllContractorsSuccess,
  getAllContractorsFailure,
  getCurrentContractorFailure,
  getCurrentContractorSuccess,
} from "./contractors.actions";

export function* addContractor({ payload, callback }) {
  const { contractorDetails, contacts, currentUser } = payload;
  const postContractorData = {
    contractorColumns: Object.keys(contractorDetails),
    contractorValues: [[...Object.values(contractorDetails)]],
    who: currentUser.user,
    object: contractorDetails.obj_id,
  };
  if (contacts.length) {
    postContractorData.contactColumns = Object.keys(contacts[0]);
    postContractorData.contactValues = [
      ...contacts.map((contact) => [...Object.values(contact)]),
    ];
  }
  try {
    yield axios.post("/api/contractor_form/add", postContractorData);
    yield put(addContractorSuccess());
    yield put(getContractorsStart(currentUser, contractorDetails.obj_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addContractorFailure(error));
  }
}

export function* getCurrentContractor({ payload }) {
  const { contractorId, userId } = payload;
  try {
    const response = yield axios.get(
      `/api/contractor_by_id/${userId}/${contractorId}`
    );
    yield put(getCurrentContractorSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getCurrentContractorFailure(error));
  }
}

export function* getContractors({ payload: { currentUser, site_id } }) {
  try {
    const response = yield axios.get(
      `/api/contractor_form/${currentUser.user}/${site_id}`
    );
    // const response = yield axios.get(`/api/objects/${id}/${organization}`);
    yield put(getContractorsSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getContractorsFailure(error));
  }
}

export function* getAllContractors({ payload }) {
  try {
    const response = yield axios.get(
      `/api/contractors_org/${payload.user}/${payload.organization}`
    );
    yield put(getAllContractorsSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getAllContractorsFailure(error));
  }
}

export function* editContractor({ payload, callback }) {
  const { contractorDetails, contacts, currentUser } = payload;
  const putContractorData = {
    contractors: contractorDetails,
    who: currentUser.user,
    contractor_id: payload.currentContractor.id,
    obj_id: contractorDetails.obj_id,
  };
  if (contacts.length) {
    const columns = ["name", "tel", "email", "other_contacts"];
    putContractorData.contactColumns = columns;
    putContractorData.contactValues = [
      ...contacts.map((rep) => {
        const valueArr = [];
        for (const i in columns) {
          valueArr.push(rep[columns[i]]);
        }
        console.log("VALUE ARR: ", valueArr);
        return valueArr;
      }),
    ];
  }
  try {
    yield axios.put("/api/contractor_form/edit", putContractorData);
    yield put(editContractorSuccess());
    yield put(getContractorsStart(currentUser, contractorDetails.obj_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editContractorFailure(error));
  }
}

export function* deleteContractor({ payload, callback }) {
  const { currentUser, id, object } = payload;
  const deleteData = { who: currentUser.user, id, object };
  try {
    yield axios.delete("/api/contractors/delete", { data: deleteData });
    yield console.log("axios.delete(", payload);
    yield put(deleteContractorSuccess());
    yield put(getContractorsStart(currentUser, object));
    callback();
  } catch (e) {
    let error = "Ошибка сети";
    if (e.response && e.response.data) {
      if (e.response.data.message) error = e.response.data.message;
      else if (e.response.data.errno) {
        if (e.response.data.errno === 1451)
          error = "Нельзя удалить поставщика, прикрепленного к действиям";
      }
    }
    console.log(e.response);
    yield put(deleteContractorFailure(error));
  }
}

export function* onAddContractorStart() {
  yield takeLatest(ContractorsActionTypes.ADD_CONTRACTOR_START, addContractor);
}

export function* onGetCurrentContractorStart() {
  yield takeLeading(
    ContractorsActionTypes.GET_CURRENT_CONTRACTOR_START,
    getCurrentContractor
  );
}

export function* onGetContractorsStart() {
  yield takeLeading(
    ContractorsActionTypes.GET_CONTRACTORS_START,
    getContractors
  );
}

export function* onGetAllContractorsStart() {
  yield takeLeading(
    ContractorsActionTypes.GET_ALL_CONTRACTORS_START,
    getAllContractors
  );
}

export function* onEditContractorStart() {
  yield takeLatest(
    ContractorsActionTypes.EDIT_CONTRACTOR_START,
    editContractor
  );
}

export function* onDeleteContractorStart() {
  yield takeLeading(
    ContractorsActionTypes.DELETE_CONTRACTOR_START,
    deleteContractor
  );
}

export function* contractorsSagas(args) {
  yield all([
    call(onAddContractorStart),
    call(onGetContractorsStart),
    call(onEditContractorStart),
    call(onDeleteContractorStart),
    call(onGetAllContractorsStart),
    call(onGetCurrentContractorStart),
  ]);
}
