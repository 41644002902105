import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addSubsectionStart,
  editSubsectionStart,
  deleteSubsectionStart,
  clearErrors,
} from "../../../redux/subsections/subsections.actions";
import { plan_images } from "../../../config";
import { InputField, SelectInput, TextAreaInput } from "../../input-forms";
import VariablesList from "./variables-list";
import ImageUpload from "../../image-upload";
import { isEmpty, validate } from "../../../utils/normalizeData";
import ConfirmDeleteModal from "../../confirmActionModal";
import AddSubsectionGroup from "./add-subsection-group";

const Subsection = ({
  path,
  subToEdit,
  checkInputs,
  close,
  checkInputsAndClose,
  groupId = null,
}) => {
  const [groupOrSub, setGroupOrSub] = useState(subToEdit ? "sub" : "group");
  console.log("GROUP ID INSIDE ADD SUB: ", groupId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  const { site_id } = useParams();

  const statuses = useSelector((state) => state.statuses.subsections);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetching = useSelector((state) => state.subsections.isFetching);
  const errorMessage = useSelector((state) => state.subsections.error);

  const [plans, setPlans] = useState([]);
  const [enhancedPlan, setEnhancedPlan] = useState(null);

  const [subsection, setSubsection] = useState({
    name: "",
    status_id: "",
    descriptions: "",
    obj_id: parseInt(site_id),
    subsection_group_id: groupId,
  });
  // const [variableInputs, setVariableInputs] = useState([]);
  const [variableInputs, setVariableInputs] = useState({});
  const [zoomedPlan, setZoomedPlan] = useState();

  const [errors, setErrors] = useState({
    name: { type: "required", error: null },
  });
  const [isDataValid, setIsDataValid] = useState(true);

  useEffect(() => {
    if (statuses) {
      for (let id in statuses) {
        if (statuses[id] === "В работе")
          setSubsection((s) => ({ ...s, status_id: Number(id) }));
      }
    }
  }, [statuses]);

  // if this is not the edit window, then check inputs and prompt the user when he
  // tries to close the window without saving
  if (!subToEdit) {
    checkInputs.current = () => {
      if (
        subsection.name ||
        subsection.descriptions ||
        plans.length ||
        (statuses && statuses[subsection.status_id] !== "В работе")
      )
        return true;
      return false;
    };
  }

  // this useEffect is used for setting inputs when edit subsection route is activated
  useEffect(() => {
    if (subToEdit) {
      setSubsection({
        name: subToEdit.name,
        status_id: subToEdit.status_id,
        descriptions: subToEdit.descriptions || "",
        obj_id: subToEdit.obj_id,
      });
      if (!isEmpty(subToEdit.subsection_plans)) {
        const plansToEdit = [];
        for (const i in subToEdit.subsection_plans) {
          const planInDB = subToEdit.subsection_plans[i];
          plansToEdit.push({
            filename: planInDB.plan,
            id: planInDB.id,
            preview: `${plan_images}/${subToEdit.obj_id}/${subToEdit.id}/${planInDB.plan}`,
          });
        }
        setPlans(plansToEdit);
      }
    }
  }, [subToEdit]);

  // check if data input errors have been corrected and remove message if so
  useEffect(() => {
    if (!isDataValid) {
      for (const i in errors) {
        if (errors[i].error) return;
      }
      setIsDataValid(true);
    }
  }, [errors, isDataValid]);

  const handleChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      validate(
        e.target.value,
        () => {},
        () =>
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: { ...errors[e.target.name], error: null },
          })),
        errors[e.target.name].type
      );
    }
    if (e.target.name === "status_id") {
      return setSubsection({
        ...subsection,
        [e.target.name]: parseInt(e.target.value),
      });
    }
    setSubsection({ ...subsection, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!subsection.name) {
      setErrors((errors) => ({
        ...errors,
        name: { type: "required", error: "Обязательное поле" },
      }));
      return setIsDataValid(false);
    }

    const normalizedVariableInputs = [];

    for (const i in variableInputs) {
      if (variableInputs[i]) {
        normalizedVariableInputs.push([+i, variableInputs[i]]);
      }
    }

    const action = subToEdit ? editSubsectionStart : addSubsectionStart;
    dispatch(
      action(
        {
          subsection,
          currentUser,
          subToEdit,
          plans,
          plansToDelete,
          variableInputs: normalizedVariableInputs,
        },
        close
      )
    );
  };

  const [confirmDelete, setConfirmDelete] = useState();

  const history = useHistory();

  const deleteSub = () => {
    path = `/main/sites/${site_id}/subsections`;
    dispatch(
      deleteSubsectionStart({ currentUser, subToEdit }, () =>
        history.push(path)
      )
    );
  };
  const plansToDelete = useRef();

  const handleDeletePlan = (index) => {
    if (subToEdit) {
      if (!Array.isArray(plansToDelete.current)) plansToDelete.current = [];
      plansToDelete.current.push(plans[index]);
    }
    setPlans(plans.filter((plan, i) => i !== index));
    if (enhancedPlan !== null) setEnhancedPlan(null);
  };

  const handleZoom = (index) => {
    if (zoomedPlan === index) return setZoomedPlan(null);
    setZoomedPlan(index);
  };

  return (
    <div>
      <div className="caption">
        Подраздел
        <button className="close" onClick={checkInputsAndClose}></button>
      </div>

      <div className="field">
        <label>
          <input
            name="groupOrSub"
            type="radio"
            onClick={() => setGroupOrSub("group")}
            defaultChecked={groupOrSub === "group"}
          />
          Группу
        </label>
        <label>
          <input
            name="groupOrSub"
            type="radio"
            onClick={() => setGroupOrSub("sub")}
            defaultChecked={groupOrSub === "sub"}
          />
          Подраздел
        </label>
      </div>
      {groupOrSub === "group" ? (
        <AddSubsectionGroup
          close={close}
          checkInputs={checkInputs}
          checkInputsAndClose={checkInputsAndClose}
          groupId={groupId}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <InputField
            type="text"
            label="Название"
            name="name"
            value={subsection.name}
            onChange={handleChange}
            validationType="required"
            placeholder="Заливка фундамента"
            err={errors.name.error}
          />
          <SelectInput
            label="Статус"
            name="status_id"
            onChange={handleChange}
            defaultOption={subsection.status_id}
            options={statuses}
          />
          <div className="hdr">Планы</div>

          {plans.map((plan, i) => (
            <div key={i} className={`plan ${zoomedPlan === i ? "zoomed" : ""}`}>
              <div className="scroll">
                <div className="container">
                  <img src={plan.preview} alt="plan" />
                </div>
              </div>

              <span className="control">
                <button
                  className="zoom"
                  onClick={() => handleZoom(i)}
                  type="button"
                />
                <button
                  className="remove"
                  type="button"
                  onClick={() => handleDeletePlan(i)}
                >
                  Удалить
                </button>
              </span>
            </div>
          ))}
          <ImageUpload
            filesToUpload={plans}
            setFilesToUpload={setPlans}
            label="Кликните или перетащите сюда картинки"
            pdfToImg
          />
          <TextAreaInput
            label="Дополнительное описание"
            name="descriptions"
            value={subsection.descriptions}
            onChange={handleChange}
            height="15vh"
          />
          <VariablesList
            variableInputs={variableInputs}
            setVariableInputs={setVariableInputs}
            subsection={subToEdit}
          />

          <div className="info err">
            {errorMessage ||
              (!isDataValid && "Не все поля заполнены корректно")}
          </div>

          <div className="buttons">
            <button className="green" disabled={isFetching}>
              {isFetching ? "Загрузка..." : "Сохранить"}
            </button>
            <button
              className="cancel"
              type="button"
              onClick={checkInputsAndClose}
            >
              Отмена
            </button>
            {subToEdit && (
              <button
                className="remove"
                type="button"
                deletestyle="true"
                onClick={() => setConfirmDelete(true)}
                disabled={!subToEdit.deletable}
              />
            )}
          </div>
        </form>
      )}

      {confirmDelete && (
        <ConfirmDeleteModal
          message={`Удалить подраздел "${subsection.name}"?`}
          confirm={deleteSub}
          cancel={() => setConfirmDelete(false)}
          processing={isFetching}
          errorMsg={errorMessage}
        />
      )}
    </div>
  );
};

export default Subsection;
