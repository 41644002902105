import React, { useRef, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import GroupsList from "./groups-list-3";
import WorksListBySubAndUser from "./works-list-by-sub-and-user";
import InlineModal from "../../inlineModal";
import WorkType from "./work-type";
import WorkInfo from "./work-info";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, isEmpty } from "../../../utils/normalizeData";
import { getWorksTreeStart } from "../../../redux/works/works.actions";

const WorksAndGroups = ({ currentSite }) => {
  const { site_id, sub_id, info } = useParams();
  const [showOnlyMyWorks, toggleOnlyMyWorks] = useState(false);

  const [dayLineWidth, setDayLineWidth] = useState(50.2);
  const [bkStyles, setBkStyles] = useState({
    left: "55.2px",
    backgroundSize: "251.2px auto",
  });

  const currentUser = useSelector((state) => state.user.currentUser);
  // const [worksTree, setWorksTree] = useState();
  const worksTree = useSelector((state) => state.works.worksTree);
  const isFetching = useSelector((state) => state.works.fetchingWorksTree);
  const error = useSelector((state) => state.works.error);

  useEffect(() => {
    const changeDayLineWidth = () => {
      if (
        window.innerWidth < 990 &&
        window.innerWidth > 575 &&
        dayLineWidth !== 35.15
      ) {
        setDayLineWidth(35.15);
        setBkStyles({
          left: "50.15px",
          backgroundSize: "175.65px auto",
        });
      } else if (window.innerWidth >= 990 && dayLineWidth !== 50.2) {
        setDayLineWidth(50.2);
        setBkStyles({
          left: "55.2px",
          backgroundSize: "251.2px auto",
        });
      }
    };
    changeDayLineWidth();
    window.addEventListener("resize", changeDayLineWidth);
    return () => {
      window.removeEventListener("resize", changeDayLineWidth);
    };
  }, [dayLineWidth]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!worksTree) {
      // axios
      //   .get(`/api/job_tree_by_obj/${currentUser.user}/${site_id}`)
      //   .then((response) => {
      //     setWorksTree(response.data);
      //     console.log("WORKS TREE: ", response.data);
      //   })
      //   .catch((e) => console.log("ERROR TREE: ", e));
      dispatch(getWorksTreeStart(currentUser.user, site_id));
    }
  }, [currentUser.user, site_id, worksTree, dispatch]);

  const groupId = useRef();
  const [addWorkWindow, showAddWorkWindow] = useState();

  const handleAddWork = (group_id) => {
    groupId.current = group_id;
    showAddWorkWindow(true);
  };

  const renderDates = (minDate, maxDate) => {
    const dates = [];
    let startDate = new Date(minDate);
    let endDate = new Date(maxDate);
    while (startDate <= endDate) {
      dates.push(
        <span className="day" key={startDate}>
          {formatDate(startDate, "short")}
        </span>
      );
      startDate = new Date(startDate.getTime() + 60 * 60 * 24 * 1000);
    }
    return dates;
  };

  return (
    <>
      {info ? (
        <WorkInfo work_id={info} />
      ) : (
        <div className="content">
          {addWorkWindow && (
            <InlineModal close={() => showAddWorkWindow(false)}>
              <WorkType
                close={() => showAddWorkWindow(false)}
                group_id={groupId.current}
              />
            </InlineModal>
          )}

          <p className="only_my">
            <label>
              <input
                type="checkbox"
                checked={showOnlyMyWorks}
                onChange={() => toggleOnlyMyWorks(!showOnlyMyWorks)}
              />
              Показать только мои работы
            </label>
          </p>

          {sub_id || showOnlyMyWorks ? (
            <>
              <div className="buttons top">
                <button className="blue" onClick={() => handleAddWork(null)}>
                  добавить работу
                </button>
              </div>

              <WorksListBySubAndUser
                sub_id={sub_id}
                showOnlyMyWorks={showOnlyMyWorks}
              />
            </>
          ) : (
            <>
              {isFetching && <div className="info">Загрузка...</div>}
              {error && <div className="info err">{error}</div>}
              <div className="graph">
                <table>
                  <tbody>
                    <tr>
                      <td className="project">
                        {currentSite && (
                          <span className="project-name">
                            {currentSite.name}
                          </span>
                        )}

                        <Link
                          to="#"
                          className="add"
                          onClick={() => handleAddWork(null)}
                        />
                      </td>
                      {worksTree && "minDate" in worksTree && (
                        <td className="time">
                          {renderDates(worksTree.minDate, worksTree.maxDate)}
                        </td>
                      )}
                    </tr>

                    {!isEmpty(worksTree) && (
                      <GroupsList
                        handleAddWork={handleAddWork}
                        site_id={site_id}
                        job_group_id={null}
                        level={0}
                        groups={worksTree.groups}
                        works={worksTree.jobs}
                        siteMinDate={worksTree.minDate}
                        dayLineWidth={dayLineWidth}
                        bkStyles={bkStyles}
                      />
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default WorksAndGroups;
