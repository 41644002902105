import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  title: {
    paddingLeft: "5px",
  },
  add: {
    margin: "1% 0",
    "& button": {
      minWidth: "auto",
    },
  },
  delete: {
    border: "none",
    textDecoration: "underline",
    color: "#FF6262",
    backgroundColor: "white",
    cursor: "pointer",
    height: "12px",
    lineHeight: "12px",
    fontSize: "12px",
  },
  listItem: {
    padding: "1vh 0 1vh 5px",
  },
});
