import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../../utils/normalizeData";

import ReasonsActionTypes from "./reasons.types";

import {
  addReasonSuccess,
  addReasonFailure,
  getReasonsStart,
  getReasonsSuccess,
  getReasonsFailure,
  editReasonSuccess,
  editReasonFailure,
  deleteReasonSuccess,
  deleteReasonFailure,
} from "./reasons.actions";

export function* addReason({ payload, callback }) {
  const { newReasonName, adminId } = payload;
  const postData = {
    row: { name: newReasonName },
    who: adminId,
  };
  try {
    yield axios.post(`/api/admin/reasons/add`, postData);
    yield put(addReasonSuccess());
    yield put(getReasonsStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addReasonFailure(error));
  }
}

export function* getReasons({ payload }) {
  try {
    const response = yield axios.get(`/api/admin/reasons/${payload}`);
    yield put(getReasonsSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getReasonsFailure(error));
  }
}

export function* editReason({ payload, callback }) {
  const { reasonName, adminId, reasonId } = payload;
  const putReasonData = {
    row: { name: reasonName },
    who: adminId,
    reason: reasonId,
  };
  try {
    yield axios.put("/api/admin/reasons/edit", putReasonData);
    yield put(editReasonSuccess());
    yield put(getReasonsStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editReasonFailure(error));
  }
}

export function* deleteReason({ payload, callback }) {
  const { reasonId, adminId } = payload;
  try {
    yield axios.put("/api/admin/reasons/mark_as_deleted", {
      who: adminId,
      reason_id: reasonId,
    });
    yield put(deleteReasonSuccess(reasonId));
    yield put(getReasonsStart(adminId));
    callback();
  } catch (e) {
    let error = "Ошибка сети";
    if (e.response && e.response.data) {
      if (e.response.data.message) error = e.response.data.message;
      else if (e.response.data.errno) {
        if (e.response.data.errno === 1451)
          error = "Нельзя удалить специальность, прикрепленное к действиям";
      }
    }
    console.log(e.response);
    yield put(deleteReasonFailure(error));
  }
}

export function* onGetReasonsStart() {
  yield takeLeading(ReasonsActionTypes.GET_REASONS_START, getReasons);
}

export function* onAddReasonStart() {
  yield takeLatest(ReasonsActionTypes.ADD_REASON_START, addReason);
}

export function* onEditReasonStart() {
  yield takeLatest(ReasonsActionTypes.EDIT_REASON_START, editReason);
}

export function* onDeleteReasonStart() {
  yield takeLeading(ReasonsActionTypes.DELETE_REASON_START, deleteReason);
}

export function* reasonsSagas(args) {
  yield all([
    call(onGetReasonsStart),
    call(onAddReasonStart),
    call(onEditReasonStart),
    call(onDeleteReasonStart),
  ]);
}
