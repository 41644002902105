import { Fragment, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import WorkGroupContext from "../../../contexts/work-groups.context";
import {
  getSubsectionGroupsStart,
  getSubsectionsStart,
} from "../../../redux/subsections/subsections.actions";

const SubsectionsGroups = ({
  subsection_group_id = null,
  handleAddSubsection,
  handleGroupEdit,
  confirmDeleteGroup,
  site_id,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);

  const groups = useSelector((state) => state.subsections.groups);
  const subsections = useSelector((state) => state.subsections.subsections);
  const { openSubsectionGroups, toggleSubsectionGroup } =
    useContext(WorkGroupContext);

  useEffect(() => {
    if (!groups) {
      dispatch(getSubsectionGroupsStart(currentUser.user, site_id));
    }
  }, [groups, currentUser.user, dispatch, site_id]);

  useEffect(() => {
    if (!subsections) {
      dispatch(getSubsectionsStart(currentUser, site_id));
    }
  }, [currentUser, dispatch, site_id, subsections]);

  // console.log("OPEN SUB GROUPS: ", openSubsectionGroups);
  // console.log("SUBSECTIONS: ", subsections);
  return (
    <>
      {groups &&
        groups
          .filter((group) => group.subsection_group_id === subsection_group_id)
          .map((group, i) => (
            <div className="sub_group" key={i}>
              <Fragment>
                <span
                  className={`hdr ${
                    openSubsectionGroups.includes(group.id) ? "sel" : ""
                  }`}
                  onClick={() => toggleSubsectionGroup(group.id)}
                >
                  {group.name}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddSubsection(group.id);
                    }}
                  >
                    +
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleGroupEdit(group);
                    }}
                  >
                    Ред.
                  </button>
                  <button
                    className="remove"
                    onClick={(e) => {
                      e.stopPropagation();
                      confirmDeleteGroup(group);
                    }}
                  ></button>
                </span>

                {openSubsectionGroups.includes(group.id) && (
                  <SubsectionsGroups
                    subsection_group_id={group.id}
                    handleAddSubsection={handleAddSubsection}
                    handleGroupEdit={handleGroupEdit}
                    confirmDeleteGroup={confirmDeleteGroup}
                    site_id={site_id}
                  />
                )}
              </Fragment>
            </div>
          ))}

      {subsections &&
        subsections
          .filter((sub) => sub.subsection_group_id === subsection_group_id)
          .map((sub, i) => {
            const plans = Object.keys(sub.subsection_plans);
            return (
              <div className="sub_item" key={i}>
                <Link to={`/main/sites/${site_id}/subsections/${sub.id}/plan`}>
                  {sub.name}
                </Link>
                <small>
                  <span> {sub.status_name}</span>
                  {plans.length ? <span>Планы: {plans.length}</span> : ""}
                </small>
              </div>
            );
          })}
    </>
  );
};

export default SubsectionsGroups;
