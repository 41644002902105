import TechCardsActionTypes from "./techCards.types";

export const addTechCardStart = (newTechCardData, adminId, callback) => ({
  type: TechCardsActionTypes.ADD_TECH_CARD_START,
  payload: { newTechCardData, adminId },
  callback,
});

export const addTechCardSuccess = () => ({
  type: TechCardsActionTypes.ADD_TECH_CARD_SUCCESS,
});

export const addTechCardFailure = (error) => ({
  type: TechCardsActionTypes.ADD_TECH_CARD_FAILURE,
  payload: error,
});

export const getTechCardsStart = (groupId, adminId) => ({
  type: TechCardsActionTypes.GET_TECH_CARDS_START,
  payload: { groupId, adminId },
});

export const getTechCardsSuccess = (groupId, techCards) => ({
  type: TechCardsActionTypes.GET_TECH_CARDS_SUCCESS,
  payload: { groupId, techCards },
});

export const getTechCardsFailure = (error) => ({
  type: TechCardsActionTypes.GET_TECH_CARDS_FAILURE,
  payload: error,
});

export const editTechCardStart = (
  newTechCardData,
  adminId,
  callback,
  card_id,
  oldEditCardGroupId
) => ({
  type: TechCardsActionTypes.EDIT_TECH_CARD_START,
  payload: { newTechCardData, adminId, card_id, oldEditCardGroupId },
  callback,
});

export const editTechCardSuccess = () => ({
  type: TechCardsActionTypes.EDIT_TECH_CARD_SUCCESS,
});

export const editTechCardFailure = (error) => ({
  type: TechCardsActionTypes.EDIT_TECH_CARD_FAILURE,
  payload: error,
});

export const deleteTechCardStart = (
  techCardId,
  adminId,
  callback,
  groupId
) => ({
  type: TechCardsActionTypes.DELETE_TECH_CARD_START,
  payload: { techCardId, adminId, groupId },
  callback,
});

export const deleteTechCardSuccess = (deletedTechCardId) => ({
  type: TechCardsActionTypes.DELETE_TECH_CARD_SUCCESS,
  payload: deletedTechCardId,
});

export const deleteTechCardFailure = (error) => ({
  type: TechCardsActionTypes.DELETE_TECH_CARD_FAILURE,
  payload: error,
});

export const getCurrentTechCardStart = (cardId, adminId) => ({
  type: TechCardsActionTypes.GET_CURRENT_TECH_CARD_START,
  payload: { cardId, adminId },
});

export const getCurrentTechCardSuccess = (card) => ({
  type: TechCardsActionTypes.GET_CURRENT_TECH_CARD_SUCCESS,
  payload: card,
});

export const getCurrentTechCardFailure = (error) => ({
  type: TechCardsActionTypes.GET_CURRENT_TECH_CARD_FAILURE,
  payload: error,
});

export const addTechGroupStart = (groupName, groupId, adminId, callback) => ({
  type: TechCardsActionTypes.ADD_TECH_GROUP_START,
  payload: { groupName, groupId, adminId },
  callback,
});

export const addTechGroupSuccess = () => ({
  type: TechCardsActionTypes.ADD_TECH_GROUP_SUCCESS,
});

export const addTechGroupFailure = (error) => ({
  type: TechCardsActionTypes.ADD_TECH_GROUP_FAILURE,
  payload: error,
});

export const getTechGroupsStart = (adminId) => ({
  type: TechCardsActionTypes.GET_TECH_GROUPS_START,
  payload: adminId,
});

export const getTechGroupsSuccess = (techGroups) => ({
  type: TechCardsActionTypes.GET_TECH_GROUPS_SUCCESS,
  payload: techGroups,
});

export const editTechGroupStart = (groupName, groupId, adminId, callback) => ({
  type: TechCardsActionTypes.EDIT_TECH_GROUP_START,
  payload: { groupName, groupId, adminId },
  callback,
});

export const editTechGroupSuccess = () => ({
  type: TechCardsActionTypes.EDIT_TECH_GROUP_SUCCESS,
});

export const editTechGroupFailure = (error) => ({
  type: TechCardsActionTypes.EDIT_TECH_GROUP_FAILURE,
  payload: error,
});

export const getTechGroupsFailure = (error) => ({
  type: TechCardsActionTypes.GET_TECH_GROUPS_FAILURE,
  payload: error,
});

export const deleteTechGroupStart = (techGroupId, adminId, callback) => ({
  type: TechCardsActionTypes.DELETE_TECH_GROUP_START,
  payload: { techGroupId, adminId },
  callback,
});

export const deleteTechGroupSuccess = (deletedTechCardId) => ({
  type: TechCardsActionTypes.DELETE_TECH_GROUP_SUCCESS,
  payload: deletedTechCardId,
});

export const deleteTechGroupFailure = (error) => ({
  type: TechCardsActionTypes.DELETE_TECH_GROUP_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: TechCardsActionTypes.CLEAR_ERRORS,
});
