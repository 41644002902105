import { all, call, takeLeading, put } from "redux-saga/effects";
import axios from "axios";
import StatusesActionTypes from "./statuses.types";
import { getStatusesSuccess } from "./statuses.actions";

export function* getStatuses({ payload }) {
  try {
    const response = yield axios.get(`/api/all_statuses/${payload}`);
    // console.log("STATUSES ARE: ", response.data);
    yield put(getStatusesSuccess(response.data));
  } catch (e) {
    console.log(e);
  }
}

export function* onGetStatusesClaims() {
  yield takeLeading(StatusesActionTypes.GET_STATUSES_START, getStatuses);
}

export function* statusesSagas(args) {
  yield all([call(onGetStatusesClaims)]);
}
