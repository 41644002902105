import React from "react";
import ReactDOM from "react-dom";

const Modal = ({ message, confirm, cancel, processing, errorMsg }) => {
  return ReactDOM.createPortal(
    <div className="main">
      <div className="under_popup"></div>

      <div className="popup_small">
        <div className="message">{message}</div>
        <div className="info err">{errorMsg}</div>
        <div className="buttons">
          <button className="red" onClick={confirm} disabled={processing}>
            Да
          </button>
          <button className="cancel" onClick={cancel} disabled={processing}>
            Нет
          </button>
        </div>
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

export default Modal;
