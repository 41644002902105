import RevisionsActionTypes from "./revisions.types";

export const addRevisionStart = (
  revision,
  markerCoords,
  currentUser,
  site_id,
  callback
) => ({
  type: RevisionsActionTypes.ADD_REVISION_START,
  payload: { revision, markerCoords, currentUser, site_id },
  callback,
});

export const addRevisionSuccess = () => ({
  type: RevisionsActionTypes.ADD_REVISION_SUCCESS,
});

export const addRevisionFailure = (error) => ({
  type: RevisionsActionTypes.ADD_REVISION_FAILURE,
  payload: error,
});

export const getRevisionsStart = (currentUser, site_id) => ({
  type: RevisionsActionTypes.GET_REVISIONS_START,
  payload: { currentUser, site_id },
});

export const getRevisionsSuccess = (revisions) => ({
  type: RevisionsActionTypes.GET_REVISIONS_SUCCESS,
  payload: revisions,
});

export const getRevisionsFailure = (error) => ({
  type: RevisionsActionTypes.GET_REVISIONS_FAILURE,
  payload: error,
});

export const editRevisionStart = (
  revision,
  markerCoords,
  currentUser,
  site_id,
  callback,
  rev_id,
  coordsToDelete
) => ({
  type: RevisionsActionTypes.EDIT_REVISION_START,
  payload: {
    revision,
    currentUser,
    site_id,
    rev_id,
    markerCoords,
    coordsToDelete,
  },
  callback,
});

export const editRevisionSuccess = () => ({
  type: RevisionsActionTypes.EDIT_REVISION_SUCCESS,
});

export const editRevisionFailure = (error) => ({
  type: RevisionsActionTypes.EDIT_REVISION_FAILURE,
  payload: error,
});

export const deleteRevisionStart = (revisionData, close) => ({
  type: RevisionsActionTypes.DELETE_REVISION_START,
  payload: revisionData,
  callback: close,
});

export const deleteRevisionSuccess = () => ({
  type: RevisionsActionTypes.DELETE_REVISION_SUCCESS,
});

export const deleteRevisionFailure = (error) => ({
  type: RevisionsActionTypes.DELETE_REVISION_FAILURE,
  payload: error,
});

export const postponeRevisionStart = (
  revData,
  currentUser,
  site_id,
  callback
) => ({
  type: RevisionsActionTypes.POSTPONE_REVISION_START,
  payload: { revData, currentUser, site_id, callback },
});

export const postponeRevisionSuccess = () => ({
  type: RevisionsActionTypes.POSTPONE_REVISION_SUCCESS,
});

export const postponeRevisionFailure = (error) => ({
  type: RevisionsActionTypes.POSTPONE_REVISION_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: RevisionsActionTypes.CLEAR_ERRORS,
});
