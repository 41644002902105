import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { user_photos } from "../../config";
import InlineModal from "../inlineModal";
import AddUser from "./add-user";

const User = ({ userId, showUser, basePath }) => {
  const usersList = useSelector((state) => state.user.usersList);
  const { admin } = useSelector((state) => state.user.currentUser);
  const history = useHistory();
  const { user_id, action } = useParams();
  // console.log("BASE PATH: ", basePath);

  const [userDetails, setUserDetails] = useState();
  // const [editMode, setEditMode] = useState();

  useEffect(() => {
    if (usersList) {
      setUserDetails(usersList.find((user) => user.id === userId));
    }
  }, [userId, usersList]);

  return (
    <>
      <div className="caption">
        Пользователь
        <button className="close" onClick={showUser}></button>
      </div>
      {userDetails && (
        <>
          {userDetails.blocked ? (
            <div className="warning">Заблокирован</div>
          ) : (
            ""
          )}

          <div className="two_col">
            <div className="col">
              <div className="field">
                <label>Имя</label>
                <span className="data">{`${userDetails.firstname} ${userDetails.lastname}`}</span>
              </div>

              <div className="field">
                <label>Электронная почта</label>
                <span className="data">
                  <a
                    className="contact_link"
                    href={`mailto: ${userDetails.email}`}
                  >
                    {userDetails.email}
                  </a>
                </span>
              </div>

              {userDetails.tel ? (
                <div className="field">
                  <label>Телефон</label>
                  <span className="data">
                    <a
                      className="contact_link"
                      href={`tel: ${userDetails.tel}`}
                    >
                      {userDetails.tel}
                    </a>
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col">
              {userDetails.img && (
                <div className="field">
                  <label>Фото</label>
                  <img
                    className="user_photo"
                    src={`${user_photos}/${userDetails.org_id}/${userDetails.img}`}
                    alt="avatar"
                  />
                </div>
              )}
            </div>
          </div>

          {admin ? (
            <>
              <div className="buttons left">
                <button
                  className="blue"
                  /*onClick={() => setEditMode(true)}*/ onClick={() =>
                    history.push(`${basePath}/user/${userId}/edit_user`)
                  }
                >
                  Редактировать
                </button>
              </div>

              {action === "edit_user" && (
                <InlineModal
                  close={() => history.push(`${basePath}/user/${user_id}`)}
                >
                  <AddUser
                    userToEdit={userDetails}
                    close={() => history.push(`${basePath}/user/${user_id}`)}
                    closeAfterDelete={() => history.push(basePath)}
                  />
                </InlineModal>
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default User;
