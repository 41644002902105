import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DatePicker from "react-date-picker";
import axios from "axios";
import {
  addChecklistStart,
  clearErrors,
} from "../../../redux/checklists/checklists.actions";
import PreviousActions from "../previousActions/previous-actions";
import { InputField, TextAreaInput } from "../../input-forms";
import AddBackup from "../add-backup";
import SetPointsOnPlans from "../pointsOnPlan/set-points-on-plans";
import SelectSubsection from "../subsections/select-subsection";
import SelectContractor from "../contractors/select-contractor";
import { validate /*, isEmpty*/ } from "../../../utils/normalizeData";

const AddChecklist = ({
  checkInputs,
  rev_id,
  close,
  closeResult,
  checkInputsAndClose,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  //   const claims = useSelector((state) => state.claims.claims);
  const revisions = useSelector((state) => state.revisions.revisions);

  const currentUser = useSelector((state) => state.user.currentUser);

  const isFetching = useSelector((state) => state.checklists.isFetching);
  const errorMessage = useSelector((state) => state.checklists.error);

  const currentSub = useSelector(
    (state) => state.subsections.currentSubsection
  );

  const [subToAct, selectSub] = useState(null);
  const [checklistData, setChecklistData] = useState({
    title: "",
    num: "",
    descriptions: "",
    contractor_id: "",
    revision_id: null,
    subsection_id: "",
    claim_id: null,
    user_id: currentUser.user,
  });
  const [signedDate, setSignedDate] = useState(new Date());

  const [errors, setErrors] = useState({
    subsection: { type: "required", error: null },
    num: { type: "required", error: null },
    contractor_id: { type: "required", error: null },
  });
  const [isDataValid, setIsDataValid] = useState(true);

  const backupPhotos = useRef();

  // check inputs and prompt the user when he
  // tries to close the window without saving
  checkInputs.current = () => {
    if (
      checklistData.title ||
      checklistData.item ||
      checklistData.num ||
      checklistData.descriptions ||
      checklistData.contractor_id ||
      checklistData.claim_id ||
      checklistData.revision_id ||
      signedDate.getDate() !== new Date().getDate() ||
      (!currentSub && subToAct) ||
      (currentSub && currentSub.id !== subToAct.id) ||
      (backupPhotos.current && backupPhotos.current.length)
    )
      return true;
    return false;
  };

  // this id is used in SelectSubsections component to find the neccessary subsection and set it in this component
  const [idToGetSub, setIdToGetSub] = useState(null);

  const { site_id } = useParams();

  // if this window (act creation window) is opened through the revision results window,
  // then set all the inputs according to the data specified in the given revision,
  // i.e. select the appropriate subsection, revision, revision's claim, contractor
  useEffect(() => {
    if (rev_id) {
      const revisionToAct = revisions.find(
        (revision) => revision.id === rev_id
      );
      if (revisionToAct) {
        setIdToGetSub(revisionToAct.subsection_id);
        setChecklistData((checklistData) => ({
          ...checklistData,
          revision_id: revisionToAct.id,
          claim_id: revisionToAct.claim_id || null,
          contractor_id: revisionToAct.contractor_id,
        }));
      }
    }
  }, [rev_id, revisions]);

  const [markerCoords, setMarkerCoords] = useState([]);

  // reset previous claim and revision selects if user changes subsection
  useEffect(() => {
    if (subToAct) {
      if (subToAct.id === idToGetSub) return;
      setChecklistData((a) => ({ ...a, claim_id: null, revision_id: null }));
      if (idToGetSub) setIdToGetSub(null);
    }
  }, [subToAct, idToGetSub]);

  // check if all the input data errors have been eliminated
  useEffect(() => {
    if (!isDataValid) {
      for (const i in errors) {
        if (errors[i].error) return;
      }
      setIsDataValid(true);
    }
  }, [errors, isDataValid]);

  // automatically set the subsection, contractor and points, if a previous claim has been selected
  //   useEffect(() => {
  //     if (checklistData.claim_id) {
  //       const previousClaim = claims.find(
  //         (claim) => claim.id === checklistData.claim_id
  //       );
  //       if (previousClaim) {
  //         if (!isEmpty(previousClaim.claim_plans)) {
  //           setPreviousPoints(Object.values(previousClaim.claim_plans));
  //         }
  //         if (previousClaim.subsection_id !== idToGetSub) {
  //           setIdToGetSub(previousClaim.subsection_id);
  //         }
  //         setChecklistData((checklistData) => ({
  //           ...checklistData,
  //           contractor_id: previousClaim.contractor_id,
  //         }));
  //       }
  //     }
  //   }, [checklistData.claim_id, claims, idToGetSub]);

  const setPreviousPoints = (pointsArr) => {
    setMarkerCoords(
      pointsArr.map((point) => ({
        dot_x: point.dot_x,
        dot_y: point.dot_y,
        plan_id: point.plan_id,
      }))
    );
  };

  // automatically set the subsection, contractor and points, if a previous revision has been selected
  useEffect(() => {
    if (checklistData.revision_id) {
      const previousRevision = revisions.find(
        (revision) => revision.id === checklistData.revision_id
      );
      if (previousRevision) {
        axios
          .get(
            `/api/revision_plan_points_revision/${currentUser.user}/${previousRevision.id}`
          )
          .then((response) => {
            setPreviousPoints(response.data);
          })
          .catch((e) => console.log(e));

        if (previousRevision.subsection_id !== idToGetSub) {
          setIdToGetSub(previousRevision.subsection_id);
        }

        setChecklistData((checklistData) => ({
          ...checklistData,
          contractor_id: previousRevision.contractor_id,
        }));
      }

      if (previousRevision && previousRevision.subsection_id !== idToGetSub) {
        setIdToGetSub(previousRevision.subsection_id);
      }
    }
  }, [checklistData.revision_id, revisions, idToGetSub, currentUser.user]);

  const handleSelectChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      checkValidity(e);
    }
    setChecklistData({
      ...checklistData,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const handleChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      checkValidity(e);
    }
    setChecklistData({ ...checklistData, [e.target.name]: e.target.value });
  };

  const checkValidity = (e) => {
    validate(
      e.target.value,
      () => {},
      () =>
        setErrors({
          ...errors,
          [e.target.name]: { ...errors[e.target.name], error: null },
        }),
      errors[e.target.name].type
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    for (const i in errors) {
      if (i === "subsection") {
        if (!subToAct) {
          setErrors((errors) => ({
            ...errors,
            subsection: { ...errors.subsection, error: "Выберите подраздел" },
          }));
          valid = false;
          if (isDataValid) setIsDataValid(false);
        }
      } else if (!checklistData[i]) {
        setErrors((errors) => ({
          ...errors,
          [i]: { ...errors[i], error: "Обязательное поле" },
        }));
        valid = false;
        if (isDataValid) setIsDataValid(false);
      }
    }
    if (!valid) return;

    const callback = closeResult || close;

    dispatch(
      addChecklistStart(
        {
          ...checklistData,
          subsection_id: subToAct.id,
          date: signedDate,
        },
        markerCoords,
        backupPhotos.current,
        currentUser,
        site_id,
        callback
      )
    );
  };

  return (
    <>
      <div className="caption">
        Создать чеклист
        <button className="close" onClick={checkInputsAndClose}></button>
      </div>
      <PreviousActions
        revisionBtn
        revision_id={checklistData.revision_id}
        claim_id={checklistData.claim_id}
        handleChange={handleSelectChange}
        subsectionId={subToAct && subToAct.id}
        clearData={setChecklistData}
      />
      <form onSubmit={handleSubmit}>
        <SelectSubsection
          subsection={subToAct}
          selectSub={selectSub}
          idToGetSub={idToGetSub}
          markerCoords={markerCoords}
          setMarkerCoords={setMarkerCoords}
          validationType="required"
          err={errors.subsection.error}
          setErrors={setErrors}
        />

        <SelectContractor
          contractorId={checklistData.contractor_id}
          selectContractorId={handleSelectChange}
          validationType="required"
          err={errors.contractor_id.error}
        />

        <InputField
          type="text"
          label="Номер чеклиста"
          name="num"
          value={checklistData.num}
          onChange={handleChange}
          autoComplete="off"
          validationType="required"
          placeholder="xxx"
          err={errors.num.error}
        />

        <div className="field">
          <label>Дата</label>
          <DatePicker
            onChange={setSignedDate}
            value={signedDate}
            clearIcon={null}
            format="dd-MM-yyyy"
            validationType="required"
            locale="ru-RU"
          />
        </div>

        <InputField
          type="text"
          label="Краткое название"
          name="title"
          value={checklistData.title}
          onChange={handleChange}
          autoComplete="off"
          placeholder="Заливка фундамента, побелка стены"
        />

        <TextAreaInput
          label="Текст"
          height="20vh"
          name="descriptions"
          value={checklistData.descriptions}
          onChange={handleChange}
        />

        <SetPointsOnPlans
          subsection={subToAct}
          markerCoords={markerCoords}
          setMarkerCoords={setMarkerCoords}
          pinType={
            checklistData.claim_id || checklistData.revision_id ? "many" : "act"
          }
        />

        <AddBackup
          filesForSubmit={backupPhotos}
          label="Кликните или перетащите картинки сюда"
        />

        <div className="info">
          После создания чеклиста, не будет возможности его редактировать.
        </div>
        <div className="info err">
          {errorMessage || (!isDataValid && "Не все поля заполнены корректно")}
        </div>

        <div className="buttons">
          <button className="green" disabled={isFetching}>
            {isFetching ? "загрузка..." : "добавить"}
          </button>
          <button
            className="cancel"
            type="button"
            onClick={checkInputsAndClose}
            disabled={isFetching}
          >
            Отмена
          </button>
        </div>
      </form>
    </>
  );
};

export default AddChecklist;
