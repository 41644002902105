import React from "react";
import Header from "../components/Header";
import SideMenu from "../components/SideMenu";
import MainSection from "../components/MainSection";
import { useAdminPanelStyles } from "../../css/admin/admin-panel.styles";

const AdminPanel = () => {
  const classes = useAdminPanelStyles();
  return (
    <>
      <Header />
      <div className={classes.adminPanel}>
        <SideMenu />
        <MainSection />
      </div>
    </>
  );
};

export default AdminPanel;
