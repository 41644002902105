import ChecklistsActionTypes from "./checklists.types";

export const addChecklistStart = (
  newChecklistData,
  markerCoords,
  photos,
  currentUser,
  site_id,
  callback
) => ({
  type: ChecklistsActionTypes.ADD_CHECKLIST_START,
  payload: { newChecklistData, markerCoords, photos, currentUser, site_id },
  callback,
});

export const addChecklistSuccess = () => ({
  type: ChecklistsActionTypes.ADD_CHECKLIST_SUCCESS,
});

export const addChecklistFailure = (error) => ({
  type: ChecklistsActionTypes.ADD_CHECKLIST_FAILURE,
  payload: error,
});

export const getChecklistsStart = (sub_id, userId) => ({
  type: ChecklistsActionTypes.GET_CHECKLISTS_START,
  payload: { sub_id, userId },
});

export const getChecklistsSuccess = (checklists, currentSubId) => ({
  type: ChecklistsActionTypes.GET_CHECKLISTS_SUCCESS,
  payload: { checklists, currentSubId },
});

export const getChecklistsFailure = (error) => ({
  type: ChecklistsActionTypes.GET_CHECKLISTS_FAILURE,
  payload: error,
});

export const editChecklistStart = (act) => ({
  type: ChecklistsActionTypes.EDIT_ACT_START,
  payload: act,
});

export const editChecklistSuccess = () => ({
  type: ChecklistsActionTypes.EDIT_ACT_SUCCESS,
});

export const editChecklistFailure = (error) => ({
  type: ChecklistsActionTypes.EDIT_ACT_FAILURE,
  payload: error,
});

export const deleteChecklistStart = (act) => ({
  type: ChecklistsActionTypes.DELETE_ACT_START,
  payload: act,
});

export const deleteChecklistSuccess = () => ({
  type: ChecklistsActionTypes.DELETE_ACT_SUCCESS,
});

export const deleteChecklistFailure = (error) => ({
  type: ChecklistsActionTypes.DELETE_ACT_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: ChecklistsActionTypes.CLEAR_ERRORS,
});
