import SitesActionTypes from "./sites.types";

const INITIAL_STATE = {
  isFetching: false,
  siteError: null,
  sites: [],
  savedSiteDetails: null,
};

const sitesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SitesActionTypes.ADD_SITE_START:
    case SitesActionTypes.EDIT_SITE_START:
    case SitesActionTypes.GET_SITES_START:
    case SitesActionTypes.DELETE_SITE_START:
      return { ...state, isFetching: true, error: null };

    case SitesActionTypes.ADD_SITE_SUCCESS:
    case SitesActionTypes.EDIT_SITE_SUCCESS:
    case SitesActionTypes.DELETE_SITE_SUCCESS:
      return { ...state, isFetching: false, error: null };

    case SitesActionTypes.GET_SITES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        sites: action.payload,
      };

    case SitesActionTypes.ADD_SITE_FAILURE:
    case SitesActionTypes.EDIT_SITE_FAILURE:
    case SitesActionTypes.GET_SITES_FAILURE:
    case SitesActionTypes.DELETE_SITE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case SitesActionTypes.SAVE_SITE_DETAILS:
      return { ...state, savedNewSiteDetails: action.payload };

    case SitesActionTypes.CLEAR_SITE_DETAILS:
      return { ...state, savedNewSiteDetails: null };

    case SitesActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default sitesReducer;
