import ResourcesActionTypes from "./resources.types";

export const addSpecialtyStart = (newSpecialtyData, callback) => ({
  type: ResourcesActionTypes.ADD_SPECIALTY_START,
  payload: newSpecialtyData,
  callback,
});

export const addSpecialtySuccess = () => ({
  type: ResourcesActionTypes.ADD_SPECIALTY_SUCCESS,
});

export const addSpecialtyFailure = (error) => ({
  type: ResourcesActionTypes.ADD_SPECIALTY_FAILURE,
  payload: error,
});

export const getSpecialtiesStart = (currentUserId) => ({
  type: ResourcesActionTypes.GET_SPECIALTIES_START,
  payload: currentUserId,
});

export const getSpecialtiesSuccess = (specialties) => ({
  type: ResourcesActionTypes.GET_SPECIALTIES_SUCCESS,
  payload: specialties,
});

export const getSpecialtiesFailure = (error) => ({
  type: ResourcesActionTypes.GET_SPECIALTIES_FAILURE,
  payload: error,
});

export const getEquipmentStart = (currentUserId) => ({
  type: ResourcesActionTypes.GET_EQUIPMENT_START,
  payload: currentUserId,
});

export const getEquipmentSuccess = (equipment) => ({
  type: ResourcesActionTypes.GET_EQUIPMENT_SUCCESS,
  payload: equipment,
});

export const getEquipmentFailure = (error) => ({
  type: ResourcesActionTypes.GET_EQUIPMENT_FAILURE,
  payload: error,
});

export const getMaterialsStart = (currentUserId) => ({
  type: ResourcesActionTypes.GET_MATERIALS_START,
  payload: currentUserId,
});

export const getMaterialsSuccess = (equipment) => ({
  type: ResourcesActionTypes.GET_MATERIALS_SUCCESS,
  payload: equipment,
});

export const getMaterialsFailure = (error) => ({
  type: ResourcesActionTypes.GET_MATERIALS_FAILURE,
  payload: error,
});

export const editSpecialtyStart = (specialty, callback) => ({
  type: ResourcesActionTypes.EDIT_SPECIALTY_START,
  payload: specialty,
  callback,
});

export const editSpecialtySuccess = () => ({
  type: ResourcesActionTypes.EDIT_SPECIALTY_SUCCESS,
});

export const editSpecialtyFailure = (error) => ({
  type: ResourcesActionTypes.EDIT_SPECIALTY_FAILURE,
  payload: error,
});

export const deleteSpecialtyStart = (specialty, callback) => ({
  type: ResourcesActionTypes.DELETE_SPECIALTY_START,
  payload: specialty,
  callback,
});

export const deleteSpecialtySuccess = () => ({
  type: ResourcesActionTypes.DELETE_SPECIALTY_SUCCESS,
});

export const deleteSpecialtyFailure = (error) => ({
  type: ResourcesActionTypes.DELETE_SPECIALTY_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: ResourcesActionTypes.CLEAR_ERRORS,
});
