import React, { useEffect } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getSingleSubsectionStart,
  // setCurrentSubsection,
} from "../../redux/subsections/subsections.actions";
import Subsection from "./Subsection";
import WorkInfo from "../../components/manage-sites/works/work-info";

const SubsectionRoutes = ({ match: { path } }) => {
  const { site_id, sub_id } = useParams();
  // const subsections = useSelector((state) => state.subsections.subsections);
  const currentSub = useSelector(
    (state) => state.subsections.currentSubsection
  );
  const sites = useSelector((state) => state.sites.sites);
  const currentSite = sites.find((site) => site.id === parseInt(site_id));
  const currentUser = useSelector((state) => state.user.currentUser);

  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (subsections.length) {
  //     const subToSet = subsections.find((sub) => sub.id === parseInt(sub_id));
  //     if (subToSet) dispatch(setCurrentSubsection(subToSet));
  //   }
  // }, [dispatch, sub_id, subsections]);

  useEffect(() => {
    if (!currentSub || currentSub.id !== +sub_id) {
      dispatch(getSingleSubsectionStart(currentUser.user, site_id, sub_id));
    }
  }, [currentSub, currentUser.user, site_id, sub_id, dispatch]);

  return (
    <Switch>
      <Route
        exact
        path={
          "/main/sites/:site_id/subsections/:sub_id/works/:work_id/:action?"
        }
        component={WorkInfo}
      />

      <Route
        exact
        path={path}
        render={() => {
          return (
            <Subsection currentSite={currentSite} currentSub={currentSub} />
          );
        }}
      />
    </Switch>
  );
};

export default SubsectionRoutes;
