import React, { useEffect, useState } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubsectionsStart,
  clearSubsections,
} from "../../redux/subsections/subsections.actions";
import { getRevisionsStart } from "../../redux/revisions/revisions.actions";
import { getContractorsStart } from "../../redux/contractors/contractors.actions";
import { getClaimsStart } from "../../redux/claims/claims.actions";
import { getActsStart } from "../../redux/acts/acts.actions";
import SiteInfo from "./Site-info";
import SubsectionRoutes from "./subsection-routes";
import OpenGroupsContext from "../../contexts/work-groups.context";
// import WorkInfo from "../../components/manage-sites/works/work-info";

const SitesRoutes = ({ match: { path } }) => {
  const sites = useSelector((state) => state.sites.sites);
  const currentUser = useSelector((state) => state.user.currentUser);
  const { site_id } = useParams();

  const [currentSite, setCurrentSite] = useState(null);

  const [openWorkGroups, setOpenWorkGroups] = useState([]);

  const toggleOpenWorkGroup = (groupId) => {
    if (openWorkGroups.includes(groupId)) {
      return setOpenWorkGroups(openWorkGroups.filter((id) => id !== groupId));
    }
    setOpenWorkGroups([...openWorkGroups, groupId]);
  };

  const [openSubsectionGroups, setOpenSubsectionGroup] = useState([]);

  const toggleSubsectionGroup = (groupId) => {
    if (openSubsectionGroups.includes(groupId)) {
      return setOpenSubsectionGroup(
        openSubsectionGroups.filter((id) => id !== groupId)
      );
    }
    setOpenSubsectionGroup([...openSubsectionGroups, groupId]);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (sites.length) {
      setCurrentSite(sites.find((site) => String(site.id) === site_id));
    }
  }, [sites, currentSite, site_id]);

  useEffect(() => {
    if (currentUser) {
      dispatch(clearSubsections());
      dispatch(getSubsectionsStart(currentUser, site_id));
      dispatch(getContractorsStart(currentUser, site_id));
      dispatch(getRevisionsStart(currentUser, site_id));
      dispatch(getClaimsStart(currentUser, site_id));
      dispatch(getActsStart(currentUser, site_id));
    }
  }, [dispatch, currentUser, site_id]);

  return (
    <Switch>
      {/* <Route
        exact
        path={`${path}/works/:work_id/:action?`}
        render={() => {
          return <WorkInfo />;
        }}
      /> */}

      <Route
        exact
        path={[`${path}/subsections/:sub_id/:menu/:info?/:action?/:action2?`]}
        component={SubsectionRoutes}
      />

      <Route
        exact
        path={[
          `${path}/:menu/:info/user/:user_id/:action?/`,
          `${path}/:menu/:info?/:action?/:action2?`,
        ]}
        render={() => (
          <OpenGroupsContext.Provider
            value={{
              openWorkGroups,
              toggleOpenWorkGroup,
              openSubsectionGroups,
              toggleSubsectionGroup,
            }}
          >
            <SiteInfo currentSite={currentSite} />
          </OpenGroupsContext.Provider>
        )}
      />
    </Switch>
  );
};

export default SitesRoutes;
