import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVariablesStart } from "../../../redux/variables/variables.actions";
import { isEmpty } from "../../../utils/normalizeData";
import { InputField } from "../../input-forms";

const VariablesList = ({ variableInputs, setVariableInputs, subsection }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user.currentUser);
  const variables = useSelector((state) => state.variables.variables);
  const isFetching = useSelector((state) => state.variables.isFetching);

  useEffect(() => {
    if (variables === null) {
      dispatch(getVariablesStart(currentUser.user));
    } else {
      let subsectionVariables =
        subsection && Object.values(subsection.subsection_variables);
      const variablesState = variables.reduce((variableInputs, current) => {
        const subsectionVariable =
          subsectionVariables &&
          subsectionVariables.find(
            (variable) => variable.value && variable.variable_id === current.id
          );
        if (subsectionVariable) {
          variableInputs[current.id] = subsectionVariable.value;
        } else {
          variableInputs[current.id] = "";
        }
        return variableInputs;
      }, {});
      setVariableInputs(variablesState);
    }
  }, [variables, dispatch, currentUser, setVariableInputs, subsection]);

  console.log("VARIABLE INPUTS: ", variableInputs);
  console.log("VARIABLES: ", variables);

  const handleChange = (e) => {
    let value;
    if (e.target.value === "" || e.target.value === undefined) value = "";
    else if (isNaN(+e.target.value)) return;
    else value = +e.target.value;
    setVariableInputs((v) => ({ ...v, [e.target.id]: value }));
  };

  return (
    <div>
      <div className="hdr">Переменные</div>

      {isFetching && <div>загрузка переменных...</div>}

      {variables &&
        variables.length &&
        !isEmpty(variableInputs) &&
        variables.map((variable) => (
          <InputField
            value={variableInputs[variable.id]}
            key={variable.id}
            id={variable.id}
            label={variable.name_print}
            onChange={handleChange}
            type="number"
            step="0.00001"
            pattern="[0-9]+([\.,][0-9]+)?"
          />
        ))}
    </div>
  );
};

export default VariablesList;
