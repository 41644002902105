import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { formatDate } from "../../../utils/normalizeData";
import InlineModal from "../../inlineModal";
import ViewPoint from "../view-point";
import AddChecklist from "./add-checklist";

const Checklists = ({ currentSub }) => {
  const { site_id, sub_id, menu, info } = useParams();
  const checklists = useSelector((state) => state.checklists);
  const contractors = useSelector((state) => state.contractors.contractors);

  const [addChecklistModal, showAddChecklistModal] = useState();

  const history = useHistory();

  // console.log("CHECKLISTS: ", checklists.checklists);
  // console.log("CONTRACTORS: ", contractors);

  return (
    <div className="content">
      {addChecklistModal && (
        <InlineModal close={() => showAddChecklistModal(false)}>
          <AddChecklist close={() => showAddChecklistModal(false)} />
        </InlineModal>
      )}

      {menu === "checklists" && info && (
        <InlineModal>
          <ViewPoint
            checklist_id={+info}
            close={() =>
              history.push(
                `/main/sites/${site_id}/subsections/${sub_id}/checklists`
              )
            }
          />
        </InlineModal>
      )}

      <div className="buttons top">
        <button className="blue" onClick={() => showAddChecklistModal(true)}>
          Выписать чеклист
        </button>
      </div>

      {checklists.checklists && checklists.currentSubId === sub_id && (
        <table className="list">
          <thead>
            <tr>
              <th>Номер</th>
              <th>Дата подписания</th>
              <th>Подраздел</th>
              <th>Подрядчик</th>
              <th>Описание</th>
            </tr>
          </thead>

          <tbody>
            {checklists.checklists.map((checklist, i) => {
              const contractor = contractors.find(
                (contractor) => contractor.id === checklist.contractor_id
              );
              return (
                <tr key={i}>
                  <td>
                    <label className="mobile">Номер</label>
                    <Link
                      to={`/main/sites/${site_id}/subsections/${sub_id}/checklists/${checklist.id}`}
                    >
                      {checklist.num}
                    </Link>
                  </td>

                  <td>
                    <label className="mobile">Дата</label>
                    {formatDate(checklist.date, true)}
                  </td>

                  <td>
                    <label className="mobile">Подраздел</label>
                    {currentSub.name}
                  </td>

                  <td>
                    <label className="mobile">Подрядчик</label>
                    {contractor ? contractor.name : ""}
                  </td>

                  <td>
                    <label className="mobile">Описание</label>
                    {checklist.descriptions}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Checklists;
