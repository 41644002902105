const formatPhoneNumber = (tel) => {
  let digits = tel.replace(/[^\d]/g, "");

  const l = digits.length;
  let phoneNumber = "";

  if (digits) {
    phoneNumber = `+${digits[0]}`;
    if (l > 1 && l <= 4 && !tel[7] !== ")")
      phoneNumber += ` (${digits.substring(1)}`;
    else if (l >= 4 && l <= 7 && tel[12] !== "-")
      phoneNumber += ` (${digits.substring(1, 4)}) ${digits.substring(4)}`;
    else if (l >= 7 && l <= 9 && tel[15] !== "-")
      phoneNumber += ` (${digits.substring(1, 4)}) ${digits.substring(
        4,
        7
      )}-${digits.substring(7)}`;
    else if (l >= 9)
      phoneNumber += ` (${digits.substring(1, 4)}) ${digits.substring(
        4,
        7
      )}-${digits.substring(7, 9)}-${digits.substring(9, 11)}`;
  }

  return phoneNumber;
};

export default formatPhoneNumber;
