import React, { useEffect, useRef, useState } from "react";
import { TextAreaInput } from "../../input-forms";
import AddBackup from "../add-backup";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleWorkStart,
  addWorkResultStart,
} from "../../../redux/works/works.actions";
import { useHistory, useParams } from "react-router";

const CheckWork = ({ work_id, checkInputs, close, checkInputsAndClose }) => {
  const [status, setStatus] = useState("complete");
  const [resultData, setResultData] = useState({
    quality: 0,
    consumption: 0,
    equipment: 0,
    descriptions: "",
  });
  const backupPhotos = useRef();
  const currentWork = useSelector((state) => state.works.currentWork);
  const currentUser = useSelector((state) => state.user.currentUser);
  const { site_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentWork || currentWork.id !== work_id) {
      dispatch(getSingleWorkStart(currentUser.user, work_id));
    }
  }, [currentWork, work_id, dispatch, currentUser.user]);

  checkInputs.current = () => {
    if (
      resultData.quality ||
      resultData.consumption ||
      resultData.equipment ||
      resultData.descriptions ||
      (backupPhotos.current && backupPhotos.current.length)
    )
      return true;
    return false;
  };

  const statusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      if (e.target.checked) {
        return setResultData({
          ...resultData,
          [e.target.name]: 1,
        });
      }
      return setResultData({ ...resultData, [e.target.name]: 0 });
    }
    setResultData({ ...resultData, [e.target.name]: e.target.value });
  };

  const history = useHistory();
  const isFetching = useSelector((state) => state.works.isFetching);

  const handleSubmit = () => {
    if (currentWork) {
      if (status === "complete") {
        return dispatch(
          addWorkResultStart(
            { ...resultData, job_id: currentWork.id },
            currentUser,
            backupPhotos.current,
            close
          )
        );
      }
      if (currentWork.user_id) {
        return history.push(
          `/main/sites/${site_id}/works/${work_id}/add_remark`
        );
      }
      history.push(`/main/sites/${site_id}/works/${work_id}/add_claim`);
    }
  };

  return (
    <>
      <div class="caption">
        Проверка выполнения
        <button className="close" onClick={checkInputsAndClose} />
      </div>
      <div className="two_col">
        <div className="col">
          <div className="field">
            <input
              type="radio"
              name="status"
              id="complete"
              value="complete"
              checked={status === "complete"}
              onChange={statusChange}
            />
            <label htmlFor="complete">Выполнена</label>
          </div>
        </div>
        <div className="col">
          <div className="field">
            <input
              type="radio"
              name="status"
              id="incomplete"
              value="incomplete"
              checked={status === "incomplete"}
              onChange={statusChange}
            />
            <label htmlFor="incomplete">Не выполнена</label>
          </div>
        </div>
      </div>

      {status === "complete" && (
        <>
          <div className="field">
            <input
              type="checkbox"
              name="quality"
              id="quality"
              onChange={handleChange}
            />
            <label htmlFor="quality">Все сделано качественно</label>
          </div>

          <div className="field">
            <input
              type="checkbox"
              name="consumption"
              id="consumption"
              onChange={handleChange}
            />
            <label htmlFor="consumption">
              Расход материала соответствует указанному
            </label>
          </div>

          <div className="field">
            <input
              type="checkbox"
              name="equipment"
              id="equipment"
              onChange={handleChange}
            />
            <label htmlFor="equipment">
              Оборудование не потеряно и не испорчено
            </label>
          </div>

          <TextAreaInput
            label="Комментарий"
            name="descriptions"
            value={resultData.descriptions}
            onChange={handleChange}
          />

          <AddBackup
            filesForSubmit={backupPhotos}
            label="Кликните или перетащите сюда картинки"
          />
        </>
      )}
      <div className="buttons">
        <button className="green" onClick={handleSubmit} disabled={isFetching}>
          {status === "complete"
            ? "Сохранить"
            : `Создать ${
                currentWork && currentWork.user_id ? "замечание" : "предписание"
              }`}
        </button>
        <button className="cancel" onClick={checkInputsAndClose}>
          Отмена
        </button>
      </div>
    </>
  );
};

export default CheckWork;
