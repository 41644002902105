import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-date-picker";
import { TextAreaInput } from "../../input-forms";
import AddBackup from "../add-backup";
import { addReportStart } from "../../../redux/reports/reports.actions";
import { formatDate, validate } from "../../../utils/normalizeData";
import { useParams } from "react-router";
// import { Link } from "react-router-dom";
// import { user_photos } from "../../../config";

const AddReport = ({ checkInputsAndClose, close, checkInputs }) => {
  const dispatch = useDispatch();
  const { site_id } = useParams();

  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetching = useSelector((state) => state.reports.isFetching);
  const errorMsg = useSelector((state) => state.reports.error);

  const [descriptions, setDescriptions] = useState();
  const [date, setDate] = useState(new Date());

  const [errors, setErrors] = useState({
    descriptions: { type: "required", error: null },
  });

  const filesForSubmit = useRef([]);

  checkInputs.current = () => {
    if (
      date.getDate() !== new Date().getDate() ||
      descriptions ||
      filesForSubmit.current.length
    )
      return true;
    return false;
  };

  const handleChange = (e) => {
    if (errors.descriptions.error) {
      validate(
        e.target.value,
        () => {},
        () => {
          setErrors({
            ...errors,
            descriptions: { type: "required", error: null },
          });
        },
        "required"
      );
    }
    setDescriptions(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!descriptions) {
      console.log("hello");
      return setErrors((errors) => ({
        ...errors,
        descriptions: { type: "required", error: "Обязательное поле" },
      }));
    }
    dispatch(
      addReportStart(
        {
          descriptions,
          date: formatDate(date),
          user_id: currentUser.user,
          obj_id: site_id,
        },
        filesForSubmit.current,
        currentUser,
        site_id,
        close
      )
    );
  };

  return (
    <>
      <div className="caption">
        Написать отчет
        <button className="close" onClick={checkInputsAndClose} />
      </div>

      {/* {currentUser && (
        <div className="field">
          <label>Пользователь</label>
          <span className="data">
            <Link to="#">
              {currentUser.avatar ? (
                <img
                  className="avatar"
                  src={`${user_photos}/${currentUser.organization}/${currentUser.avatar}`}
                  alt="avatar"
                />
              ) : (
                <span className="avatar">
                  {`${currentUser.firstname.charAt(
                    0
                  )}${currentUser.lastname.charAt(0)}`}
                </span>
              )}
              {`${currentUser.firstname} ${currentUser.lastname}`}
            </Link>
          </span>
        </div>
      )} */}

      <form onSubmit={handleSubmit}>
        <div className="field">
          <label>Дата</label>
          <DatePicker
            onChange={setDate}
            value={date}
            clearIcon={null}
            format="dd-MM-yyyy"
            validationType="required"
            locale="ru-RU"
          />
        </div>

        <TextAreaInput
          label="Отчет"
          value={descriptions}
          onChange={handleChange}
          validationType="required"
          err={errors.descriptions.error}
        />

        <AddBackup
          filesForSubmit={filesForSubmit}
          video
          label="Кликните или перетащите сюда видеофайлы"
        />

        {errorMsg && <div className="info err">{errorMsg}</div>}
        <div className="buttons">
          <button className="green" disabled={isFetching}>
            {isFetching ? "Загрузка..." : "Готово"}
          </button>
          <button
            className="cancel"
            type="button"
            onClick={checkInputsAndClose}
            disabled={isFetching}
          >
            Отмена
          </button>
        </div>
      </form>
    </>
  );
};

export default AddReport;
