import ClaimsActionTypes from "./claims.types";

export const addClaimStart = (
  claimData,
  markerCoords,
  photos,
  currentUser,
  site_id,
  callback
) => ({
  type: ClaimsActionTypes.ADD_CLAIM_START,
  payload: { claimData, markerCoords, photos, currentUser, site_id, callback },
  callback,
});

export const addClaimSuccess = () => ({
  type: ClaimsActionTypes.ADD_CLAIM_SUCCESS,
});

export const addClaimFailure = (error) => ({
  type: ClaimsActionTypes.ADD_CLAIM_FAILURE,
  payload: error,
});

export const getClaimsStart = (currentUser, site_id) => ({
  type: ClaimsActionTypes.GET_CLAIMS_START,
  payload: { currentUser, site_id },
});

export const getClaimsSuccess = (claims) => ({
  type: ClaimsActionTypes.GET_CLAIMS_SUCCESS,
  payload: claims,
});

export const getClaimsFailure = (error) => ({
  type: ClaimsActionTypes.GET_CLAIMS_FAILURE,
  payload: error,
});

export const editClaimStart = (claim) => ({
  type: ClaimsActionTypes.EDIT_CLAIM_START,
  payload: claim,
});

export const editClaimSuccess = () => ({
  type: ClaimsActionTypes.EDIT_CLAIM_SUCCESS,
});

export const editClaimFailure = (error) => ({
  type: ClaimsActionTypes.EDIT_CLAIM_FAILURE,
  payload: error,
});

export const deleteClaimStart = (claim) => ({
  type: ClaimsActionTypes.DELETE_CLAIM_START,
  payload: claim,
});

export const deleteClaimSuccess = () => ({
  type: ClaimsActionTypes.DELETE_CLAIM_SUCCESS,
});

export const deleteClaimFailure = (error) => ({
  type: ClaimsActionTypes.DELETE_CLAIM_FAILURE,
  payload: error,
});

export const reclaimStart = (claimId, currentUser, site_id, callback) => ({
  type: ClaimsActionTypes.RECLAIM_START,
  payload: { claimId, currentUser, site_id },
  callback,
});

export const reclaimSuccess = () => ({
  type: ClaimsActionTypes.RECLAIM_SUCCESS,
});

export const reclaimFailure = (error) => ({
  type: ClaimsActionTypes.RECLAIM_FAILURE,
  payload: error,
});

export const markClaimCloseStart = (
  claimId,
  descriptions,
  photos,
  currentUser,
  site_id,
  callback
) => ({
  type: ClaimsActionTypes.MARK_CLAIM_CLOSE_START,
  payload: { claimId, descriptions, photos, currentUser, site_id },
  callback,
});

export const markClaimCloseSuccess = () => ({
  type: ClaimsActionTypes.MARK_CLAIM_CLOSE_SUCCESS,
});

export const markClaimCloseFailure = (error) => ({
  type: ClaimsActionTypes.MARK_CLAIM_CLOSE_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: ClaimsActionTypes.CLEAR_ERRORS,
});
