import React, { useState, useEffect } from "react";
import { NumberInput, SelectInput } from "../../input-forms";
import { useDispatch, useSelector } from "react-redux";
import { getEquipmentStart } from "../../../redux/resources/resources.actions";

const Equipment = ({
  equipmentToSubmit,
  setEquipment,
  equipmentInputs,
  setEquipmentInputs,
}) => {
  const equipment = useSelector((state) => state.resources.equipment);
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const [options, setOptions] = useState({});
  const isFetchingEquipment = useSelector(
    (state) => state.resources.isFetchingEquipment
  );
  const errorMessage = useSelector((state) => state.resources.errorEquipment);

  useEffect(() => {
    if (!equipment) {
      dispatch(getEquipmentStart(currentUser.user));
    } else {
      setOptions(
        equipment.reduce((acc, tool) => {
          acc[tool.id] = tool.name;
          return acc;
        }, {})
      );
    }
  }, [equipment, dispatch, currentUser.user]);

  const handleChange = (e) => {
    setEquipmentInputs({
      ...equipmentInputs,
      [e.target.name]: e.target.value ? Number(e.target.value) : "",
    });
  };

  const addEquipment = () => {
    const equipmentIndex = equipmentToSubmit.findIndex(
      (tool) => tool[0] === equipmentInputs.id
    );
    if (equipmentIndex !== -1) {
      let newEquipmentToSubmit = [...equipmentToSubmit];
      newEquipmentToSubmit[equipmentIndex][1] += equipmentInputs.qty;
      setEquipment(newEquipmentToSubmit);
    } else {
      setEquipment([
        ...equipmentToSubmit,
        [equipmentInputs.id, equipmentInputs.qty],
      ]);
    }
    return setEquipmentInputs({ id: "", qty: "" });
  };

  const deleteItem = (index) => {
    setEquipment(equipmentToSubmit.filter((tool, i) => i !== index));
  };

  return (
    <>
      <div className="hdr">Необходимое оборудование</div>
      {equipmentToSubmit.length ? (
        <table className="list">
          <tbody>
            {equipmentToSubmit.map((tool, i) => (
              <tr key={tool[0]}>
                <td>{options[tool[0]]}</td>
                <td>{`${tool[1]} шт.`}</td>
                <td className="control">
                  <button
                    className="remove"
                    onClick={() => deleteItem(i)}
                    type="button"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        ""
      )}
      <div className="two_col">
        <div className="col">
          <SelectInput
            name="id"
            equipment={"EQUIPMENT"}
            label="Наименование"
            onChange={handleChange}
            options={options}
            defaultOption={equipmentInputs.id}
            errorMessage={errorMessage}
            description={
              isFetchingEquipment
                ? "Загрузка данных..."
                : "Выбрать оборудование"
            }
            emptyMessage={"Нет оборудования"}
          />
        </div>
        <div className="col">
          <NumberInput
            label="Количество"
            value={equipmentInputs.qty}
            onChange={handleChange}
            name="qty"
          />
        </div>
      </div>
      <div className="buttons in_form">
        <button
          className="blue"
          type="button"
          onClick={addEquipment}
          disabled={!equipmentInputs.id || !equipmentInputs.qty}
        >
          добавить
        </button>
      </div>
    </>
  );
};

export default Equipment;
