import ActsActionTypes from "./acts.types";

const INITIAL_STATE = {
  isFetching: false,
  error: null,
  acts: [],
};

const actsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActsActionTypes.ADD_ACT_START:
    case ActsActionTypes.EDIT_ACT_START:
    case ActsActionTypes.GET_ACTS_START:
    case ActsActionTypes.DELETE_ACT_START:
      return { ...state, isFetching: true, error: null };
    case ActsActionTypes.ADD_ACT_SUCCESS:
    case ActsActionTypes.EDIT_ACT_SUCCESS:
    case ActsActionTypes.DELETE_ACT_SUCCESS:
      return { ...state, isFetching: false, error: null };

    case ActsActionTypes.GET_ACTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        acts: action.payload,
      };

    case ActsActionTypes.ADD_ACT_FAILURE:
    case ActsActionTypes.GET_ACTS_FAILURE:
    case ActsActionTypes.DELETE_ACT_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case ActsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default actsReducer;
