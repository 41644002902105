import RemarksActionTypes from "./remarks.types";
import SubsectionActionTypes from "../subsections/subsections.types";

const INITIAL_STATE = {
  isFetching: false,
  error: null,
  remarks: null,
  remarksBySub: {},
  remarksByUser: null,
  remarksByWork: null,
};

const remarksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RemarksActionTypes.ADD_REMARK_START:
    case RemarksActionTypes.EDIT_REMARK_START:
    case RemarksActionTypes.GET_REMARKS_START:
    case RemarksActionTypes.GET_REMARKS_BY_SUB_START:
    case RemarksActionTypes.GET_REMARKS_BY_USER_START:
    case RemarksActionTypes.DELETE_REMARK_START:
    case RemarksActionTypes.UPDATE_REMARK_STATUS_START:
      return { ...state, isFetching: true, error: null };

    case RemarksActionTypes.ADD_REMARK_SUCCESS:
    case RemarksActionTypes.EDIT_REMARK_SUCCESS:
    case RemarksActionTypes.DELETE_REMARK_SUCCESS:
    case RemarksActionTypes.UPDATE_REMARK_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        remarks: null,
        remarksBySub: {},
        remarksByUser: null,
        remarksByWork: null,
      };

    case RemarksActionTypes.GET_REMARKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        remarks: action.payload,
      };

    case RemarksActionTypes.GET_REMARKS_BY_SUB_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        remarksBySub: {
          ...state.remarksBySub,
          [action.payload.sub_id]: action.payload.remarks,
        },
      };

    case RemarksActionTypes.GET_REMARKS_BY_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        remarksByUser: action.payload,
      };

    case RemarksActionTypes.ADD_REMARK_FAILURE:
    case RemarksActionTypes.GET_REMARKS_FAILURE:
    case RemarksActionTypes.GET_REMARKS_BY_SUB_FAILURE:
    case RemarksActionTypes.GET_REMARKS_BY_USER_FAILURE:
    case RemarksActionTypes.DELETE_REMARK_FAILURE:
    case RemarksActionTypes.UPDATE_REMARK_STATUS_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case RemarksActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    case SubsectionActionTypes.CLEAR_SUBSECTIONS:
      return { ...state, remarks: null, remarksBySub: {} };

    default:
      return state;
  }
};

export default remarksReducer;
