import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { useRemarkInfoStyles } from "../../../css/sites/remarks.styles";
import { formatDate } from "../../../utils/normalizeData";
import { useDispatch, useSelector } from "react-redux";
import { getSingleWorkStart } from "../../../redux/works/works.actions";
import { updateRemarkStatusStart } from "../../../redux/remarks/remarks.actions";
import GetPoints from "../pointsOnPlan/get-points";
import ImageGallery from "../../image-gallery";
import CustomButton from "../../buttons/custom-button";

const RemarkInfo = ({ remark_id, close }) => {
  const { site_id } = useParams();
  const classes = useRemarkInfoStyles();
  const currentUser = useSelector((state) => state.user.currentUser);
  const currentWork = useSelector((state) => state.works.currentWork);
  const statuses = useSelector((state) => state.statuses.remarks);
  const isUpdating = useSelector((state) => state.remarks.isFetching);
  const [currentRemark, setCurrentRemark] = useState();
  const [currentSub, setCurrentSub] = useState();
  const dispatch = useDispatch();

  const [isFetchingWork, fetchingWork] = useState();

  const getCurrentWork = useCallback(async () => {
    try {
      fetchingWork(true);
      const response = await axios.get(`/api/job_note/${remark_id}`);
      setCurrentRemark(...Object.values(response.data));
      fetchingWork(false);
    } catch (e) {
      fetchingWork(false);
      console.log(e);
    }
  }, [remark_id]);

  useEffect(() => {
    getCurrentWork();
  }, [getCurrentWork]);

  useEffect(() => {
    if (currentRemark) {
      axios
        .get(
          `/api/subsections/${currentUser.user}/${currentRemark.subsection_id}/${site_id}`
        )
        .then((response) => setCurrentSub(...Object.values(response.data)))
        .catch((e) => console.log(e));
      if (!currentWork || currentWork.id !== currentRemark.job_id) {
        dispatch(getSingleWorkStart(currentUser.user, currentRemark.job_id));
      }
    }
  }, [currentRemark, currentUser, site_id, dispatch, currentWork]);

  const updateRemarkStatus = (statusName) => {
    let status_id;
    for (const i in statuses) {
      if (statuses[i] === statusName) status_id = Number(i);
    }
    dispatch(
      updateRemarkStatusStart(
        status_id,
        currentUser.user,
        currentRemark.id,
        getCurrentWork
      )
    );
  };

  console.log("CURRENT REMARK: ", currentRemark);

  return (
    <>
      <div className="caption">
        Замечание
        <button className="close" onClick={close} />
      </div>

      {isFetchingWork && <div className="info">загрузка работы...</div>}
      {currentRemark && (
        <>
          {currentRemark.reason && (
            <div class="field">
              <label>Основание</label>
              <span class="data">{currentRemark.reason}</span>
            </div>
          )}

          {currentRemark.descriptions && (
            <div class="field">
              <label>Описание</label>
              <span class="data">{currentRemark.descriptions}</span>
            </div>
          )}

          <div class="field">
            <label>Устранить до</label>
            <span class="data">{formatDate(currentRemark.date, true)}</span>
          </div>

          <ImageGallery
            imgPath={`job_notes/${currentRemark.job_id}/${currentRemark.id}`}
            imgFilesObj={currentRemark.job_note_imgs}
            alt="замечание визуально"
          />

          {currentSub && currentRemark && (
            <GetPoints
              subsection={currentSub}
              markerType="claim"
              remark_id={currentRemark.id}
            />
          )}

          {statuses && currentWork && (
            <div className={classes.actionBtns}>
              {currentWork.user_id === currentUser.user &&
                (statuses[currentRemark.status_id] === "новое" ||
                  statuses[currentRemark.status_id] === "переделать") && (
                  <CustomButton
                    submitstyle="true"
                    onClick={() => updateRemarkStatus("исправлено")}
                    disabled={isUpdating || isFetchingWork}
                  >
                    Отметить исполненным
                  </CustomButton>
                )}
              {statuses[currentRemark.status_id] === "исправлено" && (
                <>
                  <CustomButton
                    submitstyle="true"
                    onClick={() =>
                      updateRemarkStatus("исправлено и подтверждено")
                    }
                    disabled={isUpdating || isFetchingWork}
                  >
                    Подтвердить исполнение
                  </CustomButton>
                  <CustomButton
                    cancelstyle="true"
                    onClick={() => updateRemarkStatus("переделать")}
                    disabled={isUpdating || isFetchingWork}
                  >
                    Переделать
                  </CustomButton>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RemarkInfo;
