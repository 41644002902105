import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReasonsStart } from "../../redux/reasons/reasons.actions";
import { SelectInput } from "../input-forms";

const SelectReason = ({ reason_id, data, setData }) => {
  const dispatch = useDispatch();

  const reasons = useSelector((state) => state.reasons.reasons);
  const user = useSelector((state) => state.user.currentUser);
  const [options, setOptions] = useState({});

  const handleChange = (e) => {
    setData((data) => ({ ...data, reason: "", reason_id: e.target.value }));
  };

  useEffect(() => {
    if (!reasons) {
      dispatch(getReasonsStart(user.user));
    } else {
      setOptions(
        reasons.reduce((acc, curr) => {
          acc[curr.id] = curr.name;
          return acc;
        }, {})
      );
    }
  }, [reasons, dispatch, user]);

  return (
    <SelectInput
      label="Основание"
      options={options}
      onChange={handleChange}
      description="Выбрать основание"
      defaultOption={reason_id}
    />
  );
};

export default SelectReason;
