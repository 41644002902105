import { takeLeading, put, all, call } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";
import VariablesActionTypes from "./variables.types";
import { getVariablesSuccess, getVariablesFailure } from "./variables.actions";

export function* getVariables({ payload }) {
  try {
    const response = yield axios.get(`/api/variables/${payload}`);
    yield put(getVariablesSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getVariablesFailure(error));
  }
}

export function* onGetVarialbesStart() {
  yield takeLeading(VariablesActionTypes.GET_VARIALBES_START, getVariables);
}

export function* variablesSagas(args) {
  yield all([call(onGetVarialbesStart)]);
}
