import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEquipmentStart } from "../../redux/equipment/equipment.actions";
import { useAddTechCardItemsStyles } from "../../../css/admin/main-section.styles";
import { ControlledSelect, FormInput } from "../../../components/input-forms";

const AddEquipment = ({ selectedEquipment, setSelectedEquipment }) => {
  const classes = useAddTechCardItemsStyles();
  const dispatch = useDispatch();
  console.log("SELECTED EQUIPMENT: ", selectedEquipment);
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const equipment = useSelector((state) => state.equipment.equipment);
  const errorEquipment = useSelector((state) => state.equipment.error);

  const [equipmentOptions, setEquipmentOptions] = useState({});
  const [equipmentInputs, setEquipmentInputs] = useState({
    equipment_id: "",
    qty: "",
  });

  const checkIfItemAdded = useCallback(
    (id) =>
      selectedEquipment.some(
        (equipment) => String(equipment.equipment_id) === String(id)
      ),
    [selectedEquipment]
  );

  useEffect(() => {
    if (!equipment) {
      dispatch(getEquipmentStart(currentAdmin.adminId));
    } else {
      setEquipmentOptions(
        equipment.reduce((options, equipment) => {
          if (!checkIfItemAdded(equipment.id)) {
            options[equipment.id] = `${equipment.name}`;
          }
          return options;
        }, {})
      );
    }
  }, [currentAdmin, dispatch, equipment, checkIfItemAdded]);

  const addEquipment = (e) => {
    const isFilled = (obj) => {
      for (const i in obj) {
        if (obj[i]) return true;
      }
      return false;
    };

    if (isFilled(equipmentInputs)) {
      setSelectedEquipment([...selectedEquipment, equipmentInputs]);
      setEquipmentInputs({
        equipment_id: "",
        qty: "",
      });
    }
  };

  const deleteEquipment = (index) => {
    setSelectedEquipment(selectedEquipment.filter((eq, i) => i !== index));
  };

  const handleChange = (e) => {
    setEquipmentInputs({
      ...equipmentInputs,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      <div>
        <b>Оборудование</b>
      </div>

      {equipment && selectedEquipment.length ? (
        <table className={classes.itemsTable}>
          <thead>
            <tr>
              <th>вид оборудования</th>
              <th>количество</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {selectedEquipment.map((equipmentRow, index) => {
              const currentEquipment = equipment.find(
                (equipment) => +equipment.id === +equipmentRow.equipment_id
              );
              if (currentEquipment)
                return (
                  // <div key={equipment.id} className={classes.listItem}>
                  <tr key={index}>
                    <td>{currentEquipment.name}</td>
                    <td>{equipmentRow.qty}</td>
                    <td className={classes.alignRight}>
                      <button
                        onClick={() => deleteEquipment(index)}
                        className={classes.delete}
                        type="button"
                      >
                        Удалить
                      </button>
                    </td>
                  </tr>
                  // </div>
                );
              return <tr key={index}></tr>;
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}

      <div className={classes.error}>{errorEquipment}</div>
      <div className={classes.twoInputs}>
        <span className={classes.selectSpan}>
          <ControlledSelect
            options={equipmentOptions}
            label="вид оборудования"
            name="equipment_id"
            description="выбрать оборудование"
            defaultOption={equipmentInputs.equipment_id}
            onChange={handleChange}
          />
        </span>
        <FormInput
          label="количество"
          placeholder="число или формула"
          name="qty"
          value={equipmentInputs.qty}
          onChange={handleChange}
        />
      </div>
      <div className="buttons top">
        <button
          className="blue"
          type="button"
          onClick={addEquipment}
          disabled={!equipmentInputs.equipment_id || !equipmentInputs.qty}
        >
          добавить
        </button>
      </div>
    </div>
  );
};

export default React.memo(AddEquipment);
