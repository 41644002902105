import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getUsersStart } from "../../redux/user/user.actions";
import UserRow from "../../components/manage-users/user-row";
import InlineModal from "../../components/inlineModal";
import AddUser from "../../components/manage-users/add-user";

const ManageUsers = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.user.currentUser);
  useEffect(() => {
    dispatch(clearErrors());
    dispatch(getUsersStart(admin));
  }, [dispatch, admin]);
  const isFetchingUsers = useSelector((state) => state.user.isFetching);
  const requestError = useSelector((state) => state.user.error);
  const usersList = useSelector((state) => state.user.usersList);

  const [addUserModal, toggleAddUserModal] = useState();
  return (
    <>
      <div className="header2">
        <ul>
          <li className="hdr">Пользователи</li>
          <li className="btn">
            <button onClick={() => toggleAddUserModal(true)}>Добавить</button>
          </li>
        </ul>
      </div>
      <div className="content">
        <div>
          {isFetchingUsers
            ? "Загружается список пользователей..."
            : requestError
            ? requestError
            : ""}
        </div>
        <table className="list">
          <thead>
            <tr>
              <th>пользователь</th>
              <th></th>
              <th>электронная почта</th>
              <th>телефон</th>
            </tr>
          </thead>
          <tbody>
            {usersList.map((user) => (
              <tr key={user.id}>
                <UserRow user={user} key={user.id} basePath={"/main/users"} />
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {addUserModal && (
        <InlineModal close={() => toggleAddUserModal(false)}>
          <AddUser close={() => toggleAddUserModal(false)} />
        </InlineModal>
      )}
    </>
  );
};

export default ManageUsers;
