import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { signOut } from "../../redux/user/user.actions";

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);

  return (
    <div className="header">
      <ul className="menu">
        <li className="objects">
          <Link to="/main/sites">
            <span>Объекты</span>
          </Link>
        </li>
        {user.admin ? (
          <li className="users">
            <Link to="/main/users">
              <span>Пользователи</span>
            </Link>
          </li>
        ) : (
          ""
        )}
      </ul>

      {user && (
        <div className="user">
          <p>{`${user.firstname} ${user.lastname}`}</p>

          <ul className="drop_down">
            <li>id компании: {user.identifier}</li>
            <li>
              <Link to="/main/change_password">Сменить пароль</Link>
            </li>
            <li>
              <Link to="#" onClick={() => dispatch(signOut())}>
                Выход
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Header;
