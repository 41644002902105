import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../../css/admin/variables-multiselect";
import { ControlledSelect } from "../../../components/input-forms";
import { getVariablesStart } from "../../redux/variables/variables.actions";

const VariablesMultiSelect = ({ selectedVariables, setSelectedVariables }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedVariable, setSelectedVariable] = useState("");
  const [variableOptions, setVariablesOptions] = useState({});

  const variables = useSelector((state) => state.variables.variables);
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);

  // Transform unselected variables into select options object every time a selectedVariables array changes
  useEffect(() => {
    if (variables) {
      setVariablesOptions(
        variables.reduce((variableOptions, variable) => {
          if (!selectedVariables.includes(variable.id)) {
            variableOptions[
              variable.id
            ] = `${variable.name} (${variable.name_print})`;
          }
          return variableOptions;
        }, {})
      );
    } else {
      dispatch(getVariablesStart(currentAdmin.adminid));
    }
  }, [selectedVariables, variables, currentAdmin, dispatch]);

  const addVariable = (e) => {
    if (selectedVariable) {
      setSelectedVariables([...selectedVariables, Number(selectedVariable)]);
      setSelectedVariable();
    }
  };

  const deleteVariable = (variableId) => {
    setSelectedVariables([
      ...selectedVariables.filter((id) => id !== variableId),
    ]);
  };

  return (
    <div>
      <div className={classes.title}>Необходимая информация (переменные)</div>
      <div
        className={
          setSelectedVariables
            ? classes.selectedVariablesGridEdit
            : classes.selectedVariables
        }
      >
        {variables &&
          selectedVariables.map((variableId) => {
            const variable = variables.find(
              (variable) => variable.id === variableId
            );
            if (variable) {
              return (
                <div key={variable.id} className={classes.listItem}>
                  <span> {`${variable.name} (${variable.name_print})`}</span>

                  <button
                    onClick={() => deleteVariable(variable.id)}
                    className={classes.delete}
                    type="button"
                  >
                    Удалить
                  </button>
                </div>
              );
            }
            return "";
          })}
      </div>
      <div>
        <ControlledSelect
          options={variableOptions}
          defaultOption={selectedVariable}
          onChange={(e) => setSelectedVariable(e.target.value)}
          description="выбрать переменную"
        />
        <div className="buttons top">
          <button
            className="blue"
            type="button"
            onClick={addVariable}
            disabled={!selectedVariable}
          >
            добавить
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VariablesMultiSelect);
