import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../../utils/normalizeData";

import EquipmentActionTypes from "./equipment.types";

import {
  addEquipmentSuccess,
  addEquipmentFailure,
  getEquipmentStart,
  getEquipmentSuccess,
  getEquipmentFailure,
  editEquipmentSuccess,
  editEquipmentFailure,
  deleteEquipmentSuccess,
  deleteEquipmentFailure,
} from "./equipment.actions";

export function* addEquipment({ payload, callback }) {
  const { equipmentName, adminId } = payload;
  const postData = {
    row: { name: equipmentName },
    who: adminId,
  };
  try {
    yield axios.post(`/api/admin/equipment/add`, postData);
    yield put(addEquipmentSuccess());
    yield put(getEquipmentStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addEquipmentFailure(error));
  }
}

export function* getEquipment({ payload }) {
  try {
    const response = yield axios.get(`/api/admin/equipment/${payload}`);
    yield put(getEquipmentSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getEquipmentFailure(error));
  }
}

export function* editEquipment({ payload, callback }) {
  const { equipmentName, adminId, equipmentId } = payload;
  const putEquipmentData = {
    row: { name: equipmentName },
    who: adminId,
    equipment: equipmentId,
  };
  try {
    yield axios.put("/api/admin/equipment/edit", putEquipmentData);
    yield put(editEquipmentSuccess());
    yield put(getEquipmentStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editEquipmentFailure(error));
  }
}

export function* deleteEquipment({ payload, callback }) {
  const { equipmentId, adminId } = payload;
  try {
    yield axios.put("/api/admin/equipment/mark_as_deleted", {
      who: adminId,
      equipment_id: equipmentId,
    });
    yield put(deleteEquipmentSuccess(equipmentId));
    yield put(getEquipmentStart(adminId));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(deleteEquipmentFailure(error));
  }
}

export function* onGetEquipmentStart() {
  yield takeLeading(EquipmentActionTypes.GET_EQUIPMENT_START, getEquipment);
}

export function* onAddEquipmentStart() {
  yield takeLatest(EquipmentActionTypes.ADD_EQUIPMENT_START, addEquipment);
}

export function* onEditEquipmentStart() {
  yield takeLatest(EquipmentActionTypes.EDIT_EQUIPMENT_START, editEquipment);
}

export function* onDeleteEquipmentStart() {
  yield takeLeading(
    EquipmentActionTypes.DELETE_EQUIPMENT_START,
    deleteEquipment
  );
}

export function* equipmentSagas(args) {
  yield all([
    call(onGetEquipmentStart),
    call(onAddEquipmentStart),
    call(onEditEquipmentStart),
    call(onDeleteEquipmentStart),
  ]);
}
