const WorksActionTypes = {
  ADD_GROUP_START: "ADD_GROUP_START",
  ADD_GROUP_SUCCESS: "ADD_GROUP_SUCCESS",
  ADD_GROUP_FAILURE: "ADD_GROUP_FAILURE",
  GET_GROUPS_START: "GET_GROUPS_START",
  GET_GROUPS_SUCCESS: "GET_GROUPS_SUCCESS",
  GET_GROUPS_FAILURE: "GET_GROUPS_FAILURE",
  ADD_WORK_START: "ADD_WORK_START",
  ADD_WORK_SUCCESS: "ADD_WORK_SUCCESS",
  ADD_WORK_FAILURE: "ADD_WORK_FAILURE",
  GET_WORKS_START: "GET_WORKS_START",
  GET_WORKS_SUCCESS: "GET_WORKS_SUCCESS",
  GET_WORKS_FAILURE: "GET_WORKS_FAILURE",
  GET_WORKS_TREE_START: "GET_WORKS_TREE_START",
  GET_WORKS_TREE_SUCCESS: "GET_WORKS_TREE_SUCCESS",
  GET_WORKS_TREE_FAILURE: "GET_WORKS_TREE_FAILURE",
  GET_WORKS_BY_SUB_START: "GET_WORKS_BY_SUB_START",
  GET_WORKS_BY_SUB_SUCCESS: "GET_WORKS_BY_SUB_SUCCESS",
  GET_WORKS_BY_SUB_FAILURE: "GET_WORKS_BY_SUB_FAILURE",
  GET_WORKS_BY_USER_START: "GET_WORKS_BY_USER_START",
  GET_WORKS_BY_USER_SUCCESS: "GET_WORKS_BY_USER_SUCCESS",
  GET_WORKS_BY_USER_FAILURE: "GET_WORKS_BY_USER_FAILURE",
  GET_SINGLE_WORK_START: "GET_SINGLE_WORK_START",
  GET_SINGLE_WORK_SUCCESS: "GET_SINGLE_WORK_SUCCESS",
  GET_SINGLE_WORK_FAILURE: "GET_SINGLE_WORK_FAILURE",
  DELETE_WORK_START: "DELETE_WORK_START",
  DELETE_WORK_SUCCESS: "DELETE_WORK_SUCCESS",
  DELETE_WORK_FAILURE: "DELETE_WORK_FAILURE",
  DELETE_GROUP_START: "DELETE_GROUP_START",
  DELETE_GROUP_SUCCESS: "DELETE_GROUP_SUCCESS",
  DELETE_GROUP_FAILURE: "DELETE_GROUP_FAILURE",
  EDIT_WORK_START: "EDIT_WORK_START",
  EDIT_WORK_SUCCESS: "EDIT_WORK_SUCCESS",
  EDIT_WORK_FAILURE: "EDIT_WORK_FAILURE",
  EDIT_GROUP_START: "EDIT_GROUP_START",
  EDIT_GROUP_SUCCESS: "EDIT_GROUP_SUCCESS",
  EDIT_GROUP_FAILURE: "EDIT_GROUP_FAILURE",
  ADD_WORK_RESULT_START: "ADD_WORK_RESULT_START",
  ADD_WORK_RESULT_SUCCESS: "ADD_WORK_RESULT_SUCCESS",
  ADD_WORK_RESULT_FAILURE: "ADD_WORK_RESULT_FAILURE",
  GET_WORK_CLAIMS_START: "GET_WORK_CLAIMS_START",
  GET_WORK_CLAIMS_SUCCESS: "GET_WORK_CLAIMS_SUCCESS",
  GET_WORK_CLAIMS_FAILURE: "GET_WORK_CLAIMS_FAILURE",
  GET_WORK_REMARKS_START: "GET_WORK_REMARKS_START",
  GET_WORK_REMARKS_SUCCESS: "GET_WORK_REMARKS_SUCCESS",
  GET_WORK_REMARKS_FAILURE: "GET_WORK_REMARKS_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default WorksActionTypes;
