import React from "react";
import { useDispatch } from "react-redux";
import { useHeaderStyles } from "../../css/admin/admin-panel.styles";
import { signOut } from "../redux/admins/admins.actions";

const Header = () => {
  const classes = useHeaderStyles();
  const dispatch = useDispatch();

  const handleExit = () => {
    dispatch(signOut());
  };

  return (
    <div className={classes.header}>
      <div className={classes.exit} onClick={handleExit}>
        Выход
      </div>
    </div>
  );
};

export default Header;
