import React from "react";
import { useLinkStyles } from "../../css/custom-button.styles";

const LinkButton = ({ children, ...otherProps }) => {
  const classes = useLinkStyles(otherProps);
  return (
    <button className={classes.linkButton} {...otherProps}>
      {children}
    </button>
  );
};

export default LinkButton;
