const RevisionsActionTypes = {
  ADD_REVISION_START: "ADD_REVISION_START",
  ADD_REVISION_SUCCESS: "ADD_REVISION_SUCCESS",
  ADD_REVISION_FAILURE: "ADD_REVISION_FAILURE",
  GET_REVISIONS_START: "GET_REVISIONS_START",
  GET_REVISIONS_SUCCESS: "GET_REVISIONS_SUCCESS",
  GET_REVISIONS_FAILURE: "GET_REVISIONS_FAILURE",
  DELETE_REVISION_START: "DELETE_REVISION_START",
  DELETE_REVISION_SUCCESS: "DELETE_REVISION_SUCCESS",
  DELETE_REVISION_FAILURE: "DELETE_REVISION_FAILURE",
  EDIT_REVISION_START: "EDIT_REVISION_START",
  EDIT_REVISION_SUCCESS: "EDIT_REVISION_SUCCESS",
  EDIT_REVISION_FAILURE: "EDIT_REVISION_FAILURE",
  POSTPONE_REVISION_START: "POSTPONE_REVISION_START",
  POSTPONE_REVISION_SUCCESS: "POSTPONE_REVISION_SUCCESS",
  POSTPONE_REVISION_FAILURE: "POSTPONE_REVISION_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default RevisionsActionTypes;
