import React from "react";
import { useParams } from "react-router";
import { useMainSectionStyles } from "../../css/admin/admin-panel.styles";
import Specialties from "./specialties/Specialties";
import Materials from "./materials/Materials";
import MaterialUnits from "./materialUnits/MaterialUnits";
import Equipment from "./equipment/Equipment";
import Reasons from "./reasons/Reasons";
import Variables from "./variables/Variables";
import TechCards from "./techCards/TechCards";

const MainSection = () => {
  const classes = useMainSectionStyles();
  const { menu } = useParams();

  // const mandatoryVariables = useRef([23]);

  return (
    <div className={classes.mainSection}>
      {menu === "specialties" && <Specialties />}
      {menu === "materials" && <Materials />}
      {menu === "materialUnits" && <MaterialUnits />}
      {menu === "equipment" && <Equipment />}
      {menu === "reasons" && <Reasons />}
      {menu === "variables" && <Variables />}
      {menu === "techCards" && <TechCards />}
    </div>
  );
};

export default MainSection;
