import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";

import SubsectionsActionTypes from "./subsections.types";
import {
  addSubsectionSuccess,
  addSubsectionFailure,
  getSubsectionsStart,
  getSubsectionsSuccess,
  getSubsectionsFailure,
  getSingleSubsectionSuccess,
  getSingleSubsectionFailure,
  editSubsectionSuccess,
  editSubsectionFailure,
  deleteSubsectionSuccess,
  deleteSubsectionFailure,
  clearCurrentSubsection,
  addSubsectionGroupFailure,
  addSubsectionGroupSuccess,
  getSubsectionGroupsFailure,
  getSubsectionGroupsSuccess,
  getSubsectionGroupsStart,
  editSubsectionGroupSuccess,
  editSubsectionGroupFailure,
  deleteSubsectionGroupFailure,
} from "./subsections.actions";
import { getRevisionsStart } from "../revisions/revisions.actions";
import { getClaimsStart } from "../claims/claims.actions";
import { getActsStart } from "../acts/acts.actions";
import { signOut } from "../user/user.actions";

export function* getSubsectionGroups({ payload }) {
  const { userId, site_id } = payload;

  try {
    const response = yield axios.get(
      `/api/subsection_groups/${userId}/${site_id}`
    );
    yield put(getSubsectionGroupsSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getSubsectionGroupsFailure(error));
  }
}

export function* addSubsectionGroup({ payload, callback }) {
  const { groupData, currentUser } = payload;

  const postData = {
    row: groupData,
    who: currentUser.user,
  };

  try {
    yield axios.post(`/api/subsection_groups/add`, postData);
    yield put(addSubsectionGroupSuccess());
    yield put(getSubsectionGroupsStart(currentUser.user, groupData.obj_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addSubsectionGroupFailure(error));
  }
}

export function* editSubsectionGroup({ payload, callback }) {
  const { editData, currentUser, subsection_group } = payload;

  const putData = {
    row: editData,
    who: currentUser.user,
    subsection_group,
  };

  try {
    yield axios.put("/api/subsection_groups/edit", putData);
    yield put(editSubsectionGroupSuccess());
    yield put(getSubsectionGroupsStart(currentUser.user, editData.obj_id));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editSubsectionGroupFailure(error));
  }
}

export function* deleteSubsectionGroup({ payload, callback }) {
  const { userId, groupId, site_id } = payload;
  const data = { who: userId, id: groupId };
  try {
    yield axios.delete("/api/subsection_groups/delete", { data });
    yield put(getSubsectionGroupsStart(userId, site_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(deleteSubsectionGroupFailure(error));
  }
}

export function* addPlan(plan, currentUser, subsection) {
  let formData = new FormData();
  let uniqueImgName = Math.floor(Math.random() * 1000) + plan.name.slice(-35);
  formData.append("subsectionPlan", plan, uniqueImgName);
  yield axios.post(
    `/api/subsections/plan/${currentUser.user}/${subsection.id}/${subsection.obj_id}`,
    formData
  );
}

export function* addSubsection({ payload, callback }) {
  const { subsection, currentUser, plans, variableInputs } = payload;
  const postData = {
    subsections: subsection,
    who: currentUser.user,
    // object: subsection.obj_id,
  };
  if (variableInputs.length) {
    postData.subsectionVariablesColumns = ["variable_id", "value"];
    postData.subsectionVariablesValues = variableInputs;
  }

  try {
    const response = yield axios.post("/api/subsections/add", postData);
    if (plans.length) {
      subsection.id = response.data.insertId;
      yield all(
        plans.map((plan) => call(addPlan, plan, currentUser, subsection))
      );
    }
    yield put(addSubsectionSuccess());

    yield put(getSubsectionsStart(currentUser, subsection.obj_id));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addSubsectionFailure(error));
  }
}

export function* getSingleSubsection({ payload }) {
  const { currentUserId, site_id, sub_id } = payload;

  try {
    const response = yield axios.get(
      `/api/subsections/${currentUserId}/${sub_id}/${site_id}`
    );
    yield put(getSingleSubsectionSuccess(...Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getSingleSubsectionFailure(error));
  }
}

export function* getSubsections({ payload: { currentUser, site_id } }) {
  try {
    const response = yield axios.get(
      `/api/subsections/${currentUser.user}/${site_id}`
    );
    yield put(getSubsectionsSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getSubsectionsFailure(error));
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put(signOut());
    }
  }
}

export function* editSubsection({ payload, callback }) {
  const {
    subsection,
    currentUser,
    subToEdit,
    plans,
    plansToDelete,
    variableInputs,
  } = payload;

  const newPlansToAdd = plans.filter((plan) => !plan.id);

  const putData = {
    subsections: subsection,
    who: currentUser.user,
    subsection_id: subToEdit.id,
  };
  if (variableInputs.length) {
    putData.subsectionVariablesColumns = ["variable_id", "value"];
    putData.subsectionVariablesValues = variableInputs.filter(
      (variable) => variable[1] !== "" && variable[1] !== undefined
    );
  }

  try {
    yield axios.put("/api/subsections/edit", putData);
    if (newPlansToAdd.length) {
      for (const i in newPlansToAdd) {
        const plan = newPlansToAdd[i];
        yield addPlan(plan, currentUser, subToEdit);
      }
    }
    if (plansToDelete.current) {
      for (const i in plansToDelete.current) {
        const plan = plansToDelete.current[i];
        const data = {
          who: currentUser.user,
          id_subsection: subToEdit.id,
          id_plan: plan.id,
          filename: plan.filename,
          object: subToEdit.obj_id,
        };

        yield axios.delete("/api/subsection_plans/delete", {
          data,
        });
      }
    }
    yield put(editSubsectionSuccess());
    yield getSubsections({
      payload: {
        currentUser,
        site_id: subsection.obj_id,
      },
    });
    yield put(clearCurrentSubsection());
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editSubsectionFailure(error));
  }
}

export function* deleteSubsection({ payload, callback }) {
  const { currentUser, subToEdit } = payload;
  const deleteData = {
    who: currentUser.user,
    id: subToEdit.id,
    object: subToEdit.obj_id,
  };

  try {
    yield axios.delete("/api/subsections/delete", {
      data: deleteData,
    });
    yield put(deleteSubsectionSuccess());
    yield put(getSubsectionsStart(currentUser, subToEdit.obj_id));
    yield put(getRevisionsStart(currentUser, subToEdit.obj_id));
    yield put(getClaimsStart(currentUser, subToEdit.obj_id));
    yield put(getActsStart(currentUser, subToEdit.obj_id));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(deleteSubsectionFailure(error));
  }
}

export function* onGetSubsectionGroupsStart() {
  yield takeLeading(
    SubsectionsActionTypes.GET_SUBSECTION_GROUPS_START,
    getSubsectionGroups
  );
}

export function* onAddSubsectionGroupStart() {
  yield takeLeading(
    SubsectionsActionTypes.ADD_SUBSECTION_GROUP_START,
    addSubsectionGroup
  );
}

export function* onEditSubsectionGroupStart() {
  yield takeLeading(
    SubsectionsActionTypes.EDIT_SUBSECTION_GROUP_START,
    editSubsectionGroup
  );
}

export function* onDeleteSubsectionGroupStart() {
  yield takeLatest(
    SubsectionsActionTypes.DELETE_SUBSECTION_GROUP_START,
    deleteSubsectionGroup
  );
}

export function* onGetSubsectionsStart() {
  yield takeLeading(
    SubsectionsActionTypes.GET_SUBSECTIONS_START,
    getSubsections
  );
}

export function* onGetSingleSubsectionStart() {
  yield takeLeading(
    SubsectionsActionTypes.GET_SINGLE_SUBSECTION_START,
    getSingleSubsection
  );
}

export function* onAddSubsectionStart() {
  yield takeLatest(SubsectionsActionTypes.ADD_SUBSECTION_START, addSubsection);
}

export function* onEditSubsectionStart() {
  yield takeLatest(
    SubsectionsActionTypes.EDIT_SUBSECTION_START,
    editSubsection
  );
}

export function* onDeleteSubsectionStart() {
  yield takeLeading(
    SubsectionsActionTypes.DELETE_SUBSECTION_START,
    deleteSubsection
  );
}

export function* subsectionsSagas(args) {
  yield all([
    call(onGetSubsectionGroupsStart),
    call(onAddSubsectionGroupStart),
    call(onEditSubsectionGroupStart),
    call(onDeleteSubsectionGroupStart),
    call(onAddSubsectionStart),
    call(onGetSubsectionsStart),
    call(onGetSingleSubsectionStart),
    call(onEditSubsectionStart),
    call(onDeleteSubsectionStart),
  ]);
}
