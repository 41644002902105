import ReportsActionTypes from "./reports.types";
import SubsectionActionTypes from "../subsections/subsections.types";

const INITIAL_STATE = {
  isFetching: false,
  error: null,
  reports: null,
};

const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportsActionTypes.ADD_REPORT_START:
    case ReportsActionTypes.GET_REPORTS_START:
      //   case ReportsActionTypes.EDIT_REPORT_START:
      // case ReportsActionTypes.DELETE_REPORT_START:
      return { ...state, isFetching: true, error: null };

    case ReportsActionTypes.ADD_REPORT_SUCCESS:
      // case ReportsActionTypes.EDIT_REPORT_SUCCESS:
      // case ReportsActionTypes.DELETE_REPORT_SUCCESS:
      return { ...state, isFetching: false, error: null };

    case ReportsActionTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        reports: action.payload,
      };

    case ReportsActionTypes.ADD_REPORT_FAILURE:
    case ReportsActionTypes.GET_REPORTS_FAILURE:
      // case ReportsActionTypes.DELETE_REPORT_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case ReportsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    case SubsectionActionTypes.CLEAR_SUBSECTIONS:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reportsReducer;
