import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTechCardsStart } from "../../../../redux/techCards/techCards.actions";
import { useSelectTechCardStyles } from "../../../../css/sites/works.styles";
import { ReactComponent as GroupIcon } from "../../../../assets/group.svg";
import { ReactComponent as CardIcon } from "../../../../assets/card.svg";
import { ReactComponent as GoBackIcon } from "../../../../assets/left-arrow.svg";

const SelectTechCard = ({
  selectedCard,
  setSelectedCard,
  setSpecialtyVariables,
  userChangedVariable,
  setSpecialties,
  setEquipment,
  setCardEquipmentInputs,
  setMaterials,
  setMaterialInputs,
  subsectionFromEditWorkSet,
}) => {
  const classes = useSelectTechCardStyles();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user.currentUser);
  const techCards = useSelector((state) => state.techCards.techCards);
  const isFetching = useSelector((state) => state.techCards.isFetching);
  const errorMsg = useSelector((state) => state.techCards.errorMsg);

  const [dropdownMenu, toggleDropdownMenu] = useState(false);
  const [groupsToRender, setGroupsToRender] = useState();
  const [cardsToRender, setCardsToRender] = useState();
  const [currentGroup, setCurrentGroup] = useState();
  const openedGroups = useRef();

  // set appropriate subgroups and cards when a group is clicked on
  useEffect(() => {
    if (techCards) {
      let groups, cards;
      if (currentGroup) {
        groups = currentGroup.groups ? currentGroup.groups : [];
        cards = currentGroup.cards ? currentGroup.cards : [];
      } else {
        groups = techCards.groups ? techCards.groups : [];
        cards = techCards.cards ? techCards.cards : [];
      }
      setGroupsToRender(groups);
      setCardsToRender(cards);
    }
  }, [techCards, currentGroup]);

  const toggleMenu = (e) => {
    if (techCards === null) {
      dispatch(getTechCardsStart(currentUser.user));
    }
    toggleDropdownMenu(!dropdownMenu);
  };

  const handleSelect = (card) => {
    userChangedVariable.current = true;
    if (selectedCard && card.id === selectedCard.id) return;
    setSpecialtyVariables(null);
    setSpecialties([]);
    setEquipment([]);
    setCardEquipmentInputs({});
    setMaterials([]);
    setMaterialInputs({});
    subsectionFromEditWorkSet.current = false;
    setSelectedCard(card);
    toggleDropdownMenu(false);
  };

  const openGroup = (group) => {
    if (!openedGroups.current) openedGroups.current = [];

    openedGroups.current.push(currentGroup);
    setCurrentGroup(group);
  };

  const goBack = () => {
    setCurrentGroup(openedGroups.current.pop());
  };

  const renderCards = () => {
    const renderArr = [];

    for (const i in groupsToRender) {
      renderArr.push(
        <div
          key={i + "group"}
          className={classes.listItem}
          onClick={() => openGroup(groupsToRender[i])}
        >
          <GroupIcon /> {groupsToRender[i].name}
        </div>
      );
    }
    for (const i in cardsToRender) {
      renderArr.push(
        <div
          key={i + "card"}
          className={classes.listItem}
          onClick={() => handleSelect(cardsToRender[i])}
        >
          <CardIcon className={classes.cardIcon} /> {cardsToRender[i].name}
        </div>
      );
    }
    return renderArr;
  };

  return (
    <div className={classes.component}>
      <div className="field">
        <label>Технологическая карта</label>
        <div
          style={{
            position: "absolute",
            height: "50px",
            width: "100%",
            // backgroundColor: "green",
            zIndex: "6",
          }}
          onClick={toggleMenu}
        ></div>
        <select>
          <option>
            {selectedCard ? selectedCard.name : "Выбрать технологическую карту"}
          </option>
        </select>
        <span className="select_arrow"></span>
      </div>
      {/* <button onClick={toggleMenu} className={classes.cardSelect} type="button">
        {selectedCard ? selectedCard.name : "Выбрать технологическую карту"}
        <Triangle />
      </button> */}

      {dropdownMenu && (
        <>
          <div className={classes.selectDropdown}>
            <div>{errorMsg}</div>

            {isFetching && (
              <div className={classes.listItem}>загрузка карт...</div>
            )}

            {currentGroup && (
              <div className={classes.listItem} onClick={goBack}>
                <GoBackIcon /> {currentGroup.name}
              </div>
            )}

            {renderCards()}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectTechCard;
