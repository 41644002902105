import React, { useEffect, useState } from "react";
import { useMainSectionStyles } from "../../../css/admin/main-section.styles";
import { FormInput } from "../../../components/input-forms";
import { useDispatch, useSelector } from "react-redux";
import {
  addSpecialtyStart,
  getSpecialtiesStart,
  deleteSpecialtyStart,
  clearErrors,
  editSpecialtyStart,
} from "../../redux/specialties/specialties.actions";
import { ReactComponent as Trash } from "../../../assets/trash.svg";
import ConfirmDeleteModal from "../../../components/confirmActionModal";

const Specialties = () => {
  const classes = useMainSectionStyles();
  const dispatch = useDispatch();
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const specialties = useSelector((state) => state.specialties.specialties);

  useEffect(() => {
    if (!specialties) {
      dispatch(getSpecialtiesStart(currentAdmin.adminId));
    }
  }, [specialties, currentAdmin.adminId, dispatch]);

  const isAdding = useSelector((state) => state.specialties.isAdding);

  const [specialtyName, setSpecialtyName] = useState("");
  const [specialtyToEdit, setSpecialtyToEdit] = useState();

  useEffect(() => {
    if (specialtyToEdit) {
      setSpecialtyName(specialtyToEdit.specialty);
    }
  }, [specialtyToEdit]);

  const callback = () => {
    setSpecialtyName("");
    document.getElementById("name").focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (specialtyToEdit) {
      const callback = () => {
        setSpecialtyToEdit(null);
        setSpecialtyName("");
      };

      return dispatch(
        editSpecialtyStart(
          specialtyName,
          currentAdmin.adminId,
          specialtyToEdit.id,
          callback
        )
      );
    }

    if (specialtyName) {
      dispatch(
        addSpecialtyStart(specialtyName, currentAdmin.adminId, callback)
      );
    }
  };

  const [confirmDelete, setConfirmDelete] = useState();

  useEffect(() => {
    dispatch(clearErrors());
  }, [confirmDelete, dispatch]);

  const isDeleting = useSelector((state) => state.specialties.isDeleting);
  const errorMessage = useSelector((state) => state.specialties.error);

  const handleDelete = (e) => {
    const callback = () => setConfirmDelete(false);

    e.preventDefault();
    dispatch(
      deleteSpecialtyStart(confirmDelete.id, currentAdmin.adminId, callback)
    );
    if (specialtyToEdit && specialtyToEdit.id === confirmDelete.id) {
      setSpecialtyToEdit(null);
      setSpecialtyName("");
    }
  };

  const cancelEdit = () => {
    setSpecialtyToEdit(null);
    setSpecialtyName("");
    document.getElementById("name").focus();
  };

  return (
    <div className={classes.main}>
      <div className={classes.list}>
        <h1>Профессии работников</h1>
        {specialties &&
          specialties.map((specialty) => (
            <div key={specialty.id} className={classes.listItem}>
              <span
                onClick={() => setSpecialtyToEdit(specialty)}
                className={
                  specialtyToEdit && specialtyToEdit.id === specialty.id
                    ? classes.selectedItem
                    : ""
                }
              >
                {specialty.specialty}
              </span>
              <Trash onClick={() => setConfirmDelete(specialty)} />
            </div>
          ))}
      </div>
      <form className={classes.addSection} onSubmit={handleSubmit}>
        <FormInput
          label="Название"
          id="name"
          value={specialtyName}
          onChange={(e) => setSpecialtyName(e.target.value)}
        />
        <div className="buttons">
          <button className="blue" disabled={!specialtyName || isAdding}>
            {specialtyToEdit ? "Сохранить" : "Добавить"}
          </button>
          {specialtyToEdit && (
            <button
              className="cancel"
              disabled={isAdding}
              type="button"
              onClick={cancelEdit}
            >
              Отмена
            </button>
          )}
        </div>
        <div className={classes.errorMsg}>{errorMessage}</div>
      </form>

      {confirmDelete && (
        <ConfirmDeleteModal
          message={`Удалить профессию "${confirmDelete.specialty}"?`}
          confirm={handleDelete}
          cancel={() => setConfirmDelete(null)}
          processing={isDeleting}
        />
      )}
    </div>
  );
};

export default Specialties;
