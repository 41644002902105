import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";

import RemarksActionTypes from "./remarks.types";
import {
  addRemarkSuccess,
  addRemarkFailure,
  getRemarksSuccess,
  getRemarksFailure,
  getRemarksBySubSuccess,
  getRemarksBySubFailure,
  getRemarksByUserSuccess,
  getRemarksByUserFailure,
  updateRemarkStatusSuccess,
  updateRemarkStatusFailure,
  // getRemarksStart,
  // editRemarkSuccess,
  // editRemarkFailure,
  // deleteRemarkSuccess,
  // deleteRemarkFailure,
} from "./remarks.actions";
// import { getWorkRemarksStart } from "../works/works.actions";

export function* addPhoto(photo, remarkId, workId, currentUserId) {
  let formData = new FormData();
  let uniqueImgName = Math.floor(Math.random() * 1000) + photo.name.slice(-35);
  formData.append("jobNoteImg", photo, uniqueImgName);
  yield axios.post(
    `/api/job_notes/img/${currentUserId}/${remarkId}/${workId}`,
    formData
  );
}

export function* addRemark({ payload, callback }) {
  const { remarkData, photos, /*site_id,*/ currentUser, markerCoords } =
    payload;
  const postRemarkData = {
    row: remarkData,
    who: currentUser.user,
  };
  console.log("REMARK DATA IS: ", remarkData);
  try {
    const response = yield axios.post("/api/job_notes/add", postRemarkData);
    if (photos.length) {
      yield all(
        photos.map((photo) =>
          call(
            addPhoto,
            photo,
            response.data.insertId,
            remarkData.job_id,
            currentUser.user
          )
        )
      );
    }
    if (markerCoords.length) {
      console.log("HELLO WORLD");
      const pointsData = {
        jobNotePlanPointsValues: [],
        who: currentUser.user,
      };
      for (const i in markerCoords) {
        markerCoords[i].job_note_id = response.data.insertId;
        pointsData.jobNotePlanPointsValues.push(Object.values(markerCoords[i]));
      }
      pointsData.jobNotePlanPointsColumns = Object.keys(markerCoords[0]);
      console.log("POINTS DATA: ", pointsData);
      const coordsResponse = yield axios.post(
        "/api/job_note_plan_points/add",
        pointsData
      );
      console.log("COORDS RESPONSE: ", coordsResponse);
    }

    yield put(addRemarkSuccess());
    // yield put(getWorkRemarksStart(currentUser.user, remarkData.job_id));
    // yield put(getRemarksStart(currentUser.user, site_id));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addRemarkFailure(error));
  }
}

export function* getRemarks({ payload: { currentUserId, site_id } }) {
  try {
    const response = yield axios.get(
      `/api/job_note_by_obj/${currentUserId}/${site_id}`
    );
    yield put(getRemarksSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getRemarksFailure(error));
  }
}

export function* getRemarksBySub({ payload: { currentUserId, sub_id } }) {
  try {
    const response = yield axios.get(
      `/api/job_note_by_subsection/${currentUserId}/${sub_id}`
    );
    yield put(getRemarksBySubSuccess(sub_id, Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getRemarksBySubFailure(error));
  }
}

export function* getRemarksByUser({ payload }) {
  try {
    const response = yield axios.get(
      `/api/job_note_by_user/${payload}/${payload}`
    );
    yield put(getRemarksByUserSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getRemarksByUserFailure(error));
  }
}

// export function* editRemark(editDetails) {
//   try {
//     yield console.log("axios.put(", editDetails);
//     yield put(editRemarkSuccess());
//   } catch (e) {
//     yield put(
//       editRemarkFailure(
//         e.response ? e.response.data.message : "Сеть недоступна"
//       )
//     );
//   }
// }

// export function* deleteRemark(remarkDetails) {
//   try {
//     yield console.log("axios.delete(", remarkDetails);
//     yield put(deleteRemarkSuccess());
//   } catch (e) {
//     yield put(
//       deleteRemarkFailure(e.response ? e.response.data : "Сеть недоступна")
//     );
//   }
// }

export function* updateRemarkStatus({ payload, callback }) {
  try {
    yield axios.put("/api/job_notes/edit", {
      row: { status_id: payload.status_id },
      who: payload.currentUserId,
      id: payload.remarkId,
    });
    yield put(updateRemarkStatusSuccess());
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(updateRemarkStatusFailure(error));
  }
}

export function* onGetRemarksStart() {
  yield takeLeading(RemarksActionTypes.GET_REMARKS_START, getRemarks);
}

export function* onGetRemarksBySubStart() {
  yield takeLeading(
    RemarksActionTypes.GET_REMARKS_BY_SUB_START,
    getRemarksBySub
  );
}

export function* onGetRemarksByUserStart() {
  yield takeLeading(
    RemarksActionTypes.GET_REMARKS_BY_USER_START,
    getRemarksByUser
  );
}

export function* onAddRemarkStart() {
  yield takeLatest(RemarksActionTypes.ADD_REMARK_START, addRemark);
}

export function* onUpdateRemarkStatus() {
  yield takeLeading(
    RemarksActionTypes.UPDATE_REMARK_STATUS_START,
    updateRemarkStatus
  );
}

// export function* onEditRemarkStart() {
//   yield takeLatest(RemarksActionTypes.EDIT_REMARK_START, editRemark);
// }

// export function* onDeleteRemarkStart() {
//   yield takeLeading(RemarksActionTypes.DELETE_REMARK_START, deleteRemark);
// }

export function* remarksSagas(args) {
  yield all([
    call(onAddRemarkStart),
    call(onGetRemarksStart),
    call(onGetRemarksBySubStart),
    call(onGetRemarksByUserStart),
    call(onUpdateRemarkStatus),
    // call(onEditRemarkStart),
    // call(onDeleteRemarkStart),
  ]);
}
