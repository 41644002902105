import React, { useState, useEffect } from "react";
import { PasswordInput } from "../../components/input-forms";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordStart } from "../../redux/user/user.actions";

const ResetPasswordByEmail = () => {
  const dispatch = useDispatch();
  const { token } = useParams();

  const [newCredentials, setNewCredentials] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [passwordLengthMsg, setPasswordLengthMsg] = useState({
    message: "",
    error: false,
  });

  const [passwordMatchMsg, setPasswordMatchMsg] = useState({
    message: "",
    error: false,
  });

  useEffect(() => {
    if (!newCredentials.newPassword) return;
    if (
      newCredentials.newPassword.length &&
      newCredentials.newPassword.length < 6
    ) {
      if (!passwordLengthMsg.error) {
        setPasswordLengthMsg({
          message: "Длина пароля должна быть не менее 6 симоволов",
          error: true,
        });
      }
    } else if (newCredentials.newPassword === newCredentials.confirmPassword) {
      setPasswordLengthMsg({
        message: "Отличный пароль! Мы гордимся вами!",
        error: false,
      });
    }
  }, [newCredentials, passwordLengthMsg.error, passwordLengthMsg.message]);

  useEffect(() => {
    if (!newCredentials.confirmPassword) return;
    if (newCredentials.confirmPassword !== newCredentials.newPassword) {
      setPasswordMatchMsg({
        message: "Пароль и подтверждение не совпадают",
        error: true,
      });
    } else {
      if (passwordMatchMsg.message) {
        setPasswordMatchMsg({
          message: "",
          error: false,
        });
      }
    }
  }, [
    newCredentials.confirmPassword,
    newCredentials.newPassword,
    passwordMatchMsg.message,
  ]);

  const handleChange = (e) => {
    setNewCredentials({ ...newCredentials, [e.target.name]: e.target.value });
  };

  const isFetching = useSelector((state) => state.user.isFetching);
  const requestErrorMessage = useSelector((state) => state.user.error);

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordMatchMsg.error || passwordLengthMsg.error) return;
    console.log("hello world");
    if (!newCredentials.newPassword)
      return setPasswordLengthMsg({
        message: "Введите новый пароль",
        error: true,
      });
    else if (!newCredentials.confirmPassword)
      return setPasswordMatchMsg({
        message: "Введите подтверждение пароля",
        error: true,
      });
    else if (newCredentials.newPassword.length < 6)
      return setPasswordLengthMsg({
        message: "Длина пароля меньше 6 симоволов",
        error: true,
      });
    else if (newCredentials.newPassword !== newCredentials.confirmPassword)
      return setPasswordMatchMsg({
        message: "Пароль и подтверждение не совпадают",
        error: true,
      });

    const callback = () => history.push("/login");

    setPasswordLengthMsg({
      message: "",
      error: false,
    });
    dispatch(
      resetPasswordStart(
        { password: newCredentials.newPassword, token },
        callback
      )
    );
  };
  console.log(passwordLengthMsg);
  return (
    <div className="login">
      <div className="header_login">
        <h1>Под Контролем</h1>
        <h2>Сменить пароль</h2>
      </div>

      <div className="content_login">
        <form onSubmit={handleSubmit}>
          <div
            className={`info  ${
              passwordMatchMsg.error || passwordLengthMsg.error ? "err" : ""
            } `}
          >
            {passwordMatchMsg.message || passwordLengthMsg.message}
          </div>

          <PasswordInput
            label="Новый пароль"
            // type="password"
            value={newCredentials.newPassword}
            onChange={handleChange}
            name="newPassword"
            validationType="required"
          />

          <PasswordInput
            label="Подтверждение"
            // type="password"
            value={newCredentials.confirmPassword}
            onChange={handleChange}
            name="confirmPassword"
            validationType="required"
          />
          <div className="button">
            <button disabled={isFetching || requestErrorMessage}>
              {isFetching ? "Загрузка..." : "Сменить пароль и войти"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordByEmail;
