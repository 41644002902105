import React from "react";
import { SelectInput } from "../../input-forms";
import { useSelector } from "react-redux";

const SelectContractor = ({
  contractorId,
  selectContractorId,
  validationType,
  err,
}) => {
  const contractors = useSelector((state) => state.contractors.contractors);
  const options = contractors.reduce((acc, curr) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});

  return (
    <SelectInput
      label="Исполнитель (подрядчик)"
      name="contractor_id"
      options={options}
      defaultOption={contractorId}
      onChange={selectContractorId}
      description="Выбрать исполнителя"
      emptyMessage="Нет созданных подрядчиков по данному объекту"
      validationType={validationType}
      err={err}
    />
  );
};

export default SelectContractor;
