import { createUseStyles } from "react-jss";

export const useButtonStyles = createUseStyles({
  button: (props) => {
    let genericStyles = {
      color: "white",
      border: "none",
      cursor: "pointer",
      minWidth: "165px",
      width: "auto",
      height: "28px",
      backgroundColor: "#6B8CFF",
      borderRadius: "55px",
      padding: 0,
    };
    if (props.loginbutton) {
      genericStyles.width = "100%";
      genericStyles.backgroundColor = "#1AA839";
      genericStyles.height = "44px";
      genericStyles.borderRadius = 0;
    }
    if (props.submitstyle) {
      genericStyles.backgroundColor = "#1AA839";
    }
    if (props.cancelstyle) {
      genericStyles.backgroundColor = "#FF6262";
    }
    if (props.disabled) {
      genericStyles.backgroundColor = "grey";
    }
    return genericStyles;
  },
});

export const useLinkStyles = createUseStyles({
  linkButton: (props) => ({
    color: props.disabled ? "grey" : props.deletestyle ? "#ff6262" : "#6b8cff",
    border: "none",
    textDecoration: "underline",
    fontSize: "12px",
    backgroundColor: "white",
    padding: 0,
    cursor: "pointer",
  }),
});
