import { Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { adminStore } from "./admin/redux/adminStore";
import AdminRoutes from "./admin/pages/admin-routes";
import ClientRoutes from "./pages/client-routes";
import ContractorRoutes from "./contractor-routes/contractor-routes";
import axios from "axios";
import { server_url } from "./config";
import { useEffect } from "react";
import "./css/style.css";
import "./css/styles-date-picker.css";
// import PdfToImg from "./components/manage-sites/PdfToImg";

// axios.defaults.baseURL = "http://localhost:3210";
axios.defaults.baseURL = server_url;

function App() {
  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const unsupportedMsg =
    "Настоящее приложение не поддерживается в браузере Internet Explorer. Пожалуйста, используйте более современный браузер, например: Google Chrome, Firefox, Safari, Microsoft Edge.";

  if (isIE) return <div>{unsupportedMsg}</div>;

  return (
    <Switch>
      {/* <Route path="/pdf/" component={PdfToImg} /> */}

      <Route
        path="/admin"
        render={(props) => (
          <Provider store={adminStore}>
            <AdminRoutes {...props} />
          </Provider>
        )}
      />

      <Route
        path="/contractor"
        render={(props) => <ContractorRoutes {...props} />}
      />

      <Route
        path="/"
        render={() => (
          <Provider store={store}>
            <ClientRoutes />
          </Provider>
        )}
      />
    </Switch>
  );
}

export default App;
