const EquipmentActionTypes = {
  ADD_EQUIPMENT_START: "ADD_EQUIPMENT_START",
  ADD_EQUIPMENT_SUCCESS: "ADD_EQUIPMENT_SUCCESS",
  ADD_EQUIPMENT_FAILURE: "ADD_EQUIPMENT_FAILURE",
  GET_EQUIPMENT_START: "GET_EQUIPMENT_START",
  GET_EQUIPMENT_SUCCESS: "GET_EQUIPMENT_SUCCESS",
  GET_EQUIPMENT_FAILURE: "GET_EQUIPMENT_FAILURE",
  DELETE_EQUIPMENT_START: "DELETE_EQUIPMENT_START",
  DELETE_EQUIPMENT_SUCCESS: "DELETE_EQUIPMENT_SUCCESS",
  DELETE_EQUIPMENT_FAILURE: "DELETE_EQUIPMENT_FAILURE",
  EDIT_EQUIPMENT_START: "EDIT_EQUIPMENT_START",
  EDIT_EQUIPMENT_SUCCESS: "EDIT_EQUIPMENT_SUCCESS",
  EDIT_EQUIPMENT_FAILURE: "EDIT_EQUIPMENT_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default EquipmentActionTypes;
