import React, { useEffect, useState } from "react";
import { NumberInput, SelectInput } from "../../input-forms";
import { useDispatch, useSelector } from "react-redux";
import { getSpecialtiesStart } from "../../../redux/resources/resources.actions";

const Specialties = ({
  specialtiesToSubmit,
  setSpecialties,
  specialtyInputs,
  setSpecialtyInputs,
}) => {
  const specialties = useSelector((state) => state.resources.specialties);
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const [options, setOptions] = useState({});
  const isFetchingSpecialties = useSelector(
    (state) => state.resources.isFetchingSpecialties
  );
  const errorMessage = useSelector((state) => state.resources.errorSpecialty);

  useEffect(() => {
    if (!specialties) {
      dispatch(getSpecialtiesStart(currentUser.user));
    } else {
      setOptions(
        specialties.reduce((acc, specialty) => {
          acc[specialty.id] = specialty.specialty;
          return acc;
        }, {})
      );
    }
  }, [specialties, dispatch, currentUser.user]);

  const handleChange = (e) => {
    setSpecialtyInputs({
      ...specialtyInputs,
      [e.target.name]: e.target.value ? Number(e.target.value) : "",
    });
  };

  const addSpecialty = () => {
    const specialtyIndex = specialtiesToSubmit.findIndex(
      (specialty) => specialty[0] === specialtyInputs.id
    );
    if (specialtyIndex !== -1) {
      let newSpecialtiesToSubmit = [...specialtiesToSubmit];
      newSpecialtiesToSubmit[specialtyIndex][1] += specialtyInputs.qty;
      setSpecialties(newSpecialtiesToSubmit);
    } else {
      setSpecialties([
        ...specialtiesToSubmit,
        [specialtyInputs.id, specialtyInputs.qty],
      ]);
    }
    setSpecialtyInputs({ id: "", qty: "" });
  };

  const deleteItem = (index) => {
    setSpecialties(specialtiesToSubmit.filter((specialty, i) => i !== index));
  };

  return (
    <>
      <div className="hdr">Необходимые работники</div>
      {specialtiesToSubmit.length ? (
        <table className="list">
          <tbody>
            {specialtiesToSubmit.map((specialty, i) => (
              <tr key={specialty[0]}>
                <td>{options[specialty[0]]}</td>
                <td>{`${specialty[1]} чел.`}</td>
                <td className="control">
                  <button
                    className="remove"
                    onClick={() => deleteItem(i)}
                    type="button"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        ""
      )}
      <div className="two_col">
        <div className="col">
          <SelectInput
            name="id"
            label="Специализация"
            onChange={handleChange}
            options={options}
            defaultOption={specialtyInputs.id}
            errorMessage={errorMessage}
            description={
              isFetchingSpecialties
                ? "Загрузка данных..."
                : "Выбрать специализацию"
            }
            emptyMessage={"Нет добавленных специализаций"}
          />
        </div>
        <div className="col">
          <NumberInput
            label="Количество"
            value={specialtyInputs.qty}
            onChange={handleChange}
            name="qty"
          />
        </div>
      </div>
      <div className="buttons in_form">
        <button
          className="blue"
          type="button"
          onClick={addSpecialty}
          disabled={!specialtyInputs.id || !specialtyInputs.qty}
        >
          добавить
        </button>
      </div>
    </>
  );
};

export default Specialties;
