import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addGroupStart } from "../../../redux/works/works.actions";
import { InputField } from "../../input-forms";

const AddGroup = ({ close, checkInputsAndClose, checkInputs, group_id }) => {
  const [name, setName] = useState("");
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetching = useSelector((state) => state.works.isFetching);
  const errorMessage = useSelector((state) => state.works.error);

  const [errors, setErrors] = useState({
    type: "required",
    error: null,
  });

  const { site_id } = useParams();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (errors.error && e.target.value) {
      setErrors({ ...errors, error: null });
    }
    setName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      return;
    }
    dispatch(addGroupStart(name, currentUser, site_id, close, group_id));
  };

  checkInputs.current = () => {
    if (name) return true;
    return false;
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputField
        type="text"
        label="Название"
        value={name}
        onChange={handleChange}
        validationType="required"
        err={errors.error}
      />
      <div className="info err">{errorMessage}</div>
      <div className="buttons">
        <button className="green" disabled={isFetching}>
          Добавить
        </button>
        <button
          type="button"
          className="cancel"
          onClick={checkInputsAndClose}
          disabled={isFetching}
        >
          Отмена
        </button>
      </div>
    </form>
  );
};

export default AddGroup;
