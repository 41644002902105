import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Compress from "compress.js";
import {
  baseStyle,
  acceptStyle,
  activeStyle,
  rejectStyle,
  plusStyle,
} from "../css/image-upload.styles";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import PopupSelectDataUrlImg from "./manage-sites/subsections/PopupSelectDataUrlImg";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function ImageUpload({
  pdfToImg,
  maxWidth,
  maxHeight,
  filesToUpload,
  setFilesToUpload,
  label,
  video,
}) {
  const [PDFDataURLs, setPDFDataURLs] = useState();
  const [isLoading, setIsLoading] = useState();
  const filename = useRef();

  const handlePdfToImg = useCallback((file) => {
    let currPage = 1;
    let numPages = 0;
    let thePDF = null;
    const dataURLs = [];

    const handlePages = (page) => {
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement("canvas");

      // canvas.style.display = "block";
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const renderTask = page.render({
        canvasContext: context,
        viewport: viewport,
      });
      renderTask.promise
        .then(() => {
          const dataURL = canvas.toDataURL("image/png", 1.0);
          dataURLs.push(dataURL);

          currPage++;
          if (thePDF !== null && currPage <= numPages) {
            return thePDF
              .getPage(currPage)
              .then(handlePages)
              .catch((error) => console.log(error));
          } else if (dataURLs.length) {
            setPDFDataURLs(dataURLs);
            setIsLoading(false);
          }
        })
        .catch((error) => console.log(error));
    };

    if (file.type !== "application/pdf") {
      return console.log(file.name, "is not a pdf file.");
    }
    const fileReader = new FileReader();
    fileReader.onload = function () {
      const typedarray = new Uint8Array(this.result);
      const loadingTask = pdfjsLib.getDocument(typedarray);
      loadingTask.promise
        .then((pdf) => {
          thePDF = pdf;
          numPages = pdf.numPages;
          pdf
            .getPage(1)
            .then(handlePages)
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    };
    fileReader.readAsArrayBuffer(file);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    /*doc
      ? "application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, text/plain, .pdf,.docx,.doc,.txt"
      : */
    accept: video
      ? "video/mp4, video/webm"
      : `${
          pdfToImg ? "application/pdf," : ""
        } image/jpeg, image/png, image/bmp, image/tiff, image/gif, .jpeg,.jpg,.png,.bmp,.tiff,.tiff,.gif`,
    // // accept: "image/*",
    // accept: "image/jpeg",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length) {
        if (maxWidth || maxHeight) {
          acceptedFiles.splice(1);
          const compress = new Compress();
          return compress
            .compress(acceptedFiles, {
              quality: 1,
              maxHeight: maxHeight,
              maxWidth: maxWidth,
            })
            .then((results) => {
              const img1 = results[0];
              const base64str = img1.data;
              const imgExt = img1.ext;
              const file = Compress.convertBase64ToFile(base64str, imgExt);
              return setFilesToUpload(
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              );
            })
            .catch((error) => console.log(error));
        } else if (pdfToImg && acceptedFiles[0].type === "application/pdf") {
          setIsLoading(true);
          filename.current = acceptedFiles[0].name;
          return handlePdfToImg(acceptedFiles[0]);
        }

        const filesWithPreviews = acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        );

        return setFilesToUpload([...filesToUpload, ...filesWithPreviews]);
      }
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <>
      {PDFDataURLs && (
        <PopupSelectDataUrlImg
          close={() => setPDFDataURLs(null)}
          dataURLsArray={PDFDataURLs}
          filename={filename.current}
          setFilesToUpload={setFilesToUpload}
        />
      )}

      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <span style={plusStyle}>+</span>
          {label && (
            <span>
              {isLoading
                ? "Загрузка..."
                : Array.isArray(filesToUpload)
                ? label
                : filesToUpload
                ? "Картинка загружена"
                : label}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default ImageUpload;
