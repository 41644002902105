import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import InlineModal from "../../inlineModal";
import ContractorInfo from "./contractor-info";
import AddContractor from "./add-contractor";

const Contractors = () => {
  const contractors = useSelector((state) => state.contractors.contractors);
  const history = useHistory();
  const { site_id, menu, info } = useParams();
  console.log("CONTRACTORS: ", contractors);
  const [addContractorWindow, showAddContractorWindow] = useState();

  const getFirstContact = (obj) => {
    for (let i in obj) return obj[i];
    return false;
  };

  // const truncate = (text) => {
  //   if (text.length > 35) {
  //     return text.substring(0, 35) + "...";
  //   }
  //   return text;
  // };

  return (
    <div className="content">
      <div className="buttons top">
        <button className="blue" onClick={() => showAddContractorWindow(true)}>
          Добавить подрядчика
        </button>
      </div>
      {contractors.length ? (
        <table className="list">
          <tbody>
            <tr>
              <th>название</th>
              <th>представитель</th>
              <th>телефон</th>
              <th>эл.почта</th>
              <th>специализация</th>
            </tr>
            {contractors.map((contractor) => {
              const { contractor_contacts } = contractor;
              const firstContact = getFirstContact(contractor_contacts);
              return (
                <tr key={contractor.id}>
                  <td>
                    <label className="mobile">название</label>
                    <Link
                      to={`/main/sites/${site_id}/contractors/${contractor.id}`}
                    >
                      {contractor.name}
                    </Link>
                  </td>
                  {firstContact ? (
                    <Fragment>
                      <td>
                        <label className="mobile">представитель</label>
                        {firstContact.name}
                      </td>
                      <td>
                        <label className="mobile">телефон</label>
                        <a href={`tel: ${firstContact.tel}`}>
                          {firstContact.tel}
                        </a>
                      </td>
                      <td>
                        <label className="mobile">эл.почта</label>{" "}
                        <a href={`mailto: ${firstContact.email}`}>
                          {firstContact.email}
                        </a>
                      </td>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <td></td>
                      <td></td>
                      <td></td>
                    </Fragment>
                  )}
                  <td>
                    <label className="mobile">специализация</label>
                    {contractor.specialization}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}

      {addContractorWindow && (
        <InlineModal close={() => showAddContractorWindow(false)}>
          <AddContractor close={() => showAddContractorWindow(false)} />
        </InlineModal>
      )}
      {menu === "contractors" && info && (
        <InlineModal>
          <ContractorInfo
            close={() => history.push(`/main/sites/${site_id}/contractors`)}
            contractorIdToView={+info}
          />
        </InlineModal>
      )}
    </div>
  );
};

export default Contractors;
