import React, { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Menu from "../../components/manage-sites/menu";
import SubInfo from "../../components/manage-sites/subsections/subsection-info";
import RevisionsList from "../../components/manage-sites/revisions/revisions-list";
import ClaimsList from "../../components/manage-sites/claims/claims-list";
import ActsList from "../../components/manage-sites/acts/acts-list";
import Checklists from "../../components/manage-sites/checklists/checklists";
import WorksAndGroups from "../../components/manage-sites/works/works-groups-section";
import RemarksList from "../../components/manage-sites/remarks/remarks-list";
import InlineModal from "../../components/inlineModal";
import AddSubsecition from "../../components/manage-sites/subsections/add-subsection";
import { useDispatch, useSelector } from "react-redux";
import { getChecklistsStart } from "../../redux/checklists/checklists.actions";

const Subsection = ({ currentSite, currentSub }) => {
  const { site_id, sub_id, menu, info } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const checklists = useSelector((state) => state.checklists);

  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    if (!checklists.checklists || checklists.currentSubId !== sub_id) {
      dispatch(getChecklistsStart(sub_id, currentUser.user));
    }
  }, [
    checklists.checklists,
    checklists.currentSubId,
    dispatch,
    sub_id,
    currentUser.user,
  ]);

  return (
    <>
      {currentSub && currentSub.id === +sub_id && currentSite && (
        <>
          <div className="header2">
            <ul>
              <li className="hdr">
                <Link to={`/main/sites/${site_id}/subsections`}>
                  {currentSite.name}
                </Link>
                <span className="status">{currentSub.name}</span>
              </li>
              <li className="btn">
                <button
                  onClick={() =>
                    history.push(
                      `/main/sites/${site_id}/subsections/${sub_id}/${menu}/edit_sub`
                    )
                  }
                >
                  редактировать
                </button>
              </li>
            </ul>
          </div>
          <Menu subsection />
          {menu === "plan" && <SubInfo menu={menu} />}
          {menu === "revisions" && (
            <RevisionsList
              currentSubsectionId={currentSub && currentSub.id}
              menu={menu}
            />
          )}
          {menu === "claims" && (
            <ClaimsList
              currentSubsectionId={currentSub && currentSub.id}
              menu={menu}
            />
          )}
          {menu === "acts" && (
            <ActsList
              currentSubsectionId={currentSub && currentSub.id}
              menu={menu}
            />
          )}
          {menu === "checklists" && (
            <Checklists currentSub={currentSub} menu={menu} />
          )}
          {menu === "works" && (
            <WorksAndGroups
              currentSubsectionId={currentSub && currentSub.id}
              currentSite={currentSite}
              menu={menu}
            />
          )}
          {menu === "remarks" && (
            <RemarksList
              currentSubsectionId={currentSub && currentSub.id}
              menu={menu}
            />
          )}

          {info === "edit_sub" && (
            <InlineModal
              close={() =>
                history.push(
                  `/main/sites/${site_id}/subsections/${sub_id}/${menu}`
                )
              }
            >
              <AddSubsecition
                subToEdit={currentSub}
                close={() =>
                  history.push(
                    `/main/sites/${site_id}/subsections/${sub_id}/${menu}`
                  )
                }
              />
            </InlineModal>
          )}
        </>
      )}
    </>
  );
};

export default Subsection;
