import VariablesActionTypes from "./variables.types";

export const addVariableStart = (newVariableNames, adminId, callback) => ({
  type: VariablesActionTypes.ADD_VARIABLE_START,
  payload: { newVariableNames, adminId },
  callback,
});

export const addVariableSuccess = () => ({
  type: VariablesActionTypes.ADD_VARIABLE_SUCCESS,
});

export const addVariableFailure = (error) => ({
  type: VariablesActionTypes.ADD_VARIABLE_FAILURE,
  payload: error,
});

export const getVariablesStart = (adminId) => ({
  type: VariablesActionTypes.GET_VARIABLES_START,
  payload: adminId,
});

export const getVariablesSuccess = (specialties) => ({
  type: VariablesActionTypes.GET_VARIABLES_SUCCESS,
  payload: specialties,
});

export const getVariablesFailure = (error) => ({
  type: VariablesActionTypes.GET_VARIABLES_FAILURE,
  payload: error,
});

export const editVariableStart = (
  variableNames,
  adminId,
  variableId,
  callback
) => ({
  type: VariablesActionTypes.EDIT_VARIABLE_START,
  payload: { variableNames, adminId, variableId },
  callback,
});

export const editVariableSuccess = () => ({
  type: VariablesActionTypes.EDIT_VARIABLE_SUCCESS,
});

export const editVariableFailure = (error) => ({
  type: VariablesActionTypes.EDIT_VARIABLE_FAILURE,
  payload: error,
});

export const deleteVariableStart = (variableId, adminId, callback) => ({
  type: VariablesActionTypes.DELETE_VARIABLE_START,
  payload: { variableId, adminId },
  callback,
});

export const deleteVariableSuccess = (deletedVariableId) => ({
  type: VariablesActionTypes.DELETE_VARIABLE_SUCCESS,
  payload: deletedVariableId,
});

export const deleteVariableFailure = (error) => ({
  type: VariablesActionTypes.DELETE_VARIABLE_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: VariablesActionTypes.CLEAR_ERRORS,
});
