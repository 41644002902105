const AdminsActionTypes = {
  SIGN_IN_START: "SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  // GET_USERS_START: "GET_USERS_START",
  // GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  // GET_USERS_FAILURE: "GET_USERS_FAILURE",
  // ADD_USER_START: "ADD_USER_START",
  // ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  // ADD_USER_FAILURE: "ADD_USER_FAILURE",
  // EDIT_USER_START: "EDIT_USER_START",
  // EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  // EDIT_USER_FAILURE: "EDIT_USER_FAILURE",
  // CLEAR_ERRORS: "CLEAR_ERRORS",
  // DELETE_USER_START: "DELETE_USER_START",
  // DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  // DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  // RECOVER_PASSWORD_START: "RECOVER_PASSWORD_START",
  // RECOVER_PASSWORD_SUCCESS: "RECOVER_PASSWORD_SUCCESS",
  // RECOVER_PASSWORD_FAILURE: "RECOVER_PASSWORD_FAILURE",
  // RESET_PASSWORD_START: "RESET_PASSWORD_START",
  // RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  // RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
};
export default AdminsActionTypes;
