import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import InlineModal from "../../inlineModal";
import ViewPoint from "../view-point";
import { plan_images } from "../../../config";
import axios from "axios";
import pinRevision from "../../../css/images/pin-revision.png";
import pinClaim from "../../../css/images/pin-claim.png";
import pinAct from "../../../css/images/pin-act.png";
import pinMany from "../../../css/images/pin-many.png";
import { isEmpty } from "../../../utils/normalizeData";

const GetAllPointsOnPlan = ({ plan }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const { site_id, sub_id, menu, info, action } = useParams();
  const history = useHistory();

  const claims = useSelector((state) => state.claims.claims);
  const acts = useSelector((state) => state.acts.acts);
  const checklists = useSelector((state) => state.checklists.checklists);
  const revisions = useSelector((state) => state.revisions.revisions);

  const [showMarkers, setShowMarkers] = useState(false);
  const [markerEntries, setMarkerEntries] = useState();
  const [fetchingMarkers, setFetchingMarkers] = useState({
    revisions: false,
    claims: false,
    acts: false,
    checklists: false,
    works: false,
  });
  const [zoomed, toggleZoom] = useState(false);
  const [pointDetails, showPointDetails] = useState();

  const path = useRef(
    `/main/sites/${site_id}` + (sub_id ? `/subsections/${sub_id}` : "")
  );

  const handlePointClick = useCallback(
    (point, pointType) => {
      switch (pointType) {
        case "claim":
          showPointDetails({ type: "claim", id: point.claim_id });
          return history.push(`${path.current}/plan/${point.claim_id}/claim`);

        case "revision":
          showPointDetails({ type: "revision", id: point.revision_id });
          return history.push(
            `${path.current}/plan/${point.revision_id}/revision`
          );

        case "act":
          showPointDetails({ type: "act", id: point.act_id });
          return history.push(`${path.current}/plan/${point.act_id}/act`);

        case "checklist":
          showPointDetails({ type: "checklist", id: point.checklist_id });
          return history.push(
            `${path.current}/plan/${point.checklist_id}/checklist`
          );

        case "work":
          // showPointDetails({ type: "work", id: point.work_id });
          return history.push(`${path.current}/works/${point.job_id}`);

        default:
          return;
      }
    },
    [history]
  );

  const definePointsType = useCallback(
    (pointsArray, type) => {
      return pointsArray.map((point, i) => {
        let record;
        if (type === "claim" && claims.length) {
          record = claims.find((claim) => claim.id === point.claim_id);
        } else if (type === "act" && acts.length) {
          record = acts.find((act) => act.id === point.act_id);
        } else if (type === "checklist" && checklists.length) {
          record = checklists.find(
            (checklist) => checklist.id === point.checklist_id
          );
        } else if (type === "revision" && revisions.length) {
          record = revisions.find((rev) => rev.id === point.revision_id);
        } else if (type === "work") {
          record = {};
        }
        if (record) {
          // console.log("RECORD: ", record);
          let pointType = type;
          if (
            record.claim_id ||
            record.revision_id ||
            !isEmpty(record.claim_to_rev) ||
            !isEmpty(record.claim_from_rev)
          ) {
            pointType = "many";
          }
          return {
            type: pointType,
            point: (
              <Link
                className={`the_pin ${
                  pointType === "checklist" ? "act" : pointType
                }`}
                to="#"
                onClick={() => handlePointClick(point, type)}
                key={i + type}
                style={{
                  left: `calc(${point.dot_x}% - 12.5px)`,
                  top: `calc(${point.dot_y}% - 40px)`,
                }}
              />
            ),
          };
        }
        return { type: "", point: "" };
      });
    },
    [claims, acts, revisions, checklists, handlePointClick]
  );

  const renderPointDetails = (pointDetails) => {
    const close = () => {
      history.push(`${path.current}/plan`);
      showPointDetails(null);
    };

    switch (pointDetails.type) {
      case "claim":
        return <ViewPoint claim_id={pointDetails.id} close={close} />;

      case "revision":
        return <ViewPoint rev_id={pointDetails.id} close={close} />;

      case "act":
        return <ViewPoint act_id={pointDetails.id} close={close} />;

      case "checklist":
        return <ViewPoint checklist_id={pointDetails.id} close={close} />;

      default:
        return "";
    }
  };

  // get all points from the server for this plan
  useEffect(() => {
    if (showMarkers && plan && !markerEntries) {
      let obtainedPoints = [];

      setFetchingMarkers((f) => ({ ...f, acts: true }));
      axios
        .get(`/api/act_plan_points_plan/${currentUser.user}/${plan.id}`)
        .then((response) => {
          const definedActPoints = definePointsType(response.data, "act");
          if (definedActPoints.length) {
            obtainedPoints = [...obtainedPoints, ...definedActPoints];
            setMarkerEntries(obtainedPoints);
          }
          setFetchingMarkers((f) => ({ ...f, acts: false }));
        })
        .catch((e) => console.log(e));

      setFetchingMarkers((f) => ({ ...f, checklists: true }));
      axios
        .get(`/api/checklist_plan_points_plan/${currentUser.user}/${plan.id}`)
        .then((response) => {
          console.log("CHECKLIST POINTS: ", response.data);
          const definedChecklistPoints = definePointsType(
            response.data,
            "checklist"
          );
          if (definedChecklistPoints.length) {
            obtainedPoints = [...obtainedPoints, ...definedChecklistPoints];
            setMarkerEntries(obtainedPoints);
          }
          setFetchingMarkers((f) => ({ ...f, checklists: false }));
        })
        .catch((e) => console.log(e));

      setFetchingMarkers((f) => ({ ...f, claims: true }));
      axios
        .get(`/api/claim_plan_points_plan/${currentUser.user}/${plan.id}`)
        .then((response) => {
          const definedClaimPoints = definePointsType(response.data, "claim");
          if (definedClaimPoints.length) {
            obtainedPoints = [...obtainedPoints, ...definedClaimPoints];
            setMarkerEntries(obtainedPoints);
          }
          setFetchingMarkers((f) => ({ ...f, claims: false }));
        })
        .catch((e) => console.log(e));

      setFetchingMarkers((f) => ({ ...f, revisions: true }));
      axios
        .get(`/api/revision_plan_points_plan/${currentUser.user}/${plan.id}`)
        .then((response) => {
          const definedRevisionPoints = definePointsType(
            response.data,
            "revision"
          );
          if (definedRevisionPoints.length) {
            obtainedPoints = [...obtainedPoints, ...definedRevisionPoints];
            setMarkerEntries(obtainedPoints);
          }
          setFetchingMarkers((f) => ({ ...f, revisions: false }));
        })
        .catch((e) => console.log(e));

      setFetchingMarkers((f) => ({ ...f, works: true }));
      axios
        .get(`/api/job_plan_points_plan/${currentUser.user}/${plan.id}`)
        .then((response) => {
          const definedWorkPoints = definePointsType(response.data, "work");
          if (definedWorkPoints.length) {
            obtainedPoints = [...obtainedPoints, ...definedWorkPoints];
            setMarkerEntries(obtainedPoints);
          }
          setFetchingMarkers((f) => ({ ...f, works: false }));
        })
        .catch((e) => console.log(e));
    }
  }, [plan, currentUser.user, definePointsType, showMarkers, markerEntries]);

  const currentSub = useSelector(
    (state) => state.subsections.currentSubsection
  );

  useEffect(() => {
    if (menu === "plan" && info && action && !pointDetails) {
      showPointDetails({ type: action, id: +info });
    }
  }, [menu, info, action, pointDetails]);

  return (
    <div className={`plan ${zoomed ? "zoomed" : ""}`}>
      {pointDetails && info && action && (
        <InlineModal close={() => showPointDetails(null)}>
          {renderPointDetails(pointDetails)}
        </InlineModal>
      )}

      <div className="scroll">
        <div className="container">
          <img
            src={`${plan_images}/${currentSub.obj_id}/${currentSub.id}/${plan.plan}`}
            alt="plan"
          />
          {showMarkers &&
            markerEntries &&
            markerEntries.length &&
            markerEntries.map((entry) => entry.point)}
        </div>
      </div>

      {showMarkers && (
        <>
          {(fetchingMarkers.claims ||
            fetchingMarkers.revisions ||
            fetchingMarkers.acts ||
            fetchingMarkers.checklists) && (
            <div className="info">загрузка координат...</div>
          )}

          <div className="pins">
            <span className="pin">
              <img src={pinRevision} alt="pin-revision" />
              Проверка
            </span>

            <span className="pin">
              <img src={pinAct} alt="pin-act" />
              Акт или Чеклист
            </span>

            <span className="pin">
              <img src={pinClaim} alt="pin-claim" />
              Предписание
            </span>

            <span className="pin">
              <img src={pinMany} alt="pin-many" />
              Разные
            </span>
          </div>
        </>
      )}

      <span className="control">
        <button className="zoom" onClick={() => toggleZoom(!zoomed)}></button>
        <button
          className="show_pins"
          onClick={() => setShowMarkers(!showMarkers)}
        >{`${showMarkers ? "Скрыть" : "Показать"} точки`}</button>
      </span>
    </div>
  );
};

export default GetAllPointsOnPlan;
