import React, { useState, useEffect } from "react";
import { usePromptWindowStyles } from "../../../css/admin/main-section.styles";
import CustomButton from "../../../components/buttons/custom-button";
import LinkButton from "../../../components/buttons/link-button";
import { FormInput } from "../../../components/input-forms";
import {
  addTechGroupStart,
  editTechGroupStart,
} from "../../redux/techCards/techCards.actions";
import { useDispatch, useSelector } from "react-redux";

const TechGroupModal = ({ groupId, groupToEdit, close }) => {
  const classes = usePromptWindowStyles();
  console.log("GROUP ID: ", groupId);
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const isAdding = useSelector((state) => state.techCards.isAdding);
  const errorMessage = useSelector((state) => state.techCards.error);

  const [groupName, setGroupName] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (groupToEdit) {
      setGroupName(groupToEdit.name);
    }
  }, [groupToEdit]);

  useEffect(() => {
    document.getElementById("groupName").focus();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    let action = addTechGroupStart;
    if (groupName) {
      if (groupToEdit) {
        action = editTechGroupStart;
        groupId = groupToEdit.id;
      }

      dispatch(action(groupName, groupId, currentAdmin.adminId, close));
    }
  };

  return (
    <form className={classes.promptWindow} onSubmit={handleSubmit}>
      <h1>{groupToEdit ? "Редактировать группу" : "Создать группу"}</h1>
      <FormInput
        id="groupName"
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
      />
      <div className={classes.errorMsg}>{errorMessage}</div>
      <div className={classes.actionBtns}>
        <CustomButton disabled={isAdding}>
          {groupToEdit ? "Сохранить" : "Добавить"}
        </CustomButton>
        <LinkButton type="button" onClick={close}>
          Отмена
        </LinkButton>
      </div>
    </form>
  );
};

export default TechGroupModal;
