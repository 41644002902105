import AdminsActionTypes from "./admins.types";

const INITIAL_STATE = {
  currentAdmin: JSON.parse(localStorage.getItem("admin")),
  isFetching: false,
  error: null,
  recover: false,
};

const adminsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AdminsActionTypes.SIGN_IN_START:
      // case AdminsActionTypes.ADD_USER_START:
      // case AdminsActionTypes.GET_USERS_START:
      // case AdminsActionTypes.EDIT_USER_START:
      // case AdminsActionTypes.RECOVER_PASSWORD_START:
      // case AdminsActionTypes.RESET_PASSWORD_START:
      return { ...state, isFetching: true, error: null };
    // case AdminsActionTypes.DELETE_USER_START:
    //   return { ...state, isDeleting: true, error: null };
    // case AdminsActionTypes.RECOVER_PASSWORD_SUCCESS:
    //   return { ...state, error: null, recover: true };
    case AdminsActionTypes.SIGN_IN_SUCCESS:
      localStorage.setItem(
        "admin",
        JSON.stringify({
          adminId: action.payload.adminId,
          organization: action.payload.organization,
          token: action.payload.token,
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
        })
      );
      return {
        ...state,
        currentAdmin: action.payload,
        error: null,
        isFetching: false,
      };

    case AdminsActionTypes.SIGN_OUT_SUCCESS: {
      localStorage.removeItem("admin");
      return { ...state, currentAdmin: null };
    }
    // case AdminsActionTypes.ADD_USER_SUCCESS:
    // case AdminsActionTypes.EDIT_USER_SUCCESS:
    // case AdminsActionTypes.RESET_PASSWORD_SUCCESS:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     error: null,
    //   };
    // case AdminsActionTypes.GET_USERS_SUCCESS: {
    //   return {
    //     ...state,
    //     usersList: action.payload,
    //     error: null,
    //     isFetching: false,
    //   };
    // }
    // case AdminsActionTypes.DELETE_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     isDeleting: false,
    //     error: null,
    //   };
    // }
    case AdminsActionTypes.SIGN_IN_FAILURE:
    case AdminsActionTypes.SIGN_OUT_FAILURE:
      // case AdminsActionTypes.ADD_USER_FAILURE:
      // case AdminsActionTypes.GET_USERS_FAILURE:
      // case AdminsActionTypes.DELETE_USER_FAILURE:
      // case AdminsActionTypes.RECOVER_PASSWORD_FAILURE:
      // case AdminsActionTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        // isDeleting: false,
        recover: false,
        error: action.payload,
      };

    case AdminsActionTypes.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default adminsReducer;
