import React, { useContext, useState, Fragment } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getWorksStart,
//   getGroupsStart,
// } from "../../../redux/works/works.actions";
import { Link, useHistory } from "react-router-dom";
import WorkGroupsContext from "../../../contexts/work-groups.context";
import InlineModal from "../../inlineModal";
import GroupInfo from "./group-info";
import Level from "./graph/level";
import { isEmpty, datediff } from "../../../utils/normalizeData";

const GroupsList = ({
  // job_group_id,
  handleAddWork,
  site_id,
  level,
  workSelector,
  groups,
  works,
  siteMinDate,
  dayLineWidth,
  bkStyles,
}) => {
  // const dispatch = useDispatch();
  // const currentUser = useSelector((state) => state.user.currentUser);
  // console.log("SITE MIN DATE: ", siteMinDate);
  // const groupsList = useSelector((state) => state.works.groups);
  // const worksList = useSelector((state) => state.works.works);
  // const fetchingWorks = useSelector((state) => state.works.fetchingWorks);

  const { openWorkGroups, toggleOpenWorkGroup } = useContext(WorkGroupsContext);

  // useEffect(() => {
  //   if (!groupsList) {
  //     dispatch(getGroupsStart(currentUser, site_id));
  //   }
  // }, [groupsList, currentUser, dispatch, site_id]);

  // useEffect(() => {
  //   if (!worksList[job_group_id]) {
  //     dispatch(getWorksStart(job_group_id, currentUser.user, site_id));
  //   }
  // }, [worksList, job_group_id, currentUser, site_id, dispatch]);

  const history = useHistory();

  const handleClick = (work) => {
    if (workSelector) return workSelector(work);
    return history.push(`/main/sites/${site_id}/works/${work.id}`);
  };

  const [groupToEdit, showGroupEditWindow] = useState();

  // const addLevels = (level, name, id) => {
  //   // const span = document.createElement("span");
  //   // span.className="level"

  //   const createLevels = (level, name) => {
  //     const Level = document.createElement("span");
  //     span.className = "level";
  //     if (level === 0) {
  //       span.innerHTML = name;
  //       return span;
  //       // return element.appendChild(span);
  //     } else {
  //       const spanToAppend = createLevels(level - 1, name);
  //       return span.appendChild(spanToAppend);
  //     }
  //   };

  //   const levels = createLevels(level, name);
  //   const element = document.getElementById(id);
  //   // console.log("LEVELS: ", levels);
  //   if (element) {
  //     element.innerHTML = levels;
  //   }
  // };
  // console.log("WORKS: ", works);

  const addLevels = (level, name) => {
    const createLevels = (level, name) => {
      if (level === 0) {
        return <Level>{name}</Level>;
        // return element.appendChild(span);
      } else if (level > 0) {
        const LevelToInsert = createLevels(level - 1, name);
        return <Level>{LevelToInsert}</Level>;
      } else return name;
    };

    return createLevels(level, name);

    // const levels = createLevels(level, name);
    // const element = document.getElementById(id);
    // // console.log("LEVELS: ", levels);
    // if (element) {
    //   element.innerHTML = levels;
    // }
  };

  return (
    <>
      {groupToEdit && (
        <InlineModal close={() => showGroupEditWindow(null)}>
          <GroupInfo
            group_id={groupToEdit}
            close={() => showGroupEditWindow(null)}
          />
        </InlineModal>
      )}

      {!isEmpty(groups) &&
        Object.values(groups).map((group) => {
          let numOfDays;
          let offsetDays = 0;
          // console.log("INDIVIDUAL GROUP: ", group);

          if ("minDate" in group) {
            numOfDays =
              datediff(new Date(group.minDate), new Date(group.maxDate)) + 1;

            if (siteMinDate) {
              offsetDays = datediff(
                new Date(siteMinDate),
                new Date(group.minDate)
              );
            }
          }
          // console.log("DATE DIFF: ", numOfDays);
          // console.log("OFFSET DAYS: ", offsetDays);

          return (
            <Fragment key={group.id}>
              <tr>
                <td className="jobs">
                  <Link
                    to="#"
                    className="group"
                    onClick={() => toggleOpenWorkGroup(group.id)}
                  >
                    {addLevels(level - 1, group.name)}
                  </Link>
                  {!workSelector && (
                    <>
                      <Link
                        to="#"
                        className="edit"
                        onClick={() => showGroupEditWindow(group.id)}
                      />

                      <Link
                        to="#"
                        className="add"
                        onClick={() => handleAddWork(group.id)}
                      />
                    </>
                  )}
                </td>
                {numOfDays ? (
                  <td className="time">
                    <span
                      className="bk"
                      style={bkStyles}
                      // style={{
                      //   left: `${dayLineWidth < 50.2 ? 50.15 : 55.2}px`,
                      //   backgroundSize: `${
                      //     dayLineWidth < 50.2 ? 175.65 : 251.2
                      //   }px auto`,
                      // }}
                    ></span>
                    <span
                      className="timing"
                      style={{
                        marginLeft: `${offsetDays * dayLineWidth}px`,
                        width: `${numOfDays * dayLineWidth}px`,
                      }}
                    ></span>
                  </td>
                ) : null}
              </tr>

              {openWorkGroups.includes(group.id) && (
                <GroupsList
                  job_group_id={group.id}
                  handleAddWork={handleAddWork}
                  site_id={site_id}
                  workSelector={workSelector}
                  level={level + 1}
                  groups={group.groups}
                  works={group.jobs}
                  siteMinDate={siteMinDate}
                  dayLineWidth={dayLineWidth}
                />
              )}
            </Fragment>
          );
        })}

      {/* {fetchingWorks.includes(job_group_id) && (
        <tr>
          <td className="jobs">
            <div>{addLevels(level, "загрузка...")}</div>
          </td>
        </tr>
      )} */}

      {works &&
        Object.values(works).map((work, i) => {
          const numOfDays =
            datediff(new Date(work.date_start), new Date(work.date_end)) + 1;
          const offsetDays = datediff(
            new Date(siteMinDate),
            new Date(work.date_start)
          );

          return (
            <tr key={i}>
              <td className="jobs" key={work.id}>
                <Link
                  to="#"
                  className="group"
                  onClick={() => handleClick(work)}
                  id={`work_${work.id}`}
                >
                  {addLevels(level, work.name)}
                </Link>
              </td>

              <td className="time">
                <span className="bk"></span>
                <span
                  className="timing"
                  style={{
                    marginLeft: `${offsetDays * dayLineWidth}px`,
                    width: `${numOfDays * dayLineWidth}px`,
                  }}
                ></span>
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default GroupsList;
