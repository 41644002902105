import React, { useState } from "react";
import { InputField } from "../input-forms";
import {
  checkEmailValidity,
  emailPattern,
} from "../../utils/checkEmailValidity";
import formatPhoneNumber from "../../utils/formatPhoneNumber";

const Contacts = ({
  contactInputs,
  setContactInputs,
  contactsToAdd,
  setContactsToAdd,
}) => {
  const [isEmail, setIsEmail] = useState(true);

  const [editMode, setEditMode] = useState({ status: false, i: null });

  const handleChange = (e) => {
    if (e.target.name === "tel") {
      return setContactInputs({
        ...contactInputs,
        [e.target.name]: formatPhoneNumber(e.target.value),
      });
    }

    setContactInputs({ ...contactInputs, [e.target.name]: e.target.value });
    setIsEmail(true);
  };

  const handleSubmit = () => {
    if (contactInputs.email && !checkEmailValidity(contactInputs.email))
      return setIsEmail(false);
    if (editMode.status) {
      setContactsToAdd(
        contactsToAdd.map((contact, index) => {
          if (index === editMode.i) return contactInputs;
          return contact;
        })
      );
      setEditMode({ status: false, i: null });
      setIsEmail(true);
      return setContactInputs({
        name: "",
        email: "",
        tel: "",
        other_contacts: "",
      });
    }
    console.log("CONTACT INPUTS: ", contactInputs);
    for (const field in contactInputs) {
      if (contactInputs[field]) {
        setContactsToAdd([...contactsToAdd, contactInputs]);
        break;
      }
    }
    setContactInputs({ name: "", email: "", tel: "", other_contacts: "" });
    setIsEmail(true);
  };

  const editContact = (index) => {
    setEditMode({ status: true, i: index });
    const contactToEdit = contactsToAdd[index];
    setContactInputs(contactToEdit);
  };

  const deleteContact = (itemIndex) => {
    setContactsToAdd(
      contactsToAdd.filter((contact, index) => index !== itemIndex)
    );
  };

  const cancelEdit = () => {
    setEditMode({ status: false, i: null });
    setContactInputs({ name: "", email: "", tel: "", other_contacts: "" });
  };
  return (
    <>
      <div className="hdr">Контакты</div>
      <table className="list">
        <tbody>
          {contactsToAdd.map((contact, index) => (
            <tr key={index}>
              <td className="contact_link">{contact.name}</td>
              <td className="contact_link">
                <a href={`mailto: ${contact.email}`}> {contact.email}</a>
              </td>
              <td className="contact_link">
                {" "}
                <a href={`mailto: ${contact.tel}`}> {contact.tel}</a>
              </td>
              <td className="contact_link">{contact.other_contacts}</td>

              <td className="control">
                <button
                  type="button"
                  onClick={() => editContact(index)}
                  disabled={editMode.status}
                >
                  Ред.
                </button>
                <button
                  onClick={() => deleteContact(index)}
                  disabled={editMode.status}
                  type="button"
                  className="remove"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="two_col">
        <div className="col">
          <InputField
            type="text"
            label="Имя"
            name="name"
            value={contactInputs.name}
            onChange={handleChange}
            autoComplete="off"
          />
          <InputField
            type="email"
            label="Электронная почта"
            name="email"
            value={contactInputs.email}
            onChange={handleChange}
            autoComplete="off"
            err={isEmail ? "" : "Введите корректный e-mail"}
            onBlur={(e) => checkEmailValidity(e.target.value)}
            pattern={emailPattern}
            validationType="email"
          />
        </div>
        <div className="col">
          <InputField
            type="text"
            label="Телефон"
            name="tel"
            value={contactInputs.tel}
            onChange={handleChange}
            autoComplete="off"
          />
          <InputField
            type="text"
            label="Другие способы связи"
            name="other_contacts"
            value={contactInputs.other_contacts}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
      </div>
      <div>
        <div className="buttons in_form">
          <button className="blue" type="button" onClick={handleSubmit}>
            {`${editMode.status ? "сохранить" : "добавить контакт"}`}
          </button>
          {editMode.status ? (
            <button className="cancel" onClick={cancelEdit}>
              Отмена
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Contacts;
