import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InlineModal from "../../inlineModal";
import AddSubsection from "./add-subsection";
import AddSubsectionGroup from "./add-subsection-group";
import SubsectionsGroups from "./subsectionsGroups";
import ConfirmDeleteModal from "../../confirmActionModal";
import { deleteSubsectionGroupStart } from "../../../redux/subsections/subsections.actions";

const SubsectionsList = () => {
  const { site_id } = useParams();

  const isFetching = useSelector((state) => state.subsections.isFetching);
  const errorMsg = useSelector((state) => state.subsections.error);
  const currentUser = useSelector((state) => state.user.currentUser);

  // const groups = useSelector((state) => state.subsections.groups);

  // console.log("SUB GROUPS: ", groups);

  const [addSubsectionWindow, showAddSubsectionWindow] = useState();
  const [groupToEdit, showEditGroupWindow] = useState();
  const [groupToDelete, confirmDeleteGroup] = useState();

  const groupId = useRef();

  const handleAddSubsection = (subsection_group_id) => {
    groupId.current = subsection_group_id;
    showAddSubsectionWindow(true);
  };

  const handleGroupEdit = (groupToEdit) => {
    showEditGroupWindow(groupToEdit);
  };

  const dispatch = useDispatch();

  const deleteGroup = () => {
    dispatch(
      deleteSubsectionGroupStart(
        groupToDelete.id,
        currentUser.user,
        site_id,
        () => confirmDeleteGroup(null)
      )
    );
  };

  return (
    <div className="content">
      <div className="buttons top">
        <button className="blue" onClick={() => handleAddSubsection(null)}>
          Добавить в корень
        </button>
      </div>

      {addSubsectionWindow && (
        <InlineModal close={() => showAddSubsectionWindow(false)}>
          <AddSubsection
            close={() => showAddSubsectionWindow(false)}
            groupId={groupId.current}
          />
        </InlineModal>
      )}

      {groupToEdit && (
        <InlineModal close={() => showEditGroupWindow(null)}>
          <AddSubsectionGroup
            groupToEdit={groupToEdit}
            close={() => showEditGroupWindow(null)}
            groupId={groupToEdit.id}
          />
        </InlineModal>
      )}

      {groupToDelete && (
        <ConfirmDeleteModal
          message={`Удалить группу "${groupToDelete.name}"?`}
          confirm={deleteGroup}
          cancel={() => confirmDeleteGroup(null)}
          processing={isFetching}
          errorMsg={errorMsg}
        />
      )}

      {errorMsg && <div className="info err">{errorMsg}</div>}

      <div className="subs">
        <SubsectionsGroups
          handleAddSubsection={handleAddSubsection}
          handleGroupEdit={handleGroupEdit}
          confirmDeleteGroup={confirmDeleteGroup}
          site_id={site_id}
        />
      </div>
    </div>
  );
};

export default SubsectionsList;
