const TechCardsActionTypes = {
  ADD_TECH_CARD_START: "ADD_TECH_CARD_START",
  ADD_TECH_CARD_SUCCESS: "ADD_TECH_CARD_SUCCESS",
  ADD_TECH_CARD_FAILURE: "ADD_TECH_CARD_FAILURE",
  GET_TECH_CARDS_START: "GET_TECH_CARDS_START",
  GET_TECH_CARDS_SUCCESS: "GET_TECH_CARDS_SUCCESS",
  GET_TECH_CARDS_FAILURE: "GET_TECH_CARDS_FAILURE",
  DELETE_TECH_CARD_START: "DELETE_TECH_CARD_START",
  DELETE_TECH_CARD_SUCCESS: "DELETE_TECH_CARD_SUCCESS",
  DELETE_TECH_CARD_FAILURE: "DELETE_TECH_CARD_FAILURE",
  DELETE_TECH_GROUP_START: "DELETE_TECH_GROUP_START",
  DELETE_TECH_GROUP_SUCCESS: "DELETE_TECH_GROUP_SUCCESS",
  DELETE_TECH_GROUP_FAILURE: "DELETE_TECH_GROUP_FAILURE",
  EDIT_TECH_CARD_START: "EDIT_TECH_CARD_START",
  EDIT_TECH_CARD_SUCCESS: "EDIT_TECH_CARD_SUCCESS",
  EDIT_TECH_CARD_FAILURE: "EDIT_TECH_CARD_FAILURE",
  ADD_TECH_GROUP_START: "ADD_TECH_GROUP_START",
  ADD_TECH_GROUP_SUCCESS: "ADD_TECH_GROUP_SUCCESS",
  ADD_TECH_GROUP_FAILURE: "ADD_TECH_GROUP_FAILURE",
  GET_TECH_GROUPS_START: "GET_TECH_GROUPS_START",
  GET_TECH_GROUPS_SUCCESS: "GET_TECH_GROUPS_SUCCESS",
  GET_TECH_GROUPS_FAILURE: "GET_TECH_GROUPS_FAILURE",
  EDIT_TECH_GROUP_START: "EDIT_TECH_GROUP_START",
  EDIT_TECH_GROUP_SUCCESS: "EDIT_TECH_GROUP_SUCCESS",
  EDIT_TECH_GROUP_FAILURE: "EDIT_TECH_GROUP_FAILURE",
  GET_CURRENT_TECH_CARD_START: "GET_CURRENT_TECH_CARD_START",
  GET_CURRENT_TECH_CARD_SUCCESS: "GET_CURRENT_TECH_CARD_SUCCESS",
  GET_CURRENT_TECH_CARD_FAILURE: "GET_CURRENT_TECH_CARD_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default TechCardsActionTypes;
