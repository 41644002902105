import React, { useEffect, useState } from "react";
import { useMainSectionStyles } from "../../../css/admin/main-section.styles";
import { FormInput } from "../../../components/input-forms";
import { useDispatch, useSelector } from "react-redux";
import {
  addEquipmentStart,
  getEquipmentStart,
  deleteEquipmentStart,
  clearErrors,
  editEquipmentStart,
} from "../../redux/equipment/equipment.actions";
import { ReactComponent as Trash } from "../../../assets/trash.svg";
import ConfirmDeleteModal from "../../../components/confirmActionModal";

const Equipment = () => {
  const classes = useMainSectionStyles();
  const dispatch = useDispatch();
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const equipment = useSelector((state) => state.equipment.equipment);
  const isDeleting = useSelector((state) => state.equipment.isDeleting);
  const errorMessage = useSelector((state) => state.equipment.error);
  const isAdding = useSelector((state) => state.equipment.isAdding);

  const [equipmentName, setEquipmentName] = useState("");
  const [equipmentToEdit, setEquipmentToEdit] = useState();
  const [confirmDelete, setConfirmDelete] = useState();

  useEffect(() => {
    if (equipmentToEdit) {
      setEquipmentName(equipmentToEdit.name);
    }
  }, [equipmentToEdit]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [confirmDelete, dispatch]);

  useEffect(() => {
    if (!equipment) {
      dispatch(getEquipmentStart(currentAdmin.adminId));
    }
  }, [equipment, currentAdmin.adminId, dispatch]);

  const callback = () => {
    setEquipmentName("");
    document.getElementById("name").focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (equipmentToEdit) {
      const callback = () => {
        setEquipmentToEdit(null);
        setEquipmentName("");
      };

      return dispatch(
        editEquipmentStart(
          equipmentName,
          currentAdmin.adminId,
          equipmentToEdit.id,
          callback
        )
      );
    }

    if (equipmentName) {
      dispatch(
        addEquipmentStart(equipmentName, currentAdmin.adminId, callback)
      );
    }
  };

  const handleDelete = (e) => {
    const callback = () => setConfirmDelete(false);

    e.preventDefault();
    dispatch(
      deleteEquipmentStart(confirmDelete.id, currentAdmin.adminId, callback)
    );
    if (equipmentToEdit && equipmentToEdit.id === confirmDelete.id) {
      setEquipmentToEdit(null);
      setEquipmentName("");
    }
  };

  const cancelEdit = () => {
    setEquipmentToEdit(null);
    setEquipmentName("");
    document.getElementById("name").focus();
  };

  return (
    <div className={classes.main}>
      <div className={classes.list}>
        <h1>Виды оборудования</h1>
        {equipment &&
          equipment.map((equipment) => (
            <div key={equipment.id} className={classes.listItem}>
              <span
                onClick={() => setEquipmentToEdit(equipment)}
                className={
                  equipmentToEdit && equipmentToEdit.id === equipment.id
                    ? classes.selectedItem
                    : ""
                }
              >
                {equipment.name}
              </span>
              <Trash onClick={() => setConfirmDelete(equipment)} />
            </div>
          ))}
      </div>
      <form className={classes.addSection} onSubmit={handleSubmit}>
        <FormInput
          label="Название"
          id="name"
          value={equipmentName}
          onChange={(e) => setEquipmentName(e.target.value)}
        />
        <div className="buttons">
          <button
            className="blue"
            submitstyle="true"
            disabled={!equipmentName || isAdding}
          >
            {equipmentToEdit ? "Сохранить" : "Добавить"}
          </button>
          {equipmentToEdit && (
            <button
              className="cancel"
              disabled={isAdding}
              type="button"
              onClick={cancelEdit}
            >
              Отмена
            </button>
          )}
        </div>
        <div className={classes.errorMsg}>{errorMessage}</div>
      </form>

      {confirmDelete && (
        <ConfirmDeleteModal
          message={`Удалить оборудование "${confirmDelete.name}" ?`}
          confirm={handleDelete}
          cancel={() => setConfirmDelete(null)}
          processing={isDeleting}
          errorMsg={errorMessage}
        />
      )}
    </div>
  );
};

export default Equipment;
