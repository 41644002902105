import React from "react";
import { SelectInput } from "../../input-forms";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/normalizeData";

const PreviousClaims = ({ claim_id, handleChange, subsectionId }) => {
  const claims = useSelector((state) => state.claims.claims);
  const statuses = useSelector((state) => state.statuses.claims);

  let filteredClaims = claims;
  if (subsectionId) {
    filteredClaims = filteredClaims.filter(
      (claim) =>
        claim.subsection_id === subsectionId &&
        statuses[claim.status_id] !== "Закрыто актом" &&
        statuses[claim.status_id] !== "Закрыто отметкой" &&
        statuses[claim.status_id] !== "Отозвано"
    );
  }
  filteredClaims = filteredClaims.filter(
    (claim) =>
      statuses &&
      statuses[claim.status_id] !== "Закрыто актом" &&
      statuses[claim.status_id] !== "Закрыто отметкой" &&
      statuses[claim.status_id] !== "Отозвано"
  );

  const claimOptions = statuses
    ? filteredClaims.reduce((acc, curr) => {
        acc[curr.id] = `№${curr.num} от ${formatDate(
          curr.datecreated,
          true
        )} - ${curr.title}`;
        return acc;
      }, {})
    : {};

  return (
    <SelectInput
      name="claim_id"
      defaultOption={claim_id}
      onChange={handleChange}
      options={claimOptions}
      label="Список предписаний"
      emptyMessage="Нет активных предписаний"
      description="Выбрать прошлое предписание"
    />
  );
};

export default PreviousClaims;
