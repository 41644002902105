import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";
import { signOut } from "../user/user.actions";

import SitesActionTypes from "./sites.types";
import {
  addSiteSuccess,
  addSiteFailure,
  getSitesSuccess,
  getSitesFailure,
  editSiteSuccess,
  editSiteFailure,
  deleteSiteSuccess,
  deleteSiteFailure,
  getSitesStart,
} from "./sites.actions";

export function* addSite({ payload, callback }) {
  const { siteDetails, emailsToAdd, contactsToAdd, team, currentUser } =
    payload;
  // console.log("PAYLOAD DATA: ", payload);
  const requestData = {
    objColumns: Object.keys(siteDetails),
    objValues: [Object.values(siteDetails)],
    teamColumns: ["user_id"],
    teamValues: [...team.map((member) => [member])],
    who: currentUser.user,
    organization: currentUser.organization,
  };
  if (emailsToAdd.length) {
    requestData.emailColumns = ["email"];
    requestData.emailValues = [...emailsToAdd.map((email) => [email])];
  }
  if (contactsToAdd.length) {
    requestData.contactColumns = Object.keys(contactsToAdd[0]);
    requestData.contactValues = [
      ...contactsToAdd.map((contact) => Object.values(contact)),
    ];
  }

  try {
    yield axios.post("/api/object_form/add", requestData);
    yield put(addSiteSuccess());
    yield put(getSitesStart(currentUser));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addSiteFailure(error));
  }
}

export function* onAddSiteStart() {
  yield takeLatest(SitesActionTypes.ADD_SITE_START, addSite);
}

export function* getSites({ payload: { user, organization } }) {
  try {
    const response = yield axios.get(
      `/api/object_form/${user}/${organization}`
    );
    // const response = yield axios.get(`/api/objects/${id}/${organization}`);
    // yield console.log("SITE RESPONSE DATA: ", response_test.data);
    const sites = Object.values(response.data).reverse();
    yield put(getSitesSuccess(sites));
  } catch (e) {
    const error = handleError(e);
    yield put(getSitesFailure(error));
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put(signOut());
    }
  }
}

export function* onGetSitesStart() {
  yield takeLeading(SitesActionTypes.GET_SITES_START, getSites);
}

export function* editSite({ payload, callback }) {
  const { siteDetails, obj_id, emailsToAdd, team, contactsToAdd, currentUser } =
    payload;
  const requestData = {
    objects: siteDetails,
    obj_id,
    emailColumns: ["email"],
    emailValues: [...emailsToAdd.map((email) => [email])],
    teamColumns: ["user_id"],
    teamValues: [...team.map((member) => [member])],
  };
  if (contactsToAdd.length) {
    requestData.contactColumns = Object.keys(contactsToAdd[0]);
    requestData.contactValues = [
      ...contactsToAdd.map((contact) => Object.values(contact)),
    ];
  }

  try {
    const response = yield axios.put("/api/object_form/edit", requestData);
    yield console.log(response.data);
    yield put(editSiteSuccess());
    yield put(getSitesStart(currentUser));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editSiteFailure(error));
  }
}

export function* onEditSiteStart() {
  yield takeLatest(SitesActionTypes.EDIT_SITE_START, editSite);
}

export function* deleteSite(siteDetails) {
  try {
    yield console.log("axios.delete(", siteDetails);
    yield put(deleteSiteSuccess());
  } catch (e) {
    const error = handleError(e);
    yield put(deleteSiteFailure(error));
  }
}

export function* onDeleteSiteStart() {
  yield takeLeading(SitesActionTypes.DELETE_SITE_START, deleteSite);
}

export function* sitesSagas(args) {
  yield all([
    call(onAddSiteStart),
    call(onGetSitesStart),
    call(onEditSiteStart),
    call(onDeleteSiteStart),
  ]);
}
