import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminLoginPage from "./admin-login-page";
import AdminRecoverPage from "./admin-recover-page";

const AdminLoginRoutes = ({ match }) => {
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);

  return (
    <div className="login">
      <Route
        exact
        path={`${match.path}`}
        render={() =>
          currentAdmin ? <Redirect to="/admin/" /> : <AdminLoginPage />
        }
      />
      <Route
        exact
        path={`${match.path}/restore_by_email`}
        render={() =>
          currentAdmin ? <Redirect to="/admin/" /> : <AdminRecoverPage />
        }
      />
    </div>
  );
};

export default AdminLoginRoutes;
