import React, { useState } from "react";
import { InputField } from "../input-forms";
import {
  checkEmailValidity,
  emailPattern,
} from "../../utils/checkEmailValidity";

const Emails = ({
  emailsToAdd,
  setEmailsToAdd,
  emailInput,
  setEmailInput,
  emailIndexToEdit,
  setEmailIndexToEdit,
}) => {
  const [isEmail, setIsEmail] = useState(true);

  const handleEdit = (index) => {
    setEmailIndexToEdit(index);
    console.log(index);
    setEmailInput(emailsToAdd[index]);
  };

  const cancelEdit = () => {
    setEmailIndexToEdit(null);
    setEmailInput("");
  };

  const handleSubmit = () => {
    if (!checkEmailValidity(emailInput)) {
      setIsEmail(false);
    } else if (emailInput && emailIndexToEdit) {
      const newEmailsToAdd = [...emailsToAdd];
      newEmailsToAdd[emailIndexToEdit] = emailInput;
      setEmailsToAdd(newEmailsToAdd);
      setEmailIndexToEdit(null);
      setEmailInput("");
    } else if (emailInput && !emailsToAdd.includes(emailInput)) {
      setIsEmail(true);
      setEmailsToAdd([...emailsToAdd, emailInput]);
      setEmailInput("");
    }
  };

  const handleChange = (e) => {
    setEmailInput(e.target.value);
    setIsEmail(true);
  };

  const deleteEmail = (emailToDelete) => {
    setEmailsToAdd(emailsToAdd.filter((email) => email !== emailToDelete));
  };

  return (
    <>
      <div className="hdr">
        Электронные почты заказчика для отправки уведомлений
      </div>
      {emailsToAdd.length ? (
        <table className="list">
          <tbody>
            {emailsToAdd.map((email, index) => (
              <tr key={email}>
                <td className="name">
                  <a href={`mailto: ${email}`}>{email}</a>
                </td>
                <td className="control">
                  <button type="button" onClick={() => handleEdit(index)}>
                    Ред.
                  </button>
                  <button
                    className="remove"
                    onClick={() => deleteEmail(email)}
                    type="button"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        ""
      )}

      <div>
        <InputField
          value={emailInput}
          onChange={handleChange}
          err={isEmail ? "" : "Почта не валидна"}
          type="email"
          id="email"
          pattern={emailPattern}
        />
        <div className="buttons in_form">
          <button className="blue" type="button" onClick={handleSubmit}>
            {emailIndexToEdit ? "Сохранить" : "Добавить"}
          </button>
          {emailIndexToEdit && (
            <button className="cancel" type="button" onClick={cancelEdit}>
              Отмена
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Emails;
