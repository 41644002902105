import EquipmentActionTypes from "./equipment.types";

export const addEquipmentStart = (equipmentName, adminId, callback) => ({
  type: EquipmentActionTypes.ADD_EQUIPMENT_START,
  payload: { equipmentName, adminId },
  callback,
});

export const addEquipmentSuccess = () => ({
  type: EquipmentActionTypes.ADD_EQUIPMENT_SUCCESS,
});

export const addEquipmentFailure = (error) => ({
  type: EquipmentActionTypes.ADD_EQUIPMENT_FAILURE,
  payload: error,
});

export const getEquipmentStart = (adminId) => ({
  type: EquipmentActionTypes.GET_EQUIPMENT_START,
  payload: adminId,
});

export const getEquipmentSuccess = (specialties) => ({
  type: EquipmentActionTypes.GET_EQUIPMENT_SUCCESS,
  payload: specialties,
});

export const getEquipmentFailure = (error) => ({
  type: EquipmentActionTypes.GET_EQUIPMENT_FAILURE,
  payload: error,
});

export const editEquipmentStart = (
  equipmentName,
  adminId,
  equipmentId,
  callback
) => ({
  type: EquipmentActionTypes.EDIT_EQUIPMENT_START,
  payload: { equipmentName, adminId, equipmentId },
  callback,
});

export const editEquipmentSuccess = () => ({
  type: EquipmentActionTypes.EDIT_EQUIPMENT_SUCCESS,
});

export const editEquipmentFailure = (error) => ({
  type: EquipmentActionTypes.EDIT_EQUIPMENT_FAILURE,
  payload: error,
});

export const deleteEquipmentStart = (equipmentId, adminId, callback) => ({
  type: EquipmentActionTypes.DELETE_EQUIPMENT_START,
  payload: { equipmentId, adminId },
  callback,
});

export const deleteEquipmentSuccess = (deletedEquipmentId) => ({
  type: EquipmentActionTypes.DELETE_EQUIPMENT_SUCCESS,
  payload: deletedEquipmentId,
});

export const deleteEquipmentFailure = (error) => ({
  type: EquipmentActionTypes.DELETE_EQUIPMENT_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: EquipmentActionTypes.CLEAR_ERRORS,
});
