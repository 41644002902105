import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialsStart } from "../../redux/materials/materials.actions";
import { getMaterialUnitsStart } from "../../redux/materialUnits/materialUnits.actions";
import { useAddTechCardItemsStyles } from "../../../css/admin/main-section.styles";
import { ControlledSelect, FormInput } from "../../../components/input-forms";

const AddMaterials = ({ selectedMaterials, setSelectedMaterials }) => {
  const classes = useAddTechCardItemsStyles();
  const dispatch = useDispatch();

  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const materials = useSelector((state) => state.materials.materials);
  const materialUnits = useSelector(
    (state) => state.materialUnits.materialUnits
  );
  const errorMaterials = useSelector((state) => state.materials.error);

  const [materialsOptions, setMaterialsOptions] = useState({});
  const [materialInputs, setMaterialInputs] = useState({
    material_id: "",
    qty: "",
  });

  const checkIfItemAdded = useCallback(
    (id) =>
      selectedMaterials.some(
        (material) => String(material.material_id) === String(id)
      ),
    [selectedMaterials]
  );

  useEffect(() => {
    if (!materials) {
      dispatch(getMaterialsStart(currentAdmin.adminId));
    } else if (!materialUnits) {
      dispatch(getMaterialUnitsStart(currentAdmin.adminId));
    } else {
      setMaterialsOptions(
        materials.reduce((options, material) => {
          // console.log("MATERIAL: ", !checkIfItemAdded(material.id));
          if (!checkIfItemAdded(material.id)) {
            const unit = materialUnits.find(
              (unit) => unit.id === material.unit_id
            );
            options[material.id] = `${material.name} (${
              unit ? unit.name : ""
            })`;
          }
          return options;
        }, {})
      );
    }
  }, [currentAdmin, dispatch, materials, materialUnits, checkIfItemAdded]);

  const addMaterial = (e) => {
    if (materialInputs.material_id && materialInputs.qty) {
      setSelectedMaterials([...selectedMaterials, materialInputs]);
      setMaterialInputs({
        material_id: "",
        qty: "",
      });
    }
  };

  const deleteMaterial = (index) => {
    setSelectedMaterials(selectedMaterials.filter((mat, i) => i !== index));
  };

  const handleChange = (e) => {
    setMaterialInputs({ ...materialInputs, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div>
        <b>Материалы</b>
      </div>

      {materials && materialUnits && selectedMaterials.length ? (
        <table className={classes.itemsTable}>
          <thead>
            <tr>
              <th>Вид материала</th>
              <th>Количество</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {selectedMaterials.map((materialRow, index) => {
              const material = materials.find(
                (material) => +material.id === +materialRow.material_id
              );
              if (material) {
                const unit = materialUnits.find(
                  (unit) => unit.id === material.unit_id
                );
                if (unit) {
                  return (
                    <tr key={index}>
                      <td>{`${material.name} (${unit.name})`}</td>
                      <td>{materialRow.qty}</td>
                      <td className={classes.alignRight}>
                        <button
                          onClick={() => deleteMaterial(index)}
                          className={classes.delete}
                          type="button"
                        >
                          Удалить
                        </button>
                      </td>
                    </tr>
                  );
                }
              }
              return <tr></tr>;
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}

      <div className={classes.error}>{errorMaterials}</div>
      <div className={classes.twoInputs}>
        <span className={classes.selectSpan}>
          <ControlledSelect
            options={materialsOptions}
            label="вид материала"
            name="material_id"
            description="выбрать материал"
            defaultOption={materialInputs.material_id}
            onChange={handleChange}
          />
        </span>
        <FormInput
          label="количество"
          placeholder="число или формула"
          name="qty"
          value={materialInputs.qty}
          onChange={handleChange}
        />
      </div>
      <div className="buttons top">
        <button
          className="blue"
          type="button"
          onClick={addMaterial}
          disabled={!materialInputs.material_id || !materialInputs.qty}
        >
          добавить
        </button>
      </div>
    </div>
  );
};

export default React.memo(AddMaterials);
