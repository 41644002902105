import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signInStart, clearErrors } from "../../redux/user/user.actions";
import { InputField, PasswordInput } from "../../components/input-forms";
import { validate } from "../../utils/normalizeData";

const Login = () => {
  const dispatch = useDispatch();

  useEffect(() => dispatch(clearErrors()), [dispatch]);

  const loginError = useSelector((state) => state.user.error);
  const isUserLoggingIn = useSelector((state) => state.user.isFetching);

  const [userCredentials, setCredentials] = useState({
    identifier: "identifier66",
    email: "email_30@mail.ru",
    password: "123456",
  });

  const [errors, setErrors] = useState({
    identifier: { type: "required", error: null },
    email: { type: "required", error: null },
    password: { type: "required", error: null },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    if (!userCredentials.identifier) {
      setErrors((errors) => ({
        ...errors,
        identifier: {
          ...errors.identifier,
          error: "Введите идентификатор компании",
        },
      }));
      valid = false;
    }
    if (!userCredentials.email) {
      setErrors((errors) => ({
        ...errors,
        email: { ...errors.email, error: "Введите e-mail" },
      }));
      valid = false;
    }
    if (!userCredentials.password) {
      setErrors((errors) => ({
        ...errors,
        password: { ...errors.password, error: "Введите пароль now" },
      }));
      valid = false;
    }
    if (!valid) return;

    dispatch(signInStart(userCredentials));
  };

  const handleChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      validate(
        e.target.value,
        () => {},
        () =>
          setErrors({
            ...errors,
            [e.target.name]: { ...errors[e.target.name], error: null },
          }),
        errors[e.target.name].type
      );
    }
    setCredentials({ ...userCredentials, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className="header_login">
        <h1>Под Контролем</h1>
        <h2>Вход</h2>
      </div>

      <div className="content_login">
        <form onSubmit={handleSubmit}>
          <div className="info err">{loginError}</div>
          <InputField
            label="Идентификатор компании"
            type="text"
            value={userCredentials.identifier}
            name="identifier"
            onChange={handleChange}
            placeholder="xxxxxxx"
            validationType="required"
            err={errors.identifier.error}
          />
          <InputField
            label="Электронная почта"
            type="email"
            name="email"
            value={userCredentials.email}
            onChange={handleChange}
            validationType="required"
            placeholder="example@example.ru"
            err={errors.email.error}
          />
          <PasswordInput
            label="Пароль"
            name="password"
            value={userCredentials.password}
            onChange={handleChange}
            validationType="required"
            err={errors.password.error}
          >
            <Link className="forgot" to="/login/restore_by_email">
              Забыли пароль?
            </Link>
          </PasswordInput>

          <div className="button">
            <button disabled={isUserLoggingIn}>
              {isUserLoggingIn ? "авторизация..." : "Войти"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
