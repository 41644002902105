import { createUseStyles } from "react-jss";

export const useHeaderStyles = createUseStyles({
  header: {
    height: "5vh",
    backgroundColor: "#c4c4c4",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: "2vw",
    position: "absolute",
    width: "100%",
    boxSizing: "border-box",
  },
  exit: {
    cursor: "pointer",
  },
});

export const useAdminPanelStyles = createUseStyles({
  adminPanel: {
    display: "flex",
    minHeight: "100%",
    height: "auto",
    fontSize: "12px",
  },
});

export const useSideMenuStyles = createUseStyles({
  sidemenu: {
    padding: "5vh 0",
    backgroundColor: "#e1e1e1",
    width: "20vw",
    minHeight: "100%",
    height: "auto",
  },
  menuItems: {
    margin: "7vh 0",
  },
  menuItem: {
    "& a:hover": {
      backgroundColor: "#d4d2d2",
    },

    "& a, a:visited": {
      display: "inline-block",
      width: "100%",
      boxSizing: "border-box",
      padding: "2vh 1vw 2vh 3vw",
      color: "black",
      textDecoration: "none",
    },
  },
  selected: {
    backgroundColor: "#c4c4c4",
  },
});

export const useMainSectionStyles = createUseStyles({
  mainSection: {
    padding: "5vh 0",
    backgroundColor: "white",
    width: "100%",
    minHeight: "100%",
    height: "auto",
  },
});
