import VariablesActionTypes from "./variables.types";

export const getVariablesStart = (userId) => ({
  type: VariablesActionTypes.GET_VARIALBES_START,
  payload: userId,
});

export const getVariablesSuccess = (variables) => ({
  type: VariablesActionTypes.GET_VARIALBES_SUCCESS,
  payload: variables,
});

export const getVariablesFailure = (error) => ({
  type: VariablesActionTypes.GET_VARIALBES_FAILURE,
  payload: error,
});
