const SubsectionsActionTypes = {
  ADD_SUBSECTION_GROUP_START: "ADD_SUBSECTION_GROUP_START",
  ADD_SUBSECTION_GROUP_SUCCESS: "ADD_SUBSECTION_GROUP_SUCCESS",
  ADD_SUBSECTION_GROUP_FAILURE: "ADD_SUBSECTION_GROUP_FAILURE",
  EDIT_SUBSECTION_GROUP_START: "EDIT_SUBSECTION_GROUP_START",
  EDIT_SUBSECTION_GROUP_SUCCESS: "EDIT_SUBSECTION_GROUP_SUCCESS",
  EDIT_SUBSECTION_GROUP_FAILURE: "EDIT_SUBSECTION_GROUP_FAILURE",
  DELETE_SUBSECTION_GROUP_START: "DELETE_SUBSECTION_GROUP_START",
  DELETE_SUBSECTION_GROUP_SUCCESS: "DELETE_SUBSECTION_GROUP_SUCCESS",
  DELETE_SUBSECTION_GROUP_FAILURE: "DELETE_SUBSECTION_GROUP_FAILURE",
  GET_SUBSECTION_GROUPS_START: "GET_SUBSECTION_GROUPS_START",
  GET_SUBSECTION_GROUPS_SUCCESS: "GET_SUBSECTION_GROUPS_SUCCESS",
  GET_SUBSECTION_GROUPS_FAILURE: "GET_SUBSECTION_GROUPS_FAILURE",
  ADD_SUBSECTION_START: "ADD_SUBSECTION_START",
  ADD_SUBSECTION_SUCCESS: "ADD_SUBSECTION_SUCCESS",
  ADD_SUBSECTION_FAILURE: "ADD_SUBSECTION_FAILURE",
  GET_SUBSECTIONS_START: "GET_SUBSECTIONS_START",
  GET_SUBSECTIONS_SUCCESS: "GET_SUBSECTIONS_SUCCESS",
  GET_SUBSECTIONS_FAILURE: "GET_SUBSECTIONS_FAILURE",
  GET_SINGLE_SUBSECTION_START: "GET_SINGLE_SUBSECTION_START",
  GET_SINGLE_SUBSECTION_SUCCESS: "GET_SINGLE_SUBSECTION_SUCCESS",
  GET_SINGLE_SUBSECTION_FAILURE: "GET_SINGLE_SUBSECTION_FAILURE",
  DELETE_SUBSECTION_START: "DELETE_SUBSECTION_START",
  DELETE_SUBSECTION_SUCCESS: "DELETE_SUBSECTION_SUCCESS",
  DELETE_SUBSECTION_FAILURE: "DELETE_SUBSECTION_FAILURE",
  EDIT_SUBSECTION_START: "EDIT_SUBSECTION_START",
  EDIT_SUBSECTION_SUCCESS: "EDIT_SUBSECTION_SUCCESS",
  EDIT_SUBSECTION_FAILURE: "EDIT_SUBSECTION_FAILURE",
  SET_CURRENT_SUBSECTION: "SET_CURRENT_SUBSECTION",
  CLEAR_CURRENT_SUBSECTION: "CLEAR_CURRENT_SUBSECTION",
  CLEAR_SUBSECTIONS: "CLEAR_SUBSECTIONS",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default SubsectionsActionTypes;
