import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { formatDate } from "../../../utils/normalizeData";
import InlineModal from "../../inlineModal";
import AddClaim from "./add-claim";
import ViewPoint from "../view-point";

const ClaimsList = ({ currentSubsectionId }) => {
  const statuses = useSelector((state) => state.statuses.claims);
  const claims = useSelector((state) => state.claims.claims);
  const subsections = useSelector((state) => state.subsections.subsections);
  const contractors = useSelector((state) => state.contractors.contractors);
  const [addClaimWindow, showAddClaimWindow] = useState();
  const { site_id, sub_id, menu, info } = useParams();
  const history = useHistory();
  console.log("CLAIMS: ", claims);
  let claimsToRender = claims;

  if (currentSubsectionId) {
    claimsToRender = claims.filter(
      (claim) => claim.subsection_id === currentSubsectionId
    );
  }

  const statusHeaders = {
    Выписано: { order: 1, name: "Выписаны" },

    "Исполнитель отметил, что исполнил": {
      order: 2,
      name: "Исполнены, ждут проверки",
    },

    "Закрыто актом": { order: 3, name: "Исполнены, проверены" },
    "Закрыто отметкой": { order: 3, name: "Исполнены, проверены" },

    Отозвано: { order: 4, name: "Отозваны" },
  };

  claimsToRender = claimsToRender.reduce((acc, curr) => {
    let order;
    if (statuses && curr.status_id && statusHeaders[statuses[curr.status_id]]) {
      order = statuses && statusHeaders[statuses[curr.status_id]].order;
    }
    if (order) {
      if (!acc[order]) {
        acc[order] = {
          name: statusHeaders[statuses[curr.status_id]].name,
          list: [],
        };
      }
      acc[order].list.push(curr);
    }
    return acc;
  }, {});

  const path = `/main/sites/${site_id}`;
  const subPath = sub_id ? `/subsections/${sub_id}` : "";

  return (
    <div className="content">
      {addClaimWindow && (
        <InlineModal close={() => showAddClaimWindow(false)}>
          <AddClaim close={() => showAddClaimWindow(false)} />
        </InlineModal>
      )}

      {menu === "claims" && info && (
        <InlineModal>
          <ViewPoint
            claim_id={+info}
            close={() => history.push(`${path}${subPath}/claims`)}
          />
        </InlineModal>
      )}

      <div className="buttons top">
        <button className="blue" onClick={() => showAddClaimWindow(true)}>
          создать предписание
        </button>
      </div>

      {Object.keys(claimsToRender).map((status) => (
        <Fragment key={status}>
          <h3>{claimsToRender[status].name}</h3>
          <table className="list">
            <thead>
              <tr>
                <th>номер</th>
                <th>дата создания</th>
                <th>подраздел</th>
                <th>подрядчик</th>
                <th>описание</th>
              </tr>
            </thead>
            <tbody>
              {claimsToRender[status].list
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((claim) => {
                  const sub = subsections.find(
                    (sub) => sub.id === claim.subsection_id
                  );
                  const contractor = contractors.find(
                    (cont) => cont.id === claim.contractor_id
                  );
                  return (
                    <tr key={claim.id}>
                      <td>
                        <label className="mobile">Номер</label>
                        <Link to={`${path}${subPath}/claims/${claim.id}`}>
                          {claim.num}
                        </Link>
                      </td>
                      <td>
                        <label className="mobile">Дата создания</label>

                        {formatDate(claim.date_created, true)}
                      </td>
                      <td>
                        <label className="mobile">Подраздел</label>

                        {sub && sub.name}
                      </td>
                      <td>
                        <label className="mobile">Подрядчик</label>

                        {contractor && contractor.name}
                      </td>
                      <td>
                        <label className="mobile">Описание</label>

                        {claim.descriptions}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Fragment>
      ))}
    </div>
  );
};

export default ClaimsList;
