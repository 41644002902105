import TechCardsActionTypes from "./techCards.types";

const INITIAL_STATE = {
  isAdding: false,
  isFetchingGroups: false,
  isFetchingCards: false,
  isFetchingACard: false,
  isDeleting: false,
  error: null,
  techCards: {},
  currentCard: null,
  techGroups: null,
};

const techCardsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TechCardsActionTypes.ADD_TECH_CARD_START:
    case TechCardsActionTypes.ADD_TECH_GROUP_START:
    case TechCardsActionTypes.EDIT_TECH_CARD_START:
    case TechCardsActionTypes.EDIT_TECH_GROUP_START:
      return { ...state, isAdding: true, error: null };
    case TechCardsActionTypes.GET_TECH_CARDS_START:
      return { ...state, isFetchingCards: true, error: null };
    case TechCardsActionTypes.GET_CURRENT_TECH_CARD_START:
      return { ...state, isFetchingACard: true, error: null };
    case TechCardsActionTypes.GET_TECH_GROUPS_START:
      return { ...state, isFetchingGroups: true, error: null };
    case TechCardsActionTypes.DELETE_TECH_CARD_START:
    case TechCardsActionTypes.DELETE_TECH_GROUP_START:
      return {
        ...state,
        isDeleting: true,
        error: null,
      };

    case TechCardsActionTypes.EDIT_TECH_CARD_SUCCESS:
      return { ...state, isAdding: false, error: null, currentCard: null };
    case TechCardsActionTypes.ADD_TECH_CARD_SUCCESS:
    case TechCardsActionTypes.ADD_TECH_GROUP_SUCCESS:
    case TechCardsActionTypes.EDIT_TECH_GROUP_SUCCESS:
      return { ...state, isAdding: false, error: null };
    case TechCardsActionTypes.DELETE_TECH_CARD_SUCCESS:
    case TechCardsActionTypes.DELETE_TECH_GROUP_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        error: null,
      };
    case TechCardsActionTypes.GET_TECH_GROUPS_SUCCESS:
      return { ...state, isFetchingGroups: false, techGroups: action.payload };

    case TechCardsActionTypes.GET_TECH_CARDS_SUCCESS:
      const { groupId, techCards } = action.payload;
      return {
        ...state,
        isFetchingCards: false,
        error: null,
        techCards: { ...state.techCards, [groupId]: techCards },
      };

    case TechCardsActionTypes.GET_CURRENT_TECH_CARD_SUCCESS:
      return {
        ...state,
        isFetchingACard: false,
        error: null,
        currentCard: action.payload,
      };

    case TechCardsActionTypes.ADD_TECH_CARD_FAILURE:
    case TechCardsActionTypes.ADD_TECH_GROUP_FAILURE:
    case TechCardsActionTypes.EDIT_TECH_CARD_FAILURE:
    case TechCardsActionTypes.EDIT_TECH_GROUP_FAILURE:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      };

    case TechCardsActionTypes.GET_TECH_CARDS_FAILURE:
      return {
        ...state,
        isFetchingCards: false,
        error: action.payload,
      };

    case TechCardsActionTypes.GET_CURRENT_TECH_CARD_FAILURE:
      return {
        ...state,
        isFetchingACard: false,
        error: action.payload,
      };
    case TechCardsActionTypes.GET_TECH_GROUPS_FAILURE:
      return {
        ...state,
        isFetchingGroups: false,
        error: action.payload,
      };
    case TechCardsActionTypes.DELETE_TECH_CARD_FAILURE:
    case TechCardsActionTypes.DELETE_TECH_GROUP_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };

    case TechCardsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default techCardsReducer;
