import StatusesActionTypes from "./statuses.types";

export const getStatusesStart = (currentUserId) => ({
  type: StatusesActionTypes.GET_STATUSES_START,
  payload: currentUserId,
});

export const getStatusesSuccess = (Statuses) => ({
  type: StatusesActionTypes.GET_STATUSES_SUCCESS,
  payload: Statuses,
});

export const getStatusesFailure = (error) => ({
  type: StatusesActionTypes.GET_STATUSES_FAILURE,
  payload: error,
});
