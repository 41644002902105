import SubsectionActionTypes from "./subsections.types";

export const addSubsectionGroupStart = (groupData, currentUser, callback) => ({
  type: SubsectionActionTypes.ADD_SUBSECTION_GROUP_START,
  payload: { groupData, currentUser },
  callback,
});

export const addSubsectionGroupSuccess = () => ({
  type: SubsectionActionTypes.ADD_SUBSECTION_GROUP_SUCCESS,
});

export const addSubsectionGroupFailure = (error) => ({
  type: SubsectionActionTypes.ADD_SUBSECTION_GROUP_FAILURE,
  payload: error,
});

export const getSubsectionGroupsStart = (userId, site_id) => ({
  type: SubsectionActionTypes.GET_SUBSECTION_GROUPS_START,
  payload: { userId, site_id },
});

export const getSubsectionGroupsSuccess = (groups) => ({
  type: SubsectionActionTypes.GET_SUBSECTION_GROUPS_SUCCESS,
  payload: groups,
});

export const getSubsectionGroupsFailure = (error) => ({
  type: SubsectionActionTypes.GET_SUBSECTION_GROUPS_FAILURE,
  payload: error,
});

export const editSubsectionGroupStart = (
  editData,
  currentUser,
  callback,
  subsection_group
) => ({
  type: SubsectionActionTypes.EDIT_SUBSECTION_GROUP_START,
  payload: { editData, currentUser, subsection_group },
  callback,
});

export const editSubsectionGroupSuccess = () => ({
  type: SubsectionActionTypes.EDIT_SUBSECTION_GROUP_SUCCESS,
});

export const editSubsectionGroupFailure = (error) => ({
  type: SubsectionActionTypes.EDIT_SUBSECTION_GROUP_FAILURE,
  payload: error,
});

export const deleteSubsectionGroupStart = (
  groupId,
  userId,
  site_id,
  callback
) => ({
  type: SubsectionActionTypes.DELETE_SUBSECTION_GROUP_START,
  payload: { groupId, userId, site_id },
  callback,
});

export const deleteSubsectionGroupSuccess = () => ({
  type: SubsectionActionTypes.DELETE_SUBSECTION_GROUP_SUCCESS,
});

export const deleteSubsectionGroupFailure = (error) => ({
  type: SubsectionActionTypes.DELETE_SUBSECTION_GROUP_FAILURE,
  payload: error,
});

export const addSubsectionStart = (subData, callback) => ({
  type: SubsectionActionTypes.ADD_SUBSECTION_START,
  payload: subData,
  callback,
});

export const addSubsectionSuccess = () => ({
  type: SubsectionActionTypes.ADD_SUBSECTION_SUCCESS,
});

export const addSubsectionFailure = (error) => ({
  type: SubsectionActionTypes.ADD_SUBSECTION_FAILURE,
  payload: error,
});

export const getSubsectionsStart = (currentUser, site_id) => ({
  type: SubsectionActionTypes.GET_SUBSECTIONS_START,
  payload: { currentUser, site_id },
});

export const getSubsectionsSuccess = (Subsections) => ({
  type: SubsectionActionTypes.GET_SUBSECTIONS_SUCCESS,
  payload: Subsections,
});

export const getSubsectionsFailure = (error) => ({
  type: SubsectionActionTypes.GET_SUBSECTIONS_FAILURE,
  payload: error,
});

export const getSingleSubsectionStart = (currentUserId, site_id, sub_id) => ({
  type: SubsectionActionTypes.GET_SINGLE_SUBSECTION_START,
  payload: { currentUserId, site_id, sub_id },
});

export const getSingleSubsectionSuccess = (subsection) => ({
  type: SubsectionActionTypes.GET_SINGLE_SUBSECTION_SUCCESS,
  payload: subsection,
});

export const getSingleSubsectionFailure = (error) => ({
  type: SubsectionActionTypes.GET_SINGLE_SUBSECTION_FAILURE,
  payload: error,
});

export const editSubsectionStart = (subToEditData, callback) => ({
  type: SubsectionActionTypes.EDIT_SUBSECTION_START,
  payload: subToEditData,
  callback,
});

export const editSubsectionSuccess = () => ({
  type: SubsectionActionTypes.EDIT_SUBSECTION_SUCCESS,
});

export const editSubsectionFailure = (error) => ({
  type: SubsectionActionTypes.EDIT_SUBSECTION_FAILURE,
  payload: error,
});

export const deleteSubsectionStart = (subData, callback) => ({
  type: SubsectionActionTypes.DELETE_SUBSECTION_START,
  payload: subData,
  callback,
});

export const deleteSubsectionSuccess = () => ({
  type: SubsectionActionTypes.DELETE_SUBSECTION_SUCCESS,
});

export const deleteSubsectionFailure = (error) => ({
  type: SubsectionActionTypes.DELETE_SUBSECTION_FAILURE,
  payload: error,
});

export const setCurrentSubsection = (currentSubsection) => ({
  type: SubsectionActionTypes.SET_CURRENT_SUBSECTION,
  payload: currentSubsection,
});

export const clearCurrentSubsection = () => ({
  type: SubsectionActionTypes.CLEAR_CURRENT_SUBSECTION,
});

export const clearSubsections = () => ({
  type: SubsectionActionTypes.CLEAR_SUBSECTIONS,
});

export const clearErrors = () => ({
  type: SubsectionActionTypes.CLEAR_ERRORS,
});
