import ContractorsActionTypes from "./contractors.types";

export const addContractorStart = (newContractorData, callback) => ({
  type: ContractorsActionTypes.ADD_CONTRACTOR_START,
  payload: newContractorData,
  callback,
});

export const addContractorSuccess = () => ({
  type: ContractorsActionTypes.ADD_CONTRACTOR_SUCCESS,
});

export const addContractorFailure = (error) => ({
  type: ContractorsActionTypes.ADD_CONTRACTOR_FAILURE,
  payload: error,
});

export const getCurrentContractorStart = (contractorId, userId) => ({
  type: ContractorsActionTypes.GET_CURRENT_CONTRACTOR_START,
  payload: { contractorId, userId },
});

export const getCurrentContractorSuccess = (contractorDetails) => ({
  type: ContractorsActionTypes.GET_CURRENT_CONTRACTOR_SUCCESS,
  payload: contractorDetails,
});

export const getCurrentContractorFailure = (error) => ({
  type: ContractorsActionTypes.GET_CURRENT_CONTRACTOR_FAILURE,
  payload: error,
});

export const getContractorsStart = (currentUser, site_id) => ({
  type: ContractorsActionTypes.GET_CONTRACTORS_START,
  payload: { currentUser, site_id },
});

export const getContractorsSuccess = (contractors) => ({
  type: ContractorsActionTypes.GET_CONTRACTORS_SUCCESS,
  payload: contractors,
});

export const getContractorsFailure = (error) => ({
  type: ContractorsActionTypes.GET_CONTRACTORS_FAILURE,
  payload: error,
});

export const getAllContractorsStart = (currentUser) => ({
  type: ContractorsActionTypes.GET_ALL_CONTRACTORS_START,
  payload: currentUser,
});

export const getAllContractorsSuccess = (allContractors) => ({
  type: ContractorsActionTypes.GET_ALL_CONTRACTORS_SUCCESS,
  payload: allContractors,
});

export const getAllContractorsFailure = (error) => ({
  type: ContractorsActionTypes.GET_ALL_CONTRACTORS_FAILURE,
  payload: error,
});

export const editContractorStart = (contractor, callback) => ({
  type: ContractorsActionTypes.EDIT_CONTRACTOR_START,
  payload: contractor,
  callback,
});

export const editContractorSuccess = () => ({
  type: ContractorsActionTypes.EDIT_CONTRACTOR_SUCCESS,
});

export const editContractorFailure = (error) => ({
  type: ContractorsActionTypes.EDIT_CONTRACTOR_FAILURE,
  payload: error,
});

export const deleteContractorStart = (contractor, callback) => ({
  type: ContractorsActionTypes.DELETE_CONTRACTOR_START,
  payload: contractor,
  callback,
});

export const deleteContractorSuccess = () => ({
  type: ContractorsActionTypes.DELETE_CONTRACTOR_SUCCESS,
});

export const deleteContractorFailure = (error) => ({
  type: ContractorsActionTypes.DELETE_CONTRACTOR_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: ContractorsActionTypes.CLEAR_ERRORS,
});
