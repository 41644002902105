const ResourcesActionTypes = {
  ADD_SPECIALTY_START: "ADD_SPECIALTY_START",
  ADD_SPECIALTY_SUCCESS: "ADD_SPECIALTY_SUCCESS",
  ADD_SPECIALTY_FAILURE: "ADD_SPECIALTY_FAILURE",
  GET_SPECIALTIES_START: "GET_SPECIALTIES_START",
  GET_SPECIALTIES_SUCCESS: "GET_SPECIALTIES_SUCCESS",
  GET_SPECIALTIES_FAILURE: "GET_SPECIALTIES_FAILURE",
  DELETE_SPECIALTY_START: "DELETE_SPECIALTY_START",
  DELETE_SPECIALTY_SUCCESS: "DELETE_SPECIALTY_SUCCESS",
  DELETE_SPECIALTY_FAILURE: "DELETE_SPECIALTY_FAILURE",
  EDIT_SPECIALTY_START: "EDIT_SPECIALTY_START",
  EDIT_SPECIALTY_SUCCESS: "EDIT_SPECIALTY_SUCCESS",
  EDIT_SPECIALTY_FAILURE: "EDIT_SPECIALTY_FAILURE",
  ADD_EQUIPMENT_START: "ADD_EQUIPMENT_START",
  ADD_EQUIPMENT_SUCCESS: "ADD_EQUIPMENT_SUCCESS",
  ADD_EQUIPMENT_FAILURE: "ADD_EQUIPMENT_FAILURE",
  GET_EQUIPMENT_START: "GET_EQUIPMENT_START",
  GET_EQUIPMENT_SUCCESS: "GET_EQUIPMENT_SUCCESS",
  GET_EQUIPMENT_FAILURE: "GET_EQUIPMENT_FAILURE",
  DELETE_EQUIPMENT_START: "DELETE_EQUIPMENT_START",
  DELETE_EQUIPMENT_SUCCESS: "DELETE_EQUIPMENT_SUCCESS",
  DELETE_EQUIPMENT_FAILURE: "DELETE_EQUIPMENT_FAILURE",
  EDIT_EQUIPMENT_START: "EDIT_EQUIPMENT_START",
  EDIT_EQUIPMENT_SUCCESS: "EDIT_EQUIPMENT_SUCCESS",
  EDIT_EQUIPMENT_FAILURE: "EDIT_EQUIPMENT_FAILURE",
  ADD_MATERIAL_START: "ADD_MATERIAL_START",
  ADD_MATERIAL_SUCCESS: "ADD_MATERIAL_SUCCESS",
  ADD_MATERIAL_FAILURE: "ADD_MATERIAL_FAILURE",
  GET_MATERIALS_START: "GET_MATERIALS_START",
  GET_MATERIALS_SUCCESS: "GET_MATERIALS_SUCCESS",
  GET_MATERIALS_FAILURE: "GET_MATERIALS_FAILURE",
  DELETE_MATERIAL_START: "DELETE_MATERIAL_START",
  DELETE_MATERIAL_SUCCESS: "DELETE_MATERIAL_SUCCESS",
  DELETE_MATERIAL_FAILURE: "DELETE_MATERIAL_FAILURE",
  EDIT_MATERIAL_START: "EDIT_MATERIAL_START",
  EDIT_MATERIAL_SUCCESS: "EDIT_MATERIAL_SUCCESS",
  EDIT_MATERIAL_FAILURE: "EDIT_MATERIAL_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default ResourcesActionTypes;
