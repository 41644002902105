const VariablesActionTypes = {
  ADD_VARIABLE_START: "ADD_VARIABLE_START",
  ADD_VARIABLE_SUCCESS: "ADD_VARIABLE_SUCCESS",
  ADD_VARIABLE_FAILURE: "ADD_VARIABLE_FAILURE",
  GET_VARIABLES_START: "GET_VARIABLES_START",
  GET_VARIABLES_SUCCESS: "GET_VARIABLES_SUCCESS",
  GET_VARIABLES_FAILURE: "GET_VARIABLES_FAILURE",
  DELETE_VARIABLE_START: "DELETE_VARIABLE_START",
  DELETE_VARIABLE_SUCCESS: "DELETE_VARIABLE_SUCCESS",
  DELETE_VARIABLE_FAILURE: "DELETE_VARIABLE_FAILURE",
  EDIT_VARIABLE_START: "EDIT_VARIABLE_START",
  EDIT_VARIABLE_SUCCESS: "EDIT_VARIABLE_SUCCESS",
  EDIT_VARIABLE_FAILURE: "EDIT_VARIABLE_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default VariablesActionTypes;
