import RevisionsActionTypes from "./revisions.types";
import SubsectionsActionTypes from "../subsections/subsections.types";

const INITIAL_STATE = {
  isFetching: false,
  error: null,
  revisions: [],
};

const revisionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RevisionsActionTypes.ADD_REVISION_START:
    case RevisionsActionTypes.EDIT_REVISION_START:
    case RevisionsActionTypes.GET_REVISIONS_START:
    case RevisionsActionTypes.POSTPONE_REVISION_START:
      return { ...state, isFetching: true, error: null };
    case RevisionsActionTypes.DELETE_REVISION_START:
      return { ...state, isFetching: action.payload.id, error: null };
    case RevisionsActionTypes.ADD_REVISION_SUCCESS:
    case RevisionsActionTypes.EDIT_REVISION_SUCCESS:
    case RevisionsActionTypes.DELETE_REVISION_SUCCESS:
    case RevisionsActionTypes.POSTPONE_REVISION_SUCCESS:
      return { ...state, isFetching: false, error: null };

    case RevisionsActionTypes.GET_REVISIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        revisions: action.payload,
      };

    case RevisionsActionTypes.ADD_REVISION_FAILURE:
    case RevisionsActionTypes.EDIT_REVISION_FAILURE:
    case RevisionsActionTypes.GET_REVISIONS_FAILURE:
    case RevisionsActionTypes.DELETE_REVISION_FAILURE:
    case RevisionsActionTypes.POSTPONE_REVISION_FAILURE:
      return { ...state, isFetching: false, error: action.payload };
    case SubsectionsActionTypes.CLEAR_SUBSECTIONS:
      return { ...state, subsections: [] };

    case RevisionsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default revisionsReducer;
