import React, { useState, useEffect } from "react";
import { plan_images } from "../../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isEmpty } from "../../../utils/normalizeData";

export const PointOnPlan = ({
  subsection,
  planFilename,
  pointToRender,
  markerType,
}) => {
  const [enhance, toggleEnhance] = useState(false);

  return (
    <div className={`plan ${enhance ? "zoomed" : ""}`}>
      <div className="scroll">
        <div className="container">
          <img
            src={`${plan_images}/${subsection.obj_id}/${subsection.id}/${planFilename}`}
            alt="plan"
          />

          {pointToRender && (
            <Link
              to="#"
              className={`the_pin ${markerType}`}
              style={{
                left: `calc(${pointToRender.dot_x}% - 12.5px)`,
                top: `calc(${pointToRender.dot_y}% - 40px)`,
              }}
            />
          )}
        </div>
      </div>

      <span className="control">
        <button className="zoom" onClick={() => toggleEnhance(!enhance)} />
      </span>
    </div>
  );
};

const GetPointOnPlan = ({
  subsection,
  markerType,
  rev_id,
  claim_id,
  act_id,
  checklist_id,
  remark_id,
  work_id,
}) => {
  const [plansToRender, setPlansToRender] = useState();
  const [isFetchingPoints, setIsFetchingPoints] = useState();
  const [errorFetching, setErrorFetching] = useState();
  const [pointsToRender, setPointsToRender] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const requestCoords = async (path) => {
      setIsFetchingPoints(true);
      try {
        const response = await axios.get(path);
        setPointsToRender(response.data);
        setIsFetchingPoints(false);
      } catch (e) {
        console.log(e);
        let error = "Ошибка сети";
        try {
          error = e.response.data.message || "Ошибка сети";
        } catch {}
        setErrorFetching(error);
      }
    };

    if (act_id && !pointsToRender) {
      requestCoords(`/api/act_plan_points_act/${currentUser.user}/${act_id}`);
    } else if (checklist_id && !pointsToRender) {
      requestCoords(
        `/api/checklist_plan_points_checklist/${currentUser.user}/${checklist_id}`
      );
    } else if (claim_id && !pointsToRender) {
      requestCoords(
        `/api/claim_plan_points_claim/${currentUser.user}/${claim_id}`
      );
    } else if (rev_id && !pointsToRender) {
      requestCoords(
        `/api/revision_plan_points_revision/${currentUser.user}/${rev_id}`
      );
    } else if (remark_id && !pointsToRender) {
      requestCoords(
        `/api/job_note_plan_points_job_note/${currentUser.user}/${remark_id}`
      );
    } else if (work_id && !pointsToRender) {
      requestCoords(`/api/job_plan_points_job/${currentUser.user}/${work_id}`);
    }
  }, [
    act_id,
    rev_id,
    claim_id,
    remark_id,
    checklist_id,
    work_id,
    currentUser,
    pointsToRender,
  ]);

  useEffect(() => {
    if (subsection && pointsToRender) {
      const plans = [];
      for (const i in subsection.subsection_plans) {
        const plan = subsection.subsection_plans[i];
        const pointToRender = pointsToRender.find(
          (point) => point.plan_id === plan.id
        );

        plans.push(
          <PointOnPlan
            key={plan.id}
            subsection={subsection}
            planFilename={plan.plan}
            pointToRender={pointToRender}
            markerType={markerType}
          />
        );
        setPlansToRender(plans);
      }
    }
  }, [subsection, pointsToRender, markerType]);

  if (subsection && !isEmpty(subsection.subsection_plans) && !plansToRender) {
    return <div className="info">загрузка...</div>;
  } else if (plansToRender) {
    return (
      <>
        <div className="info">
          {isFetchingPoints
            ? "Загрузка координат..."
            : errorFetching
            ? "Ошибка при загрузке координат"
            : ""}
        </div>
        <div className="hdr">На плане</div>
        {plansToRender}
      </>
    );
  } else if (subsection && isEmpty(subsection.subsection_plans)) {
    return <div>ПЛАН НЕ ДОБАВЛЕН</div>;
  }
  return "";
};

export default GetPointOnPlan;
