import ReasonsActionTypes from "./reasons.types";

export const addReasonStart = (newReasonName, adminId, callback) => ({
  type: ReasonsActionTypes.ADD_REASON_START,
  payload: { newReasonName, adminId },
  callback,
});

export const addReasonSuccess = () => ({
  type: ReasonsActionTypes.ADD_REASON_SUCCESS,
});

export const addReasonFailure = (error) => ({
  type: ReasonsActionTypes.ADD_REASON_FAILURE,
  payload: error,
});

export const getReasonsStart = (adminId) => ({
  type: ReasonsActionTypes.GET_REASONS_START,
  payload: adminId,
});

export const getReasonsSuccess = (specialties) => ({
  type: ReasonsActionTypes.GET_REASONS_SUCCESS,
  payload: specialties,
});

export const getReasonsFailure = (error) => ({
  type: ReasonsActionTypes.GET_REASONS_FAILURE,
  payload: error,
});

export const editReasonStart = (reasonName, adminId, reasonId, callback) => ({
  type: ReasonsActionTypes.EDIT_REASON_START,
  payload: { reasonName, adminId, reasonId },
  callback,
});

export const editReasonSuccess = () => ({
  type: ReasonsActionTypes.EDIT_REASON_SUCCESS,
});

export const editReasonFailure = (error) => ({
  type: ReasonsActionTypes.EDIT_REASON_FAILURE,
  payload: error,
});

export const deleteReasonStart = (reasonId, adminId, callback) => ({
  type: ReasonsActionTypes.DELETE_REASON_START,
  payload: { reasonId, adminId },
  callback,
});

export const deleteReasonSuccess = (deletedReasonId) => ({
  type: ReasonsActionTypes.DELETE_REASON_SUCCESS,
  payload: deletedReasonId,
});

export const deleteReasonFailure = (error) => ({
  type: ReasonsActionTypes.DELETE_REASON_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: ReasonsActionTypes.CLEAR_ERRORS,
});
