import MaterialUnitsActionTypes from "./materialUnits.types";

export const addMaterialUnitStart = (newMaterialUnit, adminId, callback) => ({
  type: MaterialUnitsActionTypes.ADD_MATERIAL_UNIT_START,
  payload: { newMaterialUnit, adminId },
  callback,
});

export const addMaterialUnitSuccess = () => ({
  type: MaterialUnitsActionTypes.ADD_MATERIAL_UNIT_SUCCESS,
});

export const addMaterialUnitFailure = (error) => ({
  type: MaterialUnitsActionTypes.ADD_MATERIAL_UNIT_FAILURE,
  payload: error,
});

export const getMaterialUnitsStart = (adminId) => ({
  type: MaterialUnitsActionTypes.GET_MATERIAL_UNITS_START,
  payload: adminId,
});

export const getMaterialUnitsSuccess = (materialUnits) => ({
  type: MaterialUnitsActionTypes.GET_MATERIAL_UNITS_SUCCESS,
  payload: materialUnits,
});

export const getMaterialUnitsFailure = (error) => ({
  type: MaterialUnitsActionTypes.GET_MATERIAL_UNITS_FAILURE,
  payload: error,
});

export const editMaterialUnitStart = (
  materialUnitName,
  adminId,
  materialUnitId,
  callback
) => ({
  type: MaterialUnitsActionTypes.EDIT_MATERIAL_UNIT_START,
  payload: { materialUnitName, adminId, materialUnitId },
  callback,
});

export const editMaterialUnitSuccess = () => ({
  type: MaterialUnitsActionTypes.EDIT_MATERIAL_UNIT_SUCCESS,
});

export const editMaterialUnitFailure = (error) => ({
  type: MaterialUnitsActionTypes.EDIT_MATERIAL_UNIT_FAILURE,
  payload: error,
});

export const deleteMaterialUnitStart = (materialUnitId, adminId, callback) => ({
  type: MaterialUnitsActionTypes.DELETE_MATERIAL_UNIT_START,
  payload: { materialUnitId, adminId },
  callback,
});

export const deleteMaterialUnitSuccess = () => ({
  type: MaterialUnitsActionTypes.DELETE_MATERIAL_UNIT_SUCCESS,
});

export const deleteMaterialUnitFailure = (error) => ({
  type: MaterialUnitsActionTypes.DELETE_MATERIAL_UNIT_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: MaterialUnitsActionTypes.CLEAR_ERRORS,
});
