import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getCurrentContractorStart } from "../../../redux/contractors/contractors.actions";
import { isEmpty } from "../../../utils/normalizeData";
import InlineModal from "../../inlineModal";
import AddContractor from "./add-contractor";

const ContractorInfo = ({ close, contractorIdToView }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { site_id, menu, info, action } = useParams();
  console.log("PARAMS: ", menu, info, action);

  const currentContractor = useSelector(
    (state) => state.contractors.currentContractor
  );
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    if (contractorIdToView) {
      if (!currentContractor || currentContractor.id !== contractorIdToView) {
        dispatch(
          getCurrentContractorStart(contractorIdToView, currentUser.user)
        );
      }
    }
  }, [contractorIdToView, currentContractor, currentUser, dispatch]);
  console.log("CURRENT CONTRACTOR: ", currentContractor);

  return (
    <>
      <div className="caption">
        Подрядчик
        <button className="close" onClick={close}></button>
      </div>

      {currentContractor && currentContractor.id === contractorIdToView && (
        <>
          <div className="two_col">
            <div className="col">
              <div className="field">
                <label>Наименование</label>
                <span className="data">{currentContractor.name}</span>
              </div>
            </div>

            <div className="col">
              {currentContractor.ogrn && (
                <div className="field">
                  <label>ОГРН/ОГРНИП</label>
                  <span className="data">{currentContractor.ogrn}</span>
                </div>
              )}
            </div>
          </div>

          {!isEmpty(currentContractor.contractor_contacts) && (
            <>
              {" "}
              <div className="hdr">Контакты</div>
              <table className="list">
                <tbody>
                  {Object.values(currentContractor.contractor_contacts).map(
                    (contact, i) => (
                      <tr key={i}>
                        <td>{contact.name}</td>

                        <td>
                          <a
                            className="contact_link"
                            href={`mailto: ${contact.email}`}
                          >
                            {contact.email}
                          </a>
                        </td>

                        <td>
                          <a
                            className="contact_link"
                            href={`tel: ${contact.tel}`}
                          >
                            {contact.tel}
                          </a>
                        </td>

                        <td>{contact.other_contacts}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </>
          )}

          {currentContractor.specialization && (
            <div className="field">
              <label>Специализация</label>
              <span className="data">{currentContractor.specialization}</span>
            </div>
          )}

          {currentContractor.descriptions && (
            <div className="field">
              <label>Дополнительное описание</label>
              <span className="data">{currentContractor.descriptions}</span>
            </div>
          )}

          <div className="buttons">
            <button
              className="blue"
              onClick={() =>
                history.push(
                  `/main/sites/${site_id}/contractors/${info}/edit_contractor`
                )
              }
            >
              Редактировать
            </button>
          </div>
        </>
      )}

      {action === "edit_contractor" && currentContractor && (
        <InlineModal
          close={() =>
            history.push(`/main/sites/${site_id}/contractors/${info}`)
          }
        >
          <AddContractor
            closeAfterDelete={close}
            close={() =>
              history.push(`/main/sites/${site_id}/contractors/${info}`)
            }
            contractorIdToEdit={+info}
          />
        </InlineModal>
      )}
    </>
  );
};

export default ContractorInfo;
