import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router";
import DatePicker from "react-date-picker";
import { InputField, NumberInput, TextAreaInput } from "../../input-forms";
import OtherWorks from "./other-works";
import SelectSubsection from "../subsections/select-subsection";
import SetPointsOnPlans from "../pointsOnPlan/set-points-on-plans";
import PickUser from "../../manage-users/pick-user";
import SelectContractor from "../contractors/select-contractor";
import Specialties from "./specialties";
import Equipment from "./equipment";
import Materials from "./materials";
import TechCardVariables from "./tech-card/techcard-variables";
import TechCardSpecialties from "./tech-card/techcard-specialties";
import TechCardEquipment from "./tech-card/techcard-equipment";
import TechCardMaterials from "./tech-card/techcard-materials";
import SelectTechCard from "./tech-card/select-techcard";
import { useSelector, useDispatch } from "react-redux";
import {
  addWorkStart,
  getSingleWorkStart,
  editWorkStart,
} from "../../../redux/works/works.actions";
import {
  clearTechCardDetails,
  getTechCardDetailsStart,
} from "../../../redux/techCards/techCards.actions";
import { formatDate, isEmpty, validate } from "../../../utils/normalizeData";
import axios from "axios";

const AddWork = ({
  checkInputs,
  work_id,
  group_id,
  close,
  checkInputsAndClose,
}) => {
  const { site_id } = useParams();

  const currentWork = useSelector((state) => state.works.currentWork);
  const currentUser = useSelector((state) => state.user.currentUser);
  const subsections = useSelector((state) => state.subsections.subsections);
  const techCardDetails = useSelector(
    (state) => state.techCards.techCardDetails
  );
  const dispatch = useDispatch();
  const editValuesSet = useRef();
  const subsectionFromEditWorkSet = useRef();
  const [workData, setWorkData] = useState({
    name: "",
    descriptions: "",
    prev_job_id: null,
  });
  const [otherWorks, toggleOtherWorks] = useState(false);
  const [afterThisWorkDate, setAfterThisWorkDate] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [dailyWorkHours, setDailyWorkHours] = useState(8);

  const [endDate, setEndDate] = useState(new Date());
  const isFetching = useSelector((state) => state.works.isFetching);
  const isFetchingTechCardDetails = useSelector(
    (state) => state.techCards.isFetching
  );

  const [subsection, selectSubsection] = useState();
  const [showPlan, setShowPlan] = useState(false);
  const [markerCoords, setMarkerCoords] = useState([]);
  const [coordsToDelete, setCoordsToDelete] = useState([]);

  const [performer, setPerformer] = useState("contractor");
  const [contractor_id, setContractorId] = useState(null);
  const [responsibleUser, setResponsibleUser] = useState(null);

  const [selectedCard, setSelectedCard] = useState();
  const [variableInputs, setVariableInputs] = useState({});
  const [specialtyVariables, setSpecialtyVariables] = useState();
  const userChangedVariable = useRef(false);
  const [specialties, setSpecialties] = useState([]);
  const [specialtyInputs, setSpecialtyInputs] = useState({
    id: "",
    qty: "",
  });
  const cardSpecialties = useRef([]);
  const [cardSpecInputs, setCardSpecInputs] = useState({});
  const specVarNamesAndIds = useRef({});
  const userEditedCardSpecs = useRef({});
  const [equipment, setEquipment] = useState([]);
  const [equipmentInputs, setEquipmentInputs] = useState({
    id: "",
    qty: "",
  });
  const [cardEquipmentInputs, setCardEquipmentInputs] = useState({});
  const userEditedCardEquipment = useRef({});

  const [materials, setMaterials] = useState([]);
  const [materialInputs, setMaterialInputs] = useState({
    id: "",
    qty: "",
  });
  const [cardMaterialInputs, setCardMaterialInputs] = useState({});
  const userEditedCardMaterials = useRef({});

  const [errors, setErrors] = useState({
    name: { type: "required", error: null },
    subsection: { type: "required", error: null },
    contractor_id: { type: "required", error: null },
    responsibleUser: { type: "required", error: null },
  });
  const [isDataValid, setIsDataValid] = useState(true);

  const extractSubsectionVariables = useCallback(() => {
    if (subsection && !isEmpty(subsection.subsection_variables)) {
      let subVariables = Object.values(subsection.subsection_variables);
      subVariables = subVariables.reduce((acc, variable) => {
        acc[variable.name] = variable.value;
        return acc;
      }, {});
      setVariableInputs((variableInputs) => ({
        ...variableInputs,
        ...subVariables,
      }));
    }
  }, [subsection]);

  // get tech card details when a card is selected
  useEffect(() => {
    if (selectedCard) {
      if (
        techCardDetails === null ||
        (techCardDetails && techCardDetails.id !== selectedCard.id)
      ) {
        dispatch(getTechCardDetailsStart(selectedCard.id, currentUser.user));
      }
    } else if (techCardDetails === undefined) {
      if (selectedCard) setSelectedCard(null);
      dispatch(clearTechCardDetails(null));
    }
  }, [selectedCard, currentUser, dispatch, techCardDetails]);

  // if tech card details are fetched, set the variable inputs state
  useEffect(() => {
    if (
      techCardDetails &&
      selectedCard &&
      selectedCard.id === techCardDetails.id
    ) {
      if (!isEmpty(techCardDetails.card_variables)) {
        const cardVariables = techCardDetails.card_variables;
        const newVars = {};
        for (const i in cardVariables) {
          newVars[cardVariables[i].variable] = "";
        }
        setVariableInputs((v) => ({ ...newVars, ...v }));
        if (!subsectionFromEditWorkSet.current) {
          extractSubsectionVariables();
        }
      }

      // if (!isEmpty(techCardDetails.card_employees)) {
      //   cardSpecialties.current = Object.values(techCardDetails.card_employees);
      //   // specVarNamesAndIds.current = cardSpecialties.current.reduce()
      //   const newSpecVars = cardSpecialties.current.reduce(
      //     (specVars, cardSpec) => {
      //       if (cardSpec.variable_qty) {
      //         specVarNamesAndIds.current[cardSpec.employee_id] =
      //           cardSpec.variable_qty;
      //         specVars[cardSpec.variable_qty] = "";
      //       }
      //       return specVars;
      //     },
      //     {}
      //   );

      //   setSpecialtyVariables((v) => ({ ...newSpecVars, ...v }));
      // }
    }
  }, [techCardDetails, selectedCard, extractSubsectionVariables]);

  // if tech card details are fetched, set the specialty variables
  useEffect(() => {
    if (
      techCardDetails &&
      selectedCard &&
      selectedCard.id === techCardDetails.id &&
      !isEmpty(techCardDetails.card_employees)
    ) {
      cardSpecialties.current = Object.values(techCardDetails.card_employees);

      const newSpecVars = cardSpecialties.current.reduce(
        (specVars, cardSpec) => {
          if (cardSpec.variable_qty) {
            specVarNamesAndIds.current[cardSpec.employee_id] =
              cardSpec.variable_qty;
            specVars[cardSpec.variable_qty] = "";
          }
          return specVars;
        },
        {}
      );

      setSpecialtyVariables((v) => ({ ...newSpecVars, ...v }));
    }
  }, [techCardDetails, selectedCard]);

  // if inputs for tech card specialties are changed, then update the variables for
  // tech card specialties
  // --------------------------------------------------
  // useEffect(() => {
  //   const specVars = {};
  //   for (const i in cardSpecInputs) {
  //     // const spec = cardSpecialties.current.find(
  //     //   (s) => String(s.employee_id) === i
  //     // );
  //     // if (spec) {
  //     //   specVars[spec.variable_qty] = cardSpecInputs[i];
  //     // }
  //     if (specVarNamesAndIds.current[i]) {
  //       specVars[specVarNamesAndIds.current[i]] = cardSpecInputs[i];
  //     }
  //   }
  //   setSpecialtyVariables((v) => {
  //     // console.log("PERFORMANCE TIME END: ", Date.now());
  //     return { ...v, ...specVars };
  //   });
  // }, [cardSpecInputs]);

  // if subsection is selected and it has filled in variables, set those values
  // into the appropriate card variables
  useEffect(() => {
    if (subsection) {
      if (!work_id || !subsectionFromEditWorkSet.current) {
        let subVariables = Object.values(subsection.subsection_variables);
        subVariables = subVariables.reduce((acc, variable) => {
          acc[variable.name] = variable.value;
          return acc;
        }, {});

        setVariableInputs((variableInputs) => ({
          ...variableInputs,
          ...subVariables,
        }));
      }
    }
  }, [subsection, work_id]);
  // check if this is the edit page (by checking the work_id parameter and the redux state currentWork id )
  //  and set all the necessary data, if true
  useEffect(() => {
    if (work_id) {
      if (!currentWork || currentWork.id !== work_id) {
        dispatch(getSingleWorkStart(currentUser.user, work_id));
      } else {
        axios
          .get(`/api/job_plan_points_job/${currentUser.user}/${currentWork.id}`)
          .then((response) => {
            setMarkerCoords(response.data);
          })
          .catch((e) => console.log(e));

        setWorkData({
          name: currentWork.name,
          descriptions: currentWork.descriptions,
          prev_job_id: isEmpty(currentWork.prev_job)
            ? null
            : currentWork.prev_job.id,
        });
        if (!isEmpty(currentWork.prev_job)) {
          toggleOtherWorks(true);
        }

        const today = new Date();
        let editStartDate = new Date(currentWork.date_start);
        if (today > editStartDate) editStartDate = today;
        setStartDate(editStartDate);
        let editEndDate = new Date(currentWork.date_end);
        if (today > editEndDate) editEndDate = today;
        setEndDate(editEndDate);
        const currentSub =
          subsections.length &&
          subsections.find((sub) => sub.id === currentWork.subsection_id);
        if (currentSub) {
          subsectionFromEditWorkSet.current = true;
          selectSubsection(currentSub);
        }
        if (currentWork.user_id) {
          setPerformer("user");
          setResponsibleUser(currentWork.user_id);
        } else if (currentWork.contractor_id) {
          setContractorId(Number(currentWork.contractor_id));
        }
      }
    }
  }, [work_id, currentWork, currentUser, dispatch, subsections]);

  // if this is the edit page and a tech card had been chosen before, get the tech
  // card details and set those details
  useEffect(() => {
    if (
      !editValuesSet.current &&
      work_id &&
      currentWork &&
      currentWork.id === work_id &&
      currentWork.card_id
    ) {
      if (!techCardDetails || techCardDetails.id !== currentWork.card_id) {
        return dispatch(
          getTechCardDetailsStart(currentWork.card_id, currentUser.user)
        );
      } else {
        setSelectedCard({
          id: techCardDetails.id,
          name: techCardDetails.name,
          card_group_id: techCardDetails.card_group_id,
        });
      }
    }
  }, [currentWork, work_id, techCardDetails, currentUser, dispatch]);

  // if the edit window is opened, set the appropriate values for specialties, equipment, materials
  // by checking whether a tech card had been selected or not
  useEffect(() => {
    if (
      work_id &&
      !editValuesSet.current &&
      currentWork &&
      currentWork.id === work_id
    ) {
      if (currentWork.card_id) {
        if (techCardDetails && techCardDetails.id === currentWork.card_id) {
          if (!isEmpty(currentWork.job_variables)) {
            const variableInputs = {};
            const workVariables = Object.values(currentWork.job_variables);

            for (const i in workVariables) {
              variableInputs[workVariables[i].name] = workVariables[i].value;
            }

            setVariableInputs((v) => ({ ...v, ...variableInputs }));
            // if (!subsectionFromEditWorkSet.current) {
            //   extractSubsectionVariables();
            // }
          }
        }
      }
    }
  }, [work_id, currentWork, techCardDetails]);

  // if the edit window is opened, set the appropriate values for specialties, equipment, materials
  // by checking whether a tech card had been selected or not
  useEffect(() => {
    if (
      work_id &&
      !editValuesSet.current &&
      currentWork &&
      currentWork.id === work_id
    ) {
      if (currentWork.card_id) {
        if (techCardDetails && techCardDetails.id === currentWork.card_id) {
          if (currentWork.hours_in_day) {
            setDailyWorkHours(currentWork.hours_in_day);
          }

          // if (!isEmpty(currentWork.job_variables)) {
          //   const variableInputs = {};
          //   const workVariables = Object.values(currentWork.job_variables);

          //   for (const i in workVariables) {
          //     variableInputs[workVariables[i].name] = workVariables[i].value;
          //   }

          //   setVariableInputs((v) => ({ ...v, ...variableInputs }));
          //   if (!subsectionFromEditWorkSet.current) {
          //     extractSubsectionVariables();
          //   }
          // }

          if (!isEmpty(techCardDetails.card_employees)) {
            const cardSpecs = Object.values(techCardDetails.card_employees);
            const specs = currentWork.job_employees;
            const cardSpecsToState = cardSpecs.reduce((state, spec) => {
              state[spec.employee_id] = {
                qty: "",
                man_hour: "",
                variable: spec.variable_qty,
              };
              return state;
            }, {});
            let specsToState = [];

            for (const i in specs) {
              if (cardSpecsToState.hasOwnProperty(specs[i].employee_id)) {
                cardSpecsToState[specs[i].employee_id].qty = specs[i].qty;
              } else {
                specsToState.push([
                  specs[i].employee_id,
                  specs[i].qty,
                  specs[i].specialty,
                ]);
              }
            }
            setCardSpecInputs(cardSpecsToState);
            if (specsToState.length) {
              setSpecialties(specsToState);
            }
          }

          if (!isEmpty(techCardDetails.card_equipment)) {
            const workEquipment = currentWork.job_equipment;
            const cardEquipmentToState = Object.values(
              techCardDetails.card_equipment
            ).reduce((state, eq) => {
              state[eq.equipment_id] = "";
              return state;
            }, {});
            let equipmentToState = [];

            for (const i in workEquipment) {
              if (
                cardEquipmentToState.hasOwnProperty(
                  workEquipment[i].equipment_id
                )
              ) {
                cardEquipmentToState[workEquipment[i].equipment_id] = {
                  qty: workEquipment[i].qty,
                };
              } else {
                equipmentToState.push([
                  workEquipment[i].equipment_id,
                  workEquipment[i].qty,
                  workEquipment[i].equipment,
                ]);
              }
            }
            setCardEquipmentInputs(cardEquipmentToState);
            if (equipmentToState.length) {
              setEquipment(equipmentToState);
            }
          }

          if (!isEmpty(techCardDetails.card_materials)) {
            const workMaterials = currentWork.job_materials;
            const cardMaterialsToState = Object.values(
              techCardDetails.card_materials
            ).reduce((state, material) => {
              state[material.material_id] = "";
              return state;
            }, {});

            let materialsToState = [];
            for (const i in workMaterials) {
              if (
                cardMaterialsToState.hasOwnProperty(
                  workMaterials[i].material_id
                )
              ) {
                cardMaterialsToState[workMaterials[i].material_id] = {
                  qty: workMaterials[i].qty,
                };
              } else {
                materialsToState.push([
                  workMaterials[i].material_id,
                  workMaterials[i].qty,
                  workMaterials[i].material,
                  workMaterials[i].unit,
                ]);
              }
            }
            setCardMaterialInputs(cardMaterialsToState);
            if (materialsToState.length) {
              setMaterials(materialsToState);
            }
          }
          editValuesSet.current = true;
        }
      } else {
        if (!isEmpty(currentWork.job_employees)) {
          let specialties = [];
          for (const i in currentWork.job_employees) {
            specialties.push([
              currentWork.job_employees[i].employee_id,
              currentWork.job_employees[i].qty,
              currentWork.job_employees[i].specialty,
            ]);
          }
          setSpecialties(specialties);
        }

        if (!isEmpty(currentWork.job_equipment)) {
          let equipment = [];
          for (const tool in currentWork.job_equipment) {
            equipment.push([
              currentWork.job_equipment[tool].equipment_id,
              currentWork.job_equipment[tool].qty,
              currentWork.job_equipment[tool].equipment,
            ]);
          }
          setEquipment(equipment);
        }

        if (!isEmpty(currentWork.job_materials)) {
          let materials = [];
          for (const material in currentWork.job_materials) {
            materials.push([
              currentWork.job_materials[material].material_id,
              currentWork.job_materials[material].qty,
              currentWork.job_materials[material].name,
            ]);
          }
          setMaterials(materials);
        }
      }
    }
  }, [work_id, currentWork, techCardDetails]);

  useEffect(() => {
    if (!otherWorks && afterThisWorkDate) {
      setAfterThisWorkDate(null);
    } else if (afterThisWorkDate) {
      setStartDate(afterThisWorkDate);
    }
  }, [afterThisWorkDate, otherWorks, startDate]);

  // when the start date is changed, change the end date to a date later than the start date,
  // if a tech card had not been selected (i.e. no calculations are needed based on work-hours)
  // useEffect(() => {
  //   if (!selectedCard && endDate < startDate) setEndDate(startDate);
  // }, [startDate, endDate, selectedCard]);

  useEffect(() => {
    if (!isDataValid) {
      for (const i in errors) {
        if (errors[i].error) return;
      }
      setIsDataValid(true);
    }
  }, [errors, isDataValid]);

  // if this is not the edit window, then check inputs and prompt the user when he
  // tries to close the window without saving
  if (!work_id) {
    checkInputs.current = () => {
      if (
        workData.name ||
        workData.descriptions ||
        afterThisWorkDate ||
        subsection ||
        contractor_id ||
        responsibleUser ||
        specialties.length ||
        specialtyInputs.id ||
        specialtyInputs.qty ||
        equipment.length ||
        equipmentInputs.id ||
        equipmentInputs.qty ||
        materials.length ||
        materialInputs.id ||
        materialInputs.qty ||
        selectedCard
      )
        return true;
      return false;
    };
  }

  const handleChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      validate(
        e.target.value,
        () => {},
        () => {
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: { ...errors[e.target.name], error: null },
          }));
        },
        errors[e.target.name].type
      );
    }
    setWorkData({ ...workData, [e.target.name]: e.target.value });
  };

  const changePerformerType = (e) => {
    if (errors.contractor_id.error || errors.responsibleUser.error) {
      const newErrors = { ...errors };
      newErrors.contractor_id.error = null;
      newErrors.responsibleUser.error = null;
      setErrors(newErrors);
    }
    setPerformer(e.target.value);
  };

  const handlePerformerChange = (e) => {
    if (errors[e.target.name].error) {
      setErrors({
        ...errors,
        [e.target.name]: { ...errors[e.target.name], error: null },
      });
    }
    if (e.target.name === "contractor_id") {
      setContractorId(e.target.value);
    } else {
      setResponsibleUser(e.target.value);
    }
  };

  const handleSubsectionSelect = (subsection) => {
    if (subsectionFromEditWorkSet.current) {
      subsectionFromEditWorkSet.current = false;
    }
    selectSubsection(subsection);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = { ...errors };
    for (const i in errors) {
      if (i === "subsection") {
        if (!subsection) {
          newErrors.subsection.error = "Выберите подраздел";
          valid = false;
        }
      } else if (i === "contractor_id") {
        if (performer === "contractor" && !contractor_id) {
          newErrors.contractor_id.error = "Выберите подрядчика";
          valid = false;
        }
      } else if (i === "responsibleUser") {
        if (performer === "user" && !responsibleUser) {
          newErrors.responsibleUser.error = "Выберите пользователя";
          valid = false;
        }
      } else if (!workData[i]) {
        newErrors[i].error = "Обязательное поле";
        valid = false;
      }
    }
    if (!valid) {
      setErrors(newErrors);
      return setIsDataValid(false);
    }

    let normalizedSpecialties = [];
    for (const i in cardSpecInputs) {
      if (cardSpecInputs[i] && cardSpecInputs[i].qty) {
        normalizedSpecialties.push([+i, cardSpecInputs[i].qty]);
      }
    }
    normalizedSpecialties = [
      ...normalizedSpecialties,
      ...specialties.map((spec) => [spec[0], spec[1]]),
    ];

    let normalizedEquipment = [];
    for (const i in cardEquipmentInputs) {
      if (cardEquipmentInputs[i] && cardEquipmentInputs[i].qty) {
        normalizedEquipment.push([+i, cardEquipmentInputs[i].qty]);
      }
    }
    normalizedEquipment = [
      ...normalizedEquipment,
      ...equipment.map((eq) => [eq[0], eq[1]]),
    ];

    let normalizedMaterials = [];

    for (const i in cardMaterialInputs) {
      if (cardMaterialInputs[i] && cardMaterialInputs[i].qty) {
        normalizedMaterials.push([+i, cardMaterialInputs[i].qty]);
      }
    }
    normalizedMaterials = [
      ...normalizedMaterials,
      ...materials.map((material) => [material[0], material[1]]),
    ];

    let normalizedVariables = [];
    if (selectedCard) {
      const cardVariables = techCardDetails.card_variables;
      for (const i in cardVariables) {
        if (variableInputs[cardVariables[i].variable]) {
          normalizedVariables.push([
            cardVariables[i].variable_id,
            variableInputs[cardVariables[i].variable],
          ]);
        }
      }
    }

    const action = work_id ? editWorkStart : addWorkStart;

    dispatch(
      action(
        {
          ...workData,
          date_start: formatDate(startDate),
          date_end: formatDate(endDate),
          obj_id: site_id,
          subsection_id: subsection.id,
          contractor_id: performer === "contractor" ? contractor_id : null,
          job_group_id:
            work_id && currentWork ? currentWork.job_group_id : group_id,
          user_id: performer === "user" ? responsibleUser : null,
          card_id: selectedCard ? selectedCard.id : null,
          hours_in_day: dailyWorkHours,
        },
        {
          specialties: normalizedSpecialties,
          equipment: normalizedEquipment,
          materials: normalizedMaterials,
        },
        normalizedVariables,
        currentUser,
        site_id,
        close,
        markerCoords,
        coordsToDelete,
        work_id
      )
    );
  };

  const handleStartDateChange = (startDate) => {
    setStartDate(startDate);
    if (startDate > endDate) {
      if (!selectedCard || !dailyWorkHours) {
        setEndDate(startDate);
      } else {
        calculateWorkEndDate(cardSpecInputs, startDate);
      }
    }
  };

  const handleDailyWorkHoursChange = (e) => {
    const value = e.target.value ? +e.target.value : "";
    if (value && (value > 24 || value < 1)) return;
    setDailyWorkHours(value);
  };

  const calculateWorkEndDate = (cardSpecInputs, initialDate = startDate) => {
    if (dailyWorkHours) {
      let daysToFinish = 1;
      for (const i in cardSpecInputs) {
        const { man_hour, qty } = cardSpecInputs[i];
        if (man_hour && qty) {
          const currentSpecsDaysToFinsih = Math.ceil(
            man_hour / dailyWorkHours / qty
          );
          if (currentSpecsDaysToFinsih > daysToFinish) {
            daysToFinish = currentSpecsDaysToFinsih;
          }
        }
      }
      const date = new Date(initialDate);
      const dateToFinish = date.setDate(date.getDate() + daysToFinish);
      setEndDate(new Date(dateToFinish));
    }
  };

  if (work_id && (!currentWork || currentWork.id !== work_id)) {
    return <div>Загрузка данных о работе...</div>;
  }

  const showOtherWorks = () => {
    if (otherWorks) {
      setWorkData((w) => ({ ...w, prev_job_id: null }));
      toggleOtherWorks(false);
    } else {
      toggleOtherWorks(true);
    }
  };

  return (
    <>
      {work_id && (
        <div className="caption">
          Редактировать работу
          <button className="close" onClick={checkInputsAndClose}></button>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <>
          <InputField
            label="Название"
            type="text"
            name="name"
            value={workData.name}
            onChange={handleChange}
            validationType="required"
            err={errors.name.error}
          />

          <div className="two_col">
            <div className="col">
              <div className="field">
                <label>Дата начала</label>
                <DatePicker
                  onChange={handleStartDateChange}
                  value={startDate}
                  clearIcon={null}
                  format="dd-MM-yyyy"
                  validationType="required"
                  minDate={afterThisWorkDate || new Date()}
                  locale="ru-RU"
                />
              </div>
            </div>
            <div className="col">
              <div className="field">
                <label>Дата окончания</label>
                <DatePicker
                  onChange={setEndDate}
                  value={endDate}
                  clearIcon={null}
                  format="dd-MM-yyyy"
                  validationType="required"
                  minDate={startDate}
                  locale="ru-RU"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="buttons in_form">
              <button className="blue" onClick={showOtherWorks} type="button">
                Идет после другой работы
              </button>
            </div>
          </div>
          {otherWorks && (
            <OtherWorks
              group_id={group_id}
              setAfterThisWorkDate={setAfterThisWorkDate}
              prev_job_id={workData.prev_job_id}
              setWorkData={setWorkData}
            />
          )}

          <div className="hdr">Исполнитель</div>

          <div className="field">
            <input
              type="radio"
              name="performer"
              id="contractor"
              value="contractor"
              checked={performer === "contractor"}
              onChange={changePerformerType}
            />
            <label htmlFor="contractor">Подрядчик</label>
            <input
              type="radio"
              name="performer"
              id="user"
              value="user"
              checked={performer === "user"}
              onChange={changePerformerType}
            />
            <label htmlFor="user">Своими силами</label>
          </div>

          {performer === "user" && (
            <div>
              <PickUser
                userId={responsibleUser}
                selectUserId={setResponsibleUser}
                validationType="required"
                err={errors.responsibleUser.error}
                name="responsibleUser"
              />
            </div>
          )}

          {performer === "contractor" && (
            <div>
              <SelectContractor
                contractorId={contractor_id}
                selectContractorId={handlePerformerChange}
                validationType="required"
                err={errors.contractor_id.error}
                setErrors={setErrors}
                name="contractor_id"
              />
            </div>
          )}

          <SelectSubsection
            subsection={subsection}
            selectSub={handleSubsectionSelect}
            // idToGetSub={idToGetSub}
            // markerCoords={markerCoords}
            // setMarkerCoords={setMarkerCoords}
            validationType="required"
            err={errors.subsection.error}
            setErrors={setErrors}
            subsectionSetFromParent
          />

          <div className="plan">
            <button
              className="show"
              onClick={() => setShowPlan(!showPlan)}
              type="button"
            >
              {`${showPlan ? "Скрыть" : "Показать"} план`}
            </button>
            {showPlan &&
              (subsection ? (
                <SetPointsOnPlans
                  subsection={subsection}
                  markerCoords={markerCoords}
                  setMarkerCoords={setMarkerCoords}
                  coordsToDelete={coordsToDelete}
                  setCoordsToDelete={setCoordsToDelete}
                  pinType="work"
                />
              ) : (
                // renderSubsectionPlans(subsection)
                <div className="info"> Подраздел не выбран </div>
              ))}
          </div>

          <TextAreaInput
            label="Описание"
            height="15vh"
            onChange={handleChange}
            value={workData.descriptions}
            name="descriptions"
          />
        </>

        <SelectTechCard
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          setSpecialtyVariables={setSpecialtyVariables}
          userChangedVariable={userChangedVariable}
          setSpecialties={setSpecialties}
          setEquipment={setEquipment}
          setCardEquipmentInputs={setCardEquipmentInputs}
          setMaterials={setMaterials}
          setMaterialInputs={setCardMaterialInputs}
          subsectionFromEditWorkSet={subsectionFromEditWorkSet}
        />

        {selectedCard && isFetchingTechCardDetails && (
          // (!techCardDetails || (techCardDetails && techCardDetails.id !== selectedCard.id)) &&
          <div>загрузка данных по карте...</div>
        )}

        {selectedCard &&
          techCardDetails &&
          techCardDetails.id === selectedCard.id && (
            <>
              <div>
                <div>
                  <span>рабочих часов в дне</span>
                </div>
                <div>
                  <NumberInput
                    value={dailyWorkHours}
                    onChange={handleDailyWorkHoursChange}
                    max={24}
                  />
                </div>
                <TechCardVariables
                  cardVariables={techCardDetails.card_variables}
                  variableInputs={variableInputs}
                  setVariableInputs={setVariableInputs}
                  userEditedCardSpecs={userEditedCardSpecs}
                  userEditedCardEquipment={userEditedCardEquipment}
                  userEditedCardMaterials={userEditedCardMaterials}
                  userChangedVariable={userChangedVariable}
                />
              </div>

              <TechCardSpecialties
                cardSpecialties={cardSpecialties.current}
                specialtiesToSubmit={specialties}
                setSpecialties={setSpecialties}
                specialtyInputs={specialtyInputs}
                setSpecialtyInputs={setSpecialtyInputs}
                cardSpecInputs={cardSpecInputs}
                setCardSpecInputs={setCardSpecInputs}
                variableInputs={variableInputs}
                specialtyVariables={specialtyVariables}
                setSpecialtyVariables={setSpecialtyVariables}
                userEditedCardSpecs={userEditedCardSpecs}
                calculateWorkEndDate={calculateWorkEndDate}
                userChangedVariable={userChangedVariable}
                dailyWorkHours={dailyWorkHours}
                work_id={work_id}
              />

              <TechCardEquipment
                cardEquipment={techCardDetails.card_equipment}
                cardSpecialties={techCardDetails.card_employees}
                cardEquipmentInputs={cardEquipmentInputs}
                setCardEquipmentInputs={setCardEquipmentInputs}
                equipmentToSubmit={equipment}
                setEquipment={setEquipment}
                equipmentInputs={equipmentInputs}
                setEquipmentInputs={setEquipmentInputs}
                userChangedVariable={userChangedVariable}
                userEditedCardEquipment={userEditedCardEquipment}
                variableInputs={variableInputs}
                specialtyVariables={specialtyVariables}
                dailyWorkHours={dailyWorkHours}
                work_id={work_id}
              />

              <TechCardMaterials
                cardMaterials={techCardDetails.card_materials}
                cardSpecialties={techCardDetails.card_employees}
                cardMaterialInputs={cardMaterialInputs}
                setCardMaterialInputs={setCardMaterialInputs}
                materialsToSubmit={materials}
                setMaterials={setMaterials}
                materialInputs={materialInputs}
                setMaterialInputs={setMaterialInputs}
                userChangedVariable={userChangedVariable}
                userEditedCardMaterials={userEditedCardMaterials}
                variableInputs={variableInputs}
                specialtyVariables={specialtyVariables}
                dailyWorkHours={dailyWorkHours}
                work_id={work_id}
              />
            </>
          )}

        {!selectedCard && (
          <>
            <Specialties
              specialtiesToSubmit={specialties}
              setSpecialties={setSpecialties}
              specialtyInputs={specialtyInputs}
              setSpecialtyInputs={setSpecialtyInputs}
            />
            <Equipment
              equipmentToSubmit={equipment}
              setEquipment={setEquipment}
              equipmentInputs={equipmentInputs}
              setEquipmentInputs={setEquipmentInputs}
            />
            <Materials
              materialsToSubmit={materials}
              setMaterials={setMaterials}
              materialInputs={materialInputs}
              setMaterialInputs={setMaterialInputs}
            />
          </>
        )}

        <div className="info err">
          {!isDataValid && "Не все поля заполнены корректно"}
        </div>

        <div className="buttons">
          <button className="green" disabled={isFetching}>
            {work_id ? "Сохранить" : "Добавить"}
          </button>
          <button
            className="cancel"
            type="button"
            onClick={checkInputsAndClose}
          >
            Отмена
          </button>
        </div>
      </form>
    </>
  );
};

export default AddWork;
