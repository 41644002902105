import React, { useEffect, useCallback } from "react";
import { SelectInput } from "../../input-forms";
import { useSelector } from "react-redux";
import { validate } from "../../../utils/normalizeData";

const SelectSubsection = ({
  subsection,
  selectSub,
  idToGetSub,
  markerCoords,
  setMarkerCoords,
  setCoordsToDelete,
  err,
  setErrors,
  validationType,
  subsectionSetFromParent,
}) => {
  const subsections = useSelector((state) => state.subsections.subsections);
  const currentSub = useSelector(
    (state) => state.subsections.currentSubsection
  );

  const findSubsectionById = useCallback(
    (id) => {
      return subsections.find((sub) => sub.id === parseInt(id));
    },
    [subsections]
  );

  useEffect(() => {
    if (subsectionSetFromParent) return;
    if (currentSub) return selectSub(currentSub);
    if (idToGetSub) selectSub(findSubsectionById(idToGetSub));
  }, [
    currentSub,
    selectSub,
    idToGetSub,
    findSubsectionById,
    subsectionSetFromParent,
  ]);

  const options = subsections.reduce((acc, curr) => {
    acc[curr.id] = curr.name;
    return acc;
  }, {});

  const handleChange = (e) => {
    if (err) {
      validate(
        e.target.value,
        () => {},
        () =>
          setErrors((errors) => ({
            ...errors,
            subsection: { ...errors.subsection, error: null },
          })),
        validationType
      );
    }
    if (markerCoords && markerCoords.length) {
      if (setCoordsToDelete) setCoordsToDelete((c) => [...c, ...markerCoords]);
      setMarkerCoords([]);
    }
    selectSub(findSubsectionById(e.target.value));
  };

  return (
    <SelectInput
      label="Подраздел"
      options={options}
      onChange={handleChange}
      defaultOption={subsection ? subsection.id : ""}
      description={subsection ? null : "Выберите подраздел"}
      validationType={validationType}
      err={err}
    />
  );
};

export default SelectSubsection;
