import React, { useState } from "react";
// import { useWorkTypeStyles } from "../../../css/sites/works.styles";
import AddGroup from "./add-group";
import AddWork from "./add-work";

const WorkType = ({
  path,
  close,
  checkInputs,
  checkInputsAndClose,
  group_id,
}) => {
  // const classes = useWorkTypeStyles();
  const [workType, setWorkType] = useState("group");

  const handleWorkTypeChange = (e) => setWorkType(e.target.value);

  return (
    <>
      <div className="caption">
        Добавить
        <button className="close" onClick={checkInputsAndClose}></button>
      </div>

      <div className="field in_line" onChange={handleWorkTypeChange}>
        <input
          type="radio"
          name="workType"
          id="group"
          value="group"
          defaultChecked={workType === "group"}
        />
        <label htmlFor="group">Группу</label>
        <input
          type="radio"
          id="work"
          name="workType"
          value="work"
          defaultChecked={workType === "work"}
        />
        <label htmlFor="work">Работу</label>
      </div>
      {workType === "group" && (
        <AddGroup
          close={close}
          checkInputs={checkInputs}
          checkInputsAndClose={checkInputsAndClose}
          group_id={group_id}
        />
      )}
      {workType === "work" && (
        <AddWork
          close={close}
          checkInputs={checkInputs}
          checkInputsAndClose={checkInputsAndClose}
          group_id={group_id}
          path={path}
        />
      )}
    </>
  );
};

export default WorkType;
