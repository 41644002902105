import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputField } from "../input-forms";
import ImageUpload from "../image-upload";
import {
  addUserStart,
  clearErrors,
  editUserStart,
  deleteUserStart,
} from "../../redux/user/user.actions";
import {
  checkEmailValidity,
  emailPattern,
} from "../../utils/checkEmailValidity";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import { user_photos } from "../../config";
import ConfirmDeleteModal from "../confirmActionModal";
import { validate } from "../../utils/normalizeData";

const AddUser = ({
  userToEdit,
  close,
  checkInputs,
  checkInputsAndClose,
  showUser,
  closeAfterDelete,
}) => {
  const dispatch = useDispatch();
  useEffect(() => dispatch(clearErrors()), [dispatch]);
  const admin = useSelector((state) => state.user.currentUser);
  const isRequestError = useSelector((state) => state.user.error);
  const isUserBeingAdded = useSelector((state) => state.user.isFetching);
  const isDeleting = useSelector((state) => state.user.isDeleting);
  const [photo, setPhoto] = useState(null);

  const [confirmDelete, setConfirmDelete] = useState();
  const [userCredentials, setUserCredentials] = useState({
    lastname: "",
    firstname: "",
    tel: "",
    email: "",
    blocked: false,
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    lastname: { type: "required", error: null },
    firstname: { type: "required", error: null },
    email: { type: "required email", error: null },
    password: { type: "required", error: null },
    confirmPassword: { type: "required", error: null },
  });
  const [isDataValid, setIsDataValid] = useState(true);
  const [validPasswordMsg, setValidPassword] = useState();

  // if this is not the edit window, then check inputs and prompt the user when he
  // tries to close the window without saving
  if (!userToEdit) {
    checkInputs.current = () => {
      if (
        userCredentials.firstname ||
        userCredentials.lastname ||
        userCredentials.tel ||
        userCredentials.email ||
        userCredentials.blocked ||
        userCredentials.password ||
        photo
      )
        return true;
      return false;
    };
  }

  // if the edit user window is opened, then set all the necessary inputs
  useEffect(() => {
    if (userToEdit) {
      const { lastname, firstname, tel, email, blocked, org_id } = userToEdit;
      if (userToEdit.img) {
        setPhoto({
          preview: `${user_photos}/${userToEdit.org_id}/${userToEdit.img}`,
        });
      }
      setUserCredentials({
        lastname,
        firstname,
        email,
        org_id,
        blocked,
        tel: `${tel || ""}`,
      });
    }
  }, [userToEdit]);

  const handleChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      validate(
        e.target.value,
        () => {},
        () =>
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: { ...errors[e.target.name], error: null },
          })),
        errors[e.target.name].type
      );
    }

    if (e.target.name === "tel") {
      return setUserCredentials({
        ...userCredentials,
        [e.target.name]: formatPhoneNumber(e.target.value),
      });
    }
    setUserCredentials({ ...userCredentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    for (const i in errors) {
      if (errors[i].error) return;
      else if (i === "email") {
        if (!checkEmailValidity(userCredentials.email)) {
          setErrors((errors) => ({
            ...errors,
            email: { ...errors.email, error: "Введите e-mail" },
          }));
          valid = false;
          if (isDataValid) setIsDataValid(false);
        }
      } else if ((i !== "password" && i !== "confirmPassword") || !userToEdit) {
        if (!userCredentials[i]) {
          setErrors((errors) => ({
            ...errors,
            [i]: { ...errors[i], error: "Обязательное поле" },
          }));
          valid = false;
          if (isDataValid) setIsDataValid(false);
        }
      }
    }

    if (!valid) return;

    const newUserData = { ...userCredentials };
    delete newUserData.confirmPassword;

    if (userToEdit) {
      return dispatch(
        editUserStart(
          newUserData,
          photo instanceof Blob ? photo : null,
          userToEdit.id,
          admin,
          close
        )
      );
    }
    dispatch(
      addUserStart(
        { ...newUserData, org_id: admin.organization },
        photo,
        admin,
        close
      )
    );
  };

  const handleDelete = (e) => {
    if (!isUserBeingAdded) {
      dispatch(
        deleteUserStart(
          {
            who: admin.user,
            organization: admin.organization,
            id: userToEdit.id,
          },
          admin,
          closeAfterDelete
        )
      );
    }
  };

  // check password length
  useEffect(() => {
    if (!userCredentials.password && !userCredentials.confirmPassword) return;
    else if (userCredentials.password.length >= 6) {
      if (errors.password.error) {
        setErrors((errors) => ({
          ...errors,
          password: {
            ...errors.password,
            error: null,
          },
        }));
      }
    } else if (userCredentials.password.length < 6) {
      if (!errors.password.error) {
        setErrors((errors) => ({
          ...errors,
          password: {
            ...errors.password,
            error: "Длина пароля должна быть не менее 6 симоволов",
          },
        }));
      }
    }
  }, [
    userCredentials.password,
    userCredentials.confirmPassword,
    errors.password.error,
  ]);

  //check password match and validity
  useEffect(() => {
    if (!userCredentials.confirmPassword) {
      if (validPasswordMsg) setValidPassword();
      if (
        errors.confirmPassword.error &&
        errors.confirmPassword.error !== "Обязательное поле"
      ) {
        return setErrors((errors) => ({
          ...errors,
          confirmPassword: {
            ...errors.confirmPassword,
            error: null,
          },
        }));
      }
    } else if (userCredentials.confirmPassword !== userCredentials.password) {
      if (!errors.confirmPassword.error) {
        setErrors((errors) => ({
          ...errors,
          confirmPassword: {
            ...errors.confirmPassword,
            error: "Пароль и подтверждение не совпадают",
          },
        }));
      }
      if (validPasswordMsg) setValidPassword();
    } else if (
      userCredentials.password.length >= 6 &&
      userCredentials.password === userCredentials.confirmPassword
    ) {
      if (!validPasswordMsg) setValidPassword("Пароль принят");
      if (errors.confirmPassword.error) {
        setErrors((errors) => ({
          ...errors,
          confirmPassword: { ...errors.confirmPassword, error: null },
        }));
      }
    }
  }, [
    userCredentials.confirmPassword,
    userCredentials.password,
    errors.confirmPassword,
    validPasswordMsg,
  ]);

  // check if no errors on input data
  useEffect(() => {
    if (!isDataValid) {
      for (const i in errors) {
        if (errors[i].error) return;
      }
      setIsDataValid(true);
    }
  }, [errors, isDataValid]);

  return (
    <>
      {confirmDelete && (
        <ConfirmDeleteModal
          message="Удалить пользователя"
          confirm={handleDelete}
          cancel={() => setConfirmDelete(false)}
          processing={isDeleting}
          errorMsg={isRequestError}
        />
      )}
      <form>
        <div className="caption">
          Пользователь
          <span className="form">
            <label>
              <input
                type="checkbox"
                name="blocked"
                checked={userCredentials.blocked}
                onChange={() =>
                  setUserCredentials({
                    ...userCredentials,
                    blocked: !userCredentials.blocked,
                  })
                }
              />
              заблокирован
            </label>
          </span>
          <button
            className="close"
            type="button"
            onClick={checkInputsAndClose}
          ></button>
        </div>
        <div className="two_col">
          <div className="col">
            <InputField
              type="text"
              label="Фамилия"
              name="lastname"
              width="290px"
              value={userCredentials.lastname}
              onChange={handleChange}
              autoComplete="off"
              validationType="required"
              err={errors.lastname.error}
            />

            <InputField
              type="text"
              label="Имя"
              name="firstname"
              value={userCredentials.firstname}
              onChange={handleChange}
              autoComplete="off"
              validationType="required"
              err={errors.firstname.error}
            />

            <InputField
              label="Электронная почта"
              type="email"
              name="email"
              value={userCredentials.email}
              onChange={handleChange}
              autoComplete="off"
              validationType="required email"
              pattern={emailPattern}
              err={errors.email.error}
            />

            <InputField
              type="text"
              label="Телефон"
              name="tel"
              value={userCredentials.tel}
              onChange={handleChange}
              autoComplete="off"
            />
          </div>

          <div className="col">
            <div className="field">
              <label>Фото</label>
              {photo && (
                <img className="user_photo" src={photo.preview} alt="avatar" />
              )}
            </div>

            <ImageUpload
              filesToUpload={photo}
              setFilesToUpload={setPhoto}
              maxHeight={144}
              maxWidth={144}
              label="Кликните или перетащите картинку"
            />
          </div>
        </div>

        {!userToEdit && (
          <div className="two_col password">
            <div className="col">
              <InputField
                type="password"
                label="Пароль"
                name="password"
                value={userCredentials.password}
                onChange={handleChange}
                autoComplete="off"
                validationType="required"
                err={errors.password.error}
              />
            </div>

            <div className="col">
              <InputField
                label="Подтверждение пароля"
                type="password"
                name="confirmPassword"
                value={userCredentials.confirmPassword}
                onChange={handleChange}
                autoComplete="off"
                validationType="required"
                info={validPasswordMsg}
                err={errors.confirmPassword.error}
              />
            </div>
          </div>
        )}

        <div className="info err">
          {isRequestError ||
            (!isDataValid && "Не все поля заполнены корректно")}
        </div>
        <div className="buttons">
          <button
            className="green"
            type="button"
            onClick={handleSubmit}
            disabled={isUserBeingAdded}
          >
            {isUserBeingAdded ? "Загрузка..." : "Сохранить"}
          </button>
          <button
            className="cancel"
            onClick={checkInputsAndClose}
            type="button"
          >
            Отмена
          </button>
          {userToEdit && (
            <button
              className="remove"
              type="button"
              onClick={() => setConfirmDelete(true)}
            />
          )}
        </div>
      </form>
    </>
  );
};

export default AddUser;
