import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginRoutes from "./login/login-routes";
import MainRoutes from "./main/main-routes";
import ResetPasswordPage from "./login/reset-password";
import axios from "axios";
import { server_url } from "../config";
// axios.defaults.baseURL = "http://localhost:3210";
axios.defaults.baseURL = server_url;

const ClientRoutes = () => {
  const user = useSelector((state) => state.user.currentUser);
  axios.defaults.headers.authorization = user && user.token;
  return (
    <div>
      <Switch>
        <Route
          path="/login"
          render={(props) =>
            user ? <Redirect to="/main/sites" /> : <LoginRoutes {...props} />
          }
        />
        <Route
          path="/main"
          render={(props) =>
            user ? <MainRoutes {...props} /> : <Redirect to="/login" />
          }
        />

        <Route
          exact
          path={`/restore_password/:token`}
          render={() =>
            user ? <Redirect to="/main/sites" /> : <ResetPasswordPage />
          }
        />

        <Route
          path="/"
          render={() => {
            return <Redirect to="/login" />;
          }}
        />
      </Switch>
    </div>
  );
};

export default ClientRoutes;
