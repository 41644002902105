import VariablesActionTypes from "./variables.types";

const INITIAL_STATE = {
  isFetching: false,
  error: null,
  variables: null,
};

const variablesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VariablesActionTypes.GET_VARIABLES_START:
      return { ...state, isFetching: true, error: null };

    case VariablesActionTypes.GET_VARIALBES_SUCCESS:
      return { ...state, isFetching: false, variables: action.payload };

    case VariablesActionTypes.GET_VARIABLES_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    default:
      return state;
  }
};

export default variablesReducer;
