import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getWorksStart,
  getGroupsStart,
} from "../../../redux/works/works.actions";
import { useHistory } from "react-router-dom";
import { ReactComponent as Triangle } from "../../../assets/triangle.svg";
import LinkButton from "../../buttons/link-button";
import { useGroupsListStyles } from "../../../css/sites/works.styles";
import WorkGroupsContext from "../../../contexts/work-groups.context";
import InlineModal from "../../inlineModal";
import GroupInfo from "./group-info";

const GroupsList = ({ job_group_id, handleAddWork, site_id, workSelector }) => {
  const classes = useGroupsListStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);

  const groupsList = useSelector((state) => state.works.groups);
  const worksList = useSelector((state) => state.works.works);
  const fetchingWorks = useSelector((state) => state.works.fetchingWorks);

  const { openWorkGroups, toggleOpenWorkGroup } = useContext(WorkGroupsContext);

  useEffect(() => {
    if (!groupsList) {
      dispatch(getGroupsStart(currentUser, site_id));
    }
  }, [groupsList, currentUser, dispatch, site_id]);

  useEffect(() => {
    if (!worksList[job_group_id]) {
      dispatch(getWorksStart(job_group_id, currentUser.user, site_id));
    }
  }, [worksList, job_group_id, currentUser, site_id, dispatch]);

  const history = useHistory();

  const handleClick = (work) => {
    if (workSelector) return workSelector(work);
    return history.push(`/main/sites/${site_id}/works/${work.id}`);
  };

  const [groupToEdit, showGroupEditWindow] = useState();

  return (
    <>
      {groupToEdit && (
        <InlineModal close={() => showGroupEditWindow(null)}>
          <GroupInfo
            group_id={groupToEdit}
            close={() => showGroupEditWindow(null)}
          />
        </InlineModal>
      )}

      {groupsList &&
        groupsList
          .filter((group) => group.job_group_id === job_group_id)
          .map((group) => (
            <div
              key={group.id}
              className={job_group_id ? classes.subgroup : classes.group}
            >
              <div className={classes.groupContent}>
                <div
                  className={`${classes.groupIcon} ${
                    openWorkGroups.includes(group.id) ? classes.open : ""
                  }`}
                >
                  <Triangle onClick={() => toggleOpenWorkGroup(group.id)} />
                </div>
                <div
                  className={classes.groupName}
                  onClick={() => toggleOpenWorkGroup(group.id)}
                >
                  {group.name}
                </div>
                <div className={classes.addGroupBtn}>
                  {!workSelector && (
                    <>
                      <LinkButton onClick={() => showGroupEditWindow(group.id)}>
                        открыть
                      </LinkButton>
                      <LinkButton onClick={() => handleAddWork(group.id)}>
                        добавить
                      </LinkButton>
                    </>
                  )}
                </div>
              </div>
              {openWorkGroups.includes(group.id) && (
                <GroupsList
                  job_group_id={group.id}
                  handleAddWork={handleAddWork}
                  site_id={site_id}
                  workSelector={workSelector}
                />
              )}
            </div>
          ))}

      {fetchingWorks.includes(job_group_id) && (
        <div className={job_group_id ? classes.worksInsideGroup : ""}>
          загрузка...
        </div>
      )}

      {worksList[job_group_id] && (
        <div className={job_group_id ? classes.worksInsideGroup : ""}>
          {worksList[job_group_id]
            .filter((work) => (workSelector ? !work.user_id : work))
            .map((work) => {
              return (
                <div className={classes.work} key={work.id}>
                  <LinkButton type="button" onClick={() => handleClick(work)}>
                    {work.name}
                  </LinkButton>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default GroupsList;
