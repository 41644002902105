import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { SelectInput } from "../../input-forms";
import { getWorksStart } from "../../../redux/works/works.actions";

const OtherWorks = ({
  setAfterThisWorkDate,
  prev_job_id,
  setWorkData,
  group_id,
}) => {
  const { site_id } = useParams();
  const works = useSelector((state) => state.works.works);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [options, setOptions] = useState({});
  const dispatch = useDispatch();
  // const [defaultOption, setDefaultOption] = useState();

  useEffect(() => {
    if (!works[group_id]) {
      dispatch(getWorksStart(group_id, currentUser, site_id));
    } else {
      const options = works[group_id].reduce((acc, work) => {
        acc[work.id] = work.name;
        return acc;
      }, {});
      setOptions(options);
    }
  }, [group_id, works, currentUser, site_id, dispatch]);

  const handleChange = (e) => {
    const selectedWork = works[group_id].find(
      (work) => String(work.id) === e.target.value
    );
    const today = new Date();
    const end_date = new Date(selectedWork.date_end);
    if (today > end_date) setAfterThisWorkDate(today);
    else setAfterThisWorkDate(end_date);
    // setDefaultOption(e.target.value);
    setWorkData((w) => ({ ...w, prev_job_id: e.target.value }));
  };

  return (
    <div className="field appear">
      <SelectInput
        description="Выберите другую работу"
        emptyMessage="Нет добавленных работ"
        options={options}
        onChange={handleChange}
        defaultOption={prev_job_id}
      />
    </div>
  );
};

export default OtherWorks;
