const ActsActionTypes = {
  ADD_ACT_START: "ADD_ACT_START",
  ADD_ACT_SUCCESS: "ADD_ACT_SUCCESS",
  ADD_ACT_FAILURE: "ADD_ACT_FAILURE",
  GET_ACTS_START: "GET_ACTS_START",
  GET_ACTS_SUCCESS: "GET_ACTS_SUCCESS",
  GET_ACTS_FAILURE: "GET_ACTS_FAILURE",
  DELETE_ACT_START: "DELETE_ACT_START",
  DELETE_ACT_SUCCESS: "DELETE_ACT_SUCCESS",
  DELETE_ACT_FAILURE: "DELETE_ACT_FAILURE",
  EDIT_ACT_START: "EDIT_ACT_START",
  EDIT_ACT_SUCCESS: "EDIT_ACT_SUCCESS",
  EDIT_ACT_FAILURE: "EDIT_ACT_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default ActsActionTypes;
