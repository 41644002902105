import React from "react";
import { SelectInput } from "../../input-forms";
import { useSelector } from "react-redux";

const PreviousRevisions = ({
  revision_id,
  handleChange,
  subsectionId,
  noAttachedRevisions,
}) => {
  const statuses = useSelector((state) => state.statuses.revisions);
  const revisions = useSelector((state) => state.revisions.revisions);
  let filteredRevisions = revisions.filter(
    (revision) => statuses && statuses[revision.status_id] !== "Пройдена"
  );
  if (subsectionId) {
    filteredRevisions = filteredRevisions.filter(
      (revision) => revision.subsection_id === subsectionId
    );
  }
  if (noAttachedRevisions) {
    filteredRevisions = filteredRevisions.filter(
      (revision) =>
        statuses &&
        statuses[revision.status_id] !== "Не пройдена - создано предписание"
    );
  }
  const revisionOptions = filteredRevisions.reduce((acc, curr) => {
    acc[curr.id] = curr.title;
    return acc;
  }, {});

  return (
    <SelectInput
      name="revision_id"
      defaultOption={revision_id}
      onChange={handleChange}
      options={revisionOptions}
      label="Список проверок"
      emptyMessage="Нет доступных проверок"
      description="Выбрать прошлую проверку"
    />
  );
};

export default PreviousRevisions;
