import TechCardsActionsTypes from "./techCards.types";

const INITIAL_STATE = {
  isFetching: false,
  error: null,
  techCards: null,
  techCardDetails: null,
};

const techCardsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TechCardsActionsTypes.GET_TECH_CARDS_START:
    case TechCardsActionsTypes.GET_TECH_CARD_DETAILS_START:
      return { ...state, isFetching: true, error: null };

    case TechCardsActionsTypes.GET_TECH_CARDS_SUCCESS:
      return { ...state, isFetching: false, techCards: action.payload };

    case TechCardsActionsTypes.GET_TECH_CARD_DETAILS_SUCCESS:
      if (action.payload) {
        return { ...state, isFetching: false, techCardDetails: action.payload };
      }
      return { ...state, isFetching: false };

    case TechCardsActionsTypes.GET_TECH_CARDS_FAILURE:
    case TechCardsActionsTypes.GET_TECH_CARD_DETAILS_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case TechCardsActionsTypes.CLEAR_TECH_CARD_DETAILS:
      return { ...state, techCardDetails: null };

    default:
      return state;
  }
};

export default techCardsReducer;
