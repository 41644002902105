const MaterialsActionTypes = {
  ADD_MATERIAL_UNIT_START: "ADD_MATERIAL_UNIT_START",
  ADD_MATERIAL_UNIT_SUCCESS: "ADD_MATERIAL_UNIT_SUCCESS",
  ADD_MATERIAL_UNIT_FAILURE: "ADD_MATERIAL_UNIT_FAILURE",
  GET_MATERIAL_UNITS_START: "GET_MATERIAL_UNITS_START",
  GET_MATERIAL_UNITS_SUCCESS: "GET_MATERIAL_UNITS_SUCCESS",
  GET_MATERIAL_UNITS_FAILURE: "GET_MATERIAL_UNITS_FAILURE",
  DELETE_MATERIAL_UNIT_START: "DELETE_MATERIAL_UNIT_START",
  DELETE_MATERIAL_UNIT_SUCCESS: "DELETE_MATERIAL_UNIT_SUCCESS",
  DELETE_MATERIAL_UNIT_FAILURE: "DELETE_MATERIAL_UNIT_FAILURE",
  EDIT_MATERIAL_UNIT_START: "EDIT_MATERIAL_UNIT_START",
  EDIT_MATERIAL_UNIT_SUCCESS: "EDIT_MATERIAL_UNIT_SUCCESS",
  EDIT_MATERIAL_UNIT_FAILURE: "EDIT_MATERIAL_UNIT_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default MaterialsActionTypes;
