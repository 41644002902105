import React, { useState, useEffect } from "react";
import { PasswordInput } from "../../components/input-forms";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordStart,
  clearErrors,
} from "../../redux/user/user.actions";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const [newCredentials, setNewCredentials] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [oldPasswordMsg, setOldPasswordMsg] = useState({
    message: "",
    error: false,
  });

  const [passwordLengthMsg, setPasswordLengthMsg] = useState({
    message: "",
    error: false,
  });

  const [passwordMatchMsg, setPasswordMatchMsg] = useState({
    message: "",
    error: false,
  });

  const isFetching = useSelector((state) => state.user.isFetching);
  const requestErrorMessage = useSelector((state) => state.user.error);
  const [changed, setChanged] = useState();

  useEffect(() => {
    if (newCredentials.oldPassword && oldPasswordMsg.error) {
      setOldPasswordMsg({ message: "", error: false });
    }
  }, [newCredentials.oldPassword, oldPasswordMsg]);

  useEffect(() => {
    if (newCredentials.newPassword) {
      if (newCredentials.newPassword.length < 6) {
        if (!passwordLengthMsg.error) {
          setPasswordLengthMsg({
            message: "Длина пароля должна быть не менее 6 симоволов",
            error: true,
          });
        }
      } else if (
        newCredentials.newPassword === newCredentials.confirmPassword
      ) {
        setPasswordLengthMsg({
          message: "Пароль принят",
          error: false,
        });
      } else if (passwordLengthMsg.error) {
        setPasswordLengthMsg({
          message: "",
          error: false,
        });
      }
    }
  }, [newCredentials, passwordLengthMsg.error, passwordLengthMsg.message]);

  useEffect(() => {
    if (!newCredentials.confirmPassword) return;
    if (newCredentials.confirmPassword !== newCredentials.newPassword) {
      setPasswordMatchMsg({
        message: "Пароль и подтверждение не совпадают",
        error: true,
      });
    } else {
      if (passwordMatchMsg.message) {
        setPasswordMatchMsg({
          message: "",
          error: false,
        });
      }
    }
  }, [
    newCredentials.confirmPassword,
    newCredentials.newPassword,
    passwordMatchMsg.message,
  ]);

  const handleChange = (e) => {
    if (requestErrorMessage) dispatch(clearErrors());
    setNewCredentials({ ...newCredentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordMatchMsg.error || passwordLengthMsg.error) return;

    if (!newCredentials.oldPassword) {
      return setOldPasswordMsg({
        message: "Введите текущий пароль",
        error: true,
      });
    }
    if (!newCredentials.newPassword) {
      return setPasswordLengthMsg({
        message: "Введите новый пароль (не менее 6 символов)",
        error: true,
      });
    }
    if (!newCredentials.confirmPassword) {
      return setPasswordMatchMsg({
        message: "Введите подтверждение пароля",
        error: true,
      });
    }

    const callback = () => setChanged(true);

    setPasswordLengthMsg({
      message: "",
      error: false,
    });
    const { oldPassword, newPassword } = newCredentials;

    if (oldPassword && newPassword) {
      dispatch(changePasswordStart(oldPassword, newPassword, callback));
    }
  };
  console.log(passwordMatchMsg);
  return (
    <>
      <div className="header2">
        <ul>
          <li className="hdr">Сменить пароль</li>
        </ul>
      </div>

      <div className="content">
        {changed ? (
          <div className="change_password">Пароль успешно изменен</div>
        ) : (
          <form className="change_password" onSubmit={handleSubmit}>
            <div
              className={`info ${
                oldPasswordMsg.error ||
                passwordMatchMsg.error ||
                passwordLengthMsg.error ||
                requestErrorMessage
                  ? "err"
                  : ""
              }`}
            >
              {requestErrorMessage ||
                oldPasswordMsg.message ||
                passwordMatchMsg.message ||
                passwordLengthMsg.message}
            </div>

            <PasswordInput
              label="Старый пароль"
              name="oldPassword"
              onChange={handleChange}
              value={newCredentials.oldPassword}
              validationType="required"
            />

            <hr />

            <PasswordInput
              label="Новый пароль"
              value={newCredentials.newPassword}
              onChange={handleChange}
              name="newPassword"
              validationType="required"
            />

            <PasswordInput
              label="Подтверждение"
              value={newCredentials.confirmPassword}
              onChange={handleChange}
              name="confirmPassword"
              validationType="required"
            />
            <div className="button">
              <button disabled={isFetching}>
                {isFetching ? "Загрузка..." : "Сменить пароль"}
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ChangePassword;
