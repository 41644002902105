import ClaimsActionTypes from "./claims.types";

const INITIAL_STATE = {
  isFetching: false,
  error: null,
  claims: [],
};

const claimsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClaimsActionTypes.ADD_CLAIM_START:
    case ClaimsActionTypes.EDIT_CLAIM_START:
    case ClaimsActionTypes.GET_CLAIMS_START:
    case ClaimsActionTypes.DELETE_CLAIM_START:
    case ClaimsActionTypes.RECLAIM_START:
    case ClaimsActionTypes.MARK_CLAIM_CLOSE_START:
      return { ...state, isFetching: true, error: null };

    case ClaimsActionTypes.ADD_CLAIM_SUCCESS:
    case ClaimsActionTypes.EDIT_CLAIM_SUCCESS:
    case ClaimsActionTypes.DELETE_CLAIM_SUCCESS:
    case ClaimsActionTypes.RECLAIM_SUCCESS:
    case ClaimsActionTypes.MARK_CLAIM_CLOSE_SUCCESS:
      return { ...state, isFetching: false, error: null };

    case ClaimsActionTypes.GET_CLAIMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        claims: action.payload,
      };

    case ClaimsActionTypes.ADD_CLAIM_FAILURE:
    case ClaimsActionTypes.GET_CLAIMS_FAILURE:
    case ClaimsActionTypes.DELETE_CLAIM_FAILURE:
    case ClaimsActionTypes.RECLAIM_FAILURE:
    case ClaimsActionTypes.MARK_CLAIM_CLOSE_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case ClaimsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default claimsReducer;
