import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { SelectInput } from "../input-forms";
import UserRow from "../manage-users/user-row";

const Team = ({ team, setTeam, saveInputs }) => {
  const [selectedMember, setSelectedMember] = useState("");
  const users = useSelector((state) => state.user.usersList);
  const [userOptions, setUserOptions] = useState({});
  const { site_id, menu } = useParams();

  const basePath = useRef();

  useEffect(() => {
    if (site_id) {
      basePath.current = `/main/sites/${site_id}/${menu}/edit_site`;
    } else {
      basePath.current = "/main/sites";
    }
  }, [site_id, menu]);

  // Transform unselected users into select options object every time a team array changes
  useEffect(() => {
    setUserOptions(
      users.reduce((userOptions, user) => {
        if (!team.includes(user.id)) {
          userOptions[
            user.id
          ] = `${user.firstname} ${user.lastname} (${user.email})`;
        }
        return userOptions;
      }, {})
    );
  }, [team, users]);

  // Every time the select options object changes,
  // this function makes sure that the first option is selected in state
  useEffect(() => {
    if (userOptions) {
      setSelectedMember(Object.keys(userOptions)[0]);
    }
  }, [userOptions]);

  const addMember = (e) => {
    setTeam([...team, Number(selectedMember)]);
  };

  const deleteMember = (memberId) => {
    setTeam([...team.filter((id) => id !== memberId)]);
  };
  return (
    <>
      <div className="hdr">Команда, работающая на объекте</div>
      <table className="list">
        <tbody>
          {team.map((memberId) => {
            const member = users.find((user) => user.id === memberId);
            if (member)
              return (
                <tr key={member.id}>
                  <UserRow
                    user={member}
                    saveInputs={saveInputs}
                    basePath={basePath.current}
                  />
                  {setTeam && (
                    <td className="control">
                      <button
                        onClick={() => deleteMember(member.id)}
                        type="button"
                        className="remove"
                      />
                    </td>
                  )}
                </tr>
              );
            return null;
          })}
        </tbody>
      </table>
      {setTeam && (
        <div>
          <SelectInput
            options={userOptions}
            defaultOption={selectedMember}
            onChange={(e) => setSelectedMember(e.target.value)}
          />
          <div className="buttons in_form">
            <button type="button" className="blue" onClick={addMember}>
              добавить контакт
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Team;
