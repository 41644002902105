import { createUseStyles } from "react-jss";

export const useWorksAndGroupsSectionStyles = createUseStyles({
  main: {
    margin: "2vmin",
  },
  addBtn: {
    "& button": {
      width: "175px",
    },
  },
  myWorksBtn: {
    margin: "3vh 0 0",
  },
});

export const useGroupsListStyles = createUseStyles({
  group: {
    padding: "4vh 0 0",
    fontSize: "12px",
  },
  subgroup: {
    padding: "2vh 0 0vh 2vw",
  },
  worksInsideGroup: {
    padding: "0vh 0 0vh 2vw",
  },
  groupContent: {
    display: "flex",
    alignItems: "center",
  },
  groupName: {
    fontWeight: 700,
    cursor: "pointer",
  },
  addGroupBtn: {
    "& >*": {
      marginLeft: "1vw",
    },
  },
  groupIcon: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      cursor: "pointer",
      fill: "#c4c4c4",
      height: "10px",
      width: "10px",
      marginRight: "1vw",
      transition: "transform 0.2s",
    },
  },
  open: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  work: {
    margin: "2vh 0",
    "& a, a:visited": {
      color: "black",
    },
    "& button": {
      color: "black",
    },
  },
});

export const useWorkTypeStyles = createUseStyles({
  container: {
    maxWidth: "100%",
    width: "700px",
    // minHeight: "90vh",
    height: "auto",
    // margin: "0 2vw",
    // fontSize: "12px",
  },
  main: {
    // margin: "0 2vw",
    fontSize: "12px",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      marginTop: 0,
      marginLeft: "2vw",
      paddingLeft: "5px",
    },
  },

  workType: {
    margin: "5vh 2vw",
    "& label": {
      display: "inline-block",
      margin: "0 5vw 0 0.5vw",
      width: "90px",
    },
  },
});

export const useAddWorksStyles = createUseStyles({
  main: {
    fontSize: "12px",
    width: "700px",
    maxWidth: "100%",
  },
  form: {
    padding: "0 2vw",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      margin: "0 0 2vh 5px",
    },
  },
  actionBtns: {
    margin: "4vh 15vw",
    display: "flex",
    justifyContent: "space-between",
  },
  otherWorks: {
    margin: "2vh 0",
    paddingLeft: "5px",
  },
  dates: {
    margin: "3vh 0",
    display: "flex",
  },
  calendar: {
    marginRight: "3vw",
  },
  calendarLabel: {
    paddingLeft: "5px",
  },
  planSection: {
    margin: "2vh 0",
    paddingLeft: "5px",
  },
  performer: {
    paddingLeft: "5px",
  },
  performerType: {
    margin: "1vh 0",
    "& label": {
      display: "inline-block",
      margin: "0 5vw 0 0.5vw",
      width: "90px",
    },
  },
  contractor: {
    margin: "3vh 0",
  },
  pickUser: {
    margin: "3vh 0",
  },
  variable: {
    display: "flex",
    alignItems: "center",
    margin: "2vh 0",
    "& span": {
      width: "20vw",
    },
    "& input": {
      marginLeft: "1vw",
    },
  },
});

export const useSelectTechCardStyles = createUseStyles({
  component: {
    position: "relative",
    margin: "3vh 0",
  },
  // label: {
  //   paddingLeft: "5px",
  // },
  cardSelect: {
    backgroundColor: "transparent",
    border: "1px solid #a9a9a9",
    width: "100%",
    padding: "8px 5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& svg": {
      height: "10px",
    },
  },
  selectDropdown: {
    position: "absolute",
    backgroundColor: "white",
    border: "1px solid #a9a9a9",
    height: "auto",
    width: "100%",
    boxSizing: "border-box",
    zIndex: 7,
    marginTop: "-30px",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    padding: "1vh 1vw",
    cursor: "pointer",
    "& svg": {
      height: "20px",
      marginRight: "1vw",
    },
    "&:hover": {
      backgroundColor: "#e9e9e9",
    },
  },
  cardIcon: {
    fill: "blue",
  },

  variable: {
    display: "flex",
    alignItems: "center",
    margin: "2vh 0",
    "& span": {
      width: "20vw",
    },
    "& input": {
      marginLeft: "1vw",
    },
  },
  "@media (max-width: 767.98px)": {
    selectDropdown: {
      marginTop: "-20px",
    },
  },
});

export const useSuppliesStyles = createUseStyles({
  section: {
    margin: "2vh 0",
  },
  header: {
    display: "flex",
    width: "100%",
  },
  calculationError: {
    width: "70%",
    justifyContent: "center",
    display: "flex",
    color: "red",
  },
  title: {
    fontWeight: 500,
    paddingLeft: "5px",
  },
  inputs: {
    margin: "2vh 0",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "center",
    gap: "5vmin",
  },
  addBtn: {
    height: "20px",
    "& button": {
      float: "right",
      minWidth: "100px",
    },
  },
  itemsGrid: {
    margin: "3vh 0",
    paddingLeft: "5px",
    display: "grid",
    gridTemplateColumns: "4.5fr 4.5fr 3fr",
    gap: "2vh 3vw",
  },
  materialsGrid: {
    margin: "1vh 0 3vh",
    paddingLeft: "5px",
    display: "grid",
    gridTemplateColumns: "3fr 3fr 3fr 3fr",
    gap: "2vh 2vw",
  },
  techcardElementsTable: {
    width: "100%",
    textAlign: "center",
    "& input": {
      width: "50px",
      margin: "auto",
      textAlign: "center",
    },
    "& th": {
      color: "#a9a9a9",
      fontWeight: 400,
    },
    // "& td": {
    //   border: "1px solid black",
    // },
  },
  userAddedRow: {
    "& td": {
      padding: "1vh 0",
    },
  },
  deleteBtn: {
    textAlign: "right",
  },
  emprtyRow: {
    height: 0,
  },
});

export const useWorkInfoStyles = createUseStyles({
  main: {
    margin: "2vh 1.5vw",
    fontSize: "12px",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      margin: "3vh 0 0",
    },
  },
  sections: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "0 1.5vw",
  },

  workInfo: {
    "& >*": {
      margin: "4vh 0",
    },
  },
  spanWithLeftMargin: {
    marginLeft: "2vw",
  },
  resourcesGrid: {
    marginTop: "1.5vh",
    paddingLeft: "5px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "1vh 1vw",
  },
  btns: {
    display: "flex",
    justifyContent: "space-around",
  },
  checkBtns: {
    display: "flex",
    justifyContent: "space-between",
    "& button": {
      width: "100px",
      padding: 0,
    },
  },
  actionsList: {
    "& >*": {
      margin: "2vh 0",
    },
  },
  workClaimDate: {
    color: "#a9a9a9",
  },
  workClaimTitle: {
    marginLeft: "1vw",
  },
  workClaimStatus: {
    float: "right",
    backgroundColor: "#c4c4c4",
    padding: "0 3px",
  },
  workClaimDescription: {
    margin: "1vh 0",
  },
});

export const useWorksListBySubAndUserStyles = createUseStyles({
  worksGrid: {
    marginTop: "2vh",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "2vh 0",
    "& a, a:visited": {
      color: "black",
    },
  },
  header: {
    color: "#a9a9a9",
  },
});

export const useAddWorkClaimStyles = createUseStyles({
  container: {
    width: "700px",
    maxWidth: "100%",
  },
  main: {
    margin: "0 2vw",
    fontSize: "12px",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      margin: 0,
      textAlign: "center",
    },
    "& >*": {
      marginBottom: "3vh",
    },
  },
  deadline: {
    paddingLeft: "5px",
  },
  formBtns: {
    display: "flex",
    justifyContent: "space-around",
    margin: "2vh 6vw",
  },
});

export const useAllWorksSelector = createUseStyles({
  selector: {
    fontSize: "12px",
    position: "relative",
  },
  title: {
    paddingLeft: "5px",
  },
  menu: {
    padding: "10px 5px",
    border: "1px solid #a9a9a9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },

  selectorIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fill: "#a9a9a9",
      height: "12px",
      width: "12px",
      transition: "transform 0.1s",
    },
  },
  open: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  dropdownSection: {
    border: "1px solid #a9a9a9",
    position: "absolute",
    zIndex: 20,
    backgroundColor: "white",
    height: "60vh",
    width: "100%",
    boxSizing: "border-box",
    padding: "0 5px",
    overflow: "auto",
  },
});

export const useGroupInfoStyles = createUseStyles({
  main: {
    padding: "0 1vw",
    width: "30vw",
    fontSize: "12px",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      margin: 0,
      textAlign: "center",
      marginBottom: "4vh",
    },
  },
  groupName: {
    textAlign: "center",
  },
  actionBtns: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "4vh",
    marginBottom: "2vh",
  },
  deleteForm: {
    width: "30vw",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      margin: 0,
      textAlign: "center",
    },
  },
  deleteBtns: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "6vh",
    marginBottom: "2vh",
    "& button": {
      minWidth: "60px",
    },
  },
  errorMsg: {
    color: "red",
    textAlign: "center",
  },
});

export const useCheckWorkStyles = createUseStyles({
  container: {
    width: "700px",
    maxWidth: "95vh",
  },
  main: {
    padding: "0 3vw",
    fontSize: "12px",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      margin: "0 0 4vh",
      textAlign: "center",
    },
  },
  status: {
    display: "flex",
    justifyContent: "space-around",
    margin: "0 3vw",
    "& div": {
      display: "flex",
      alignItems: "center",
      "& label": {
        padding: "4px 10px 0",
        lineHeight: "30px",
      },
    },
  },
  workQuality: {
    "& >*": {
      margin: "4vh 0",
    },
    "& label": {
      margin: "0 1vw",
    },
  },
  actionBtns: {
    margin: "6vh 5vw 2vh",
    display: "flex",
    justifyContent: "space-around",
  },
});
