import React, { useEffect, useState } from "react";
import { useAllWorksSelector } from "../../../css/sites/works.styles";
import { ReactComponent as Triangle } from "../../../assets/triangle.svg";
import GroupsList from "./groups-list";
import { useParams } from "react-router";

const AllWorksSelector = ({ workToClaim, setWorkToClaim }) => {
  const classes = useAllWorksSelector();
  const { site_id } = useParams();
  const [isMenuOpen, toggleMenu] = useState(false);

  useEffect(() => {
    if (workToClaim) {
      toggleMenu(false);
    }
  }, [workToClaim]);

  return (
    <div className={classes.selector}>
      <div className={classes.title}> Работа</div>
      <div className={classes.menu} onClick={() => toggleMenu(!isMenuOpen)}>
        <span>{workToClaim ? workToClaim.name : "Выбрать работу"}</span>
        <span
          className={`${classes.selectorIcon} ${isMenuOpen && classes.open}`}
        >
          <Triangle />
        </span>
      </div>
      {isMenuOpen && (
        <div className={classes.dropdownSection}>
          <GroupsList
            job_group_id={null}
            site_id={site_id}
            workSelector={setWorkToClaim}
          />
        </div>
      )}
    </div>
  );
};

export default AllWorksSelector;
