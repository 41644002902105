import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import AddReport from "./add-report";
import InlineModal from "../../inlineModal";
import { useDispatch, useSelector } from "react-redux";
import { getReportsStart } from "../../../redux/reports/reports.actions";
import { user_photos } from "../../../config";
import { formatDate } from "../../../utils/normalizeData";
import ReportInfo from "./report-info";

const ReportsList = ({ menu }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { site_id, info } = useParams();
  const currentUser = useSelector((state) => state.user.currentUser);
  const users = useSelector((state) => state.user.usersList);

  const reports = useSelector((state) => state.reports.reports);
  const isFetching = useSelector((state) => state.reports.isFetching);
  const error = useSelector((state) => state.reports.error);

  useEffect(() => {
    if (!reports) {
      dispatch(getReportsStart(currentUser.user, site_id));
    }
  }, [reports, currentUser.user, dispatch, site_id]);

  const [addReportWindow, showAddReportWindow] = useState();
  const [currentReport, setCurrentReport] = useState();

  useEffect(() => {
    if (info && reports && !currentReport) {
      const currentReport = reports.find((report) => report.id === +info);
      if (currentReport) setCurrentReport(currentReport);
    } else if (!info && currentReport) {
      setCurrentReport(null);
    }
  }, [info, reports, currentReport]);

  const basePath = `/main/sites/${site_id}/reports`;

  return (
    <div className="content">
      <div className="buttons top">
        <button className="blue" onClick={() => showAddReportWindow(true)}>
          Написать отчет
        </button>
      </div>

      {isFetching && <div className="info">Загрузка...</div>}
      {error && <div className="info err">{error}</div>}

      {reports && reports.length ? (
        <table className="list">
          <thead>
            <tr>
              <th>Пользователь</th>
              <th>Дата</th>
            </tr>
          </thead>

          <tbody>
            {reports.map((report, i) => {
              const user = users.find((user) => user.id === report.user_id);
              if (user) {
                return (
                  <tr key={i}>
                    <td>
                      <label className="mobile">Пользователь</label>
                      <Link
                        to={`${basePath}/${report.id}`} /*onClick={() => showUser(user.id)}*/
                      >
                        {user.img ? (
                          <img
                            className="avatar"
                            src={`${user_photos}/${user.org_id}/${user.img}`}
                            alt="avatar"
                          />
                        ) : (
                          <span className="avatar">
                            {`${user.firstname.charAt(0)}${user.lastname.charAt(
                              0
                            )}`}
                          </span>
                        )}
                        {`${user.firstname} ${user.lastname} ${
                          currentUser.user === user.id ? "(вы)" : ""
                        }`}
                      </Link>
                    </td>
                    <td>
                      <label className="mobile">Дата</label>
                      {formatDate(report.date, true)}
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}

      {addReportWindow && (
        <InlineModal close={() => showAddReportWindow(false)}>
          <AddReport close={() => showAddReportWindow(false)} />
        </InlineModal>
      )}

      {info && currentReport && (
        <InlineModal>
          <ReportInfo
            close={() => history.push(basePath)}
            reportId={info}
            currentReport={currentReport}
          />
        </InlineModal>
      )}
    </div>
  );
};

export default ReportsList;
