import ActsActionTypes from "./acts.types";

export const addActStart = (
  newActData,
  markerCoords,
  photos,
  currentUser,
  site_id,
  callback
) => ({
  type: ActsActionTypes.ADD_ACT_START,
  payload: { newActData, markerCoords, photos, currentUser, site_id },
  callback,
});

export const addActSuccess = () => ({
  type: ActsActionTypes.ADD_ACT_SUCCESS,
});

export const addActFailure = (error) => ({
  type: ActsActionTypes.ADD_ACT_FAILURE,
  payload: error,
});

export const getActsStart = (currentUser, site_id) => ({
  type: ActsActionTypes.GET_ACTS_START,
  payload: { currentUser, site_id },
});

export const getActsSuccess = (acts) => ({
  type: ActsActionTypes.GET_ACTS_SUCCESS,
  payload: acts,
});

export const getActsFailure = (error) => ({
  type: ActsActionTypes.GET_ACTS_FAILURE,
  payload: error,
});

export const editActStart = (act) => ({
  type: ActsActionTypes.EDIT_ACT_START,
  payload: act,
});

export const editActSuccess = () => ({
  type: ActsActionTypes.EDIT_ACT_SUCCESS,
});

export const editActFailure = (error) => ({
  type: ActsActionTypes.EDIT_ACT_FAILURE,
  payload: error,
});

export const deleteActStart = (act) => ({
  type: ActsActionTypes.DELETE_ACT_START,
  payload: act,
});

export const deleteActSuccess = () => ({
  type: ActsActionTypes.DELETE_ACT_SUCCESS,
});

export const deleteActFailure = (error) => ({
  type: ActsActionTypes.DELETE_ACT_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: ActsActionTypes.CLEAR_ERRORS,
});
