import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editTechCardStart,
  addTechCardStart,
  getCurrentTechCardStart,
} from "../../redux/techCards/techCards.actions";
import { useMainSectionStyles } from "../../../css/admin/main-section.styles";
import { FormInput } from "../../../components/input-forms";

import ViewCard from "./ViewCard";
import TechCardsList from "./TechCardsList";
import VariablesMultiSelect from "../variables/VarialbesMultiselect";
import AddSpecialties from "./AddSpecialties";
import AddEquipment from "./AddEquipment";
import AddMaterials from "./AddMaterials";

const TechCards = () => {
  const classes = useMainSectionStyles();
  const dispatch = useDispatch();
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const errorMessage = useSelector((state) => state.techCards.error);
  const isAdding = useSelector((state) => state.techCards.isAdding);
  const currentCard = useSelector((state) => state.techCards.currentCard);

  const [cardIdToEdit, setCardIdToEdit] = useState();
  const [cardIdToView, setCardIdToView] = useState();
  const [techCardName, setTechCardName] = useState("");
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const groupIdForCard = useRef();
  const oldEditCardGroupId = useRef(null);

  const clearInputs = () => {
    setTechCardName("");
    setSelectedVariables([]);
    setSelectedSpecialties([]);
    setSelectedEquipment([]);
    setSelectedMaterials([]);
  };

  useEffect(() => {
    if (cardIdToEdit) {
      if (!currentCard || currentCard.id !== cardIdToEdit) {
        dispatch(getCurrentTechCardStart(cardIdToEdit, currentAdmin.adminId));
      } else {
        setTechCardName(currentCard.name);
        const variableIds = [];
        for (const i in currentCard.card_variables) {
          variableIds.push(currentCard.card_variables[i].variable_id);
        }
        setSelectedVariables(variableIds);
        const specialties = [];
        for (const i in currentCard.card_employees) {
          specialties.push({
            employee_id: currentCard.card_employees[i].employee_id,
            man_hour: currentCard.card_employees[i].man_hour,
            min_qty: currentCard.card_employees[i].min_qty,
            variable_qty: currentCard.card_employees[i].variable_qty,
          });
        }
        setSelectedSpecialties(specialties);
        const equipment = [];
        for (const i in currentCard.card_equipment) {
          equipment.push({
            equipment_id: currentCard.card_equipment[i].equipment_id,
            qty: currentCard.card_equipment[i].qty,
          });
        }
        setSelectedEquipment(equipment);
        const materials = [];
        for (const i in currentCard.card_materials) {
          materials.push({
            material_id: currentCard.card_materials[i].material_id,
            qty: currentCard.card_materials[i].qty,
          });
        }
        setSelectedMaterials(materials);
        oldEditCardGroupId.current = currentCard.card_group_id;
      }
    } else {
      clearInputs();
    }
  }, [cardIdToEdit, currentCard, currentAdmin, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (techCardName) {
      let callback = clearInputs;
      let action;
      let cardId;
      if (cardIdToEdit) {
        action = editTechCardStart;
        callback = () => {
          setCardIdToView(currentCard.id);
          setCardIdToEdit(null);
        };
        cardId = currentCard.id;
      } else {
        action = addTechCardStart;
      }

      dispatch(
        action(
          {
            techCardName,
            techCardGroupId: groupIdForCard.current,
            selectedVariables,
            selectedSpecialties,
            selectedMaterials,
            selectedEquipment,
          },
          currentAdmin.adminId,
          callback,
          cardId,
          oldEditCardGroupId.current
        )
      );
    }
  };

  const cancelEdit = () => {
    setCardIdToEdit(null);
    clearInputs();
  };

  const focusOnName = () => {
    const nameInput = document.getElementById("name");
    if (nameInput) {
      nameInput.focus();
    }
  };

  return (
    <div className={classes.main}>
      <TechCardsList
        groupIdForCard={groupIdForCard}
        cardIdToEdit={cardIdToEdit}
        setCardIdToEdit={setCardIdToEdit}
        cardIdToView={cardIdToView}
        setCardIdToView={setCardIdToView}
        focusOnName={focusOnName}
      />

      {cardIdToView ? (
        <ViewCard
          cardIdToView={cardIdToView}
          setCardIdToView={setCardIdToView}
          cardIdToEdit={cardIdToEdit}
          setCardIdToEdit={setCardIdToEdit}
        />
      ) : (
        <form className={classes.addSection} onSubmit={handleSubmit}>
          <FormInput
            label="Название"
            id="name"
            value={techCardName}
            onChange={(e) => setTechCardName(e.target.value)}
          />
          <VariablesMultiSelect
            selectedVariables={selectedVariables}
            setSelectedVariables={setSelectedVariables}
          />

          <div>
            <strong>Обязательные переменные: </strong>
            <ul>
              <li>DH - Рабочих часов в дне</li>
            </ul>
          </div>

          <AddSpecialties
            selectedSpecialties={selectedSpecialties}
            setSelectedSpecialties={setSelectedSpecialties}
          />
          <AddEquipment
            selectedEquipment={selectedEquipment}
            setSelectedEquipment={setSelectedEquipment}
          />
          <AddMaterials
            selectedMaterials={selectedMaterials}
            setSelectedMaterials={setSelectedMaterials}
          />

          <div className="buttons">
            <button
              className="green"
              submitstyle="true"
              disabled={!techCardName || isAdding}
            >
              Сохранить
            </button>
            {cardIdToEdit && (
              <button
                className="cancel"
                disabled={isAdding}
                type="button"
                onClick={cancelEdit}
              >
                Отмена
              </button>
            )}
          </div>
          <div className={classes.errorMsg}>{errorMessage}</div>
        </form>
      )}
    </div>
  );
};

export default TechCards;
