import React, { useRef, cloneElement, useState } from "react";
import ReactDOM from "react-dom";

const Modal = ({ children, close }) => {
  const checkInputs = useRef();

  const [promptCloseDialog, setPromptCloseDialog] = useState();

  const checkInputsAndClose = () => {
    if (typeof checkInputs.current === "function" && checkInputs.current()) {
      if (!promptCloseDialog) return setPromptCloseDialog(true);
    }
    close();
  };

  return ReactDOM.createPortal(
    <div className="main">
      <div className="under_popup"></div>
      <div className="popup" onClick={(e) => e.stopPropagation()}>
        {cloneElement(children, { checkInputs, checkInputsAndClose })}

        {promptCloseDialog && (
          <Modal>
            <div className="popup_small">
              <div className="message">
                Вы начали заполнение.
                <br />
                Уверены, что хотите закрыть окно без сохранения?
              </div>
              <div className="buttons">
                <button className="blue" type="button" onClick={() => close()}>
                  Да
                </button>
                <button
                  className="cancel"
                  type="button"
                  onClick={() => setPromptCloseDialog(false)}
                >
                  Нет
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

export default Modal;
