import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginPage from "./login-page";
import RecoverPage from "./recover-page";

const LoginRoutes = ({ match }) => {
  const user = useSelector((state) => state.user.currentUser);
  return (
    <div className="login">
      <Route
        exact
        path={`${match.path}`}
        render={() => (user ? <Redirect to="/main/sites" /> : <LoginPage />)}
      />
      <Route
        exact
        path={`${match.path}/restore_by_email`}
        render={() => (user ? <Redirect to="/main/sites" /> : <RecoverPage />)}
      />
    </div>
  );
};

export default LoginRoutes;
