const PopupForPhoto = ({ src, close }) => {
  return (
    <div className="popup_photo">
      <button className="close" onClick={close}></button>
      <img src={src} alt="zoomed" />
    </div>
  );
};

export default PopupForPhoto;
