import { useRef } from "react";
// import Compress from "compress.js";

const PopupSelectDataUrlImg = ({
  close,
  filename,
  dataURLsArray,
  setFilesToUpload,
}) => {
  const dataURLsToAdd = useRef([]);

  const handleCheck = (e) => {
    const arr = dataURLsToAdd.current;

    if (e.target.checked) {
      arr.push(+e.target.value);
    } else {
      dataURLsToAdd.current = arr.filter(
        (dataIndex) => dataIndex !== +e.target.value
      );
    }
  };

  const urlToFile = async (dataURL, filename) => {
    const url = await fetch(dataURL);
    // console.log("DATA URL: ", url);
    const buffer = await url.arrayBuffer();
    return new File([buffer], filename, { type: "image/png" });
  };

  const addImages = async () => {
    const rawData = dataURLsToAdd.current.sort();
    const imgFilesArr = [];

    for (const i in rawData) {
      let imgFile = await urlToFile(
        dataURLsArray[rawData[i]],
        `${filename}_page_${rawData[i] + 1}`
      );
      imgFile = await Object.assign(imgFile, {
        preview: URL.createObjectURL(imgFile),
      });
      imgFilesArr.push(imgFile);
    }
    setFilesToUpload((files) => [...files, ...imgFilesArr]);
    close();
  };

  return (
    <div className="popup_medium">
      <button className="close" onClick={close} />
      <div className="hdr">{`${filename} (${dataURLsArray.length} страниц)`}</div>

      <div className="pdf_pages">
        {dataURLsArray.map((dataUrl, i) => (
          <div className="page" key={i}>
            <img src={dataUrl} alt="page" />
            <label>
              <input type="checkbox" value={i} onClick={handleCheck} />{" "}
              {`Страница ${i + 1}`}
            </label>
          </div>
        ))}
      </div>

      <div className="buttons">
        <button className="green" type="button" onClick={addImages}>
          Добавить
        </button>
        <button className="cancel" type="button" onClick={close}>
          Отмена
        </button>
      </div>
    </div>
  );
};

export default PopupSelectDataUrlImg;
