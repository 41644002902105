import AdminsActionTypes from "./admins.types";

export const signInStart = (emailAndPassword) => ({
  type: AdminsActionTypes.SIGN_IN_START,
  payload: emailAndPassword,
});

export const signInSuccess = (admin) => {
  return {
    type: AdminsActionTypes.SIGN_IN_SUCCESS,
    payload: admin,
  };
};

export const signInFailure = (error) => ({
  type: AdminsActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const signOut = () => ({
  type: AdminsActionTypes.SIGN_OUT_SUCCESS,
});

// export const getUsersStart = (currentUser) => ({
//   type: AdminsActionTypes.GET_USERS_START,
//   payload: currentUser,
// });

// export const getUsersSuccess = (usersArray) => ({
//   type: AdminsActionTypes.GET_USERS_SUCCESS,
//   payload: usersArray,
// });

// export const getUsersFailure = (error) => ({
//   type: AdminsActionTypes.GET_USERS_FAILURE,
//   payload: error,
// });

// export const addUserStart = (newUser, photo, admin, callback) => ({
//   type: AdminsActionTypes.ADD_USER_START,
//   payload: { newUser, photo, admin },
//   callback,
// });

// export const addUserSuccess = () => ({
//   type: AdminsActionTypes.ADD_USER_SUCCESS,
// });

// export const addUserFailure = (error) => ({
//   type: AdminsActionTypes.ADD_USER_FAILURE,
//   payload: error,
// });

// export const editUserStart = (
//   userNewDetails,
//   photo,
//   userId,
//   admin,
//   callback
// ) => ({
//   type: AdminsActionTypes.EDIT_USER_START,
//   payload: { userNewDetails, photo, admin, userId },
//   callback,
// });

// export const editUserSuccess = ({ admin, userId, userNewDetails }) => {
//   if (admin.id === userId) {
//     localStorage.setItem(
//       "user",
//       JSON.stringify({
//         user: admin.id,
//         organization: admin.organization,
//         token: admin.token,
//         admin: admin.admin,
//         firstname: userNewDetails.firstname,
//         lastname: userNewDetails.lastname,
//       })
//     );
//   }
//   return {
//     type: AdminsActionTypes.EDIT_USER_SUCCESS,
//   };
// };

// export const editUserFailure = () => ({
//   type: AdminsActionTypes.GET_USERS_FAILURE,
// });

// export const deleteUserStart = (userDeleteData, callback) => ({
//   type: AdminsActionTypes.DELETE_USER_START,
//   payload: userDeleteData,
//   callback,
// });

// export const deleteUserSuccess = () => ({
//   type: AdminsActionTypes.DELETE_USER_SUCCESS,
// });

// export const deleteUserFailure = (error) => ({
//   type: AdminsActionTypes.DELETE_USER_FAILURE,
//   payload: error,
// });

// export const clearErrors = () => ({
//   type: AdminsActionTypes.CLEAR_ERRORS,
// });

// export const recoverPasswordStart = (credentials) => ({
//   type: AdminsActionTypes.RECOVER_PASSWORD_START,
//   payload: credentials,
// });

// export const recoverPasswordSuccess = () => ({
//   type: AdminsActionTypes.RECOVER_PASSWORD_SUCCESS,
// });

// export const recoverPasswordFailure = (error) => ({
//   type: AdminsActionTypes.RECOVER_PASSWORD_FAILURE,
//   payload: error,
// });

// export const resetPasswordStart = (passwordDetails, callback) => ({
//   type: AdminsActionTypes.RESET_PASSWORD_START,
//   payload: passwordDetails,
//   callback,
// });

// export const resetPasswordSuccess = () => ({
//   type: AdminsActionTypes.RESET_PASSWORD_SUCCESS,
// });

// export const resetPasswordFailure = (error) => ({
//   type: AdminsActionTypes.RESET_PASSWORD_FAILURE,
//   payload: error,
// });
