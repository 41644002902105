import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSpecialtiesStart } from "../../redux/specialties/specialties.actions";
import { useAddTechCardItemsStyles } from "../../../css/admin/main-section.styles";
import { ControlledSelect, FormInput } from "../../../components/input-forms";

const AddSpecialties = ({ selectedSpecialties, setSelectedSpecialties }) => {
  const classes = useAddTechCardItemsStyles();
  const dispatch = useDispatch();

  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const specialties = useSelector((state) => state.specialties.specialties);
  const errorSpecialties = useSelector((state) => state.specialties.error);

  const [specialtiesOptions, setSpecialtiesOptions] = useState({});
  const [specialtyInputs, setSpecialtyInputs] = useState({
    employee_id: "",
    man_hour: "",
    min_qty: "",
    variable_qty: "",
  });

  const checkIfItemAdded = useCallback(
    (id) =>
      selectedSpecialties.some((spec) => {
        return spec.employee_id === id;
      }),
    [selectedSpecialties]
  );

  console.log("SELECTED SPECIALTIES: ", selectedSpecialties);

  useEffect(() => {
    if (!specialties) {
      dispatch(getSpecialtiesStart(currentAdmin.adminId));
    } else {
      setSpecialtiesOptions(
        specialties.reduce((options, specialty) => {
          if (!checkIfItemAdded(specialty.id)) {
            options[specialty.id] = `${specialty.specialty}`;
          }
          return options;
        }, {})
      );
    }
  }, [currentAdmin, dispatch, specialties, checkIfItemAdded]);

  const addSpecialty = (e) => {
    const isFilled = (obj) => {
      for (const i in obj) {
        if (obj[i]) return true;
      }
      return false;
    };

    if (isFilled(specialtyInputs)) {
      setSelectedSpecialties([...selectedSpecialties, specialtyInputs]);
      setSpecialtyInputs({
        employee_id: "",
        man_hour: "",
        min_qty: "",
        variable_qty: "",
      });
    }
  };

  const deleteSpecialty = (index) => {
    setSelectedSpecialties(
      selectedSpecialties.filter((spec, i) => i !== index)
    );
  };

  const handleChange = (e) => {
    let value;
    if (!e.target.value || isNaN(e.target.value)) value = e.target.value;
    else value = +e.target.value;
    setSpecialtyInputs({ ...specialtyInputs, [e.target.name]: value });
  };

  return (
    <div>
      <div>
        <b>Работники</b>
      </div>

      {specialties && selectedSpecialties.length ? (
        <table className={classes.itemsTable}>
          <thead>
            <tr>
              <th>специализация</th>
              <th>человекочасы</th>
              <th>мин кол-во человек</th>
              <th>переменная кол-вa работников</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {selectedSpecialties.map((specialtyRow, index) => {
              const specialty = specialties.find(
                (specialty) => +specialty.id === +specialtyRow.employee_id
              );
              if (specialty)
                return (
                  // <div key={specialty.id} className={classes.listItem}>
                  <tr key={index}>
                    <td>{specialty.specialty}</td>
                    <td>{specialtyRow.man_hour}</td>
                    <td>{specialtyRow.min_qty}</td>
                    <td>{specialtyRow.variable_qty}</td>
                    <td className={classes.alignRight}>
                      <button
                        onClick={() => deleteSpecialty(index)}
                        className={classes.delete}
                        type="button"
                      >
                        Удалить
                      </button>
                    </td>
                  </tr>
                  // </div>
                );
              return <tr key={index}></tr>;
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}

      <div className={classes.error}>{errorSpecialties}</div>
      <div className={classes.specialtiesInputs}>
        <span className={classes.specialtiesSelect}>
          <ControlledSelect
            options={specialtiesOptions}
            label="специализация"
            name="employee_id"
            description="выбрать специализацию"
            defaultOption={specialtyInputs.employee_id}
            onChange={handleChange}
          />
        </span>
        <FormInput
          label="человекочасы"
          placeholder="число или формула"
          name="man_hour"
          value={specialtyInputs.man_hour}
          onChange={handleChange}
        />
        <FormInput
          label="мин кол-во человек"
          placeholder="число или формула"
          name="min_qty"
          value={specialtyInputs.min_qty}
          onChange={handleChange}
        />
        <FormInput
          label="переменная кол-ва работников"
          placeholder="для формул"
          name="variable_qty"
          value={specialtyInputs.variable_qty}
          onChange={handleChange}
          maxLength="5"
        />
      </div>
      <div className="buttons top">
        <button
          className="blue"
          type="button"
          onClick={addSpecialty}
          disabled={!specialtyInputs.employee_id}
        >
          добавить
        </button>
      </div>
    </div>
  );
};

export default React.memo(AddSpecialties);
