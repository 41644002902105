import WorksActionTypes from "./works.types";

export const addGroupStart = (
  groupName,
  currentUser,
  site_id,
  callback,
  parentGroupId
) => ({
  type: WorksActionTypes.ADD_GROUP_START,
  payload: { groupName, currentUser, parentGroupId, site_id },
  callback,
});

export const addGroupSuccess = () => ({
  type: WorksActionTypes.ADD_GROUP_SUCCESS,
});

export const addGroupFailure = (error) => ({
  type: WorksActionTypes.ADD_WORK_FAILURE,
  payload: error,
});

export const getGroupsStart = (currentUser, site_id) => ({
  type: WorksActionTypes.GET_GROUPS_START,
  payload: { currentUser, site_id },
});

export const getGroupsSuccess = (groupsData) => ({
  type: WorksActionTypes.GET_GROUPS_SUCCESS,
  payload: groupsData,
});

export const getGroupsFailure = (error) => ({
  type: WorksActionTypes.GET_GROUPS_FAILURE,
  payload: error,
});

export const addWorkStart = (
  newWorkData,
  resources,
  variables,
  currentUser,
  site_id,
  callback,
  markerCoords
) => ({
  type: WorksActionTypes.ADD_WORK_START,
  payload: {
    newWorkData,
    variables,
    resources,
    currentUser,
    site_id,
    markerCoords,
  },
  callback,
});

export const addWorkSuccess = () => ({
  type: WorksActionTypes.ADD_WORK_SUCCESS,
});

export const addWorkFailure = (error) => ({
  type: WorksActionTypes.ADD_WORK_FAILURE,
  payload: error,
});

export const getWorksStart = (job_group_id, currentUser, site_id) => ({
  type: WorksActionTypes.GET_WORKS_START,
  payload: { job_group_id, currentUser, site_id },
});

export const getWorksSuccess = (job_group_id, worksArray) => ({
  type: WorksActionTypes.GET_WORKS_SUCCESS,
  payload: { job_group_id, worksArray },
});

export const getWorksFailure = (error) => ({
  type: WorksActionTypes.GET_WORKS_FAILURE,
  payload: error,
});

export const getWorksTreeStart = (user_id, site_id) => ({
  type: WorksActionTypes.GET_WORKS_TREE_START,
  payload: { user_id, site_id },
});

export const getWorksTreeSuccess = (worksTree) => ({
  type: WorksActionTypes.GET_WORKS_TREE_SUCCESS,
  payload: worksTree,
});

export const getWorksTreeFailure = (error) => ({
  type: WorksActionTypes.GET_WORKS_TREE_FAILURE,
  payload: error,
});

export const getWorksBySubStart = (sub_id, currentUserId) => ({
  type: WorksActionTypes.GET_WORKS_BY_SUB_START,
  payload: { sub_id, currentUserId },
});

export const getWorksBySubSuccess = (worksBySub) => ({
  type: WorksActionTypes.GET_WORKS_BY_SUB_SUCCESS,
  payload: worksBySub,
});

export const getWorksBySubFailure = (error) => ({
  type: WorksActionTypes.GET_WORKS_BY_SUB_FAILURE,
  payload: error,
});

export const getWorksByUserStart = (site_id, currentUserId) => ({
  type: WorksActionTypes.GET_WORKS_BY_USER_START,
  payload: { site_id, currentUserId },
});

export const getWorksByUserSuccess = (worksByUser) => ({
  type: WorksActionTypes.GET_WORKS_BY_USER_SUCCESS,
  payload: worksByUser,
});

export const getWorksByUserFailure = (error) => ({
  type: WorksActionTypes.GET_WORKS_BY_USER_FAILURE,
  payload: error,
});

export const getSingleWorkStart = (currentUserId, work_id) => ({
  type: WorksActionTypes.GET_SINGLE_WORK_START,
  payload: { currentUserId, work_id },
});

export const getSingleWorkSuccess = (work) => ({
  type: WorksActionTypes.GET_SINGLE_WORK_SUCCESS,
  payload: work,
});

export const getSingleWorkFailure = (error) => ({
  type: WorksActionTypes.GET_SINGLE_WORK_FAILURE,
  payload: error,
});

export const editGroupStart = (
  groupId,
  groupName,
  currentUser,
  site_id,
  callback
) => ({
  type: WorksActionTypes.EDIT_GROUP_START,
  payload: { groupId, groupName, currentUser, site_id },
  callback,
});

export const editGroupSuccess = () => ({
  type: WorksActionTypes.EDIT_GROUP_SUCCESS,
});

export const editGroupFailure = (error) => ({
  type: WorksActionTypes.EDIT_GROUP_FAILURE,
  payload: error,
});

export const editWorkStart = (
  newWorkData,
  resources,
  variables,
  currentUser,
  site_id,
  callback,
  markerCoords,
  coordsToDelete,
  work_id
) => ({
  type: WorksActionTypes.EDIT_WORK_START,
  payload: {
    newWorkData,
    variables,
    resources,
    currentUser,
    site_id,
    callback,
    work_id,
    markerCoords,
    coordsToDelete,
  },
});

export const editWorkSuccess = () => ({
  type: WorksActionTypes.EDIT_WORK_SUCCESS,
});

export const editWorkFailure = (error) => ({
  type: WorksActionTypes.EDIT_WORK_FAILURE,
  payload: error,
});

export const deleteWorkStart = (workData, callback) => ({
  type: WorksActionTypes.DELETE_WORK_START,
  payload: workData,
  callback,
});

export const deleteWorkSuccess = () => ({
  type: WorksActionTypes.DELETE_WORK_SUCCESS,
});

export const deleteWorkFailure = (error) => ({
  type: WorksActionTypes.DELETE_WORK_FAILURE,
  payload: error,
});

export const deleteGroupStart = (groupId, currentUser, site_id, callback) => ({
  type: WorksActionTypes.DELETE_GROUP_START,
  payload: { groupId, currentUser, site_id },
  callback,
});

export const deleteGroupSuccess = () => ({
  type: WorksActionTypes.DELETE_GROUP_SUCCESS,
});

export const deleteGroupFailure = (error) => ({
  type: WorksActionTypes.DELETE_GROUP_FAILURE,
  payload: error,
});

export const addWorkResultStart = (
  resultData,
  currentUser,
  photos,
  callback
) => ({
  type: WorksActionTypes.ADD_WORK_RESULT_START,
  payload: { resultData, currentUser, photos },
  callback,
});

export const addWorkResultSuccess = () => ({
  type: WorksActionTypes.ADD_WORK_RESULT_SUCCESS,
});

export const addWorkResultFailure = (error) => ({
  type: WorksActionTypes.ADD_WORK_RESULT_FAILURE,
  payload: error,
});

export const getWorkClaimsStart = (currentUserId, workId) => ({
  type: WorksActionTypes.GET_WORK_CLAIMS_START,
  payload: { currentUserId, workId },
});

export const getWorkClaimsSuccess = (workClaims) => ({
  type: WorksActionTypes.GET_WORK_CLAIMS_SUCCESS,
  payload: workClaims,
});

export const getWorkClaimsFailure = (error) => ({
  type: WorksActionTypes.GET_WORK_CLAIMS_FAILURE,
  payload: error,
});

export const getWorkRemarksStart = (currentUserId, workId) => ({
  type: WorksActionTypes.GET_WORK_REMARKS_START,
  payload: { currentUserId, workId },
});

export const getWorkRemarksSuccess = (workRemarks) => ({
  type: WorksActionTypes.GET_WORK_REMARKS_SUCCESS,
  payload: workRemarks,
});

export const getWorkRemarksFailure = (error) => ({
  type: WorksActionTypes.GET_WORK_REMARKS_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: WorksActionTypes.CLEAR_ERRORS,
});
