const SitesActionTypes = {
  ADD_SITE_START: "ADD_SITE_START",
  ADD_SITE_SUCCESS: "ADD_SITE_SUCCESS",
  ADD_SITE_FAILURE: "ADD_SITE_FAILURE",
  GET_SITES_START: "GET_SITES_START",
  GET_SITES_SUCCESS: "GET_SITES_SUCCESS",
  GET_SITES_FAILURE: "GET_SITES_FAILURE",
  DELETE_SITE_START: "DELETE_SITE_START",
  DELETE_SITE_SUCCESS: "DELETE_SITE_SUCCESS",
  DELETE_SITE_FAILURE: "DELETE_SITE_FAILURE",
  EDIT_SITE_START: "EDIT_SITE_START",
  EDIT_SITE_SUCCESS: "EDIT_SITE_SUCCESS",
  EDIT_SITE_FAILURE: "EDIT_SITE_FAILURE",
  SAVE_SITE_DETAILS: "SAVE_SITE_DETAILS",
  CLEAR_SITE_DETAILS: "CLEAR_SITE_DETAILS",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default SitesActionTypes;
