import WorksActionTypes from "./works.types";
import ActsActionTypes from "../acts/acts.types";
import ClaimsActionTypes from "../claims/claims.types";
import SubsectionActionTypes from "../subsections/subsections.types";
import RemarksActionTypes from "../remarks/remarks.types";

const INITIAL_STATE = {
  isFetching: false,
  isFetchingWorkActions: false,
  errorWorkActions: null,
  fetchingWorks: [],
  fetchingWorksTree: false,
  error: null,
  groups: null,
  works: {},
  worksTree: null,
  worksBySub: null,
  worksByUser: null,
  allWorks: null,
  currentWork: null,
  workClaims: null,
  workRemarks: null,
};

const worksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WorksActionTypes.ADD_GROUP_START:
    case WorksActionTypes.ADD_WORK_START:
    case WorksActionTypes.ADD_WORK_RESULT_START:
    case WorksActionTypes.EDIT_WORK_START:
    case WorksActionTypes.EDIT_GROUP_START:
    case WorksActionTypes.DELETE_WORK_START:
    case WorksActionTypes.DELETE_GROUP_START:
    case WorksActionTypes.GET_WORKS_BY_SUB_START:
    case WorksActionTypes.GET_WORKS_BY_USER_START:
      return { ...state, isFetching: true, error: null };

    case WorksActionTypes.GET_WORKS_START:
      return {
        ...state,
        fetchingWorks: [...state.fetchingWorks, action.payload.job_group_id],
      };

    case WorksActionTypes.GET_WORKS_TREE_START:
      return {
        ...state,
        fetchingWorksTree: true,
        error: null,
      };

    case WorksActionTypes.GET_SINGLE_WORK_START:
      return {
        ...state,
        isFetching: true,
        workClaims: null,
        workRemarks: null,
        currentWork: null,
      };

    case WorksActionTypes.GET_WORK_CLAIMS_START:
    case WorksActionTypes.GET_WORK_REMARKS_START:
      return { ...state, isFetchingWorkActions: true };

    case WorksActionTypes.ADD_GROUP_SUCCESS:
    case WorksActionTypes.EDIT_GROUP_SUCCESS:
    case WorksActionTypes.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        groups: null,
        worksTree: null,
      };

    case WorksActionTypes.ADD_WORK_RESULT_SUCCESS:
      return { ...state, isFetching: false, error: null };

    case WorksActionTypes.ADD_WORK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        works: {},
        worksTree: null,
        worksBySub: null,
        worksByUser: null,
      };

    case WorksActionTypes.DELETE_WORK_SUCCESS:
    case WorksActionTypes.EDIT_WORK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        currentWork: null,
        works: {},
        worksTree: null,
        worksBySub: [],
        worksByUser: [],
      };

    case WorksActionTypes.GET_GROUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        groups: action.payload,
      };

    case WorksActionTypes.GET_WORKS_SUCCESS:
      console.log("ACTION PAYLOAD: ", action.payload);
      console.log("FETCHING WORKS: ", state.fetchingWorks);
      return {
        ...state,
        fetchingWorks: state.fetchingWorks.filter(
          (id) => id !== action.payload.job_group_id
        ),
        error: null,
        works: {
          ...state.works,
          [action.payload.job_group_id]: action.payload.worksArray,
        },
      };

    case WorksActionTypes.GET_WORKS_TREE_SUCCESS:
      return {
        ...state,
        worksTree: action.payload,
        fetchingWorksTree: false,
        error: null,
      };

    case WorksActionTypes.GET_WORKS_BY_SUB_SUCCESS:
      return { ...state, isFetching: false, worksBySub: action.payload };

    case WorksActionTypes.GET_WORKS_BY_USER_SUCCESS:
      return { ...state, isFetching: false, worksByUser: action.payload };

    case WorksActionTypes.GET_SINGLE_WORK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        currentWork: action.payload,
      };

    case WorksActionTypes.GET_WORK_CLAIMS_SUCCESS:
      return {
        ...state,
        isFetchingWorkActions: false,
        workClaims: action.payload,
      };

    case WorksActionTypes.GET_WORK_REMARKS_SUCCESS:
      return {
        ...state,
        isFetchingWorkActions: false,
        workRemarks: action.payload,
      };

    case RemarksActionTypes.ADD_REMARK_SUCCESS:
    case RemarksActionTypes.EDIT_REMARK_SUCCESS:
    case RemarksActionTypes.DELETE_REMARK_SUCCESS:
      return { ...state, workRemarks: null };

    case WorksActionTypes.ADD_GROUP_FAILURE:
    case WorksActionTypes.EDIT_WORK_FAILURE:
    case WorksActionTypes.EDIT_GROUP_FAILURE:
    case WorksActionTypes.ADD_WORK_FAILURE:
    case WorksActionTypes.ADD_WORK_RESULT_FAILURE:
    case WorksActionTypes.GET_SINGLE_WORK_FAILURE:
    case WorksActionTypes.GET_WORKS_BY_SUB_FAILURE:
    case WorksActionTypes.DELETE_WORK_FAILURE:
    case WorksActionTypes.DELETE_GROUP_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case WorksActionTypes.GET_WORKS_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetchingWorks: state.fetchingWorks.filter(
          (id) => id !== action.payload.job_group_id
        ),
      };

    case WorksActionTypes.GET_WORKS_TREE_FAILURE:
      return {
        ...state,
        error: action.payload,
        fetchingWorksTree: false,
      };

    case WorksActionTypes.GET_WORK_CLAIMS_FAILURE:
    case WorksActionTypes.GET_WORK_REMARKS_FAILURE:
      return {
        ...state,
        errorWorkActions: action.payload,
        isFetchingWorkActions: false,
      };

    case WorksActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    case ActsActionTypes.ADD_ACT_SUCCESS:
    case ClaimsActionTypes.ADD_CLAIM_SUCCESS:
      return { ...state, workClaims: null };

    case SubsectionActionTypes.CLEAR_SUBSECTIONS:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default worksReducer;
