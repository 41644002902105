import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../../utils/normalizeData";

import VariablesActionTypes from "./variables.types";

import {
  addVariableSuccess,
  addVariableFailure,
  getVariablesStart,
  getVariablesSuccess,
  getVariablesFailure,
  editVariableSuccess,
  editVariableFailure,
  deleteVariableSuccess,
  deleteVariableFailure,
} from "./variables.actions";

export function* addVariable({ payload, callback }) {
  const { newVariableNames, adminId } = payload;
  const postData = {
    row: newVariableNames,
    who: adminId,
  };
  try {
    yield axios.post(`/api/admin/variables/add`, postData);
    yield put(addVariableSuccess());
    yield put(getVariablesStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addVariableFailure(error));
  }
}

export function* getVariables({ payload }) {
  try {
    const response = yield axios.get(`/api/admin/variables/${payload}`);
    yield put(getVariablesSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getVariablesFailure(error));
  }
}

export function* editVariable({ payload, callback }) {
  const { variableNames, adminId, variableId } = payload;
  const putVariableData = {
    row: { name: variableNames.name, name_print: variableNames.name_print },
    who: adminId,
    variable: variableId,
  };
  console.log("PUT DATA: ", putVariableData);
  try {
    yield axios.put("/api/admin/variables/edit", putVariableData);
    yield put(editVariableSuccess());
    yield put(getVariablesStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editVariableFailure(error));
  }
}

export function* deleteVariable({ payload, callback }) {
  const { variableId, adminId } = payload;
  try {
    yield axios.put("/api/admin/variables/mark_as_deleted", {
      who: adminId,
      variable_id: variableId,
    });
    yield put(deleteVariableSuccess(variableId));
    yield put(getVariablesStart(adminId));
    callback();
  } catch (e) {
    let error = "Ошибка сети";
    if (e.response && e.response.data) {
      if (e.response.data.message) error = e.response.data.message;
      else if (e.response.data.errno) {
        if (e.response.data.errno === 1451)
          error = "Нельзя удалить специальность, прикрепленное к действиям";
      }
    }
    console.log(e.response);
    yield put(deleteVariableFailure(error));
  }
}

export function* onGetVariablesStart() {
  yield takeLeading(VariablesActionTypes.GET_VARIABLES_START, getVariables);
}

export function* onAddVariableStart() {
  yield takeLatest(VariablesActionTypes.ADD_VARIABLE_START, addVariable);
}

export function* onEditVariableStart() {
  yield takeLatest(VariablesActionTypes.EDIT_VARIABLE_START, editVariable);
}

export function* onDeleteVariableStart() {
  yield takeLeading(VariablesActionTypes.DELETE_VARIABLE_START, deleteVariable);
}

export function* variablesSagas(args) {
  yield all([
    call(onGetVariablesStart),
    call(onAddVariableStart),
    call(onEditVariableStart),
    call(onDeleteVariableStart),
  ]);
}
