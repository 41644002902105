import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupsStart,
  editGroupStart,
  deleteGroupStart,
} from "../../../redux/works/works.actions";
import { useParams } from "react-router";
import { InputField } from "../../input-forms";
import ConfirmDeleteModal from "../../confirmActionModal";

const GroupInfo = ({ group_id, close }) => {
  const dispatch = useDispatch();
  const { site_id } = useParams();
  const groupsList = useSelector((state) => state.works.groups);
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    if (!groupsList) {
      dispatch(getGroupsStart(currentUser, site_id));
    }
  }, [groupsList, currentUser, dispatch, site_id]);

  const [groupToEdit, setGroupToEdit] = useState();

  useEffect(() => {
    if (groupsList && groupsList.length) {
      setGroupToEdit(groupsList.find((group) => group.id === group_id));
    }
  }, [groupsList, group_id]);

  const [editMode, setEditMode] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const isFetching = useSelector((state) => state.works.isFetching);
  const errorMsg = useSelector((state) => state.works.error);

  const handleEdit = () => {
    if (groupToEdit) {
      dispatch(
        editGroupStart(
          groupToEdit.id,
          groupToEdit.name,
          currentUser,
          site_id,
          close
        )
      );
    }
  };

  const handleDelete = () => {
    if (groupToEdit) {
      dispatch(deleteGroupStart(groupToEdit.id, currentUser, site_id, close));
    }
  };

  return (
    <>
      {editMode ? (
        <>
          <div className="caption">
            Редактировать группу
            <button className="close" onClick={close}></button>
          </div>
          <div className="info err">{errorMsg}</div>
          <InputField
            type="text"
            label="Название"
            value={groupToEdit ? groupToEdit.name : ""}
            onChange={(e) =>
              setGroupToEdit({ ...groupToEdit, name: e.target.value })
            }
          />
          <div className="buttons">
            <button
              onClick={handleEdit}
              disabled={isFetching}
              className="green"
            >
              Сохранить
            </button>
            <button
              className="cancel"
              onClick={() => setEditMode(false)}
              disabled={isFetching}
            >
              Отмена
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="caption">
            Группа
            <button className="close" onClick={close}></button>
          </div>
          <div>
            Название: <b>{groupToEdit ? groupToEdit.name : ""}</b>
          </div>

          <div className="buttons">
            <button className="blue" onClick={() => setEditMode(true)}>
              Редактировать
            </button>
            <button className="red" onClick={() => setConfirmDelete(true)}>
              Удалить
            </button>
          </div>
        </>
      )}
      {confirmDelete && (
        <ConfirmDeleteModal
          message={`Удалить группу "${groupToEdit ? groupToEdit.name : ""}"`}
          confirm={handleDelete}
          cancel={() => setConfirmDelete(false)}
          processing={isFetching}
          errorMsg={errorMsg}
        />
      )}
    </>
  );
};

export default GroupInfo;
