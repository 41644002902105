import React, { useEffect, useState, useCallback } from "react";
import { NumberInput, SelectInput } from "../../../input-forms";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialsStart } from "../../../../redux/resources/resources.actions";
import LinkButton from "../../../buttons/link-button";
import { isEmpty, isEqual } from "../../../../utils/normalizeData";
import {
  calculateFormula,
  hasQtyProperty,
} from "../../../../utils/calculateFormula";

const TechCardMaterials = ({
  cardMaterials,
  cardSpecialties,
  cardMaterialInputs,
  setCardMaterialInputs,
  materialsToSubmit,
  setMaterials,
  materialInputs,
  setMaterialInputs,
  userChangedVariable,
  userEditedCardMaterials,
  variableInputs,
  specialtyVariables,
  dailyWorkHours,
  work_id,
}) => {
  const dispatch = useDispatch();

  const materials = useSelector((state) => state.resources.materials);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetchingMaterials = useSelector(
    (state) => state.resources.isFetchingMaterials
  );
  const errorMessage = useSelector((state) => state.resources.errorMaterials);

  const [options, setOptions] = useState({});
  const [cardMaterialsToRender, setCardMaterialsToRender] = useState([]);

  const [moreMaterials, addMoreMaterials] = useState();
  const [calculationError, setCalculationError] = useState();

  useEffect(() => {
    if (!isEmpty(cardMaterials)) {
      setCardMaterialsToRender(Object.values(cardMaterials));
    }
    setCalculationError(null);
  }, [cardMaterials]);

  useEffect(() => {
    // return if card specialties object is not empty, but specialty variables have not been set yet
    if (!isEmpty(cardSpecialties) && !specialtyVariables) return;

    if (!isEmpty(cardMaterials)) {
      if (!work_id || userChangedVariable.current) {
        const variablesScope = {
          ...variableInputs,
          ...specialtyVariables,
          DH: dailyWorkHours,
        };
        const updMaterialInputsState = {};

        for (const i in cardMaterials) {
          if (
            isEmpty(cardMaterialInputs) ||
            cardMaterialInputs[cardMaterials[i].material_id].hasOwnProperty(
              "qty"
            )
          ) {
            let result;
            if (
              userEditedCardMaterials.current.hasOwnProperty([
                cardMaterials[i].material_id,
              ])
            ) {
              result =
                userEditedCardMaterials.current[cardMaterials[i].material_id];
            } else {
              result = calculateFormula(
                cardMaterials[i].qty,
                variablesScope,
                setCalculationError,
                true
              );
            }

            updMaterialInputsState[cardMaterials[i].material_id] = {
              qty: result,
            };
          }
        }
        if (
          !isEmpty(updMaterialInputsState) &&
          !isEqual(cardMaterialInputs, updMaterialInputsState)
        ) {
          setCardMaterialInputs(updMaterialInputsState);
        }
      }
    }
  }, [
    cardMaterials,
    cardSpecialties,
    cardMaterialInputs,
    variableInputs,
    specialtyVariables,
    userEditedCardMaterials,
    setCardMaterialInputs,
    work_id,
    userChangedVariable,
    dailyWorkHours,
  ]);

  const checkIfMaterialAdded = useCallback(
    (id) => {
      return (
        materialsToSubmit.some((eq) => eq[0] === id) ||
        cardMaterialInputs.hasOwnProperty(id)
      );
    },
    [materialsToSubmit, cardMaterialInputs]
  );

  useEffect(() => {
    if (!materials) {
      dispatch(getMaterialsStart(currentUser.user));
    } else {
      setOptions(
        materials.reduce((options, material) => {
          if (!checkIfMaterialAdded(material.id)) {
            options[material.id] = `${material.name} (${material.unit})`;
          }
          return options;
        }, {})
      );
    }
  }, [materials, dispatch, currentUser, checkIfMaterialAdded]);

  const handleChange = (e) => {
    setMaterialInputs({
      ...materialInputs,
      [e.target.name]: e.target.value ? Number(e.target.value) : "",
    });
  };

  const addMaterial = () => {
    const materialIndex = materialsToSubmit.findIndex(
      (material) => material[0] === materialInputs.id
    );
    if (materialIndex !== -1) {
      let newMaterialsToSubmit = [...materialsToSubmit];
      newMaterialsToSubmit[materialIndex][1] += materialInputs.qty;
      setMaterials(newMaterialsToSubmit);
    } else {
      const material = materials.find(
        (material) => material.id === materialInputs.id
      );
      if (material) {
        setMaterials([
          ...materialsToSubmit,
          [materialInputs.id, materialInputs.qty, material.name, material.unit],
        ]);
      }
    }
    setMaterialInputs({ id: "", qty: "" });
  };

  const deleteItem = (index) => {
    setMaterials(materialsToSubmit.filter((material, i) => i !== index));
  };

  const handleQtyChange = (e) => {
    userEditedCardMaterials.current[e.target.name] = e.target.value;
    setCardMaterialInputs({
      ...cardMaterialInputs,
      [e.target.name]: { qty: +e.target.value },
    });
  };

  const removeCardMaterial = (materialId) => {
    setCardMaterialInputs({ ...cardMaterialInputs, [materialId]: "" });
    setCardMaterialsToRender(
      cardMaterialsToRender.filter(
        (material) => material.material_id !== materialId
      )
    );
  };

  return (
    <>
      {hasQtyProperty(cardMaterialInputs) || materialsToSubmit.length ? (
        <>
          <div className="hdr">Необходимые материалы</div>
          {calculationError && (
            <div className="info err">{calculationError}</div>
          )}
        </>
      ) : (
        ""
      )}

      {hasQtyProperty(cardMaterialInputs) || materialsToSubmit.length ? (
        <table className="list">
          <thead>
            <tr>
              <th>наименование</th>
              <th>ед. измерения</th>
              <th>количество</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cardMaterialsToRender.map((material) => {
              if (
                cardMaterialInputs[material.material_id] &&
                cardMaterialInputs[material.material_id].hasOwnProperty("qty")
              ) {
                return (
                  <tr key={material.id}>
                    <td>{material.material}</td>
                    <td>{material.unit}</td>
                    <td>
                      <NumberInput
                        name={material.material_id}
                        value={cardMaterialInputs[material.material_id].qty}
                        onChange={handleQtyChange}
                        step="0.01"
                      />
                    </td>

                    <td className="control">
                      <button
                        className="remove"
                        deletestyle="true"
                        type="button"
                        onClick={() => removeCardMaterial(material.material_id)}
                      />
                    </td>
                  </tr>
                );
              }
              return null;
            })}

            {materialsToSubmit.length
              ? materialsToSubmit.map((material, i) => (
                  <tr key={material[0]}>
                    <td>{material[2]}</td>
                    <td>{material[3]}</td>
                    <td>{material[1]}</td>
                    <td className="control">
                      <button
                        className="remove"
                        deletestyle="true"
                        onClick={() => deleteItem(i)}
                        type="button"
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      ) : (
        ""
      )}

      {!moreMaterials && (
        <LinkButton onClick={() => addMoreMaterials(true)}>
          Нужны дополнительные материалы
        </LinkButton>
      )}

      {moreMaterials && (
        <>
          <div className="two_col">
            <div className="col">
              <SelectInput
                name="id"
                label="Наименование"
                onChange={handleChange}
                options={options}
                defaultOption={materialInputs.id}
                errorMessage={errorMessage}
                description={
                  isFetchingMaterials
                    ? "Загрузка данных..."
                    : "Выбрать материал"
                }
                emptyMessage={"Нет добавленных специализаций"}
              />
            </div>
            <div className="col">
              <NumberInput
                label="Количество"
                value={materialInputs.qty}
                onChange={handleChange}
                name="qty"
                step="0.001"
              />
            </div>
          </div>
          <div className="buttons in_form">
            <button
              type="button"
              onClick={addMaterial}
              disabled={!materialInputs.id || !materialInputs.qty}
            >
              добавить
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(TechCardMaterials);
