const ClaimsActionTypes = {
  ADD_CLAIM_START: "ADD_CLAIM_START",
  ADD_CLAIM_SUCCESS: "ADD_CLAIM_SUCCESS",
  ADD_CLAIM_FAILURE: "ADD_CLAIM_FAILURE",
  GET_CLAIMS_START: "GET_CLAIMS_START",
  GET_CLAIMS_SUCCESS: "GET_CLAIMS_SUCCESS",
  GET_CLAIMS_FAILURE: "GET_CLAIMS_FAILURE",
  DELETE_CLAIM_START: "DELETE_CLAIM_START",
  DELETE_CLAIM_SUCCESS: "DELETE_CLAIM_SUCCESS",
  DELETE_CLAIM_FAILURE: "DELETE_CLAIM_FAILURE",
  EDIT_CLAIM_START: "EDIT_CLAIM_START",
  EDIT_CLAIM_SUCCESS: "EDIT_CLAIM_SUCCESS",
  EDIT_CLAIM_FAILURE: "EDIT_CLAIM_FAILURE",
  RECLAIM_START: "RECLAIM_START",
  RECLAIM_SUCCESS: "RECLAIM_SUCCESS",
  RECLAIM_FAILURE: "RECLAIM_FAILURE",
  MARK_CLAIM_CLOSE_START: "MARK_CLAIM_CLOSE_START",
  MARK_CLAIM_CLOSE_SUCCESS: "MARK_CLAIM_CLOSE_SUCCESS",
  MARK_CLAIM_CLOSE_FAILURE: "MARK_CLAIM_CLOSE_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default ClaimsActionTypes;
