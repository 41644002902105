import ReasonsActionTypes from "./reasons.types";

export const getReasonsStart = (userId) => ({
  type: ReasonsActionTypes.GET_REASONS_START,
  payload: userId,
});

export const getReasonsSuccess = (reasons) => ({
  type: ReasonsActionTypes.GET_REASONS_SUCCESS,
  payload: reasons,
});

export const getReasonsFailure = (error) => ({
  type: ReasonsActionTypes.GET_REASONS_FAILURE,
  payload: error,
});
