import ReportsActionTypes from "./reports.types";

export const addReportStart = (
  newReportData,
  videos,
  currentUser,
  site_id,
  callback
) => ({
  type: ReportsActionTypes.ADD_REPORT_START,
  payload: { newReportData, videos, currentUser, site_id },
  callback,
});

export const addReportSuccess = () => ({
  type: ReportsActionTypes.ADD_REPORT_SUCCESS,
});

export const addReportFailure = (error) => ({
  type: ReportsActionTypes.ADD_REPORT_FAILURE,
  payload: error,
});

export const getReportsStart = (user_id, site_id) => ({
  type: ReportsActionTypes.GET_REPORTS_START,
  payload: { user_id, site_id },
});

export const getReportsSuccess = (acts) => ({
  type: ReportsActionTypes.GET_REPORTS_SUCCESS,
  payload: acts,
});

export const getReportsFailure = (error) => ({
  type: ReportsActionTypes.GET_REPORTS_FAILURE,
  payload: error,
});

// export const editReportStart = (act) => ({
//   type: ReportsActionTypes.EDIT_REPORT_START,
//   payload: act,
// });

// export const editReportSuccess = () => ({
//   type: ReportsActionTypes.EDIT_REPORT_SUCCESS,
// });

// export const editReportFailure = (error) => ({
//   type: ReportsActionTypes.EDIT_REPORT_FAILURE,
//   payload: error,
// });

// export const deleteReportStart = (act) => ({
//   type: ReportsActionTypes.DELETE_REPORT_START,
//   payload: act,
// });

// export const deleteReportSuccess = () => ({
//   type: ReportsActionTypes.DELETE_REPORT_SUCCESS,
// });

// export const deleteReportFailure = (error) => ({
//   type: ReportsActionTypes.DELETE_REPORT_FAILURE,
//   payload: error,
// });

export const clearErrors = () => ({
  type: ReportsActionTypes.CLEAR_ERRORS,
});
