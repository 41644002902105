import { put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../../utils/normalizeData";

import AdminsActionTypes from "./admins.types";
import {
  signInSuccess,
  signInFailure,
  //   addUserFailure,
  //   addUserSuccess,
  //   getUsersSuccess,
  //   getUsersFailure,
  //   editUserSuccess,
  //   editUserFailure,
  //   deleteUserSuccess,
  //   deleteUserFailure,
  //   recoverPasswordSuccess,
  //   recoverPasswordFailure,
  //   resetPasswordSuccess,
  //   resetPasswordFailure,
  //   signInStart,
} from "./admins.actions";

// export function* getUsers({ payload }) {
//   try {
//     const response = yield axios.get(
//       `/api/users/${payload.user}/${payload.organization}`
//     );
//     yield put(getUsersSuccess(response.data));
//   } catch (e) {
//     yield put(
//       getUsersFailure(e.response ? e.response.data : "Сеть недоступна")
//     );
//   }
// }

// export function* onGetUsersStart() {
//   yield takeLeading(UserActionTypes.GET_USERS_START, getUsers);
// }

export function* signIn({ payload }) {
  try {
    yield console.log(payload);

    const response = yield axios.post("/api/admin/login", payload);
    yield put(signInSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(signInFailure(error));
  }
}

// export function* addPhoto({ photo, admin, userId }) {
//   console.log("PHOTO PAYLOAD BEFORE UPLOAD: ", photo, admin, userId);
//   let formData = new FormData();
//   formData.append("avatar", photo, `${Date.now()}.jpg`);
//   yield axios.post(
//     `/api/users/avatar/${admin.user}/${userId}/${admin.organization}`,
//     formData
//   );
// }

// export function* addUser({ payload, callback }) {
//   const requestBody = {
//     row: { ...payload.newUser },
//     who: payload.admin.user,
//     organization: payload.admin.organization,
//   };
//   try {
//     const response = yield axios.post("/api/users/add/", requestBody);
//     if (payload.photo) {
//       payload.userId = response.data.insertId;
//       yield addPhoto(payload);
//     }
//     yield put(addUserSuccess());
//     yield callback();
//   } catch (e) {
//     console.log(e);
//     yield put(
//       addUserFailure(
//         e.response ? JSON.stringify(e.response.data) : "Сеть недоступна"
//       )
//     );
//   }
// }

// export function* onAddUserStart() {
//   yield takeLatest(UserActionTypes.ADD_USER_START, addUser);
// }

// export function* editUser({ payload, callback }) {
//   const requestBody = {
//     row: { ...payload.userNewDetails },
//     who: payload.admin.user,
//     user: payload.userId,
//     organization: payload.admin.organization,
//   };
//   try {
//     yield axios.put("/api/users/edit", requestBody);
//     if (payload.photo) yield addPhoto(payload);
//     yield put(editUserSuccess(payload));
//     yield callback();
//   } catch (e) {
//     put(editUserFailure(e.response ? e.response.data : "Сеть недоступна"));
//   }
// }

// export function* onEditUserStart() {
//   yield takeLatest(UserActionTypes.EDIT_USER_START, editUser);
// }

// export function* deleteUser({ payload, callback }) {
//   try {
//     yield axios.delete("/api/users/delete", {
//       data: payload,
//     });
//     yield put(deleteUserSuccess());
//     yield callback();
//   } catch (e) {
//     console.log(e);
//     yield put(
//       deleteUserFailure(e.response ? e.response.data : "Сеть недоступна")
//     );
//   }
// }

// export function* onDeleteUserStart() {
//   yield takeLeading(UserActionTypes.DELETE_USER_START, deleteUser);
// }

// export function* recoverPassword({ payload }) {
//   try {
//     yield console.log("CREDENTIALS: ", payload);
//     const response = yield axios.post("/api/user/password_recovery", payload);
//     yield console.log(response.data);
//     yield put(recoverPasswordSuccess());
//   } catch (e) {
//     yield console.log(e);
//     yield put(
//       recoverPasswordFailure("Пользователь с такими данными не найден")
//     );
//   }
// }

// export function* onRecoverStart() {
//   yield takeLeading(UserActionTypes.RECOVER_PASSWORD_START, recoverPassword);
// }

// export function* resetPassword({ payload, callback }) {
//   try {
//     const response = yield axios.post("/api/user/password_reset", payload);
//     yield console.log(response.data);
//     yield put(resetPasswordSuccess());
//     const msgToCheck = "Password recovered & sent to: ";
//     if (response.data.res && response.data.res.includes(msgToCheck)) {
//       const email = response.data.res.substring(msgToCheck.length);
//       yield put(signInStart({ email, password: payload.password }));
//     } else {
//       yield callback();
//     }
//   } catch (e) {
//     let error =
//       "Произошла ошибка при изменении пароля. Обратитесь к системному администратору.";
//     if (e.response && e.response.status === 401) {
//       error =
//         "Истек срок письма с ссылкой восстановления пароля. Необходимо заново пройти процедуру восстановления.";
//     } else if (e.response && e.response.data && e.response.status === 404) {
//       error = JSON.stringify(e.response.data);
//     }
//     // yield console.log(e);
//     yield put(resetPasswordFailure(error));
//   }
// }

// export function* onPasswordResetStart() {
//   yield takeLeading(UserActionTypes.RESET_PASSWORD_START, resetPassword);
// }

export function* onSignInStart() {
  yield takeLatest(AdminsActionTypes.SIGN_IN_START, signIn);
}

export function* adminsSagas(args) {
  yield all([
    call(onSignInStart),
    // call(onGetUsersStart),
    // call(onAddUserStart),
    // call(onEditUserStart),
    // call(onDeleteUserStart),
    // call(onRecoverStart),
    // call(onPasswordResetStart),
  ]);
}
