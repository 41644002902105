import ContractorsActionTypes from "./contractors.types";

const INITIAL_STATE = {
  isFetching: false,
  isFetchingAll: false,
  error: null,
  currentContractor: null,
  contractors: [],
  allContractors: [],
  allContError: null,
};

const contractorsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContractorsActionTypes.ADD_CONTRACTOR_START:
    case ContractorsActionTypes.EDIT_CONTRACTOR_START:
    case ContractorsActionTypes.GET_CONTRACTORS_START:
    case ContractorsActionTypes.GET_CURRENT_CONTRACTOR_START:
    case ContractorsActionTypes.DELETE_CONTRACTOR_START:
      return { ...state, isFetching: true, error: null };

    case ContractorsActionTypes.GET_ALL_CONTRACTORS_START:
      return { ...state, isFetchingAll: true, allContError: null };

    case ContractorsActionTypes.ADD_CONTRACTOR_SUCCESS:
    case ContractorsActionTypes.EDIT_CONTRACTOR_SUCCESS:
    case ContractorsActionTypes.DELETE_CONTRACTOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        allContractors: [],
        currentContractor: null,
      };

    case ContractorsActionTypes.GET_CONTRACTORS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        contractors: action.payload,
      };

    case ContractorsActionTypes.GET_CURRENT_CONTRACTOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        currentContractor: action.payload,
      };

    case ContractorsActionTypes.GET_ALL_CONTRACTORS_SUCCESS:
      return {
        ...state,
        isFetchingAll: false,
        allContractors: action.payload,
      };

    case ContractorsActionTypes.ADD_CONTRACTOR_FAILURE:
    case ContractorsActionTypes.EDIT_CONTRACTOR_FAILURE:
    case ContractorsActionTypes.GET_CONTRACTORS_FAILURE:
    case ContractorsActionTypes.GET_CURRENT_CONTRACTOR_FAILURE:
    case ContractorsActionTypes.DELETE_CONTRACTOR_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case ContractorsActionTypes.GET_ALL_CONTRACTORS_FAILURE:
      return { ...state, isFetchingAll: false, allContError: action.payload };

    case ContractorsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default contractorsReducer;
