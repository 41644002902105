import { combineReducers } from "redux";
import userReducer from "./user/user.reducer";
import sitesReducer from "./sites/sites.reducer";
import subsectionsReducer from "./subsections/subsections.reducer";
import revisionsReducer from "./revisions/revisions.reducer";
import claimsReducer from "./claims/claims.reducer";
import actsReducer from "./acts/acts.reducer";
import contractorsReducer from "./contractors/contractors.reducer";
import statusesReducer from "./statuses/statuses.reducer";
import workReducer from "./works/works.reducer";
import resourcesReducer from "./resources/resources.reducer";
import remarksReducer from "./remarks/remarks.reducer";
import variablesReducer from "./variables/variables.reducer";
import techCardsReducer from "./techCards/techCards.reducer";
import reasonsReducer from "./reasons/reasons.reducer";
import reportsReducer from "./reports/reports.reducer";
import checklistsReducer from "./checklists/checklists.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  sites: sitesReducer,
  subsections: subsectionsReducer,
  revisions: revisionsReducer,
  claims: claimsReducer,
  acts: actsReducer,
  contractors: contractorsReducer,
  works: workReducer,
  statuses: statusesReducer,
  resources: resourcesReducer,
  remarks: remarksReducer,
  variables: variablesReducer,
  techCards: techCardsReducer,
  reasons: reasonsReducer,
  reports: reportsReducer,
  checklists: checklistsReducer,
});

export default rootReducer;
