import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";

import RevisionsActionTypes from "./revisions.types";
import {
  addRevisionSuccess,
  addRevisionFailure,
  getRevisionsSuccess,
  getRevisionsFailure,
  editRevisionSuccess,
  editRevisionFailure,
  deleteRevisionSuccess,
  deleteRevisionFailure,
  getRevisionsStart,
  postponeRevisionSuccess,
  postponeRevisionFailure,
} from "./revisions.actions";
import { getClaimsStart } from "../claims/claims.actions";
import { getSingleSubsectionStart } from "../subsections/subsections.actions";

export function* addRevision({ payload, callback }) {
  const { revision, markerCoords, currentUser, site_id } = payload;
  const postRevisionData = {
    row: revision,
    who: currentUser.user,
    subsection: revision.subsection_id,
  };

  try {
    const response = yield axios.post("/api/revisions/add", postRevisionData);

    if (markerCoords.length) {
      const pointsData = {
        revisionPlanPointsValues: [],
        who: currentUser.user,
      };
      for (const i in markerCoords) {
        markerCoords[i].revision_id = response.data.insertId;
        pointsData.revisionPlanPointsValues.push(
          Object.values(markerCoords[i])
        );
      }
      pointsData.revisionPlanPointsColumns = Object.keys(markerCoords[0]);
      yield axios.post("/api/revision_plan_points/add", pointsData);
    }

    yield put(addRevisionSuccess());
    yield put(getRevisionsStart(currentUser, site_id));

    if (revision.claim_id) {
      yield put(getClaimsStart(currentUser, site_id));
    }
    yield put(
      getSingleSubsectionStart(
        currentUser.user,
        site_id,
        revision.subsection_id
      )
    );
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addRevisionFailure(error));
  }
}

export function* getRevisions({ payload }) {
  const { currentUser, site_id } = payload;
  try {
    const response = yield axios.get(
      `/api/obj_revisions/${currentUser.user}/${site_id}`
    );
    yield put(getRevisionsSuccess(Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getRevisionsFailure(error));
  }
}

export function* editRevision({ payload, callback }) {
  const {
    revision,
    rev_id,
    site_id,
    currentUser,
    markerCoords,
    coordsToDelete,
  } = payload;

  const putRevisionData = {
    row: revision,
    who: currentUser.user,
    id: rev_id,
  };
  // console.log("PUT REVISION DATA: ", putRevisionData);
  try {
    yield axios.put("/api/revisions/edit", putRevisionData);

    const newPointsToSubmit = [];
    const pointsToEdit = [];

    for (const i in markerCoords) {
      if (markerCoords[i].id) {
        if (markerCoords[i].changed) {
          pointsToEdit.push({
            row: {
              dot_x: markerCoords[i].dot_x,
              dot_y: markerCoords[i].dot_y,
            },
            revision_plan_point: markerCoords[i].id,
          });
        }
      } else {
        newPointsToSubmit.push(markerCoords[i]);
      }
    }

    if (newPointsToSubmit.length) {
      const pointsData = {
        revisionPlanPointsValues: [],
        who: currentUser.user,
      };
      for (const i in newPointsToSubmit) {
        newPointsToSubmit[i].revision_id = rev_id;
        pointsData.revisionPlanPointsValues.push(
          Object.values(newPointsToSubmit[i])
        );
      }
      pointsData.revisionPlanPointsColumns = Object.keys(newPointsToSubmit[0]);
      yield axios.post("/api/revision_plan_points/add", pointsData);
    }

    if (pointsToEdit.length) {
      yield axios.put("/api/revision_plan_points/edit", {
        rows: pointsToEdit,
        who: currentUser.user,
      });
    }

    if (coordsToDelete && coordsToDelete.length) {
      const idsToDelete = [];
      for (const i in coordsToDelete) {
        idsToDelete.push(coordsToDelete[i].id);
      }
      axios.delete("/api/revision_plan_points/delete", {
        data: { who: currentUser.user, id: idsToDelete },
      });
    }

    yield put(editRevisionSuccess());
    yield put(
      getSingleSubsectionStart(
        currentUser.user,
        site_id,
        revision.subsection_id
      )
    );
    yield callback();
  } catch (e) {
    console.log(e);
    const error = handleError(e);
    yield put(editRevisionFailure(error));
  }
}

export function* deleteRevision({ payload, callback }) {
  const { id, currentUser, site_id, subsection_id } = payload;
  const deleteData = { who: currentUser.user, id };
  try {
    yield axios.delete("/api/revisions/delete", { data: deleteData });
    yield console.log("axios.delete(", payload);
    yield put(deleteRevisionSuccess());
    yield put(getRevisionsStart(currentUser, site_id));
    yield put(
      getSingleSubsectionStart(currentUser.user, site_id, subsection_id)
    );
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(deleteRevisionFailure(error));
  }
}

export function* postponeRevision({ payload }) {
  const { revData, currentUser, site_id, callback } = payload;
  const postponeData = {
    row: revData,
    who: currentUser.user,
  };
  console.log("POSTPONE DATA: ", postponeData);
  try {
    yield axios.post("/api/revision_postpone/add", postponeData);
    yield put(postponeRevisionSuccess());
    yield put(getRevisionsStart(currentUser, site_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(postponeRevisionFailure(error));
  }
}

export function* onGetRevisionsStart() {
  yield takeLeading(RevisionsActionTypes.GET_REVISIONS_START, getRevisions);
}

export function* onAddRevisionStart() {
  yield takeLatest(RevisionsActionTypes.ADD_REVISION_START, addRevision);
}

export function* onEditRevisionStart() {
  yield takeLatest(RevisionsActionTypes.EDIT_REVISION_START, editRevision);
}

export function* onDeleteRevisionStart() {
  yield takeLeading(RevisionsActionTypes.DELETE_REVISION_START, deleteRevision);
}

export function* onPostponeRevisionStart() {
  yield takeLeading(
    RevisionsActionTypes.POSTPONE_REVISION_START,
    postponeRevision
  );
}

export function* revisionsSagas(args) {
  yield all([
    call(onAddRevisionStart),
    call(onGetRevisionsStart),
    call(onEditRevisionStart),
    call(onDeleteRevisionStart),
    call(onPostponeRevisionStart),
  ]);
}
