import React, { useEffect, useState, useCallback } from "react";
import { NumberInput, SelectInput } from "../../../input-forms";
import { useDispatch, useSelector } from "react-redux";
import { getSpecialtiesStart } from "../../../../redux/resources/resources.actions";
import LinkButton from "../../../buttons/link-button";
import { isEmpty, isEqual } from "../../../../utils/normalizeData";
import { calculateFormula } from "../../../../utils/calculateFormula";

const TechCardSpecialties = ({
  cardSpecialties,
  specialtiesToSubmit,
  setSpecialties,
  specialtyInputs,
  setSpecialtyInputs,
  cardSpecInputs,
  setCardSpecInputs,
  work_id,
  variableInputs,
  specialtyVariables,
  setSpecialtyVariables,
  userEditedCardSpecs,
  calculateWorkEndDate,
  userChangedVariable,
  dailyWorkHours,
}) => {
  const dispatch = useDispatch();
  const specialties = useSelector((state) => state.resources.specialties);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetchingSpecialties = useSelector(
    (state) => state.resources.isFetchingSpecialties
  );
  const errorMessage = useSelector((state) => state.resources.errorSpecialty);
  const [options, setOptions] = useState({});
  const [cardSpecsToRender, setCardSpecsToRender] = useState([]);

  const [addMoreSpecs, setAddMoreSpecs] = useState(false);

  const [calculationError, setCalculationError] = useState();

  const checkIfSpecailtyAdded = useCallback(
    (id) => {
      return (
        (cardSpecInputs && cardSpecInputs.hasOwnProperty(id)) ||
        specialtiesToSubmit.some((spec) => spec[0] === id)
      );
    },
    [cardSpecInputs, specialtiesToSubmit]
  );

  // get the list of all specialties from the server
  useEffect(() => {
    if (!specialties) {
      dispatch(getSpecialtiesStart(currentUser.user));
    } else {
      setOptions(
        specialties.reduce((acc, specialty) => {
          if (!checkIfSpecailtyAdded(specialty.id)) {
            acc[specialty.id] = specialty.specialty;
          }
          return acc;
        }, {})
      );
    }
  }, [specialties, dispatch, currentUser, checkIfSpecailtyAdded]);

  useEffect(() => {
    if (cardSpecialties.length) {
      setCardSpecsToRender(cardSpecialties);
    }
    setCalculationError(null);
  }, [cardSpecialties]);

  // if card specialties prop is not empty, set the card specialties to render and
  // set the state for card specialties values
  useEffect(() => {
    if (cardSpecialties.length && specialtyVariables) {
      if (!work_id || userChangedVariable.current) {
        const variablesScope = {
          ...variableInputs,
          ...specialtyVariables,
          DH: dailyWorkHours,
        };
        const updSpecInputState = {};
        const specVars = {};

        for (const i in cardSpecialties) {
          // execute the code inside the if block, only if the card specialty inputs are empty (in its initial state) or if the card specialty input has a property with the id of the current specialty from the the selected tech card
          if (
            isEmpty(cardSpecInputs) ||
            (cardSpecInputs[cardSpecialties[i].employee_id] &&
              cardSpecInputs[cardSpecialties[i].employee_id].hasOwnProperty(
                "qty"
              ))
          ) {
            let result;
            if (
              userEditedCardSpecs.current.hasOwnProperty([
                cardSpecialties[i].employee_id,
              ])
            ) {
              result =
                userEditedCardSpecs.current[cardSpecialties[i].employee_id];
            } else {
              result = calculateFormula(
                cardSpecialties[i].min_qty,
                variablesScope,
                setCalculationError,
                true
              );
            }
            if (cardSpecialties[i].variable_qty) {
              specVars[cardSpecialties[i].variable_qty] = result;
            }

            updSpecInputState[cardSpecialties[i].employee_id] = {
              ...cardSpecInputs[cardSpecialties[i].employee_id],
              qty: result,
              man_hour: calculateFormula(
                cardSpecialties[i].man_hour,
                variablesScope,
                setCalculationError,
                true
              ),
            };
          }
        }
        if (
          !isEmpty(updSpecInputState) &&
          !isEqual(cardSpecInputs, updSpecInputState)
        ) {
          calculateWorkEndDate(updSpecInputState);
          setSpecialtyVariables((v) => ({ ...v, ...specVars }));
          setCardSpecInputs(updSpecInputState);
        }
      }
    }
  }, [
    cardSpecialties,
    setCardSpecInputs,
    work_id,
    userChangedVariable,
    setCalculationError,
    cardSpecInputs,
    variableInputs,
    specialtyVariables,
    setSpecialtyVariables,
    userEditedCardSpecs,
    dailyWorkHours,
    calculateWorkEndDate,
  ]);

  const handleChange = (e) => {
    userChangedVariable.current = true;
    setSpecialtyInputs({
      ...specialtyInputs,
      [e.target.name]: e.target.value ? Number(e.target.value) : "",
    });
  };

  const addSpecialty = () => {
    const specialtyName = specialties.find(
      (spec) => spec.id === specialtyInputs.id
    );
    if (specialtyName) {
      setSpecialties([
        ...specialtiesToSubmit,
        [specialtyInputs.id, specialtyInputs.qty, specialtyName.specialty],
      ]);
      setSpecialtyInputs({ id: "", qty: "" });
    }
  };

  const deleteItem = (index) => {
    setSpecialties(specialtiesToSubmit.filter((specialty, i) => i !== index));
  };

  const handleQtyChange = (e) => {
    userEditedCardSpecs.current[e.target.name] = +e.target.value;
    if (e.target.dataset.variable) {
      setSpecialtyVariables((v) => ({
        ...v,
        [e.target.dataset.variable]: +e.target.value,
      }));
    }
    setCardSpecInputs({
      ...cardSpecInputs,
      [e.target.name]: {
        ...cardSpecInputs[e.target.name],
        qty: +e.target.value,
      },
    });
  };

  const removeCardSpec = (specId) => {
    setCardSpecInputs({
      ...cardSpecInputs,
      // [specId]: { qty: "", man_hour: "" },
      [specId]: {},
    });
    setCardSpecsToRender(
      cardSpecsToRender.filter((spec) => spec.employee_id !== specId)
    );
  };

  return (
    <>
      {cardSpecialties.length || specialtiesToSubmit.length ? (
        <>
          <div className="hdr">
            <b>Необходимые работники</b>
          </div>
          {calculationError && (
            <div className="info err">{calculationError}</div>
          )}
        </>
      ) : (
        ""
      )}

      {(cardSpecialties.length && specialtyVariables) ||
      specialtiesToSubmit.length ? (
        <table className="list">
          <thead>
            <tr>
              <th>специализация</th>
              <th>человекочасы</th>
              <th>кол-во человек</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(cardSpecInputs) &&
              cardSpecsToRender.map((specialty) => {
                const currentSpecValues = cardSpecInputs[specialty.employee_id];
                return (
                  <tr key={specialty.id}>
                    <td>{specialty.specialty}</td>
                    <td>
                      {currentSpecValues ? currentSpecValues.man_hour : ""}
                    </td>

                    <td>
                      <NumberInput
                        name={specialty.employee_id}
                        data-variable={specialty.variable_qty}
                        value={currentSpecValues ? currentSpecValues.qty : ""}
                        onChange={handleQtyChange}
                      />
                    </td>
                    <td className="control">
                      <button
                        className="remove"
                        type="button"
                        onClick={() => removeCardSpec(specialty.employee_id)}
                      />
                    </td>
                  </tr>
                );
              })}

            {specialtiesToSubmit.length
              ? specialtiesToSubmit.map((specialty, i) => (
                  <tr key={specialty[0]}>
                    <td>{specialty[2]}</td>
                    <td></td>
                    <td>{specialty[1]}</td>
                    <td className="control">
                      <button
                        className="remove"
                        onClick={() => deleteItem(i)}
                        type="button"
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      ) : (
        ""
      )}

      {addMoreSpecs ? (
        <>
          <div className="two_col">
            <div className="col">
              <SelectInput
                name="id"
                label="Специализация"
                onChange={handleChange}
                options={options}
                defaultOption={specialtyInputs.id}
                errorMessage={errorMessage}
                description={
                  isFetchingSpecialties
                    ? "Загрузка данных..."
                    : "Выбрать специализацию"
                }
                emptyMessage={"Нет добавленных специализаций"}
              />
            </div>
            <div className="col">
              <NumberInput
                label="Количество"
                value={specialtyInputs.qty}
                onChange={handleChange}
                name="qty"
              />
            </div>
          </div>
          <div className="buttons in_form">
            <button
              className="blue"
              type="button"
              onClick={addSpecialty}
              disabled={!specialtyInputs.id || !specialtyInputs.qty}
            >
              добавить
            </button>
          </div>
        </>
      ) : (
        <LinkButton onClick={() => setAddMoreSpecs(true)}>
          Нужны дополнительные работники
        </LinkButton>
      )}
    </>
  );
};

export default TechCardSpecialties;
