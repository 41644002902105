import SpecialtiesActionTypes from "./specialties.types";

const INITIAL_STATE = {
  isAdding: false,
  isFetching: false,
  isDeleting: false,
  error: null,
  specialties: null,
};

const specialtiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SpecialtiesActionTypes.ADD_SPECIALTY_START:
    case SpecialtiesActionTypes.EDIT_SPECIALTY_START:
      return { ...state, isAdding: true, error: null };
    case SpecialtiesActionTypes.GET_SPECIALTIES_START:
      return { ...state, isFetching: true, error: null };
    case SpecialtiesActionTypes.DELETE_SPECIALTY_START:
      return {
        ...state,
        isDeleting: true,
        error: null,
      };

    case SpecialtiesActionTypes.ADD_SPECIALTY_SUCCESS:
    case SpecialtiesActionTypes.EDIT_SPECIALTY_SUCCESS:
      return { ...state, isAdding: false, error: null };
    case SpecialtiesActionTypes.DELETE_SPECIALTY_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        error: null,
      };

    case SpecialtiesActionTypes.GET_SPECIALTIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        specialties: action.payload,
      };

    case SpecialtiesActionTypes.ADD_SPECIALTY_FAILURE:
    case SpecialtiesActionTypes.EDIT_SPECIALTY_FAILURE:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      };

    case SpecialtiesActionTypes.GET_SPECIALTIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case SpecialtiesActionTypes.DELETE_SPECIALTY_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };

    case SpecialtiesActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default specialtiesReducer;
