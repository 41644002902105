import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addClaimStart } from "../../../redux/claims/claims.actions";
import PreviousClaims from "../previousActions/previous-actions";
import { InputField, TextAreaInput } from "../../input-forms";
import AddBackup from "../add-backup";
import SetPointsOnPlans from "../pointsOnPlan/set-points-on-plans";
import SelectSubsection from "../subsections/select-subsection";
import SelectContractor from "../contractors/select-contractor";
import SelectReason from "../SelectReason";
import { isEmpty, validate } from "../../../utils/normalizeData";
import axios from "axios";

const AddClaim = ({
  rev_id,
  checkInputs,
  close,
  closeResult,
  checkInputsAndClose,
}) => {
  const revisions = useSelector((state) => state.revisions.revisions);
  const claims = useSelector((state) => state.claims.claims);
  const currentSub = useSelector(
    (state) => state.subsections.currentSubsection
  );
  const isFetching = useSelector((state) => state.claims.isFetching);
  const errorMsg = useSelector((state) => state.revisions.error);
  const currentUser = useSelector((state) => state.user.currentUser);

  const [subToClaim, selectSub] = useState(null);
  const [claimData, setClaimData] = useState({
    title: "",
    reason_id: null,
    reason: "",
    descriptions: "",
    contractor_id: "",
    revision_id: null,
    claim_id: null,
    user_id: currentUser.user,
  });
  const [markerCoords, setMarkerCoords] = useState([]);

  const checkValidity = (e) => {
    validate(
      e.target.value,
      () => {},
      () =>
        setErrors({
          ...errors,
          [e.target.name]: { ...errors[e.target.name], error: null },
        }),
      errors[e.target.name].type
    );
  };

  const [errors, setErrors] = useState({
    subsection: { type: "required", error: null },
    title: { type: "required", error: null },
    contractor_id: { type: "required", error: null },
  });
  const [isDataValid, setIsDataValid] = useState(true);

  const backupPhotos = useRef();

  const { site_id } = useParams();

  // check inputs and prompt the user when he
  // tries to close the window without saving
  checkInputs.current = () => {
    if (
      claimData.title ||
      claimData.item ||
      claimData.reason ||
      claimData.descriptions ||
      claimData.contractor_id ||
      claimData.claim_id ||
      claimData.revision_id ||
      (backupPhotos.current && backupPhotos.current.length) ||
      (!currentSub && subToClaim) ||
      (currentSub && subToClaim.id !== currentSub.id)
    )
      return true;

    return false;
  };

  // this id is used in SelectSubsections component to find the neccessary subsection and set it in this component
  const [idToGetSub, setIdToGetSub] = useState(null);

  const setPreviousPoints = (pointsArr) => {
    setMarkerCoords(
      pointsArr.map((point) => ({
        dot_x: point.dot_x,
        dot_y: point.dot_y,
        plan_id: point.plan_id,
      }))
    );
  };

  // automatically set the subsection, contractor and points, if a previous claim has been selected
  useEffect(() => {
    if (claimData.claim_id) {
      const previousClaim = claims.find(
        (claim) => claim.id === claimData.claim_id
      );
      if (previousClaim) {
        if (!isEmpty(previousClaim.claim_plans)) {
          setPreviousPoints(Object.values(previousClaim.claim_plans));
        }
        if (previousClaim.subsection_id !== idToGetSub) {
          setIdToGetSub(previousClaim.subsection_id);
        }
        setClaimData((claimData) => ({
          ...claimData,
          contractor_id: previousClaim.contractor_id,
        }));
      }
    }
  }, [claimData.claim_id, claims, idToGetSub]);

  // automatically set the subsection, contractor and points, if a previous revision has been selected
  useEffect(() => {
    if (claimData.revision_id) {
      const previousRevision = revisions.find(
        (revision) => revision.id === claimData.revision_id
      );
      if (previousRevision) {
        axios
          .get(
            `/api/revision_plan_points_revision/${currentUser.user}/${previousRevision.id}`
          )
          .then((response) => {
            setPreviousPoints(response.data);
          })
          .catch((e) => console.log(e));

        if (previousRevision.subsection_id !== idToGetSub) {
          setIdToGetSub(previousRevision.subsection_id);
        }

        setClaimData((claimData) => ({
          ...claimData,
          contractor_id: previousRevision.contractor_id,
        }));
      }

      if (previousRevision && previousRevision.subsection_id !== idToGetSub) {
        setIdToGetSub(previousRevision.subsection_id);
      }
    }
  }, [claimData.revision_id, revisions, idToGetSub, currentUser.user]);

  // if this window (claim creation window) is opened through the revision results window,
  // then set all the inputs according to the data specified in the given revision,
  // i.e. select the appropriate subsection, revision, revision's claim, contractor
  useEffect(() => {
    if (rev_id) {
      const revisionToClaim = revisions.find(
        (revision) => revision.id === rev_id
      );
      if (revisionToClaim) {
        setIdToGetSub(revisionToClaim.subsection_id);
        setClaimData((claimData) => ({
          ...claimData,
          revision_id: revisionToClaim.id,
          claim_id: revisionToClaim.claim_id || null,
          contractor_id: revisionToClaim.contractor_id,
        }));
      }
    }
  }, [rev_id, revisions]);

  // reset previous claim and revision selects if user changes subsection
  useEffect(() => {
    if (subToClaim) {
      if (subToClaim.id === idToGetSub) return;
      setClaimData((c) => ({ ...c, claim_id: null, revision_id: null }));
      if (idToGetSub) setIdToGetSub(null);
    }
  }, [subToClaim, idToGetSub]);

  // check if all the input data errors have been eliminated
  useEffect(() => {
    if (!isDataValid) {
      for (const i in errors) {
        if (errors[i].error) return;
      }
      setIsDataValid(true);
    }
  }, [errors, isDataValid]);

  const handleSelectChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      checkValidity(e);
    }
    setClaimData({ ...claimData, [e.target.name]: parseInt(e.target.value) });
  };

  const handleChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      checkValidity(e);
    }
    if (e.target.name === "reason") {
      return setClaimData({
        ...claimData,
        reason_id: null,
        reason: e.target.value,
      });
    }

    setClaimData({ ...claimData, [e.target.name]: e.target.value });
  };

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    for (const i in errors) {
      if (i === "subsection") {
        if (!subToClaim) {
          setErrors((errors) => ({
            ...errors,
            subsection: { ...errors.subsection, error: "Выберите подраздел" },
          }));
          valid = false;
          if (isDataValid) setIsDataValid(false);
        }
      } else if (!claimData[i]) {
        setErrors((errors) => ({
          ...errors,
          [i]: { ...errors[i], error: "Обязательное поле" },
        }));
        valid = false;
        if (isDataValid) setIsDataValid(false);
      }
    }
    if (!valid) return;

    const callback = closeResult || close;

    dispatch(
      addClaimStart(
        {
          ...claimData,
          subsection_id: subToClaim.id,
        },
        markerCoords,
        backupPhotos.current,
        currentUser,
        site_id,
        callback
      )
    );
  };

  return (
    <>
      <div className="caption">
        Выписать предписание
        <button className="close" onClick={checkInputsAndClose}></button>
      </div>
      <div>
        <PreviousClaims
          revisionBtn
          revision_id={claimData.revision_id}
          claim_id={claimData.claim_id}
          handleChange={handleSelectChange}
          subsectionId={subToClaim && subToClaim.id}
          noAttachedRevisions
          clearData={setClaimData}
        />
        <form onSubmit={handleSubmit}>
          <SelectSubsection
            subsection={subToClaim}
            selectSub={selectSub}
            idToGetSub={idToGetSub}
            markerCoords={markerCoords}
            setMarkerCoords={setMarkerCoords}
            validationType="required"
            err={errors.subsection.error}
            setErrors={setErrors}
          />
          <InputField
            type="text"
            label="Краткое название"
            name="title"
            placeholder="побелка стены, например..."
            value={claimData.title}
            onChange={handleChange}
            autoComplete="off"
            validationType="required"
            err={errors.title.error}
          />
          <SelectContractor
            contractorId={claimData.contractor_id}
            selectContractorId={handleSelectChange}
            validationType="required"
            err={errors.contractor_id.error}
          />
          <SelectReason
            reason_id={claimData.reason_id}
            data={claimData}
            setData={setClaimData}
          />
          <InputField
            type="text"
            label="или указать другое основание"
            name="reason"
            value={claimData.reason}
            onChange={handleChange}
          />
          <TextAreaInput
            label="Предписания"
            height="20vh"
            name="descriptions"
            value={claimData.descriptions}
            onChange={handleChange}
          />
          {subToClaim && (
            <SetPointsOnPlans
              subsection={subToClaim}
              markerCoords={markerCoords}
              setMarkerCoords={setMarkerCoords}
              pinType={
                claimData.revision_id || claimData.claim_id ? "many" : "claim"
              }
            />
          )}
          <AddBackup filesForSubmit={backupPhotos} />
          <div className="info">
            После добавления предписания, не будет возможности его
            редактировать.
            <br />
            Также, будет выслано уведомление исполнителю (подрядчику), при
            наличии у него указанного e-mail.
          </div>
          <div className="info err">
            {errorMsg || (!isDataValid && "Не все поля заполнены корректно")}
          </div>
          <div className="buttons">
            <button className="green" submitstyle="true" disabled={isFetching}>
              {isFetching ? "загрузка..." : "добавить"}
            </button>
            <button
              className="cancel"
              type="button"
              onClick={checkInputsAndClose}
            >
              Отмена
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddClaim;
