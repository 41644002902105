const ReportsActionTypes = {
  ADD_REPORT_START: "ADD_REPORT_START",
  ADD_REPORT_SUCCESS: "ADD_REPORT_SUCCESS",
  ADD_REPORT_FAILURE: "ADD_REPORT_FAILURE",
  GET_REPORTS_START: "GET_REPORTS_START",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  GET_REPORTS_FAILURE: "GET_REPORTS_FAILURE",
  // DELETE_REPORT_START: "DELETE_REPORT_START",
  // DELETE_REPORT_SUCCESS: "DELETE_REPORT_SUCCESS",
  // DELETE_REPORT_FAILURE: "DELETE_REPORT_FAILURE",
  // EDIT_REPORT_START: "EDIT_REPORT_START",
  // EDIT_REPORT_SUCCESS: "EDIT_REPORT_SUCCESS",
  // EDIT_REPORT_FAILURE: "EDIT_REPORT_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default ReportsActionTypes;
