import React, { useState, useEffect, useRef } from "react";
import { InputField, TextAreaInput } from "../../input-forms";
import AllWorksSelector from "./all-works-selector";
// import DatePicker from "react-date-picker";
import SetPointsOnPlans from "../pointsOnPlan/set-points-on-plans";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getSingleWorkStart,
  getWorkClaimsStart,
} from "../../../redux/works/works.actions";
import { getSingleSubsectionStart } from "../../../redux/subsections/subsections.actions";
import AddBackup from "../add-backup";
import { addClaimStart } from "../../../redux/claims/claims.actions";
// import { formatDate } from "../../../utils/normalizeData";

const AddWorkClaim = ({ work_id, close, checkInputs, checkInputsAndClose }) => {
  const dispatch = useDispatch();
  const { site_id } = useParams();

  const currentWork = useSelector((state) => state.works.currentWork);
  const currentSub = useSelector(
    (state) => state.subsections.currentSubsection
  );
  const isFetchingSub = useSelector((state) => state.subsections.isFetching);
  const isAddingClaim = useSelector((state) => state.claims.isFetching);
  const currentUser = useSelector((state) => state.user.currentUser);

  const [workToClaim, setWorkToClaim] = useState();

  useEffect(() => {
    if (!currentWork || currentWork.id !== work_id) {
      dispatch(getSingleWorkStart(currentUser.user, work_id));
    } else {
      setWorkToClaim(currentWork);
    }
  }, [currentWork, dispatch, work_id, currentUser.user, site_id]);

  console.log("CURRENT SUB: ", currentSub);
  console.log("WORK TO CLAIM: ", workToClaim);

  useEffect(() => {
    if (workToClaim) {
      if (!currentSub || workToClaim.subsection_id !== currentSub.id) {
        dispatch(
          getSingleSubsectionStart(
            currentUser.user,
            site_id,
            workToClaim.subsection_id
          )
        );
      }
    }
  }, [workToClaim, currentSub, currentUser.user, site_id, dispatch]);

  const [claimData, setClaimData] = useState({
    title: "",
    reason: "",
    descriptions: "",
  });
  // const [deadline, setDeadline] = useState(new Date());
  const [markerCoords, setMarkerCoords] = useState([]);
  const backupPhotos = useRef();

  checkInputs.current = () => {
    if (
      claimData.title ||
      claimData.item ||
      claimData.reason ||
      claimData.descriptions ||
      markerCoords.length ||
      (backupPhotos.curent && backupPhotos.current.length)
    )
      return true;
    return false;
  };

  const handleChange = (e) => {
    setClaimData({ ...claimData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentWork) {
      const callback = () => {
        dispatch(getWorkClaimsStart(currentUser.user, currentWork.id));
        close();
      };

      dispatch(
        addClaimStart(
          {
            ...claimData,
            job_id: currentWork.id,
            subsection_id: currentWork.subsection_id,
            user_id: currentUser.user,
            // date: formatDate(deadline),
          },
          markerCoords,
          backupPhotos.current,
          currentUser,
          site_id,
          callback
        )
      );
    }
  };

  return (
    <div>
      <div className="caption">
        Создать предписание к работе
        <button className="close" onClick={checkInputsAndClose}></button>
      </div>
      <form onSubmit={handleSubmit}>
        <InputField
          type="text"
          label="Название"
          placeholder="Краткое название или номер предписания"
          validationType="required"
          name="title"
          onChange={handleChange}
          value={claimData.title}
        />
        <AllWorksSelector
          workToClaim={workToClaim}
          setWorkToClaim={setWorkToClaim}
        />
        <InputField
          type="text"
          label="Основание"
          name="reason"
          onChange={handleChange}
          value={claimData.reason}
        />
        <TextAreaInput
          label="Предписания"
          name="descriptions"
          onChange={handleChange}
          value={claimData.descriptions}
        />
        {/* <div>
          <div className={classes.deadline}>Дата устранения</div>
          <DatePicker
            onChange={setDeadline}
            value={deadline}
            clearIcon={null}
            format="dd-MM-yyyy"
            validationType="required"
            locale="ru-RU"
          />
        </div> */}
        {isFetchingSub && <div>загрузка подраздела...</div>}
        {currentSub && (
          <SetPointsOnPlans
            markerCoords={markerCoords}
            setMarkerCoords={setMarkerCoords}
            subsection={currentSub}
            pinType="claim"
          />
        )}
        <AddBackup filesForSubmit={backupPhotos} />
        <div className="buttons">
          <button className="green" disabled={isAddingClaim}>
            Cоздать и отправить
          </button>
          <button
            className="cancel"
            type="button"
            onClick={checkInputsAndClose}
            disabled={isAddingClaim}
          >
            Отмена
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddWorkClaim;
