const ReasonsActionTypes = {
  ADD_REASON_START: "ADD_REASON_START",
  ADD_REASON_SUCCESS: "ADD_REASON_SUCCESS",
  ADD_REASON_FAILURE: "ADD_REASON_FAILURE",
  GET_REASONS_START: "GET_REASONS_START",
  GET_REASONS_SUCCESS: "GET_REASONS_SUCCESS",
  GET_REASONS_FAILURE: "GET_REASONS_FAILURE",
  DELETE_REASON_START: "DELETE_REASON_START",
  DELETE_REASON_SUCCESS: "DELETE_REASON_SUCCESS",
  DELETE_REASON_FAILURE: "DELETE_REASON_FAILURE",
  EDIT_REASON_START: "EDIT_REASON_START",
  EDIT_REASON_SUCCESS: "EDIT_REASON_SUCCESS",
  EDIT_REASON_FAILURE: "EDIT_REASON_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default ReasonsActionTypes;
