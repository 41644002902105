import {
  takeLeading,
  put,
  all,
  call,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import axios from "axios";
import { handleError, isEmpty } from "../../../utils/normalizeData";

import TechCardsActionTypes from "./techCards.types";

import {
  addTechCardSuccess,
  addTechCardFailure,
  getTechCardsStart,
  getTechCardsSuccess,
  getTechCardsFailure,
  editTechCardSuccess,
  editTechCardFailure,
  deleteTechCardSuccess,
  deleteTechCardFailure,
  addTechGroupSuccess,
  addTechGroupFailure,
  getTechGroupsStart,
  getTechGroupsSuccess,
  getTechGroupsFailure,
  editTechGroupSuccess,
  editTechGroupFailure,
  deleteTechGroupSuccess,
  deleteTechGroupFailure,
  getCurrentTechCardStart,
  getCurrentTechCardFailure,
  getCurrentTechCardSuccess,
} from "./techCards.actions";

const normalizeSubmitData = (newTechCardData, adminId, submitData) => {
  const {
    selectedVariables,
    selectedSpecialties,
    selectedEquipment,
    selectedMaterials,
  } = newTechCardData;

  submitData.who = adminId;

  if (selectedVariables.length) {
    submitData.variableColumns = ["variable_id"];
    submitData.variableValues = [
      ...selectedVariables.map((variable) => [variable]),
    ];
  }
  if (selectedSpecialties.length) {
    submitData.employeeColumns = Object.keys(selectedSpecialties[0]);
    submitData.employeeValues = selectedSpecialties.map((specialty) =>
      Object.values(specialty)
    );
  }
  if (selectedEquipment.length) {
    submitData.equipmentColumns = Object.keys(selectedEquipment[0]);
    submitData.equipmentValues = selectedEquipment.map((eq) =>
      Object.values(eq)
    );
  }
  if (selectedMaterials.length) {
    submitData.materialColumns = Object.keys(selectedMaterials[0]);
    submitData.materialValues = selectedMaterials.map((material) =>
      Object.values(material)
    );
  }
  return submitData;
};

export function* addTechCard({ payload, callback }) {
  const { newTechCardData, adminId } = payload;
  const { techCardName, techCardGroupId } = newTechCardData;

  const submitData = {
    cardsColumns: ["name", "card_group_id"],
    cardsValues: [[techCardName, techCardGroupId]],
  };

  const postData = normalizeSubmitData(newTechCardData, adminId, submitData);

  console.log("POST DATA: ", postData);
  try {
    yield axios.post(`/api/admin/cards/add`, postData);
    yield put(addTechCardSuccess());
    yield put(getTechCardsStart(techCardGroupId, adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addTechCardFailure(error));
  }
}

export function* editTechCard({ payload, callback }) {
  const { newTechCardData, adminId, card_id, oldEditCardGroupId } = payload;
  const { techCardName, techCardGroupId } = newTechCardData;

  const submitData = {
    card: {
      name: techCardName,
      card_group_id: techCardGroupId,
    },
    card_id,
  };

  const putData = normalizeSubmitData(newTechCardData, adminId, submitData);

  try {
    yield axios.put("/api/admin/cards/edit", putData);
    yield put(editTechCardSuccess());
    yield put(getCurrentTechCardStart(card_id, adminId));
    if (oldEditCardGroupId !== techCardGroupId) {
      console.log("NOT EQUAL");
      yield put(getTechCardsStart(oldEditCardGroupId, adminId));
    }
    yield put(getTechCardsStart(techCardGroupId, adminId));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editTechCardFailure(error));
  }
}

export function* getTechCards({ payload }) {
  const { groupId, adminId } = payload;

  const requestPath = groupId
    ? `/api/admin/cards_by_group/${adminId}/${groupId}`
    : `/api/admin/cards_no_group/${adminId}`;

  try {
    const response = yield axios.get(requestPath);
    yield put(getTechCardsSuccess(groupId, Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getTechCardsFailure(error));
  }
}

export function* deleteTechCard({ payload, callback }) {
  const { techCardId, adminId, groupId } = payload;
  try {
    yield axios.put("/api/admin/cards/mark_as_deleted", {
      who: adminId,
      card_id: techCardId,
    });
    yield put(deleteTechCardSuccess(techCardId));
    yield put(getTechCardsStart(groupId, adminId));
    callback();
  } catch (e) {
    let error = "Ошибка сети";
    if (e.response && e.response.data) {
      if (e.response.data.message) error = e.response.data.message;
      else if (e.response.data.errno) {
        if (e.response.data.errno === 1451)
          error = "Нельзя удалить специальность, прикрепленное к действиям";
      }
    }
    console.log(e);
    yield put(deleteTechCardFailure(error));
  }
}

export function* addTechGroup({ payload, callback }) {
  const { groupName, groupId, adminId } = payload;

  const techGroupPostData = {
    row: {
      name: groupName,
      card_group_id: groupId,
    },
    who: adminId,
  };
  console.log("GROUP POST DATA: ", techGroupPostData);
  try {
    yield axios.post("/api/admin/card_groups/add", techGroupPostData);
    yield put(addTechGroupSuccess());
    yield put(getTechGroupsStart(adminId));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addTechGroupFailure(error));
  }
}

export function* getTechGroups({ payload }) {
  try {
    const response = yield axios.get(`/api/admin/card_groups/${payload}`);
    yield put(getTechGroupsSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getTechGroupsFailure(error));
  }
}

export function* editTechGroup({ payload, callback }) {
  const { groupName, groupId, adminId } = payload;

  const putData = {
    row: {
      name: groupName,
    },
    who: adminId,
    card_group: groupId,
  };
  console.log("PUT DATA: ", putData);
  try {
    yield axios.put("/api/admin/card_groups/edit", putData);
    yield put(editTechGroupSuccess());
    yield put(getTechGroupsStart(adminId));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editTechGroupFailure(error));
  }
}

export function* deleteTechGroup({ payload, callback }) {
  const { techGroupId, adminId } = payload;
  const putData = {
    who: adminId,
    card_group_id: techGroupId,
  };
  console.log("MARK AS DELETE DATA: ", putData);
  try {
    yield axios.put("/api/admin/card_groups/mark_as_deleted", putData);
    yield put(deleteTechGroupSuccess());
    yield put(getTechGroupsStart(adminId));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(deleteTechGroupFailure(error));
  }
}

export function* getCurrentTechCard({ payload }) {
  const { cardId, adminId } = payload;

  try {
    const response = yield axios.get(`/api/admin/cards/${adminId}/${cardId}`);
    if (isEmpty(response.data)) {
      return yield put(getCurrentTechCardFailure("Карта не найдена"));
    }
    yield put(getCurrentTechCardSuccess(...Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getCurrentTechCardFailure(error));
  }
}

export function* onGetTechCardsStart() {
  yield takeEvery(TechCardsActionTypes.GET_TECH_CARDS_START, getTechCards);
}

export function* onAddTechCardStart() {
  yield takeLatest(TechCardsActionTypes.ADD_TECH_CARD_START, addTechCard);
}

export function* onEditTechCardStart() {
  yield takeLatest(TechCardsActionTypes.EDIT_TECH_CARD_START, editTechCard);
}

export function* onDeleteTechCardStart() {
  yield takeLeading(
    TechCardsActionTypes.DELETE_TECH_CARD_START,
    deleteTechCard
  );
}

export function* onAddTechGroupStart() {
  yield takeLatest(TechCardsActionTypes.ADD_TECH_GROUP_START, addTechGroup);
}

export function* onGetTechGroupStart() {
  yield takeLeading(TechCardsActionTypes.GET_TECH_GROUPS_START, getTechGroups);
}

export function* onEditTechGroupStart() {
  yield takeLatest(TechCardsActionTypes.EDIT_TECH_GROUP_START, editTechGroup);
}

export function* onDeleteTechGroupStart() {
  yield takeLeading(
    TechCardsActionTypes.DELETE_TECH_GROUP_START,
    deleteTechGroup
  );
}

export function* onGetCurrentTechCardStart() {
  yield takeLeading(
    TechCardsActionTypes.GET_CURRENT_TECH_CARD_START,
    getCurrentTechCard
  );
}

export function* techCardsSagas(args) {
  yield all([
    call(onGetTechCardsStart),
    call(onAddTechCardStart),
    call(onEditTechCardStart),
    call(onDeleteTechCardStart),
    call(onAddTechGroupStart),
    call(onGetTechGroupStart),
    call(onEditTechGroupStart),
    call(onDeleteTechGroupStart),
    call(onGetCurrentTechCardStart),
  ]);
}
