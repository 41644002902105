import React, { useState, useEffect } from "react";
import PreviousClaims from "./previous-claims";
import PreviousRevisions from "./previous-revisions";

const PreviousActions = ({
  revisionBtn,
  claimBtn,
  revision_id,
  claim_id,
  handleChange,
  subsectionId,
  noAttachedRevisions,
  clearData,
}) => {
  const [previous, showPrevious] = useState({
    claims: false,
    revisions: false,
  });

  // show this component at mount if claim_id provided from revision edit page
  useEffect(() => {
    if (claim_id && !previous.claims)
      showPrevious({ ...previous, claims: true });
    if (revision_id && !previous.revisions) {
      showPrevious({ ...previous, revisions: true });
    }
  }, [claim_id, revision_id, previous]);

  const togglePrevious = (e) => {
    if (e.target.name === "claims") {
      showPrevious({ ...previous, claims: !previous.claims });
      if (clearData) {
        clearData((data) => ({ ...data, claim_id: null }));
      }
    } else if (e.target.name === "revisions") {
      showPrevious({ ...previous, revisions: !previous.revisions });
      if (clearData) {
        clearData((data) => ({ ...data, revision_id: null }));
      }
    }
  };

  return (
    <>
      <div className="connect">
        {claimBtn && (
          <button
            className={`${previous.claims ? "sel" : ""}`}
            name="claims"
            onClick={togglePrevious}
            type="button"
          >
            Привязать к предписанию
          </button>
        )}
        {revisionBtn && (
          <button
            className={`${previous.revisions ? "sel" : ""}`}
            name="revisions"
            onClick={togglePrevious}
            type="button"
          >
            Привязать к проверке
          </button>
        )}
      </div>
      {(previous.claims || previous.revisions) && (
        <div className="field appear">
          {previous.claims && (
            <PreviousClaims
              handleChange={handleChange}
              subsectionId={subsectionId}
              claim_id={claim_id}
            />
          )}
          {previous.revisions && (
            <PreviousRevisions
              handleChange={handleChange}
              subsectionId={subsectionId}
              revision_id={revision_id}
              noAttachedRevisions={noAttachedRevisions}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PreviousActions;
