import RemarksActionTypes from "./remarks.types";

export const addRemarkStart = (
  remarkData,
  markerCoords,
  photos,
  currentUser,
  site_id,
  callback
) => ({
  type: RemarksActionTypes.ADD_REMARK_START,
  payload: { remarkData, markerCoords, photos, currentUser, site_id },
  callback,
});

export const addRemarkSuccess = () => ({
  type: RemarksActionTypes.ADD_REMARK_SUCCESS,
});

export const addRemarkFailure = (error) => ({
  type: RemarksActionTypes.ADD_REMARK_FAILURE,
  payload: error,
});

export const getRemarksStart = (currentUserId, site_id) => ({
  type: RemarksActionTypes.GET_REMARKS_START,
  payload: { currentUserId, site_id },
});

export const getRemarksSuccess = (remarks) => ({
  type: RemarksActionTypes.GET_REMARKS_SUCCESS,
  payload: remarks,
});

export const getRemarksFailure = (error) => ({
  type: RemarksActionTypes.GET_REMARKS_FAILURE,
  payload: error,
});

export const getRemarksBySubStart = (currentUserId, sub_id) => ({
  type: RemarksActionTypes.GET_REMARKS_BY_SUB_START,
  payload: { currentUserId, sub_id },
});

export const getRemarksBySubSuccess = (sub_id, remarks) => ({
  type: RemarksActionTypes.GET_REMARKS_BY_SUB_SUCCESS,
  payload: { sub_id, remarks },
});

export const getRemarksBySubFailure = (error) => ({
  type: RemarksActionTypes.GET_REMARKS_BY_SUB_FAILURE,
  payload: error,
});

export const getRemarksByUserStart = (currentUserId) => ({
  type: RemarksActionTypes.GET_REMARKS_BY_USER_START,
  payload: currentUserId,
});

export const getRemarksByUserSuccess = (remarks) => ({
  type: RemarksActionTypes.GET_REMARKS_BY_USER_SUCCESS,
  payload: remarks,
});

export const getRemarksByUserFailure = (error) => ({
  type: RemarksActionTypes.GET_REMARKS_BY_USER_FAILURE,
  payload: error,
});

export const editRemarkStart = (remark) => ({
  type: RemarksActionTypes.EDIT_REMARK_START,
  payload: remark,
});

export const editRemarkSuccess = () => ({
  type: RemarksActionTypes.EDIT_REMARK_SUCCESS,
});

export const editRemarkFailure = (error) => ({
  type: RemarksActionTypes.EDIT_REMARK_FAILURE,
  payload: error,
});

export const deleteRemarkStart = (remark) => ({
  type: RemarksActionTypes.DELETE_REMARK_START,
  payload: remark,
});

export const deleteRemarkSuccess = () => ({
  type: RemarksActionTypes.DELETE_REMARK_SUCCESS,
});

export const deleteRemarkFailure = (error) => ({
  type: RemarksActionTypes.DELETE_REMARK_FAILURE,
  payload: error,
});

export const updateRemarkStatusStart = (
  status_id,
  currentUserId,
  remarkId,
  callback
) => ({
  type: RemarksActionTypes.UPDATE_REMARK_STATUS_START,
  payload: { status_id, currentUserId, remarkId },
  callback,
});

export const updateRemarkStatusSuccess = () => ({
  type: RemarksActionTypes.UPDATE_REMARK_STATUS_SUCCESS,
});

export const updateRemarkStatusFailure = (error) => ({
  type: RemarksActionTypes.UPDATE_REMARK_STATUS_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: RemarksActionTypes.CLEAR_ERRORS,
});
