import MaterialUnitsActionTypes from "./materialUnits.types";

const INITIAL_STATE = {
  isAdding: false,
  isFetching: false,
  isDeleting: false,
  error: null,
  materialUnits: null,
};

const materialUnitsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MaterialUnitsActionTypes.ADD_MATERIAL_UNIT_START:
    case MaterialUnitsActionTypes.EDIT_MATERIAL_UNIT_START:
      return { ...state, isAdding: true, error: null };
    case MaterialUnitsActionTypes.GET_MATERIAL_UNITS_START:
      return { ...state, isFetching: true, error: null };
    case MaterialUnitsActionTypes.DELETE_MATERIAL_UNIT_START:
      return {
        ...state,
        isDeleting: true,
        error: null,
      };

    case MaterialUnitsActionTypes.ADD_MATERIAL_UNIT_SUCCESS:
    case MaterialUnitsActionTypes.EDIT_MATERIAL_UNIT_SUCCESS:
      return { ...state, isAdding: false, error: null };
    case MaterialUnitsActionTypes.DELETE_MATERIAL_UNIT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        error: null,
      };

    case MaterialUnitsActionTypes.GET_MATERIAL_UNITS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        materialUnits: action.payload,
      };

    case MaterialUnitsActionTypes.ADD_MATERIAL_UNIT_FAILURE:
    case MaterialUnitsActionTypes.EDIT_MATERIAL_UNIT_FAILURE:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      };

    case MaterialUnitsActionTypes.GET_MATERIAL_UNITS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case MaterialUnitsActionTypes.DELETE_MATERIAL_UNIT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };

    case MaterialUnitsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default materialUnitsReducer;
