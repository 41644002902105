import UserActionTypes from "./user.types";

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")),
  isFetching: false,
  isDeleting: false,
  error: null,
  usersList: null,
  recover: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_START:
    case UserActionTypes.ADD_USER_START:
    case UserActionTypes.GET_USERS_START:
    case UserActionTypes.EDIT_USER_START:
    case UserActionTypes.RECOVER_PASSWORD_START:
    case UserActionTypes.RESET_PASSWORD_START:
    case UserActionTypes.CHANGE_PASSWORD_START:
      return { ...state, isFetching: true, error: null };

    case UserActionTypes.DELETE_USER_START:
      return { ...state, isDeleting: true, error: null };

    case UserActionTypes.RECOVER_PASSWORD_SUCCESS:
      return { ...state, error: null, recover: true };

    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
        isFetching: false,
      };

    case UserActionTypes.SIGN_OUT_SUCCESS: {
      return { ...state, currentUser: null, usersList: null };
    }

    case UserActionTypes.EDIT_USER_SUCCESS:
      if (action.payload.currentUser) {
        return {
          ...state,
          isFetching: false,
          error: null,
          currentUser: action.payload.currentUser,
        };
      } else {
        return {
          ...state,
          isFetching: false,
          error: null,
        };
      }

    case UserActionTypes.ADD_USER_SUCCESS:
    case UserActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
      };

    case UserActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        currentUser: action.payload,
      };

    case UserActionTypes.GET_USERS_SUCCESS: {
      return {
        ...state,
        usersList: action.payload,
        error: null,
        isFetching: false,
      };
    }

    case UserActionTypes.DELETE_USER_SUCCESS: {
      return {
        ...state,
        isDeleting: false,
        error: null,
      };
    }

    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.ADD_USER_FAILURE:
    case UserActionTypes.GET_USERS_FAILURE:
    case UserActionTypes.DELETE_USER_FAILURE:
    case UserActionTypes.RECOVER_PASSWORD_FAILURE:
    case UserActionTypes.RESET_PASSWORD_FAILURE:
    case UserActionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isFetching: false,
        isDeleting: false,
        recover: false,
        error: action.payload,
      };

    case UserActionTypes.CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default userReducer;
