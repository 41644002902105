import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRemarksStart,
  getRemarksBySubStart,
  getRemarksByUserStart,
} from "../../../redux/remarks/remarks.actions";
import { useParams, Link } from "react-router-dom";
import { formatDate } from "../../../utils/normalizeData";
import InlineModal from "../../inlineModal";
import RemarkInfo from "./remark-info";

const RemarksList = () => {
  const dispatch = useDispatch();
  const [filterRemarks, setFilterRemarks] = useState("all");
  const remarks = useSelector((state) => state.remarks.remarks);
  const remarksBySub = useSelector((state) => state.remarks.remarksBySub);
  const remarksByUser = useSelector((state) => state.remarks.remarksByUser);
  const statuses = useSelector((state) => state.statuses.remarks);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetching = useSelector((state) => state.remarks.isFetching);

  const { site_id, sub_id } = useParams();

  const [onlyMyWorks, toggleOnlyMyWorks] = useState(false);
  const [remarksToRender, setRemarksToRender] = useState();
  const [remarkIdToView, showRemarkInfoModal] = useState();

  useEffect(() => {
    if (onlyMyWorks) {
      if (remarksByUser) {
        sub_id
          ? setRemarksToRender(
              remarksByUser.filter(
                (remark) => String(remark.subsection_id) === sub_id
              )
            )
          : setRemarksToRender(remarksByUser);
      } else {
        dispatch(getRemarksByUserStart(currentUser.user));
      }
    } else if (sub_id) {
      remarksBySub[sub_id]
        ? setRemarksToRender(remarksBySub[sub_id])
        : dispatch(getRemarksBySubStart(currentUser.user, sub_id));
    } else {
      remarks
        ? setRemarksToRender(remarks)
        : dispatch(getRemarksStart(currentUser.user, site_id));
    }
  }, [
    onlyMyWorks,
    sub_id,
    remarks,
    remarksBySub,
    dispatch,
    currentUser.user,
    site_id,
    remarksByUser,
  ]);

  return (
    <div className="content">
      {remarkIdToView && (
        <InlineModal close={() => showRemarkInfoModal(false)}>
          <RemarkInfo
            close={() => showRemarkInfoModal(false)}
            remark_id={remarkIdToView}
          />
        </InlineModal>
      )}

      <p className="only_my">
        <label>
          <input
            type="checkbox"
            checked={onlyMyWorks}
            onChange={() => toggleOnlyMyWorks(!onlyMyWorks)}
          />
          Показать только мои замечания
        </label>
      </p>

      <div className="navigate">
        <span
          onClick={() => setFilterRemarks("all")}
          className={filterRemarks === "all" ? "sel" : ""}
        >
          все
        </span>
        <span
          onClick={() => setFilterRemarks("unconfirmed")}
          className={filterRemarks === "unconfirmed" ? "sel" : ""}
        >
          не подтвержденные
        </span>
        <span
          onClick={() => setFilterRemarks("completed")}
          className={filterRemarks === "completed" ? "sel" : ""}
        >
          исправленные и подтвержденные
        </span>
      </div>

      {isFetching && <span className="info">загрузка...</span>}

      {remarksToRender && statuses && (
        <table className="list">
          <thead>
            <tr>
              <th>исправить до</th>
              <th>работа</th>
              <th>основание</th>
              <th>статус</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {remarksToRender
              .filter((remark) => {
                if (filterRemarks === "unconfirmed") {
                  return statuses[remark.status_id] === "исправлено";
                } else if (filterRemarks === "completed") {
                  return (
                    statuses[remark.status_id] === "исправлено и подтверждено"
                  );
                }
                return remark;
              })
              .map((remark) => (
                <tr key={remark.id}>
                  <td>{formatDate(remark.date, true)}</td>
                  <td>
                    <Link
                      className="link"
                      to={`/main/sites/${site_id}/works/${remark.job_id}`}
                    >
                      {remark.job_name}
                    </Link>{" "}
                  </td>
                  <td>{remark.reason}</td>
                  <td>{statuses && statuses[remark.status_id]}</td>
                  <td>
                    <button
                      className="blue"
                      onClick={() => showRemarkInfoModal(remark.id)}
                    >
                      смотреть замечание
                    </button>
                  </td>
                  <td></td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RemarksList;
