import React, { Fragment } from "react";
import { isEmpty } from "../../../../utils/normalizeData";
import { NumberInput } from "../../../input-forms";

const TechCardVariables = ({
  cardVariables,
  variableInputs,
  setVariableInputs,
  userEditedCardSpecs,
  userEditedCardEquipment,
  userEditedCardMaterials,
  userChangedVariable,
}) => {
  const handleVariableChange = (e) => {
    userEditedCardSpecs.current = {};
    userEditedCardEquipment.current = {};
    userEditedCardMaterials.current = {};
    userChangedVariable.current = true;
    setVariableInputs({
      ...variableInputs,
      [e.target.name]: e.target.value ? +e.target.value : "",
    });
  };

  const renderCardVariables = (variablesObj) => {
    const varArr = [];
    for (const i in variablesObj) {
      const currentVar = variablesObj[i];
      if (variableInputs.hasOwnProperty(currentVar.variable)) {
        varArr.push(
          <Fragment key={i}>
            <div>
              <span>{currentVar.name_print}</span>
            </div>
            <div>
              <NumberInput
                name={currentVar.variable}
                value={variableInputs[currentVar.variable]}
                onChange={handleVariableChange}
                step="0.00001"
              />
            </div>
          </Fragment>
        );
      }
    }
    return varArr;
  };

  return (
    <>
      {!isEmpty(variableInputs) &&
        !isEmpty(cardVariables) &&
        renderCardVariables(cardVariables)}
    </>
  );
};

export default TechCardVariables;
