import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  clearErrors,
  getSitesStart,
  clearSiteInputs,
} from "../../redux/sites/sites.actions";
import { useDispatch, useSelector } from "react-redux";
import InlineModal from "../../components/inlineModal";
import AddSite from "../../components/manage-sites/add-site";
import UserInfo from "../../components/manage-users/user-info";
import { isEmpty } from "../../utils/normalizeData";
import { user_photos } from "../../config";

const Sites = () => {
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const history = useHistory();
  const currentUser = useSelector((state) => state.user.currentUser);
  const statuses = useSelector((state) => state.statuses.sites);
  let sites = useSelector((state) => state.sites.sites);
  const users = useSelector((state) => state.user.usersList);
  const isFetching = useSelector((state) => state.sites.isFetching);
  const errorMessage = useSelector((state) => state.sites.error);
  const [addSiteWindow, showAddSiteWindow] = useState();
  const [completed, selectCompleted] = useState(false);

  useEffect(() => {
    dispatch(clearErrors());
    dispatch(getSitesStart(currentUser));
  }, [dispatch, currentUser]);

  if (statuses) {
    if (completed) {
      sites = sites.filter(
        (site) => statuses[site.status_id] === "завершенные"
      );
    } else {
      sites = sites.filter((site) => statuses[site.status_id] === "актуальные");
    }
  }

  const addSite = () => {
    dispatch(clearSiteInputs());
    showAddSiteWindow(true);
  };

  const renderContact = (contact) => (
    <>
      {contact.name}
      <small>
        <a className="black" href={`tel: ${contact.tel}`}>
          {contact.tel}
        </a>
      </small>
      <small>
        <a className="black" href={`mailto: ${contact.email}`}>
          {contact.email}
        </a>
      </small>
    </>
  );

  const renderAvatars = (team) => {
    const teamArr = Object.values(team);
    const avatars = [];

    for (const i in teamArr) {
      const member = teamArr[i];
      const user = users.find((user) => user.id === member.user_id);
      if (user) {
        avatars.push(
          <Link
            key={user.id}
            to={`/main/sites/user/${user.id}`} /*onClick={() => showUserDetails(member.user_id)} key={i}*/
          >
            {user.img ? (
              <img
                className="avatar in_list"
                src={`${user_photos}/${user.org_id}/${user.img}`}
                alt="avatar"
              />
            ) : (
              <span className="avatar in_list">{`${user.firstname.charAt(
                0
              )}${user.lastname.charAt(0)}`}</span>
            )}
          </Link>
        );
      }
    }
    return avatars;
  };

  return (
    <div>
      <div className="header2">
        <ul>
          <li className="hdr">Объекты</li>
          {isFetching ? <span>загрузка объектов...</span> : ""}

          {errorMessage ? <span>{errorMessage}</span> : ""}
          <li className="btn">
            <button onClick={addSite}>Добавить</button>
          </li>
        </ul>
      </div>

      <ul className="tabs">
        <li>
          <Link
            to="#"
            onClick={() => selectCompleted(false)}
            className={!completed ? "sel" : ""}
          >
            Актуальные
          </Link>
        </li>
        <li>
          <Link
            to="#"
            onClick={() => selectCompleted(true)}
            className={completed ? "sel" : ""}
          >
            Завершенные
          </Link>
        </li>
      </ul>

      <div className="content">
        <table className="list multiline">
          <thead>
            <tr>
              <th>название</th>
              <th>проверок на сегодня</th>
              <th>претензий исправлено</th>
              <th>адрес</th>
              <th>контакт</th>
              <th>админ/команда</th>
            </tr>
          </thead>
          <tbody>
            {statuses &&
              sites.map((site) => (
                <tr key={site.id}>
                  <td>
                    <label className="mobile">название</label>
                    <Link to={`/main/sites/${site.id}/subsections`}>
                      {site.name}
                    </Link>
                  </td>

                  <td>
                    <label className="mobile">проверок на сегодня</label>
                    {site.revisions_count}
                  </td>

                  <td>
                    <label className="mobile">претензий исправлено</label>
                    {site.issued_claims}
                  </td>

                  <td>
                    <label className="mobile">адрес</label>
                    {site.address}
                    <small>
                      <a
                        href={`https://www.google.com/maps/search/${site.address}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        показать на карте
                      </a>
                    </small>
                  </td>

                  <td>
                    <label className="mobile">контакт</label>
                    {!isEmpty(site.obj_contacts) &&
                      renderContact(Object.values(site.obj_contacts)[0])}
                  </td>

                  <td>
                    <label className="mobile">админ/команда</label>
                    {renderAvatars(site.obj_teams)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {addSiteWindow && (
        <InlineModal close={() => showAddSiteWindow(false)}>
          <AddSite close={() => showAddSiteWindow(false)} />
        </InlineModal>
      )}

      {user_id && (
        <InlineModal>
          <UserInfo
            userId={+user_id}
            showUser={() => history.push("/main/sites")}
            basePath="/main/sites"
          />
        </InlineModal>
      )}
    </div>
  );
};

export default Sites;
