import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  deleteRevisionStart,
} from "../../../redux/revisions/revisions.actions";
import ConfirmActionModal from "../../confirmActionModal";
import InlineModal from "../../inlineModal";
import { formatDate, isEmpty } from "../../../utils/normalizeData";
import RevisionResult from "./revision-result";
import AddRevision from "./add-revision";
import ViewPoint from "../view-point";
import { Link, useHistory, useParams } from "react-router-dom";

const RevisionsList = ({ currentSubsectionId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const statuses = useSelector((state) => state.statuses.revisions);
  const revisions = useSelector((state) => state.revisions.revisions);
  const contractors = useSelector((state) => state.contractors.contractors);
  const subsections = useSelector((state) => state.subsections.subsections);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetching = useSelector((state) => state.revisions.isFetching);
  const errorMessage = useSelector((state) => state.revisions.error);
  const { site_id, sub_id, menu, info, action } = useParams();

  const [revisionsToRender, setRevisionsToRender] = useState([]);
  const [oldRevisionsToRender, setOldRevisionsToRender] = useState([]);

  const [revisionToResult, showResultWindow] = useState();
  const [revisionToDelete, setConfirmDelete] = useState();
  const [addRevisionWindow, showAddRevisionWindow] = useState();
  const [oldRevisions, toggleOldRevisions] = useState(false);

  useEffect(() => {
    if (revisions.length) {
      if (currentSubsectionId) {
        setRevisionsToRender(
          revisions.filter(
            (revision) => revision.subsection_id === currentSubsectionId
          )
        );
      } else {
        setRevisionsToRender(revisions);
      }
      if (statuses) {
        if (currentSubsectionId) {
          setOldRevisionsToRender(
            revisions.filter(
              (revision) =>
                statuses[revision.status_id] === "Пройдена" &&
                revision.subsection_id === currentSubsectionId
            )
          );
        } else {
          setOldRevisionsToRender(
            revisions.filter(
              (revision) => statuses[revision.status_id] === "Пройдена"
            )
          );
        }
      }
    }
  }, [revisions, statuses, currentSubsectionId]);

  useEffect(() => {
    if (action === "revision_result" && revisions.length && !revisionToResult) {
      const revToResult = revisions.find((revision) => revision.id === +info);
      if (revToResult) {
        showResultWindow(revToResult);
      }
    }
  }, [info, action, revisions, revisionToResult]);

  const revisionsByDates = revisionsToRender.reduce((acc, curr) => {
    if (curr.date) {
      let date = new Date(curr.date);

      if (date instanceof Date && !isNaN(date)) {
        date = date.setHours(0, 0, 0, 0);
        if (!acc.hasOwnProperty(date)) acc[date] = [];
        acc[date].push(curr);
        return acc;
      }
    }
    return acc;
  }, {});

  let today = new Date();
  today = {
    d: today.getDate(),
    m: today.getMonth() + 1,
    y: today.getFullYear(),
  };

  const compareDates = (dateString) => {
    let toCompare = new Date(Number(dateString));

    toCompare = {
      d: toCompare.getDate(),
      m: toCompare.getMonth() + 1,
      y: toCompare.getFullYear(),
    };
    const date = `${toCompare.d < 10 ? "0" + toCompare.d : toCompare.d}.${
      toCompare.m < 10 ? "0" + toCompare.m : toCompare.m
    }.${toCompare.y}`;

    if (toCompare.y - today.y === 0) {
      if (toCompare.m - today.m === 0) {
        const dayDifference = toCompare.d - today.d;
        if (dayDifference === 0) return `сегодня (${date})`;
        if (dayDifference === 1) return `завтра (${date})`;
      }
    }
    return date;
  };

  const sortedDates = Object.keys(revisionsByDates).sort();

  const deletable = (revision) => {
    return (
      statuses &&
      isEmpty(revision.claim_from_rev) &&
      isEmpty(revision.claim_to_rev) &&
      (statuses[revision.status_id] === "Ждет проверки" ||
        statuses[revision.status_id] === "Перенесена")
    );
  };

  const renderContractorName = (contractorId) => {
    const contractor = contractors.find(
      (contractor) => contractor.id === contractorId
    );
    if (contractor) return contractor.name;
    return "";
  };

  const renderSubsectionName = (subsectionId) => {
    const subsection = subsections.find((sub) => sub.id === subsectionId);
    if (subsection) return subsection.name;
    return "";
  };

  const promptDeleteConfirmation = (revData) => {
    if (errorMessage) {
      dispatch(clearErrors());
    }
    setConfirmDelete(revData);
  };

  const path = `/main/sites/${site_id}`;
  const subPath = sub_id ? `/subsections/${sub_id}` : "";

  const byDates = sortedDates.map((date) => {
    const incompleteRevisions = revisionsByDates[date].filter(
      (revision) => statuses && statuses[revision.status_id] !== "Пройдена"
    );
    if (incompleteRevisions.length) {
      const byDate = (
        <Fragment key={date}>
          <h3>{`Проверки запланированные на ${compareDates(date)}`}</h3>
          <table className="list">
            <thead>
              <tr>
                <th>название</th>
                <th>подрядчик</th>
                <th>подраздел</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {incompleteRevisions.map((revision) => (
                <tr key={revision.id}>
                  <td>
                    <label className="mobile">название</label>
                    <Link to={`${path}${subPath}/revisions/${revision.id}`}>
                      {revision.title}
                    </Link>
                  </td>
                  <td>
                    <label className="mobile">подрядчик</label>
                    {renderContractorName(revision.contractor_id)}
                  </td>
                  <td>
                    <label className="mobile">подраздел</label>
                    {renderSubsectionName(revision.subsection_id)}
                  </td>
                  <td>
                    <button
                      className="link"
                      onClick={() =>
                        history.push(
                          `${path}${subPath}/revisions/${revision.id}/edit_revision`
                        )
                      }
                      disabled={
                        !isEmpty(revision.claim_from_rev) ||
                        !isEmpty(revision.claim_to_rev)
                      }
                    >
                      Ред.
                    </button>

                    <button
                      onClick={() => {
                        showResultWindow(revision);
                        history.push(
                          `${path}${subPath}/revisions/${revision.id}/revision_result`
                        );
                      }}
                    >
                      Результат
                    </button>

                    <button
                      className="remove"
                      disabled={!deletable(revision) || isFetching}
                      onClick={() =>
                        promptDeleteConfirmation({
                          name: revision.title,
                          id: revision.id,
                          subsection_id: revision.subsection_id,
                        })
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      );
      return byDate;
    }
    return "";
  });

  const handleDelete = (id, subsection_id) => {
    dispatch(
      deleteRevisionStart({ id, currentUser, site_id, subsection_id }, () =>
        setConfirmDelete(null)
      )
    );
  };

  return (
    <>
      {menu === "revisions" && info && action === "edit_revision" && (
        <InlineModal close={() => history.push(`${path}${subPath}/revisions`)}>
          <AddRevision
            rev_id={+info}
            close={() => history.push(`${path}${subPath}/revisions`)}
          />
        </InlineModal>
      )}

      {menu === "revisions" && info && !action && (
        <InlineModal close={() => history.push(`${path}${subPath}/revisions`)}>
          <ViewPoint
            rev_id={+info}
            // close={() => history.push(`/main/sites/${site_id}/revisions`)}
            close={() => history.push(`${path}${subPath}/revisions`)}
          />
        </InlineModal>
      )}

      {addRevisionWindow && (
        <InlineModal close={() => showAddRevisionWindow(false)}>
          <AddRevision close={() => showAddRevisionWindow(false)} />
        </InlineModal>
      )}

      {revisionToDelete && (
        <ConfirmActionModal
          confirm={() =>
            handleDelete(revisionToDelete.id, revisionToDelete.subsection_id)
          }
          cancel={() => setConfirmDelete(null)}
          message={`Уверены что хотите удалить проверку "${revisionToDelete.name}"?`}
          processing={isFetching}
          errorMsg={errorMessage}
        />
      )}

      {revisionToResult && action === "revision_result" && (
        <RevisionResult
          close={() => {
            showResultWindow(false);
            history.push(`/main/sites/${site_id}/revisions`);
          }}
          revision={revisionToResult}
        />
      )}

      <div className="content">
        <div className="buttons top">
          <button className="blue" onClick={() => showAddRevisionWindow(true)}>
            Запланировать проверку
          </button>
        </div>
        {byDates}
        {oldRevisionsToRender.length ? (
          <div>
            <div className="buttons">
              <button
                className="link"
                onClick={() => toggleOldRevisions(!oldRevisions)}
              >
                {`${oldRevisions ? "Скрыть" : "Показать"} пройденные проверки`}
              </button>
            </div>
            {oldRevisions ? (
              <table className="list">
                <thead>
                  <tr>
                    <th>Название</th>

                    <th>Подрядчик</th>
                    <th>Подраздел</th>
                    <th>Дата проверки</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {oldRevisionsToRender.map((revision) => (
                    <tr key={revision.id}>
                      <td>
                        <Link to={`${path}${subPath}/revisions/${revision.id}`}>
                          {revision.title}
                        </Link>
                      </td>
                      <td>{renderContractorName(revision.contractor_id)}</td>
                      <td>{renderSubsectionName(revision.subsection_id)}</td>
                      <td>{formatDate(revision.date, true)}</td>
                      <td>
                        <button
                          className="link"
                          onClick={() =>
                            history.push(
                              `${path}${subPath}/revisions/${revision.id}`
                            )
                          }
                        >
                          информация
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default RevisionsList;
