import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../../utils/normalizeData";

import MaterialUnitsActionTypes from "./materialUnits.types";
import {
  addMaterialUnitSuccess,
  addMaterialUnitFailure,
  getMaterialUnitsStart,
  getMaterialUnitsSuccess,
  getMaterialUnitsFailure,
  editMaterialUnitSuccess,
  editMaterialUnitFailure,
  deleteMaterialUnitSuccess,
  deleteMaterialUnitFailure,
} from "./materialUnits.actions";

export function* addMaterialUnit({ payload, callback }) {
  const { newMaterialUnit, adminId } = payload;
  const postData = {
    row: {
      name: newMaterialUnit,
    },
    who: adminId,
  };
  try {
    yield axios.post(`/api/admin/units/add`, postData);
    yield put(addMaterialUnitSuccess());
    yield put(getMaterialUnitsStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addMaterialUnitFailure(error));
  }
}

export function* getMaterialUnits({ payload }) {
  try {
    const response = yield axios.get(`/api/admin/units/${payload}`);
    yield put(getMaterialUnitsSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getMaterialUnitsFailure(error));
  }
}

export function* editMaterialUnit({ payload, callback }) {
  const { materialUnitName, adminId, materialUnitId } = payload;
  const putMaterialUnitData = {
    row: { name: materialUnitName },
    who: adminId,
    unit: materialUnitId,
  };
  try {
    yield axios.put("/api/admin/units/edit", putMaterialUnitData);
    yield put(editMaterialUnitSuccess());
    yield put(getMaterialUnitsStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editMaterialUnitFailure(error));
  }
}

export function* deleteMaterialUnit({ payload, callback }) {
  const { materialUnitId, adminId } = payload;
  try {
    yield axios.put("/api/admin/units/mark_as_deleted", {
      who: adminId,
      unit_id: materialUnitId,
    });
    yield put(deleteMaterialUnitSuccess());
    yield put(getMaterialUnitsStart(adminId));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(deleteMaterialUnitFailure(error));
  }
}

export function* onGetMaterialUnitsStart() {
  yield takeLeading(
    MaterialUnitsActionTypes.GET_MATERIAL_UNITS_START,
    getMaterialUnits
  );
}

export function* onAddMaterialUnitStart() {
  yield takeLatest(
    MaterialUnitsActionTypes.ADD_MATERIAL_UNIT_START,
    addMaterialUnit
  );
}

export function* onEditMaterialUnitStart() {
  yield takeLatest(
    MaterialUnitsActionTypes.EDIT_MATERIAL_UNIT_START,
    editMaterialUnit
  );
}

export function* onDeleteMaterialUnitStart() {
  yield takeLeading(
    MaterialUnitsActionTypes.DELETE_MATERIAL_UNIT_START,
    deleteMaterialUnit
  );
}

export function* materialUnitsSagas(args) {
  yield all([
    call(onGetMaterialUnitsStart),
    call(onAddMaterialUnitStart),
    call(onEditMaterialUnitStart),
    call(onDeleteMaterialUnitStart),
  ]);
}
