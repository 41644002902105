import { combineReducers } from "redux";
import adminsReducer from "./admins/admins.reducer";
import specialtiesReducer from "./specialties/specialties.reducer";
import materialUnitsReducer from "./materialUnits/materialUnits.reducer";
import materialsReducer from "./materials/materials.reducer";
import equipmentReducer from "./equipment/equipment.reducer";
import reasonsReducer from "./reasons/reasons.reducer";
import variablesReducer from "./variables/variables.reducer";
import techCardsReducer from "./techCards/techCards.reducer";

const rootReducer = combineReducers({
  admins: adminsReducer,
  specialties: specialtiesReducer,
  materialUnits: materialUnitsReducer,
  materials: materialsReducer,
  equipment: equipmentReducer,
  reasons: reasonsReducer,
  variables: variablesReducer,
  techCards: techCardsReducer,
});

export default rootReducer;
