import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GetPoints from "./pointsOnPlan/get-points";
import { main_url } from "../../config";
import { formatDate, getTime } from "../../utils/normalizeData";
import PopupForPhoto from "../popup-for-photo";
import InlineModal from "../inlineModal";
import ConfirmActionModal from "../confirmActionModal";
import { reclaimStart } from "../../redux/claims/claims.actions";
import { useHistory, useParams } from "react-router";
import CloseClaim from "./claims/close-claim";
import axios from "axios";
// import { Link } from "react-router-dom";
// import printJS from "print-js";

const ViewPoint = ({ rev_id, claim_id, act_id, checklist_id, close }) => {
  const history = useHistory();

  const [visibleSections, setVisibleSections] = useState({
    revision: false,
    claim: false,
    claimMark: false,
    claimToRev: false,
    previousClaim: false,
    act: false,
    checklist: false,
  });
  // const [html, setHtml] = useState(null);

  const currentUser = useSelector((state) => state.user.currentUser);
  const subsections = useSelector((state) => state.subsections.subsections);
  const revisions = useSelector((state) => state.revisions.revisions);
  const contractors = useSelector((state) => state.contractors.contractors);
  const claims = useSelector((state) => state.claims.claims);
  const isFetchingClaims = useSelector((state) => state.claims.isFetching);
  const claimErrorMsg = useSelector((state) => state.claims.error);
  const acts = useSelector((state) => state.acts.acts);
  const checklists = useSelector((state) => state.checklists.checklists);
  const claimStatuses = useSelector((state) => state.statuses.claims);
  const revisionStatuses = useSelector((state) => state.statuses.revisions);

  const [reclaim, setReclaim] = useState();
  const [imgSrcToZoom, zoomImage] = useState();
  const [claimMarkToRender, setClaimMarkToRender] = useState();
  // const [isLoading, setIsLoading] = useState();
  // const [checklistToRender, setChecklistToRender] = useState();

  let subsection = null;
  let markerType = "";

  let revisionToRender = "";
  let claimToRender = "";
  let claimToRender2 = "";
  let claimToRev = "";
  let claimFromRev = "";
  let actToRender = "";
  let checklistToRender = "";
  let claimToRevImages = "";
  let claimFromRevImages = "";
  let claimImages = "";
  let claimImages2 = "";
  let actImages = "";
  let checklistImages = "";

  let revisionContractor = null;
  let claimContractor = null;
  let claimContractor2 = null;
  let claimToRevContractor = null;
  let claimFromRevContractor = null;
  let actContractor = null;
  let checklistContractor = null;

  const findSubsection = (id) => {
    if (subsections) {
      return subsections.find((subsection) => subsection.id === id);
    }
    return null;
  };

  const findSubsectionName = (id) => {
    if (subsections) {
      let foundSub = subsections.find((subsection) => subsection.id === id);
      if (foundSub) return foundSub.name;
    }
    return "";
  };

  const findContractor = (id) => {
    if (contractors) {
      return contractors.find((contractor) => contractor.id === id);
    }
  };

  const isEmpty = (obj) => {
    for (let i in obj) return false;
    return true;
  };

  const isClaimStatusActive = (statusId) => {
    if (
      claimStatuses &&
      claimStatuses[statusId] !== "Закрыто актом" &&
      claimStatuses[statusId] !== "Закрыто отметкой" &&
      claimStatuses[statusId] !== "Отозвано"
    ) {
      return true;
    }
    return false;
  };

  const dispatch = useDispatch();

  const { site_id, sub_id, menu, info, action } = useParams();

  const handleReclaim = () => {
    dispatch(
      reclaimStart(claimToRender.id, currentUser, site_id, () =>
        setReclaim(false)
      )
    );
  };

  if (rev_id) {
    const revision = revisions.find((revision) => revision.id === rev_id);
    if (revision) {
      revisionToRender = revision;
      subsection = findSubsection(revision.subsection_id);
      markerType = "revision";
      if (!isEmpty(revision.claim_to_rev)) {
        claimToRev = Object.values(revision.claim_to_rev)[0];
        claimToRevImages = Object.values(claimToRev.imgs);
        markerType = "many";
      }
      if (!isEmpty(revision.claim_from_rev)) {
        claimFromRev = Object.values(revision.claim_from_rev)[0];
        claimFromRevImages = Object.values(claimFromRev.imgs);
        markerType = "many";
      }
      if (!isEmpty(revision.acts)) {
        actToRender = Object.values(revision.acts)[0];
        actImages = actToRender.imgs;
        markerType = "many";
      }
      if (!isEmpty(revision.checklists)) {
        checklistToRender = Object.values(revision.checklists)[0];
        checklistImages = checklistToRender.imgs;
        markerType = "many";
      }
    }
  }

  if (claim_id) {
    const claim = claims.find((claim) => claim.id === claim_id);
    if (claim) {
      claimToRender = claim;
      subsection = findSubsection(claim.subsection_id);
      markerType = "claim";
      claimImages = Object.values(claim.claim_imgs);

      if (
        !claimMarkToRender &&
        claimStatuses &&
        claimStatuses[claim.status_id] === "Закрыто отметкой"
      ) {
        axios
          .get(`/api/claims/mark/${currentUser.user}/${claim.id}`)
          .then((response) =>
            setClaimMarkToRender(Object.values(response.data)[0])
          )
          .catch((e) => console.log(e));
      }

      if (claimToRender.claim_id) {
        const claim2 = claims.find(
          (claim) => claim.id === claimToRender.claim_id
        );
        if (claim2) {
          markerType = "many";
          claimToRender2 = claim2;
          claimImages2 = Object.values(claim2.claim_imgs);
        }
      }
      if (claimToRender.revision_id) {
        const revision = revisions.find(
          (rev) => rev.id === claimToRender.revision_id
        );
        if (revision) {
          markerType = "many";
          revisionToRender = revision;
        }
      }
    }
  }

  if (act_id) {
    const act = acts.find((act) => act.id === act_id);
    if (act) {
      actToRender = act;
      subsection = findSubsection(act.subsection_id);
      markerType = "act";
      actImages = Object.values(act.act_imgs);
      if (actToRender.claim_id) {
        const claim = claims.find((claim) => claim.id === actToRender.claim_id);
        if (claim) {
          markerType = "many";
          claimToRender = claim;
          claimImages = Object.values(claim.claim_imgs);
        }
      }
      if (actToRender.revision_id) {
        const revision = revisions.find(
          (rev) => rev.id === actToRender.revision_id
        );
        if (revision) {
          markerType = "many";
          revisionToRender = revision;
        }
      }
    }
  }

  if (checklist_id) {
    const checklist = checklists.find(
      (checklist) => checklist.id === checklist_id
    );
    if (checklist) {
      checklistToRender = checklist;
      subsection = findSubsection(checklist.subsection_id);
      markerType = "act";
      checklistImages = Object.values(checklist.checklist_imgs);
      // if (actToRender.claim_id) {
      //   const claim = claims.find((claim) => claim.id === actToRender.claim_id);
      //   if (claim) {
      //     markerType = "many";
      //     claimToRender = claim;
      //     claimImages = Object.values(claim.claim_imgs);
      //   }
      // }
      if (checklist.revision_id) {
        const revision = revisions.find(
          (rev) => rev.id === checklist.revision_id
        );
        if (revision) {
          markerType = "many";
          revisionToRender = revision;
        }
      }
    }
  }

  if (revisionToRender && revisionToRender.contractor_id) {
    revisionContractor = findContractor(revisionToRender.contractor_id);
  }
  if (claimToRender && claimToRender.contractor_id) {
    claimContractor = findContractor(claimToRender.contractor_id);
  }
  if (claimToRev && claimToRev.contractor_id) {
    claimToRevContractor = findContractor(claimToRev.contractor_id);
  }
  if (claimFromRev && claimFromRev.contractor_id) {
    claimFromRevContractor = findContractor(claimFromRev.contractor_id);
  }
  if (claimToRender2 && claimToRender2.contractor_id) {
    claimContractor2 = findContractor(claimToRender2.contractor_id);
  }
  if (actToRender && actToRender.contractor_id) {
    actContractor = findContractor(actToRender.contractor_id);
  }
  if (checklistToRender && checklistToRender.contractor_id) {
    checklistContractor = findContractor(checklistToRender.contractor_id);
  }

  const path = `/main/sites/${site_id}`;
  const subPath = sub_id ? `/subsections/${sub_id}` : "";

  //  console.log("DATA TO RENDER: ", data);
  // console.log("REVISION TO RENDER: ", revisionToRender);
  // console.log("CLAIM TO REV: ", claimToRev);
  // console.log("CLAIM TO RENDER: ", claimToRender);
  // console.log("MARK TO RENDER: ", claimMarkToRender);
  // console.log("CLAIM STATUSES: ", claimStatuses);
  // console.log("REVISION STATUSES: ", revisionStatuses);
  // console.log("ACT TO RENDER: ", actToRender);
  // console.log("CHECKLIST TO RENDER: ", checklistToRender);
  // console.log("IMAGES CLAIM TO REV: ", claimToRevImages);
  // console.log("VIEW POINT MARKER TYPE: ", markerType);

  // const convert = (url) => {
  //   console.log(url);
  //   fetch("http://localhost:3000/182_claim.docx")
  //     .then((res) => res.arrayBuffer())
  //     .then((ab) =>
  //       mammoth
  //         .convertToHtml({ arrayBuffer: ab })
  //         .then((result) => {
  //           const html = result.value;
  //           if (html) setHtml(html);
  //           const messages = result.messages;
  //           console.log("MAMMOTH: ", html);
  //         })
  //         .catch((e) => console.log(e))
  //         .done()
  //     );

  //   // mammoth
  //   //   .convertToHtml({ path: "http://192.168.34.2:3000/182_claim.docx" })
  //   //   .then((result) => {
  //   //     const html = result.value;
  //   //     const messages = result.messages;
  //   //     console.log("MAMMOTH: ", html);
  //   //   })
  //   //   .catch((e) => console.log(e))
  //   //   .done();
  // };

  return (
    <div>
      {reclaim && (
        <InlineModal>
          <ConfirmActionModal
            message={`Отозвать предписание "${claimToRender.title}"?`}
            confirm={handleReclaim}
            cancel={() => setReclaim(false)}
            processing={isFetchingClaims}
            errorMsg={claimErrorMsg}
          />
        </InlineModal>
      )}

      <div className="caption">
        Точка на плане
        <button className="close" onClick={close} />
      </div>

      {imgSrcToZoom && (
        <PopupForPhoto src={imgSrcToZoom} close={() => zoomImage(null)} />
      )}

      {revisionToRender && (
        <>
          <div
            className={`pin_info_header ${
              visibleSections.revision ? "sel" : ""
            }`}
            onClick={() =>
              setVisibleSections({
                ...visibleSections,
                revision: !visibleSections.revision,
              })
            }
          >
            <span>Проверка:</span> <span> {revisionToRender.title}</span>
            <span>{formatDate(revisionToRender.date, true)}</span>
            <span>
              {revisionStatuses && revisionStatuses[revisionToRender.status_id]}
            </span>
          </div>
          {visibleSections.revision && (
            <div>
              <div className="two_col">
                <div className="col">
                  <div className="field">
                    <label>Название:</label>
                    <span className="data">{revisionToRender.title}</span>
                  </div>
                  <div className="field">
                    <label>Дата:</label>
                    <span className="data">
                      {`${formatDate(revisionToRender.date, true)} ${getTime(
                        revisionToRender.date
                      )}`}
                    </span>
                  </div>
                  <div className="field">
                    <label>Подраздел:</label>
                    <span className="data">
                      {findSubsectionName(revisionToRender.subsection_id)}
                    </span>
                  </div>
                  <div className="field">
                    <label>Подрядчик:</label>
                    <span className="data">
                      {revisionContractor && revisionContractor.name}
                    </span>
                  </div>
                </div>

                <div className="col">
                  {isEmpty(revisionToRender.revision_postpone) ? (
                    ""
                  ) : (
                    <div className="field">
                      <label>Перенесенные даты проверок: </label>
                      <div className="data">
                        {Object.values(revisionToRender.revision_postpone).map(
                          (entry, i) => (
                            <span className="data" key={i}>
                              {formatDate(entry.old_date, "withTime")}
                              <small>{entry.reason}</small>
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {revisionToRender.descriptions && (
                <div className="field">
                  <label>Пояснение: </label>
                  <span className="data">{revisionToRender.descriptions}</span>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {claimToRev && (
        <>
          <div
            className={`pin_info_header ${
              visibleSections.claimToRev ? "sel" : ""
            }`}
            onClick={() =>
              setVisibleSections({
                ...visibleSections,
                claimToRev: !visibleSections.claimToRev,
              })
            }
          >
            <span>Предписание, привязанное к проверке:</span>
            <span>{claimToRev.title}</span>
            <span>{formatDate(claimToRev.date_created, true)}</span>
            <span>{claimStatuses && claimStatuses[claimToRev.status_id]}</span>
          </div>
          {visibleSections.claimToRev && (
            <div>
              <div className="two_col">
                <div className="col">
                  <div className="field">
                    <label>Название:</label>
                    <span className="data">{claimToRev.title}</span>
                  </div>
                  <div className="field">
                    <label>Дата создания:</label>
                    <span className="data">
                      {`${formatDate(claimToRev.date_created, true)} ${getTime(
                        claimToRev.date_created
                      )} `}
                    </span>
                  </div>
                  <div className="field">
                    <label>Статус:</label>
                    <span className="data">
                      {claimStatuses && claimStatuses[claimToRev.status_id]}
                    </span>
                  </div>
                </div>

                <div className="col">
                  <div className="field">
                    <label>Подраздел:</label>
                    <span className="data">
                      {findSubsectionName(claimToRev.subsection_id)}
                    </span>
                  </div>

                  <div className="field">
                    <label>Подрядчик:</label>
                    <span className="data">
                      {claimToRevContractor && claimToRevContractor.name}
                    </span>
                  </div>
                </div>
              </div>
              {(claimToRev.reason || claimToRev.reason_name) && (
                <div className="field">
                  <label>Основание:</label>
                  <span className="data">
                    {claimToRev.reason || claimToRev.reason_name}
                  </span>
                </div>
              )}
              {claimToRev.descriptions && (
                <div className="field">
                  <label>Предписания: </label>
                  <span>{claimToRev.descriptions}</span>
                </div>
              )}
              {claimToRevImages.length ? (
                <div className="photos">
                  {claimToRevImages.map((image, i) => {
                    const source = `${main_url}/claims/${claimToRev.subsection_id}/${claimToRev.id}/${image.img}`;
                    return (
                      <span className="photo" key={i}>
                        <img
                          src={source}
                          alt="visual proof"
                          onClick={() => zoomImage(source)}
                        />
                      </span>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}

      {claimFromRev && (
        <>
          <div
            className={`pin_info_header ${
              visibleSections.claimFromRev ? "sel" : ""
            }`}
            onClick={() =>
              setVisibleSections({
                ...visibleSections,
                claimFromRev: !visibleSections.claimFromRev,
              })
            }
          >
            <span>Предписание по результатам проверки:</span>
            <span>{claimFromRev.title}</span>
            <span>{formatDate(claimFromRev.date_created, true)}</span>
            <span>
              {claimStatuses && claimStatuses[claimFromRev.status_id]}
            </span>
          </div>
          {visibleSections.claimFromRev && (
            <div>
              <div className="two_col">
                <div className="col">
                  <div className="field">
                    <label>Название:</label>
                    <span className="data">{claimFromRev.title}</span>
                  </div>
                  <div className="field">
                    <label>Дата создания:</label>
                    <span className="data">
                      {`${formatDate(
                        claimFromRev.date_created,
                        true
                      )} ${getTime(claimFromRev.date_created)}`}
                    </span>
                  </div>
                  <div className="field">
                    <label>Статус:</label>
                    <span className="data">
                      {claimStatuses && claimStatuses[claimFromRev.status_id]}
                    </span>
                  </div>
                </div>

                <div className="col">
                  {claimFromRev.item && (
                    <div className="field">
                      <label>Элемент:</label>
                      <span className="data">{claimFromRev.item}</span>
                    </div>
                  )}
                  <div className="field">
                    <label>Подраздел:</label>
                    <span className="data">
                      {findSubsectionName(claimFromRev.subsection_id)}
                    </span>
                  </div>

                  <div className="field">
                    <label>Подрядчик:</label>
                    <span className="data">
                      {claimFromRevContractor && claimFromRevContractor.name}
                    </span>
                  </div>
                </div>
              </div>
              {(claimFromRev.reason || claimFromRev.reason_name) && (
                <div className="field">
                  <label>Основание:</label>
                  <span className="data">
                    {claimFromRev.reason || claimFromRev.reason_name}
                  </span>
                </div>
              )}
              {claimFromRev.descriptions && (
                <div className="field">
                  <label>Предписания: </label>
                  <span>{claimFromRev.descriptions}</span>
                </div>
              )}
              {claimFromRevImages.length ? (
                <div className="photos">
                  {claimFromRevImages.map((image, i) => {
                    const source = `${main_url}/claims/${claimFromRev.subsection_id}/${claimFromRev.id}/${image.img}`;
                    return (
                      <span className="photo" key={i}>
                        <img
                          src={source}
                          alt="visual proof"
                          onClick={() => zoomImage(source)}
                        />
                      </span>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}

      {claimToRender && (
        <>
          <div
            className={`pin_info_header ${visibleSections.claim ? "sel" : ""}`}
            onClick={() =>
              setVisibleSections({
                ...visibleSections,
                claim: !visibleSections.claim,
              })
            }
          >
            <span>Предписание:</span>
            <span>{claimToRender.title}</span>
            <span>{formatDate(claimToRender.date_created, true)}</span>
            <span>
              {claimStatuses && claimStatuses[claimToRender.status_id]}
            </span>
          </div>
          {visibleSections.claim && (
            <div>
              <div className="two_col">
                <div className="col">
                  <div className="field">
                    <label>Название:</label>
                    <span className="data">{claimToRender.title}</span>
                  </div>
                  <div className="field">
                    <label>Дата создания:</label>
                    <span className="data">
                      {`${formatDate(
                        claimToRender.date_created,
                        true
                      )} ${getTime(claimToRender.date_created)} `}
                    </span>
                  </div>
                  <div className="field">
                    <label>Подраздел:</label>
                    <span className="data">
                      {findSubsectionName(claimToRender.subsection_id)}
                    </span>
                  </div>

                  <div className="field">
                    <label>Подрядчик:</label>
                    <span className="data">
                      {claimContractor && claimContractor.name}
                    </span>
                  </div>

                  <div className="field">
                    <label>Файл</label>
                    <div className="links">
                      <a
                        className="download"
                        href={`${main_url}/claims_document/${claimToRender.subsection_id}/${claimToRender.id}/${claimToRender.id}_claim.docx`}
                        download
                      >
                        Скачать
                      </a>
                      {/* <Link to="#" onClick={() => printJS("claims.pdf")}>
                        Распечатать{" "}
                      </Link> */}
                      {/* <Link
                        to="#"
                        onClick={() =>
                          convert(
                            `${main_url}/claims_document/${claimToRender.subsection_id}/${claimToRender.id}/${claimToRender.id}_claim.docx`
                          )
                        }
                      >
                        Распечатать{" "}
                      </Link> */}
                      {/* <a
                        target="_blank"
                        rel="noreferrer"
                        className="print"
                        href={
                          "https://docs.google.com/gview?url=" +
                          `${main_url}/claims_document/${claimToRender.subsection_id}/${claimToRender.id}/${claimToRender.id}_claim.docx`
                        }
                      >
                        Распечатать
                      </a> */}
                    </div>
                  </div>
                  {/* <div className="field">
                    <label>Статус:</label>
                    <span className="data">
                      {claimStatuses && claimStatuses[claimToRender.status_id]}
                    </span>
                  </div> */}
                </div>

                <div className="col">
                  {isClaimStatusActive(claimToRender.status_id) && (
                    <div className="buttons vertical">
                      <button
                        className="blue"
                        onClick={() =>
                          history.push(
                            `${path}${subPath}/${menu}/${info}/close_claim`
                          )
                        }
                      >
                        Закрыть предписание
                      </button>
                      <button className="red" onClick={() => setReclaim(true)}>
                        Отозвать предписание
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {claimToRender.descriptions && (
                <div className="field">
                  <label>Предписания: </label>
                  <span>{claimToRender.descriptions}</span>
                </div>
              )}

              {claimImages.length ? (
                <div className="photos">
                  {claimImages.map((image, i) => {
                    const source = `${main_url}/claims/${claimToRender.subsection_id}/${claimToRender.id}/${image.img}`;
                    return (
                      <span className="photo" key={i}>
                        <img
                          src={source}
                          alt="visual proof"
                          onClick={() => zoomImage(source)}
                        />
                      </span>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}

      {claimMarkToRender && (
        <>
          <div
            className={`pin_info_header ${
              visibleSections.claimMark ? "sel" : ""
            }`}
            onClick={() =>
              setVisibleSections({
                ...visibleSections,
                claimMark: !visibleSections.claimMark,
              })
            }
          >
            <span>Предписание:</span>
            <span>{claimToRender.title}</span>
            <span>{formatDate(claimToRender.date_created, true)}</span>
            <span>Информация об отметке</span>
          </div>

          {visibleSections.claimMark && (
            <>
              <div className="field">
                <label>Комментарий:</label>
                <span className="data">{claimMarkToRender.descriptions}</span>
              </div>

              {!isEmpty(claimMarkToRender.mark_imgs) && (
                <div className="photos">
                  {Object.values(claimMarkToRender.mark_imgs).map(
                    (image, i) => {
                      const source = `${main_url}/claim_mark/${claimMarkToRender.claim_id}/${image.img}`;
                      return (
                        <span className="photo" key={i}>
                          <img
                            src={source}
                            alt="visual proof"
                            onClick={() => zoomImage(source)}
                          />
                        </span>
                      );
                    }
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}

      {claimToRender2 && (
        <>
          <div
            className={`pin_info_header ${
              visibleSections.previousClaim ? "sel" : ""
            }`}
            onClick={() =>
              setVisibleSections({
                ...visibleSections,
                previousClaim: !visibleSections.previousClaim,
              })
            }
          >
            <span>Предыдущее предписание:</span>
            <span>{claimToRender2.title}</span>
            <span>{formatDate(claimToRender2.date_created, true)}</span>
            <span>
              {claimStatuses && claimStatuses[claimToRender2.status_id]}
            </span>
          </div>
          {visibleSections.previousClaim && (
            <div>
              <div className="two_col">
                <div className="col">
                  <div className="field">
                    <label>Название:</label>
                    <span className="data">{claimToRender2.title}</span>
                  </div>
                  <div className="field">
                    <label>Дата создания:</label>
                    <span className="data">
                      {`${formatDate(
                        claimToRender2.date_created,
                        true
                      )} ${getTime(claimToRender2.date_created)}`}
                    </span>
                  </div>

                  <div className="field">
                    <label>Статус:</label>
                    <span className="data">
                      {claimStatuses && claimStatuses[claimToRender2.status_id]}
                    </span>
                  </div>
                </div>

                <div className="col">
                  <div className="field">
                    <label>Подраздел:</label>
                    <span className="data">
                      {findSubsectionName(claimToRender2.subsection_id)}
                    </span>
                  </div>

                  <div className="field">
                    <label>Подрядчик:</label>
                    <span className="data">
                      {claimContractor2 && claimContractor2.name}
                    </span>
                  </div>
                </div>
              </div>
              {(claimToRender2.reason || claimToRender2.reason_name) && (
                <div className="field">
                  <label>Основание:</label>
                  <span className="data">
                    {claimToRender2.reason || claimToRender2.reason_name}
                  </span>
                </div>
              )}
              {claimToRender2.descriptions && (
                <div className="field">
                  <label>Предписания: </label>
                  <span>{claimToRender2.descriptions}</span>
                </div>
              )}
              {claimImages2.length ? (
                <div className="photos">
                  {claimImages2.map((image, i) => {
                    const source = `${main_url}/claims/${claimToRender2.subsection_id}/${claimToRender2.id}/${image.img}`;
                    return (
                      <span className="photo" key={i}>
                        <img
                          key={i}
                          src={source}
                          alt="visual proof"
                          onClick={() => zoomImage(source)}
                        />
                      </span>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}

      {actToRender && (
        <>
          <div
            className={`pin_info_header ${visibleSections.act ? "sel" : ""}`}
            onClick={() =>
              setVisibleSections({
                ...visibleSections,
                act: !visibleSections.act,
              })
            }
          >
            <span>Акт:</span>
            <span>{`№ ${actToRender.num} ${actToRender.title}`}</span>
            <span>{formatDate(actToRender.date, true)}</span>
          </div>

          {visibleSections.act && (
            <div>
              <div className="two_col">
                <div className="col">
                  <div className="field">
                    <label>Название:</label>
                    <span className="data">{actToRender.title}</span>
                  </div>
                  <div className="field">
                    <label>Дата:</label>
                    <span className="data">
                      {formatDate(actToRender.date, true)}
                    </span>
                  </div>
                </div>

                <div className="col">
                  <div className="field">
                    <label>Подраздел:</label>
                    <span className="data">
                      {findSubsectionName(actToRender.subsection_id)}
                    </span>
                  </div>

                  <div className="field">
                    <label>Подрядчик:</label>
                    <span className="data">
                      {actContractor && actContractor.name}
                    </span>
                  </div>
                </div>
              </div>

              <div className="field">
                <label>Файл</label>
                <div className="links">
                  <a
                    className="download"
                    href={`${main_url}/acts_document/${actToRender.subsection_id}/${actToRender.id}/${actToRender.id}_act.docx`}
                    download
                  >
                    Скачать
                  </a>
                </div>
              </div>

              {actToRender.reason && (
                <div className="field">
                  <label>Основание:</label>
                  <span className="data">{actToRender.reason}</span>
                </div>
              )}
              {actToRender.descriptions && (
                <div className="field">
                  <label>Предписания: </label>
                  <span>{actToRender.descriptions}</span>
                </div>
              )}
              {actToRender.document && (
                <div className="field">
                  <a
                    download
                    target="_blank"
                    rel="noreferrer "
                    href={`${main_url}/acts_document/${actToRender.subsection_id}/${actToRender.document}`}
                  >
                    Скачать документ
                  </a>
                </div>
              )}
              {actImages.length ? (
                <div className="photos">
                  {actImages.map((image, i) => {
                    const source = `${main_url}/acts/${actToRender.subsection_id}/${actToRender.id}/${image.img}`;
                    return (
                      <span key={i} className="photo">
                        <img
                          src={source}
                          alt="visual proof"
                          onClick={() => zoomImage(source)}
                        />
                      </span>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}

      {checklistToRender && (
        <>
          <div
            className={`pin_info_header ${
              visibleSections.checklist ? "sel" : ""
            }`}
            onClick={() =>
              setVisibleSections({
                ...visibleSections,
                checklist: !visibleSections.checklist,
              })
            }
          >
            <span>Чеклист:</span>
            <span>{`№ ${checklistToRender.num} ${checklistToRender.title}`}</span>
            <span>{formatDate(checklistToRender.date, true)}</span>
          </div>

          {visibleSections.checklist && (
            <div>
              <div className="two_col">
                <div className="col">
                  <div className="field">
                    <label>Название:</label>
                    <span className="data">{checklistToRender.title}</span>
                  </div>
                  <div className="field">
                    <label>Дата:</label>
                    <span className="data">
                      {formatDate(checklistToRender.date, true)}
                    </span>
                  </div>
                </div>

                <div className="col">
                  <div className="field">
                    <label>Подраздел:</label>
                    <span className="data">
                      {findSubsectionName(checklistToRender.subsection_id)}
                    </span>
                  </div>

                  <div className="field">
                    <label>Подрядчик:</label>
                    <span className="data">
                      {checklistContractor && checklistContractor.name}
                    </span>
                  </div>
                </div>
              </div>
              {checklistToRender.descriptions && (
                <div className="field">
                  <label>Текст: </label>
                  <span>{checklistToRender.descriptions}</span>
                </div>
              )}
              {actToRender.document && (
                <div className="field">
                  <a
                    download
                    target="_blank"
                    rel="noreferrer "
                    href={`${main_url}/acts_document/${actToRender.subsection_id}/${actToRender.document}`}
                  >
                    Скачать документ
                  </a>
                </div>
              )}
              {checklistImages.length ? (
                <div className="photos">
                  {checklistImages.map((image, i) => {
                    const source = `${main_url}/checklists/${checklistToRender.subsection_id}/${checklistToRender.id}/${image.img}`;
                    return (
                      <span key={i} className="photo">
                        <img
                          src={source}
                          alt="visual proof"
                          onClick={() => zoomImage(source)}
                        />
                      </span>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </>
      )}

      {/* <iframe
        class="doc"
        id="iframe"
        src="https://docs.google.com/gview?url=http://writing.engr.psu.edu/workbooks/formal_report_template.doc&embedded=true"
      ></iframe> */}

      <GetPoints
        subsection={subsection}
        markerType={markerType}
        act_id={act_id}
        claim_id={claim_id}
        rev_id={rev_id}
        checklist_id={checklist_id}
      />

      {action === "close_claim" &&
        claimToRender &&
        claimContractor &&
        subsections && (
          <InlineModal
            close={() => history.push(`${path}${subPath}/${menu}/${info}`)}
          >
            <CloseClaim
              close={() => history.push(`${path}${subPath}/${menu}/${info}`)}
              claim={claimToRender}
              subsectionName={findSubsectionName(claimToRender.subsection_id)}
              claimContractor={claimContractor}
            />
          </InlineModal>
        )}
    </div>
  );
};

export default ViewPoint;
