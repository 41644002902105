import React, { useEffect, useState } from "react";
import { useMainSectionStyles } from "../../../css/admin/main-section.styles";
import { FormInput } from "../../../components/input-forms";
import { useDispatch, useSelector } from "react-redux";
import {
  getVariablesStart,
  clearErrors,
  editVariableStart,
  addVariableStart,
  deleteVariableStart,
} from "../../redux/variables/variables.actions";
import { ReactComponent as Trash } from "../../../assets/trash.svg";
import ConfirmDeleteModal from "../../../components/confirmActionModal";

const Variables = ({ mandatoryVariables }) => {
  const classes = useMainSectionStyles();
  const dispatch = useDispatch();
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const variables = useSelector((state) => state.variables.variables);
  const isDeleting = useSelector((state) => state.variables.isDeleting);
  const errorMessage = useSelector((state) => state.variables.error);
  const isAdding = useSelector((state) => state.variables.isAdding);
  const [variableNames, setVariableNames] = useState({
    name: "",
    name_print: "",
  });
  const [variableToEdit, setVariableToEdit] = useState();
  const [confirmDelete, setConfirmDelete] = useState();

  useEffect(() => {
    if (!variables) {
      dispatch(getVariablesStart(currentAdmin.adminId));
    }
  }, [variables, currentAdmin.adminId, dispatch]);

  useEffect(() => {
    if (variableToEdit) {
      setVariableNames({
        name: variableToEdit.name,
        name_print: variableToEdit.name_print,
      });
    }
  }, [variableToEdit]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [confirmDelete, dispatch]);

  const callback = () => {
    setVariableNames({ name: "", name_print: "" });
    document.getElementById("name").focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (variableToEdit) {
      const callback = () => {
        setVariableToEdit(null);
        setVariableNames({ name: "", name_print: "" });
      };

      return dispatch(
        editVariableStart(
          variableNames,
          currentAdmin.adminId,
          variableToEdit.id,
          callback
        )
      );
    }

    if (variableNames.name && variableNames.name_print) {
      dispatch(addVariableStart(variableNames, currentAdmin.adminId, callback));
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const callback = () => setConfirmDelete(false);

    dispatch(
      deleteVariableStart(confirmDelete.id, currentAdmin.adminId, callback)
    );
    if (variableToEdit && variableToEdit.id === confirmDelete.id) {
      setVariableToEdit(null);
      setVariableNames({ name: "", name_print: "" });
    }
  };

  const cancelEdit = () => {
    setVariableToEdit(null);
    setVariableNames({ name: "", name_print: "" });
    document.getElementById("name").focus();
  };

  const handleChange = (e) => {
    setVariableNames({ ...variableNames, [e.target.id]: e.target.value });
  };

  return (
    <div className={classes.main}>
      <div className={classes.list}>
        <h1>Виды переменных</h1>

        {variables && (
          <>
            {variables.map((variable) => (
              <div key={variable.id} className={classes.listItem}>
                <span
                  onClick={() => setVariableToEdit(variable)}
                  className={
                    variableToEdit && variableToEdit.id === variable.id
                      ? classes.selectedItem
                      : ""
                  }
                >
                  {`${variable.name} (${variable.name_print})`}
                </span>
                <Trash onClick={() => setConfirmDelete(variable)} />
              </div>
            ))}
          </>
        )}
      </div>

      <form className={classes.addSection} onSubmit={handleSubmit}>
        <div className={classes.inputSection}>
          <FormInput
            label="Название для формулы"
            id="name"
            value={variableNames.name}
            onChange={handleChange}
          />
          <FormInput
            label="Название для вывода"
            id="name_print"
            value={variableNames.name_print}
            onChange={handleChange}
          />
        </div>
        <div className="buttons">
          <button
            className="blue"
            submitstyle="true"
            disabled={
              !variableNames.name || !variableNames.name_print || isAdding
            }
          >
            {variableToEdit ? "Сохранить" : "Добавить"}
          </button>
          {variableToEdit && (
            <button
              className="cancel"
              disabled={isAdding}
              type="button"
              onClick={cancelEdit}
            >
              Отмена
            </button>
          )}
        </div>
        <div className={classes.errorMsg}>{errorMessage}</div>
      </form>

      {confirmDelete && (
        <ConfirmDeleteModal
          message={`Удалить переменную "${confirmDelete.name}" ?`}
          confirm={handleDelete}
          cancel={() => setConfirmDelete(null)}
          processing={isDeleting}
          errorMsg={errorMessage}
        />
      )}
    </div>
  );
};

export default Variables;
