const TechCardsActionTypes = {
  GET_TECH_CARDS_START: "GET_TECH_CARDS_START",
  GET_TECH_CARDS_SUCCESS: "GET_TECH_CARDS_SUCCESS",
  GET_TECH_CARDS_FAILURE: "GET_TECH_CARDS_FAILURE",
  GET_TECH_CARD_DETAILS_START: "GET_TECH_CARD_DETAILS_START",
  GET_TECH_CARD_DETAILS_SUCCESS: "GET_TECH_CARD_DETAILS_SUCCESS",
  GET_TECH_CARD_DETAILS_FAILURE: "GET_TECH_CARD_DETAILS_FAILURE",
  CLEAR_TECH_CARD_DETAILS: "CLEAR_TECH_CARD_DETAILS",
};

export default TechCardsActionTypes;
