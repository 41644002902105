import React, { useState, useEffect, useRef } from "react";
import { plan_images } from "../../../config";

const PointOnPlan = ({
  markerCoords,
  setMarkerCoords,
  subsection,
  planFilename,
  planId,
  setCoordsToDelete,
  pinType,
}) => {
  const [Coords, setCoords] = useState(null);
  useEffect(() => {
    const planElement = document.getElementById(planFilename);

    const getOffsetData = (e) => {
      setCoords({ x: e.offsetX, y: e.offsetY });
    };

    if (planElement) {
      planElement.addEventListener("click", getOffsetData);
    }
    return () => {
      if (planElement) {
        planElement.removeEventListener("click", getOffsetData);
      }
    };
  }, [subsection, planFilename]);

  useEffect(() => {
    if (Coords) {
      const imgSize = document
        .getElementById(planFilename)
        .getBoundingClientRect();
      const xCoordInPercent = (Coords.x / imgSize.width) * 100;
      const yCoordInPercent = (Coords.y / imgSize.height) * 100;
      const existingMarker = markerCoords.findIndex(
        (marker) => marker.plan_id === planId
      );
      let newArrOfMarkers;
      if (existingMarker !== -1) {
        newArrOfMarkers = [...markerCoords];
        newArrOfMarkers[existingMarker].dot_x = xCoordInPercent;
        newArrOfMarkers[existingMarker].dot_y = yCoordInPercent;
        if (newArrOfMarkers[existingMarker].id) {
          newArrOfMarkers[existingMarker].changed = true;
        }
        setMarkerCoords(newArrOfMarkers);
      } else {
        setMarkerCoords([
          ...markerCoords,
          { dot_x: xCoordInPercent, dot_y: yCoordInPercent, plan_id: planId },
        ]);
      }
      setCoords(null);
    }
  }, [Coords, setMarkerCoords, markerCoords, planFilename, planId]);

  const zoomImage = (e) => {
    setZoom(!zoom);
  };
  const zoomedOnce = useRef();
  const [zoom, setZoom] = useState(false);

  useEffect(() => {
    if (zoom && !zoomedOnce.current) zoomedOnce.current = true;
    if (zoomedOnce.current) {
      const plan = document.getElementById(planFilename);
      if (plan) plan.scrollIntoView();
    }
  }, [zoom, zoomedOnce, planFilename]);

  const [markerForThisPlan, setMarkerForThisPlan] = useState();

  useEffect(() => {
    const mark = markerCoords.find((marker) => marker.plan_id === planId);
    if (mark) {
      if (
        !markerForThisPlan ||
        (markerForThisPlan.dot_x !== mark.dot_x &&
          markerForThisPlan.dot_y !== mark.dot_y)
      ) {
        setMarkerForThisPlan(mark);
      }
    } else if (markerForThisPlan) {
      setMarkerForThisPlan(null);
    }
  }, [markerCoords, markerForThisPlan, planId]);

  const handleDelete = () => {
    setMarkerCoords(
      markerCoords.filter((marker) => {
        if (marker.plan_id !== planId) {
          return true;
        }
        if (marker.id) setCoordsToDelete((c) => [...c, marker]);
        return false;
      })
    );
  };

  return (
    <div className={`plan ${zoom ? "zoomed" : ""}`}>
      <div className="scroll">
        <div className="container">
          <img
            id={planFilename}
            src={`${plan_images}/${subsection.obj_id}/${subsection.id}/${planFilename}`}
            alt="plan"
          />
          {markerForThisPlan && (
            <span
              href="#"
              className={`the_pin ${pinType}`}
              style={{
                left: `calc(${markerForThisPlan.dot_x}% - 12.5px)`,
                top: `calc(${markerForThisPlan.dot_y}% - 40px)`,
              }}
            />
          )}
        </div>
      </div>

      <span className="control">
        <button className="zoom" onClick={zoomImage} type="button" />
        <button className="remove" onClick={handleDelete} type="button">
          Убрать отметку
        </button>
      </span>
    </div>
  );
};

const SetPointsOnPlans = ({
  subsection,
  markerCoords,
  setMarkerCoords,
  setCoordsToDelete,
  pinType,
}) => {
  const [plansToRender, setPlansToRender] = useState([]);
  useEffect(() => {
    if (subsection && subsection.subsection_plans) {
      const plansArray = [];
      for (const i in subsection.subsection_plans) {
        const planInDB = subsection.subsection_plans[i];
        plansArray.push(
          <PointOnPlan
            key={planInDB.id}
            subsection={subsection}
            planFilename={planInDB.plan}
            planId={planInDB.id}
            markerCoords={markerCoords}
            setMarkerCoords={setMarkerCoords}
            setCoordsToDelete={setCoordsToDelete}
            pinType={pinType}
          />
        );
      }
      if (plansArray.length) {
        setPlansToRender(plansArray);
      }
    }
  }, [subsection, markerCoords, setMarkerCoords, setCoordsToDelete, pinType]);

  if (plansToRender.length) return plansToRender;
  else if (subsection) return <div>ПЛАН НЕ ДОБАВЛЕН</div>;
  return <div>Выберите подраздел для отображения плана</div>;
};

export default SetPointsOnPlans;
