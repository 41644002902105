import SubsectionsActionTypes from "./subsections.types";

const INITIAL_STATE = {
  isFetching: false,
  isFetchingSubs: [],
  subsectionError: null,
  subsections: [],
  subsByGroups: {},
  fetchingSubs: [],
  currentSubsection: null,
  groups: null,
};

const subsectionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SubsectionsActionTypes.ADD_SUBSECTION_GROUP_START:
    case SubsectionsActionTypes.EDIT_SUBSECTION_GROUP_START:
    case SubsectionsActionTypes.DELETE_SUBSECTION_GROUP_START:
    case SubsectionsActionTypes.ADD_SUBSECTION_START:
    case SubsectionsActionTypes.EDIT_SUBSECTION_START:
    case SubsectionsActionTypes.GET_SUBSECTIONS_START:
    case SubsectionsActionTypes.GET_SINGLE_SUBSECTION_START:
    case SubsectionsActionTypes.DELETE_SUBSECTION_START:
      return { ...state, isFetching: true, error: null };

    case SubsectionsActionTypes.ADD_SUBSECTION_SUCCESS:
    case SubsectionsActionTypes.ADD_SUBSECTION_GROUP_SUCCESS:
    case SubsectionsActionTypes.EDIT_SUBSECTION_GROUP_SUCCESS:
    case SubsectionsActionTypes.DELETE_SUBSECTION_GROUP_SUCCESS:
    case SubsectionsActionTypes.EDIT_SUBSECTION_SUCCESS:
    case SubsectionsActionTypes.DELETE_SUBSECTION_SUCCESS:
      return { ...state, isFetching: false, error: null };

    case SubsectionsActionTypes.GET_SUBSECTION_GROUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        groups: action.payload,
      };

    case SubsectionsActionTypes.GET_SUBSECTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        subsections: action.payload,
      };

    case SubsectionsActionTypes.GET_SINGLE_SUBSECTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        currentSubsection: action.payload,
      };

    case SubsectionsActionTypes.ADD_SUBSECTION_FAILURE:
    case SubsectionsActionTypes.EDIT_SUBSECTION_FAILURE:
    case SubsectionsActionTypes.GET_SUBSECTION_GROUPS_FAILURE:
    case SubsectionsActionTypes.ADD_SUBSECTION_GROUP_FAILURE:
    case SubsectionsActionTypes.EDIT_SUBSECTION_GROUP_FAILURE:
    case SubsectionsActionTypes.DELETE_SUBSECTION_GROUP_FAILURE:
    case SubsectionsActionTypes.GET_SUBSECTIONS_FAILURE:
    case SubsectionsActionTypes.GET_SINGLE_SUBSECTION_FAILURE:
    case SubsectionsActionTypes.DELETE_SUBSECTION_FAILURE:
      return { ...state, isFetching: false, error: action.payload };

    case SubsectionsActionTypes.SET_CURRENT_SUBSECTION:
      return { ...state, currentSubsection: action.payload };

    case SubsectionsActionTypes.CLEAR_CURRENT_SUBSECTION:
      return { ...state, currentSubsection: null };

    case SubsectionsActionTypes.CLEAR_SUBSECTIONS:
      return { ...state, subsections: [], groups: null };

    case SubsectionsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default subsectionsReducer;
