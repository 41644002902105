import React, { useEffect, useState, Fragment } from "react";
import { NumberInput, SelectInput } from "../../input-forms";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialsStart } from "../../../redux/resources/resources.actions";

const Materials = ({
  materialsToSubmit,
  setMaterials,
  materialInputs,
  setMaterialInputs,
}) => {
  const materials = useSelector((state) => state.resources.materials);
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const [options, setOptions] = useState({});
  const isFetchingMaterials = useSelector(
    (state) => state.resources.isFetchingMaterials
  );
  const errorMessage = useSelector((state) => state.resources.errorMaterials);

  useEffect(() => {
    if (!materials) {
      dispatch(getMaterialsStart(currentUser.user));
    } else {
      setOptions(
        materials.reduce((acc, material) => {
          acc[material.id] = `${material.name} (${material.unit})`;
          return acc;
        }, {})
      );
    }
  }, [materials, dispatch, currentUser.user]);

  const handleChange = (e) => {
    setMaterialInputs({
      ...materialInputs,
      [e.target.name]: e.target.value ? Number(e.target.value) : "",
    });
  };

  const addMaterial = () => {
    const materialIndex = materialsToSubmit.findIndex(
      (material) => material[0] === materialInputs.id
    );
    if (materialIndex !== -1) {
      let newMaterialsToSubmit = [...materialsToSubmit];
      newMaterialsToSubmit[materialIndex][1] += materialInputs.qty;
      setMaterials(newMaterialsToSubmit);
    } else {
      setMaterials([
        ...materialsToSubmit,
        [materialInputs.id, materialInputs.qty],
      ]);
    }
    setMaterialInputs({ id: "", qty: "" });
  };

  const deleteItem = (index) => {
    setMaterials(materialsToSubmit.filter((material, i) => i !== index));
  };
  return (
    <>
      <div className="hdr">Необходимые материалы</div>
      {materialsToSubmit.length ? (
        <table className="list">
          <tbody>
            {materialsToSubmit.map((materialToSubmit, i) => {
              const materialToRender =
                materials &&
                materials.find(
                  (material) => material.id === materialToSubmit[0]
                );
              if (materialToRender) {
                return (
                  <tr key={materialToRender.id}>
                    <td>{materialToRender.name}</td>
                    <td>{`${materialToSubmit[1]} ${materialToRender.unit}`}</td>
                    <td className="control">
                      <button
                        className="remove"
                        onClick={() => deleteItem(i)}
                        type="button"
                      />
                    </td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}
      <div className="two_col">
        <div className="col">
          <SelectInput
            name="id"
            label="Наименование"
            onChange={handleChange}
            options={options}
            defaultOption={materialInputs.id}
            errorMessage={errorMessage}
            description={
              isFetchingMaterials ? "Загрузка данных..." : "Выбрать материал"
            }
            emptyMessage={"Нет добавленных специализаций"}
          />
        </div>
        <div className="col">
          <NumberInput
            label="Количество"
            value={materialInputs.qty}
            onChange={handleChange}
            name="qty"
            step="0.001"
          />
        </div>
      </div>
      <div className="buttons in_form">
        <button
          className="blue"
          type="button"
          onClick={addMaterial}
          disabled={!materialInputs.id || !materialInputs.qty}
        >
          добавить
        </button>
      </div>
    </>
  );
};

export default Materials;
