import React, { useState } from "react";
import { main_url } from "../config";
import PopupForPhoto from "./popup-for-photo";

const ImageGallery = ({ imgPath, imgFilesObj, alt }) => {
  const [enhancedImgSrc, setEnhancedImg] = useState();

  const renderImages = () => {
    const imgArr = [];
    for (const i in imgFilesObj) {
      const imgSrc = `${main_url}/${imgPath}/${imgFilesObj[i].img}`;
      const image = (
        <img src={imgSrc} alt={alt} onClick={() => setEnhancedImg(imgSrc)} />
      );

      imgArr.push(
        <span key={imgFilesObj[i].id} className="photo">
          {image}
        </span>
      );
    }
    return imgArr.length ? imgArr : "";
  };

  return (
    <>
      <div className="hdr">Фотографии</div>
      <div className="photos">{renderImages()}</div>
      {enhancedImgSrc && (
        <PopupForPhoto
          src={enhancedImgSrc}
          close={() => setEnhancedImg(null)}
        />
      )}
    </>
  );
};

export default ImageGallery;
