import { takeLeading, put, all, call } from "redux-saga/effects";
import axios from "axios";
import TechCardsActionTypes from "./techCards.types";
import {
  getTechCardsSuccess,
  getTechCardsFailure,
  getTechCardDetailsSuccess,
  getTechCardDetailsFailure,
} from "./techCards.actions";
import { handleError } from "../../utils/normalizeData";

export function* getTechCards({ payload }) {
  try {
    const response = yield axios.get(`/api/cards_and_groups/${payload}`);
    yield put(getTechCardsSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getTechCardsFailure(error));
  }
}

export function* getTechCardDetails({ payload }) {
  const { cardId, userId } = payload;

  console.log("CARD ID: ", cardId);
  try {
    const response = yield axios.get(`/api/cards/${userId}/${cardId}`);
    yield put(getTechCardDetailsSuccess(...Object.values(response.data)));
  } catch (e) {
    const error = handleError(e);
    yield put(getTechCardDetailsFailure(error));
  }
}

export function* onGetTechCardsStart() {
  yield takeLeading(TechCardsActionTypes.GET_TECH_CARDS_START, getTechCards);
}

export function* onGetTechCardDetailsStart() {
  yield takeLeading(
    TechCardsActionTypes.GET_TECH_CARD_DETAILS_START,
    getTechCardDetails
  );
}

export function* techCardsSagas(args) {
  yield all([call(onGetTechCardsStart), call(onGetTechCardDetailsStart)]);
}
