import { takeLeading, put, all, call } from "redux-saga/effects";
import ReasonsActionTypes from "./reasons.types";

import { getReasonsFailure, getReasonsSuccess } from "./reasons.actions";
import { handleError } from "../../utils/normalizeData";
import axios from "axios";

export function* getReasons({ payload }) {
  try {
    const response = yield axios.get(`/api/reasons/${payload}`);
    yield put(getReasonsSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getReasonsFailure(error));
  }
}

export function* onGetReasonsStart() {
  yield takeLeading(ReasonsActionTypes.GET_REASONS_START, getReasons);
}

export function* reasonsSagas(args) {
  yield all([call(onGetReasonsStart)]);
}
