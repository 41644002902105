import StatusesActionTypes from "./statuses.types";

const INITIAL_STATE = {
  sites: null,
  subsections: null,
  revisions: null,
  claims: null,
  remarks: null,
};

const normalizeStatuses = (statuses) => {
  return statuses.reduce((acc, status) => {
    acc[status.id] = status.name;
    return acc;
  }, {});
};

const StatusesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StatusesActionTypes.GET_STATUSES_SUCCESS:
      return {
        ...state,
        sites:
          action.payload.obj_statuses &&
          normalizeStatuses(action.payload.obj_statuses),
        subsections:
          action.payload.subsection_statuses &&
          normalizeStatuses(action.payload.subsection_statuses),
        revisions:
          action.payload.revision_statuses &&
          normalizeStatuses(action.payload.revision_statuses),
        claims:
          action.payload.claim_statuses &&
          normalizeStatuses(action.payload.claim_statuses),
        remarks:
          action.payload.job_note_statuses &&
          normalizeStatuses(action.payload.job_note_statuses),
      };
    default:
      return state;
  }
};

export default StatusesReducer;
