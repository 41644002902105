import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";

import ChecklistsActionTypes from "./checklists.types";
import {
  addChecklistSuccess,
  addChecklistFailure,
  getChecklistsSuccess,
  getChecklistsFailure,
  getChecklistsStart,
  // getActsFailure,
  // editActSuccess,
  // editActFailure,
  // deleteActSuccess,
  // deleteActFailure,
  // getActsStart,
} from "./checklists.actions";
import { getRevisionsStart } from "../revisions/revisions.actions";
// import { getClaimsStart } from "../claims/claims.actions";
import { getSingleSubsectionStart } from "../subsections/subsections.actions";

// export function* addDocument(document, checklistId, subsectionId, currentUserId) {
//   let formData = new FormData();
//   let uniqueDocName =
//     Math.floor(Math.random() * 1000) + document.name.slice(-35);
//   formData.append("checklistDocument", document, uniqueDocName);
//   yield axios.post(
//     `/api/acts/document/${currentUserId}/${actId}/${subsectionId}`,
//     formData
//   );
// }

export function* addPhoto(photo, checklistId, subsectionId, currentUserId) {
  let formData = new FormData();
  let uniqueImgName = Math.floor(Math.random() * 1000) + photo.name.slice(-35);
  formData.append("checklistImg", photo, uniqueImgName);
  yield axios.post(
    `/api/checklists/img/${currentUserId}/${checklistId}/${subsectionId}`,
    formData
  );
}

export function* addChecklist({ payload, callback }) {
  const { newChecklistData, markerCoords, photos, currentUser, site_id } =
    payload;

  const postChecklistData = {
    row: newChecklistData,
    who: currentUser.user,
    subsection: newChecklistData.subsection_id,
  };
  console.log("POST CHECKLIST DATA: ", postChecklistData);
  try {
    const response = yield axios.post("/api/checklists/add", postChecklistData);
    if (markerCoords.length) {
      const pointsData = {
        checklistPlanPointsValues: [],
        who: currentUser.user,
      };
      for (const i in markerCoords) {
        markerCoords[i].checklist_id = response.data.resultForRes.insertId;
        pointsData.checklistPlanPointsValues.push(
          Object.values(markerCoords[i])
        );
      }
      pointsData.checklistPlanPointsColumns = Object.keys(markerCoords[0]);
      yield axios.post("/api/checklist_plan_points/add", pointsData);
    }

    if (photos && photos.length) {
      yield all(
        photos.map((photo) =>
          call(
            addPhoto,
            photo,
            response.data.resultForRes.insertId,
            newChecklistData.subsection_id,
            currentUser.user
          )
        )
      );
    }
    yield put(addChecklistSuccess());
    yield put(
      getChecklistsStart(newChecklistData.subsection_id, currentUser.user)
    );
    if (newChecklistData.revision_id) {
      yield put(getRevisionsStart(currentUser, site_id));
    }
    // if (newChecklistData.claim_id) {
    //   yield put(getClaimsStart(currentUser, site_id));
    // }
    yield put(
      getSingleSubsectionStart(
        currentUser.user,
        site_id,
        newChecklistData.subsection_id
      )
    );
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addChecklistFailure(error));
  }
}

export function* getChecklists({ payload: { userId, sub_id } }) {
  try {
    const response = yield axios.get(`/api/checklists/${userId}/${sub_id}`);
    yield put(getChecklistsSuccess(Object.values(response.data), sub_id));
  } catch (e) {
    const error = handleError(e);
    yield put(getChecklistsFailure(error));
  }
}

// export function* editChecklist(editDetails) {
//   try {
//     yield console.log("axios.put(", editDetails);
//     yield put(editChecklistSuccess());
//   } catch (e) {
//     yield put(
//       editChecklistFailure(
//         e.response ? e.response.data.message : "Сеть недоступна"
//       )
//     );
//   }
// }

// export function* deleteChecklist(ChecklistDetails) {
//   try {
//     yield console.log("axios.delete(", ChecklistDetails);
//     yield put(deleteChecklistSuccess());
//   } catch (e) {
//     yield put(
//       deleteChecklistFailure(e.response ? e.response.data : "Сеть недоступна")
//     );
//   }
// }

export function* onGetChecklistsStart() {
  yield takeLeading(ChecklistsActionTypes.GET_CHECKLISTS_START, getChecklists);
}

export function* onAddChecklistStart() {
  yield takeLatest(ChecklistsActionTypes.ADD_CHECKLIST_START, addChecklist);
}

// export function* onEditChecklistStart() {
//   yield takeLatest(ChecklistsChecklistionTypes.EDIT_ACT_START, editChecklist);
// }

// export function* onDeleteChecklistStart() {
//   yield takeLeading(
//     ChecklistsChecklistionTypes.DELETE_ACT_START,
//     deleteChecklist
//   );
// }

export function* checklistsSagas(args) {
  yield all([
    call(onAddChecklistStart),
    call(onGetChecklistsStart),
    // call(onEditChecklistStart),
    // call(onDeleteChecklistStart),
  ]);
}
