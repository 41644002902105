import React from "react";
import { useSelector } from "react-redux";
import { user_photos } from "../../config";
import InlineModal from "../inlineModal";
import UserInfo from "./user-info";
import { Link, useHistory, useParams } from "react-router-dom";

const UserRow = ({ user, basePath }) => {
  const currentUser = useSelector((state) => state.user.currentUser);

  const history = useHistory();
  const { user_id } = useParams();
  // console.log("BASE PATH: ", basePath);
  return (
    <>
      <td className="name">
        <label className="mobile">пользователь</label>
        <Link
          to={`${basePath}/user/${user.id}`} /*onClick={() => showUser(user.id)}*/
        >
          {user.img ? (
            <img
              className="avatar"
              src={`${user_photos}/${user.org_id}/${user.img}`}
              alt="avatar"
            />
          ) : (
            <span className="avatar">
              {`${user.firstname.charAt(0)}${user.lastname.charAt(0)}`}
            </span>
          )}
          {`${user.firstname} ${user.lastname} ${
            currentUser.user === user.id ? "(вы)" : ""
          }`}
        </Link>
      </td>
      <td>{user.blocked ? "заблокирован" : ""}</td>

      <td>
        <label className="mobile">электронная почта</label>

        <a className="black" href={`mailto: ${user.email}`}>
          {`${user.email || ""}`}
        </a>
      </td>
      <td>
        <label className="mobile">телефон</label>

        <a className="black" href={`tel: ${user.tel}`}>{`${user.tel || ""}`}</a>
      </td>

      {user.id === +user_id && (
        <InlineModal>
          <UserInfo
            userId={+user_id}
            showUser={() => history.push(basePath)}
            basePath={basePath}
          />
        </InlineModal>
      )}
    </>
  );
};

export default UserRow;
