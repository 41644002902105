import React, { useEffect } from "react";
import { SelectInput } from "../../input-forms";
import { useSelector, useDispatch } from "react-redux";
import { getAllContractorsStart } from "../../../redux/contractors/contractors.actions";

const AllContractors = ({ setContractorDetails, setReps, site_id }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user.currentUser);
  const allContractors = useSelector(
    (state) => state.contractors.allContractors
  );
  const isFetchingAll = useSelector((state) => state.contractors.isFetchingAll);

  const contractorOptions = allContractors.reduce((acc, cur) => {
    acc[cur.id] = cur.name;
    return acc;
  }, {});

  useEffect(() => {
    if (!allContractors.length && currentUser) {
      dispatch(getAllContractorsStart(currentUser));
    }
  }, [allContractors, currentUser, dispatch]);

  const handleChange = (e) => {
    const { name, ogrn, specialization, descriptions, contractor_contacts } =
      allContractors.find(
        (contractor) => String(contractor.id) === e.target.value
      );
    setContractorDetails({
      name,
      ogrn,
      specialization,
      descriptions,
      obj_id: parseInt(site_id),
    });
    setReps(
      Object.values(contractor_contacts).map((contact) => {
        delete contact.contractor_id;
        delete contact.id;
        return contact;
      })
    );
  };
  return (
    <SelectInput
      label="Быстрое добавление с другого объекта"
      options={contractorOptions}
      onChange={handleChange}
      description={
        isFetchingAll
          ? "Список загружается..."
          : !!allContractors.length
          ? "Выбрать из списка подрядчиков"
          : "Список подрядчиков пуст"
      }
    />
  );
};

export default AllContractors;
