import React from "react";
import axios from "axios";
import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminLoginRoutes from "./admin-login-routes";
import AdminPanel from "./admin-panel";

const AdminRoutes = ({ match }) => {
  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  console.log("CURRENT ADMIN: ", currentAdmin);
  axios.defaults.headers.authorization = currentAdmin && currentAdmin.token;
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/login`}
        render={(props) =>
          currentAdmin ? (
            <Redirect to="/admin/specialties" />
          ) : (
            <AdminLoginRoutes {...props} />
          )
        }
      />

      <Route
        exact
        path={[`${match.path}/:menu`]}
        render={(props) =>
          currentAdmin ? <AdminPanel /> : <Redirect to="/admin/login" />
        }
      />

      <Redirect to="/admin/specialties" />
    </Switch>
  );
};

export default AdminRoutes;
