import { create, all } from "mathjs";

const math = create(all, {});

export const calculateFormula = (data, scope, setError, ceil) => {
  if (!data) return "";
  if (!isNaN(data)) return +data;
  try {
    let result = math.evaluate(data, scope);
    // console.log("scope: ", scope);
    // console.log("data: ", data);
    // console.log("result: ", result);
    result = ceil ? math.ceil(result) : result;
    return !result || result === Infinity || isNaN(result) ? "" : result;
  } catch (e) {
    console.log(e);
    setError("Ошибка в расчетах, обратитесь к администратору");
    return "";
  }
};

export const hasQtyProperty = (obj) => {
  for (const i in obj) {
    if (obj[i].hasOwnProperty("qty")) return true;
  }
  return false;
};
