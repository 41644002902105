import { checkEmailValidity } from "./checkEmailValidity";

export const normalizeSiteData = (siteData) => {
  const siteDetails = {
    name: siteData.name,
    address: siteData.address,
    status_id: siteData.status_id,
    regulations: siteData.regulations,
    descriptions: siteData.descriptions,
    customer_info: siteData.customer_info,
    org_id: siteData.org_id,
  };
  const contacts = Object.values(siteData.obj_contacts).map((contact) => ({
    name: contact.name,
    tel: contact.tel,
    email: contact.email,
    other_contacts: contact.other_contacts,
  }));

  const team = Object.values(siteData.obj_teams).map(
    (member) => member.user_id
  );

  const emails = Object.values(siteData.obj_emails).map((email) => email.email);

  const obj_id = siteData.id;
  return { siteDetails, contacts, emails, team, obj_id };
};

export const formatDate = (date, readable) => {
  const d = new Date(date);
  if (d instanceof Date && !isNaN(d)) {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    if (readable === "withTime") {
      let hours = d.getUTCHours();
      if (hours < 10) hours = "0" + hours;
      let minutes = d.getUTCMinutes();
      if (minutes < 10) minutes = "0" + minutes;
      return `${[year, month, day].join("-")} ${hours}:${minutes}`;
    }

    if (readable === "short") {
      return [day, month].join(".");
    }

    if (readable) return [day, month, year].join(".");
    return [year, month, day].join("-");
  }
  return "";
};

export const concatDateTime = (date, time) => {
  const d = new Date(date);
  if (d instanceof Date && !isNaN(d)) {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return `${[year, month, day].join("-")} ${time ? time : ""}`;
  }
  return "";
};

export const getTime = (dateWithTime) => {
  const d = new Date(dateWithTime);
  if (d instanceof Date && !isNaN(d)) {
    let hours = d.getUTCHours();
    if (hours < 10) hours = "0" + hours;
    let minutes = d.getUTCMinutes();
    if (minutes < 10) minutes = "0" + minutes;
    return `${hours}:${minutes}`;
  }
  return "09:00";
};

export const isEmpty = (obj) => {
  for (const x in obj) return false;
  return true;
};

export const isFilled = (obj) => {
  for (const x in obj) {
    if (obj[x]) return true;
  }
  return false;
};

export const isEqual = (obj1, obj2) => {
  //Loop through properties in object 1
  for (const p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof obj1[p]) {
      //Deep compare objects
      case "object":
        if (!isEqual(obj1[p], obj2[p])) return false;
        break;
      //Compare function code
      case "function":
        if (
          typeof obj2[p] === undefined ||
          obj1[p].toString() !== obj2[p].toString()
        )
          return false;
        break;
      //Compare values
      default:
        if (obj1[p] !== obj2[p]) return false;
    }
  }

  //Check object 2 for any extra properties
  for (const p in obj2) {
    if (typeof obj1[p] == "undefined") return false;
  }
  return true;
};

export const handleError = (error) => {
  let errorMessage = "Ошибка сети";
  try {
    errorMessage = error.response.data.message || "Ошибка сети";
  } catch {}
  if (error.response) {
  } else {
    console.log(error);
  }
  return errorMessage;
};

export const validate = (value, setError, setValid, validation) => {
  switch (validation) {
    case "required":
      if (!value) {
        setError();
        return false;
      }
      setValid();
      return true;

    case "email":
      if (value && !checkEmailValidity(value)) {
        setError();
        return false;
      }
      setValid();
      return true;

    case "required email":
      if (!checkEmailValidity(value)) {
        setError();
        return false;
      }
      setValid();
      return true;

    default:
      return;
  }
};

export const datediff = (first, second) => {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
};
