import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { formatDate, isEmpty } from "../utils/normalizeData";
import { main_url, plan_images } from "../config";
import { handleError } from "../utils/normalizeData";
import { Link } from "react-router-dom";
import PopupForPhoto from "../components/popup-for-photo";

const ClaimContractor = () => {
  const { token } = useParams();

  const [claim, setClaimToRender] = useState();
  const [zoomedPlan, setZoomedPlan] = useState();
  const [completed, markAsCompleted] = useState();
  const [isRequesting, setIsRequesting] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [zoomedImgSrc, setZoomedImgSrc] = useState();

  console.log("CLAIM TO RENDER: ", claim);

  useEffect(() => {
    const getClaimData = async () => {
      try {
        const response = await axios.get(`/api/claim_by_token/${token}`);
        if (response.data) {
          setClaimToRender(...Object.values(response.data));
        }
      } catch (e) {
        console.log(e);
        setErrorMessage(handleError(e));
      }
    };
    getClaimData();
  }, [token]);

  const handleZoom = (planId) => {
    if (zoomedPlan === planId) return setZoomedPlan(null);
    setZoomedPlan(planId);
  };

  const handleStatusChange = async () => {
    setIsRequesting(true);
    try {
      const response = await axios.put("/api/claim_status/edit", { token });
      if (response.status === 200) markAsCompleted(true);
      setIsRequesting(false);
    } catch (e) {
      console.log(e);
      setErrorMessage(handleError(e));
      setIsRequesting(false);
    }
  };
  console.log("CLAIM: ", claim);
  if (claim) {
    return (
      <div className="content">
        <h3>{`Предписание №${claim.num}`}</h3>

        <div className="field">
          <label>Исполнитель</label>
          <span className="data">{claim.contractor}</span>
        </div>

        <div className="two_col">
          <div className="col">
            <div className="field">
              <label>Устранить до</label>
              <span className="data">{formatDate(claim.date, true)}</span>
            </div>

            {(claim.reason || claim.reason_name) && (
              <div className="field">
                <label>Основание</label>
                <span className="data">
                  {claim.reason || claim.reason_name}
                </span>
              </div>
            )}
          </div>

          <div className="col">
            <div className="field">
              <label>Подраздел</label>
              <span className="data">{claim.subsection}</span>
            </div>

            {claim.item && (
              <div className="field">
                <label>Элемент</label>
                <span className="data">{claim.item}</span>
              </div>
            )}
          </div>
        </div>

        {claim.descriptions && (
          <div className="field">
            <label>Текст предписания</label>
            <span className="data">{claim.descriptions}</span>
          </div>
        )}

        {!isEmpty(claim.claim_plans) && (
          <>
            <div className="hdr">Отметка на плане</div>

            {/* <img src={`${plan_images}/`} alt="" /> */}
            {Object.values(claim.claim_plans).map((plan) => (
              <div
                key={plan.id}
                className={`plan ${zoomedPlan === plan.id ? "zoomed" : ""}`}
              >
                <div className="scroll">
                  <div className="container">
                    <img
                      src={`${plan_images}/${claim.obj_id}/${claim.subsection_id}/${plan.plan}`}
                      alt=""
                    />
                    <span
                      href="#"
                      className="the_pin claim"
                      style={{
                        left: `calc(${plan.dot_x}% - 12.5px)`,
                        top: `calc(${plan.dot_y}% - 40px)`,
                      }}
                    />
                  </div>
                </div>

                <span className="control">
                  <button
                    className="zoom"
                    onClick={() => handleZoom(plan.id)}
                  ></button>
                </span>
              </div>
            ))}
          </>
        )}

        {zoomedImgSrc && (
          <PopupForPhoto
            src={zoomedImgSrc}
            close={() => setZoomedImgSrc(null)}
          />
        )}

        {!isEmpty(claim.claim_imgs) && (
          <>
            <div className="hdr">Фотографии</div>

            <div className="photos">
              {Object.values(claim.claim_imgs).map((img) => {
                const src = `${main_url}/claims/${claim.subsection_id}/${claim.id}/${img.img}`;
                return (
                  <Link to="#" onClick={() => setZoomedImgSrc(src)}>
                    <img src={src} alt="visual proof" />
                  </Link>
                );
              })}
            </div>
          </>
        )}

        <div className="info err">{errorMessage}</div>
        <div className="buttons">
          {(claim.status_id === 2 || completed) && (
            <div>Отмечено что исполнено, ждёт проверки заказчиком</div>
          )}

          {(claim.status_id === 3 || claim.status_id === 4) && (
            <div>Исполнено, проверено заказчиком</div>
          )}

          {claim.status_id === 5 && <div>Отозвано заказчиком</div>}

          {claim.status_id === 1 && !completed && (
            <button
              className="green"
              onClick={handleStatusChange}
              disabled={isRequesting}
            >
              {isRequesting ? "Загрузка..." : "Отметить исполненым"}
            </button>
          )}
        </div>
      </div>
    );
  }
  return "";
};

export default ClaimContractor;
