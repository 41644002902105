import ReasonsActionTypes from "./reasons.types";

const INITIAL_STATE = {
  isAdding: false,
  isFetching: false,
  isDeleting: false,
  error: null,
  reasons: null,
};

const reasonsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReasonsActionTypes.ADD_REASON_START:
    case ReasonsActionTypes.EDIT_REASON_START:
      return { ...state, isAdding: true, error: null };
    case ReasonsActionTypes.GET_REASONS_START:
      return { ...state, isFetching: true, error: null };
    case ReasonsActionTypes.DELETE_REASON_START:
      return {
        ...state,
        isDeleting: true,
        error: null,
      };

    case ReasonsActionTypes.ADD_REASON_SUCCESS:
    case ReasonsActionTypes.EDIT_REASON_SUCCESS:
      return { ...state, isAdding: false, error: null };
    case ReasonsActionTypes.DELETE_REASON_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        error: null,
      };

    case ReasonsActionTypes.GET_REASONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        reasons: action.payload,
      };

    case ReasonsActionTypes.ADD_REASON_FAILURE:
    case ReasonsActionTypes.EDIT_REASON_FAILURE:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      };

    case ReasonsActionTypes.GET_REASONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ReasonsActionTypes.DELETE_REASON_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };

    case ReasonsActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default reasonsReducer;
