import MaterialsActionTypes from "./materials.types";

export const addMaterialStart = (newMaterialData, adminId, callback) => ({
  type: MaterialsActionTypes.ADD_MATERIAL_START,
  payload: { newMaterialData, adminId },
  callback,
});

export const addMaterialSuccess = () => ({
  type: MaterialsActionTypes.ADD_MATERIAL_SUCCESS,
});

export const addMaterialFailure = (error) => ({
  type: MaterialsActionTypes.ADD_MATERIAL_FAILURE,
  payload: error,
});

export const getMaterialsStart = (adminId) => ({
  type: MaterialsActionTypes.GET_MATERIALS_START,
  payload: adminId,
});

export const getMaterialsSuccess = (specialties) => ({
  type: MaterialsActionTypes.GET_MATERIALS_SUCCESS,
  payload: specialties,
});

export const getMaterialsFailure = (error) => ({
  type: MaterialsActionTypes.GET_MATERIALS_FAILURE,
  payload: error,
});

export const editMaterialStart = (
  materialData,
  adminId,
  materialId,
  callback
) => ({
  type: MaterialsActionTypes.EDIT_MATERIAL_START,
  payload: { materialData, adminId, materialId },
  callback,
});

export const editMaterialSuccess = () => ({
  type: MaterialsActionTypes.EDIT_MATERIAL_SUCCESS,
});

export const editMaterialFailure = (error) => ({
  type: MaterialsActionTypes.EDIT_MATERIAL_FAILURE,
  payload: error,
});

export const deleteMaterialStart = (materialId, adminId, callback) => ({
  type: MaterialsActionTypes.DELETE_MATERIAL_START,
  payload: { materialId, adminId },
  callback,
});

export const deleteMaterialSuccess = (deletedMaterialId) => ({
  type: MaterialsActionTypes.DELETE_MATERIAL_SUCCESS,
  payload: deletedMaterialId,
});

export const deleteMaterialFailure = (error) => ({
  type: MaterialsActionTypes.DELETE_MATERIAL_FAILURE,
  payload: error,
});

export const clearErrors = () => ({
  type: MaterialsActionTypes.CLEAR_ERRORS,
});
