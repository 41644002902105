import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../utils/normalizeData";

import ResourcesActionTypes from "./resources.types";
import {
  addSpecialtySuccess,
  addSpecialtyFailure,
  getSpecialtiesStart,
  getSpecialtiesSuccess,
  getSpecialtiesFailure,
  editSpecialtySuccess,
  editSpecialtyFailure,
  deleteSpecialtySuccess,
  deleteSpecialtyFailure,
  getEquipmentSuccess,
  getEquipmentFailure,
  getMaterialsSuccess,
  getMaterialsFailure,
} from "./resources.actions";

export function* addSpecialty({ payload, callback }) {
  const { contractorDetails, contacts, currentUser } = payload;
  const postSpecialtyData = {
    contractorColumns: Object.keys(contractorDetails),
    contractorValues: [[...Object.values(contractorDetails)]],
    who: currentUser.user,
    object: contractorDetails.obj_id,
  };
  if (contacts.length) {
    postSpecialtyData.contactColumns = Object.keys(contacts[0]);
    postSpecialtyData.contactValues = [
      ...contacts.map((contact) => [...Object.values(contact)]),
    ];
  }
  console.log("CONTACTS: ", contacts);
  console.log("NEW CONTRACTOR: ", postSpecialtyData);
  try {
    yield axios.post("/api/contractor_form/add", postSpecialtyData);
    yield put(addSpecialtySuccess());
    yield put(getSpecialtiesStart(currentUser, contractorDetails.obj_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addSpecialtyFailure(error));
  }
}

export function* onAddSpecialtyStart() {
  yield takeLatest(ResourcesActionTypes.ADD_SPECIALTY_START, addSpecialty);
}

export function* getSpecialties({ payload }) {
  try {
    const response = yield axios.get(`/api/employees/${payload}`);
    yield put(getSpecialtiesSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getSpecialtiesFailure(error));
  }
}

export function* onGetSpecialtiesStart() {
  yield takeLeading(ResourcesActionTypes.GET_SPECIALTIES_START, getSpecialties);
}

export function* getEquipment({ payload }) {
  try {
    const response = yield axios.get(`/api/equipment/${payload}`);
    yield put(getEquipmentSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getEquipmentFailure(error));
  }
}

export function* onGetEquipmentStart() {
  yield takeLeading(ResourcesActionTypes.GET_EQUIPMENT_START, getEquipment);
}

export function* getMaterials({ payload }) {
  try {
    const response = yield axios.get(`/api/materials_full/${payload}`);
    yield put(getMaterialsSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getMaterialsFailure(error));
  }
}

export function* onGetMaterialsStart() {
  yield takeLeading(ResourcesActionTypes.GET_MATERIALS_START, getMaterials);
}

export function* editSpecialty({ payload, callback }) {
  const { contractorDetails, contacts, currentUser } = payload;
  const putSpecialtyData = {
    contractors: contractorDetails,
    who: currentUser.user,
    contractor_id: payload.contractorToEdit.id,
    obj_id: contractorDetails.obj_id,
  };
  if (contacts.length) {
    putSpecialtyData.contactColumns = Object.keys(contacts[0]);
    putSpecialtyData.contactValues = [
      ...contacts.map((rep) => Object.values(rep)),
    ];
  }
  try {
    yield axios.put("/api/contractor_form/edit", putSpecialtyData);
    yield put(editSpecialtySuccess());
    yield put(getSpecialtiesStart(currentUser, contractorDetails.obj_id));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editSpecialtyFailure(error));
  }
}

export function* onEditSpecialtyStart() {
  yield takeLatest(ResourcesActionTypes.EDIT_SPECIALTY_START, editSpecialty);
}

export function* deleteSpecialty({ payload, callback }) {
  const { currentUser, id, object } = payload;
  const deleteData = { who: currentUser.user, id, object };
  try {
    yield axios.delete("/api/contractors/delete", { data: deleteData });
    yield console.log("axios.delete(", payload);
    yield put(deleteSpecialtySuccess());
    yield put(getSpecialtiesStart(currentUser, object));
    callback();
  } catch (e) {
    let error = "Ошибка сети";
    if (e.response && e.response.data) {
      if (e.response.data.message) error = e.response.data.message;
      else if (e.response.data.errno) {
        if (e.response.data.errno === 1451)
          error = "Нельзя удалить поставщика, прикрепленного к действиям";
      }
    }
    console.log(e.response);
    yield put(deleteSpecialtyFailure(error));
  }
}

export function* onDeleteSpecialtyStart() {
  yield takeLeading(
    ResourcesActionTypes.DELETE_SPECIALTY_START,
    deleteSpecialty
  );
}

export function* resourcesSagas(args) {
  yield all([
    call(onGetSpecialtiesStart),
    call(onGetEquipmentStart),
    call(onGetMaterialsStart),
  ]);
}
