import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTechCardsStart,
  clearErrors,
  getTechGroupsStart,
} from "../../redux/techCards/techCards.actions";
import { useCardsListStyles } from "../../../css/admin/main-section.styles";
import InlineModal from "../../../components/inlineModal";
import LinkButton from "../../../components/buttons/link-button";
import TechGroupModal from "./TechGroupModal";
import DeletePrompt from "./DeletePrompt";
import { ReactComponent as Edit } from "../../../assets/edit.svg";
import { ReactComponent as Trash } from "../../../assets/trash.svg";
import { ReactComponent as GroupIcon } from "../../../assets/group.svg";
import { ReactComponent as CardIcon } from "../../../assets/card.svg";
import { ReactComponent as GoBack } from "../../../assets/left-arrow.svg";

const TechCardsList = ({
  cardIdToView,
  setCardIdToView,
  cardIdToEdit,
  setCardIdToEdit,
  groupIdForCard,
  focusOnName,
}) => {
  const classes = useCardsListStyles();
  const dispatch = useDispatch();

  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const techGroups = useSelector((state) => state.techCards.techGroups);
  const techCards = useSelector((state) => state.techCards.techCards);
  // console.log("TECH CARDS: ", techCards);
  const isFetchingGroups = useSelector(
    (state) => state.techCards.isFetchingGroups
  );
  const isFetchingCards = useSelector(
    (state) => state.techCards.isFetchingCards
  );

  const [groupModal, toggleGroupModal] = useState();
  const [groupToEdit, setGroupToEdit] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const [currentGroup, setCurrentGroup] = useState(null);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [groupsToRender, setGroupsToRender] = useState([]);

  const openedGroups = useRef();
  const groupId = currentGroup ? currentGroup.id : null;

  useEffect(() => {
    if (!techGroups) {
      dispatch(getTechGroupsStart(currentAdmin.adminId));
    } else {
      const filteredGroups = currentGroup
        ? techGroups.filter((group) => group.card_group_id === currentGroup.id)
        : techGroups.filter((group) => !group.card_group_id);

      setGroupsToRender(filteredGroups);
    }
  }, [techGroups, currentAdmin, dispatch, currentGroup]);

  useEffect(() => {
    if (!techCards[groupId]) {
      dispatch(getTechCardsStart(groupId, currentAdmin.adminId));
    }
  }, [techCards, currentGroup, groupId, currentAdmin, dispatch]);

  useEffect(() => {
    groupIdForCard.current = currentGroupId;
  }, [currentGroupId, groupIdForCard]);

  useEffect(() => {
    dispatch(clearErrors());
  }, [confirmDelete, dispatch]);

  const openGroup = (group) => {
    if (!openedGroups.current) openedGroups.current = [];
    openedGroups.current.push(currentGroup);
    setCurrentGroup(group);
    setCurrentGroupId(group.id);
  };

  const goToPreviousGroup = () => {
    const previousGroup = openedGroups.current.pop();
    setCurrentGroup(previousGroup);
    setCurrentGroupId(previousGroup ? previousGroup.id : null);
  };

  const openGroupModal = (groupToEdit) => {
    if (groupToEdit) setGroupToEdit(groupToEdit);
    toggleGroupModal(true);
  };

  const closeGroupModal = () => {
    toggleGroupModal(false);
    setGroupToEdit(null);
  };

  const handleCardView = (cardId) => {
    setCardIdToEdit(null);
    setCardIdToView(cardId);
  };

  const handleCardEdit = (cardId) => {
    setCardIdToView(null);
    setCardIdToEdit(cardId);
  };

  const clearCurrentIds = () => {
    setCardIdToView(null);
    setCardIdToEdit(null);
    focusOnName();
  };

  return (
    <div className={classes.list}>
      <h1>Технологические карты</h1>

      {currentGroup && (
        <div className={classes.goBack}>
          <span onClick={goToPreviousGroup}>
            <GoBack />
          </span>
          {currentGroup.name}
        </div>
      )}
      <div className={classes.createBtns}>
        <LinkButton onClick={() => toggleGroupModal(true)} type="button">
          Создать группу
        </LinkButton>
        <LinkButton onClick={clearCurrentIds} type="button">
          Создать карту
        </LinkButton>
      </div>
      {isFetchingGroups && (
        <div className={classes.loading}>загрузка групп...</div>
      )}

      {groupsToRender.map((group) => {
        return (
          <div className={classes.listItem} key={group.id}>
            <span onClick={() => openGroup(group)}>
              <GroupIcon className={classes.icon} />
              {group.name}
            </span>
            <div className={classes.editBtns}>
              <div type="button" onClick={() => openGroupModal(group)}>
                <Edit />
              </div>
              <div
                type="button"
                onClick={() => setConfirmDelete({ type: "group", data: group })}
              >
                <Trash className={classes.trashIcon} />
              </div>
            </div>
          </div>
        );
      })}

      {isFetchingCards && (
        <div className={classes.loading}>загрузка карт...</div>
      )}

      {techCards[currentGroupId] &&
        techCards[currentGroupId].map((techCard) => (
          <div key={techCard.id} className={classes.listItem}>
            <span
              onClick={() => handleCardView(techCard.id)}
              className={`${classes.techCard}
                ${
                  (cardIdToEdit && cardIdToEdit.id === techCard.id) ||
                  cardIdToView === techCard.id
                    ? classes.selectedItem
                    : ""
                }
              `}
            >
              <CardIcon className={classes.cardIcon} />
              {`${techCard.name}`}
            </span>

            <div className={classes.editBtns}>
              <div type="button" onClick={() => handleCardEdit(techCard.id)}>
                <Edit />
              </div>

              <div>
                <Trash
                  className={classes.trashIcon}
                  onClick={() =>
                    setConfirmDelete({ type: "card", data: techCard })
                  }
                />
              </div>
            </div>
          </div>
        ))}

      {groupModal && (
        <InlineModal close={closeGroupModal}>
          <TechGroupModal
            groupId={currentGroup ? currentGroup.id : null}
            close={closeGroupModal}
            groupToEdit={groupToEdit}
          />
        </InlineModal>
      )}

      {confirmDelete && (
        <DeletePrompt
          close={() => setConfirmDelete(null)}
          confirmDelete={confirmDelete}
          cardIdToEdit={cardIdToEdit}
          setCardIdToEdit={setCardIdToEdit}
          cardIdToView={cardIdToView}
          setCardIdToView={setCardIdToView}
          groupIdForCard={groupIdForCard}
        />
      )}
    </div>
  );
};

export default TechCardsList;
