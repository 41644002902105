import { createUseStyles } from "react-jss";

export const useMainSectionStyles = createUseStyles({
  main: {
    padding: "7vh 2vw 0 2vw",
    display: "flex",
    height: "100%",
    boxSizing: "border-box",
  },
  list: {
    marginRight: "2vw",
    height: "auto",
    width: "18vw",
    boxSizing: "border-box",
    "& h1": {
      marginBottom: "4vh",
      fontSize: "12px",
      margin: 0,
    },
    "& h2": {
      margin: "4vh 0 1vh",
      fontSize: "12px",
      fontWeight: "500",
    },
  },
  listItem: {
    width: "18vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "black",
    boxSizing: "border-box",

    "& span": {
      width: "14vw",
      borderBottom: "1px #e8e8e8 solid",
      padding: "1.5vh 1vw",
      cursor: "pointer",
    },
    "& svg": {
      height: "20px",
      opacity: 0,
      transition: "opacity 0.7s",
      fill: "red",
      cursor: "pointer",
    },

    "&:hover": {
      "& span": {
        backgroundColor: "#d4d2d2",
      },
      "& svg": {
        opacity: 1,
      },
    },
  },

  constant: {
    width: "18vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "black",
    boxSizing: "border-box",

    "& span": {
      width: "14vw",
      borderBottom: "1px #e8e8e8 solid",
      padding: "1.5vh 1vw",
    },

    "&:hover": {
      "& span": {
        backgroundColor: "#d4d2d2",
      },
    },
  },

  deletingItem: {
    "& svg": {
      opacity: 1,
      fill: "grey",
    },
  },
  addSection: {
    width: "100%",

    "& input, select": {
      maxWidth: "25vw",
    },

    "& >*": {
      marginBottom: "4vh",
    },
    "& li": {
      fontSize: "16px",
      margin: "5px 15px",
    },
  },
  actionBtns: {
    display: "flex",
    "& button": {
      margin: "0 3vw 3vh 0",
      borderRadius: 0,
    },
  },
  errorMsg: {
    color: "red",
  },
  promptDelete: {
    padding: "0 2vw",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      margin: 0,
      textAlign: "center",
    },
    "& button": {
      borderRadius: 0,
      minWidth: "50px",
    },
  },
  deleteBtns: {
    margin: "2vh 0",
    display: "flex",
    justifyContent: "space-around",
  },
  errorMessage: {
    minHeight: "16px",
    textAlign: "center",
    color: "red",
  },
  inputSection: {
    display: "flex",
    alignItems: "center",
    "& >*": {
      width: "45%",
      marginRight: "5%",
    },
  },
});

export const useCardsListStyles = createUseStyles({
  list: {
    marginRight: "2vw",
    height: "auto",
    width: "18vw",
    boxSizing: "border-box",
    "& h1": {
      marginBottom: "5vh",
      fontSize: "12px",
      margin: 0,
    },
  },
  loading: {
    margin: "1vh 0",
  },
  listGroup: {
    margin: "1.5vh 0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& span": { marginLeft: "1vw" },
  },
  goBack: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2vh",
    "& svg": {
      height: "3.5vh",
      marginRight: "1vw",
      cursor: "pointer",
    },
  },
  techCard: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: "1vw",
    },
  },
  icon: {
    height: "20px",
  },
  cardIcon: {
    height: "20px",
    fill: "blue",
  },
  trashIcon: {
    fill: "red",
  },
  listItem: {
    width: "16vw",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "black",
    boxSizing: "border-box",

    "& span": {
      minWidth: "11vw",
      width: "11vw",
      maxWidth: "11vw",
      borderBottom: "1px #e8e8e8 solid",
      padding: "1.5vh 1vw",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",

      "& svg": {
        marginRight: "1vw",
      },
    },

    "& button": {
      border: "none",
      backgroundColor: "transparent",
    },

    "&:hover": {
      "& span": {
        backgroundColor: "#d4d2d2",
      },
      "& svg": {
        opacity: 1,
      },
    },
  },
  createBtns: {
    paddingLeft: "1vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    "& button": {
      marginBottom: "2vh",
    },
  },
  editBtns: {
    display: "flex",
    justifyContent: "space-between",
    width: "5vw",

    "& div:nth-child(1)": {
      paddingLeft: "3px",
      marginRight: "1vw",
    },

    "& svg": {
      height: "20px",
      // width: "20px",
      opacity: 0,
      transition: "opacity 0.7s",
      cursor: "pointer",
    },
  },
  selectedItem: {
    backgroundColor: "#f0f0f0",
  },
  promptDelete: {
    padding: "0 2vw",
    width: "25vw",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      margin: "0 0 2vh",
      textAlign: "center",
    },
  },
  errorMessage: {
    minHeight: "16px",
    textAlign: "center",
    color: "red",
  },
  deleteBtns: {
    margin: "2vh 0",
    display: "flex",
    justifyContent: "space-around",
    "& button": {
      minWidth: "5vw",
      borderRadius: 0,
    },
  },
});

export const useAddTechCardItemsStyles = createUseStyles({
  listItem: {
    padding: "2vh 0 1vh 5px",
    display: "flex",
    justifyContent: "space-between",
  },
  error: {
    color: "red",
  },
  add: {
    margin: "1% 0",
    "& button": {
      minWidth: "auto",
    },
  },
  delete: {
    border: "none",
    textDecoration: "underline",
    color: "#FF6262",
    backgroundColor: "white",
    cursor: "pointer",
    height: "12px",
    lineHeight: "12px",
    fontSize: "12px",
    // marginRight: "2vw",
  },
  specialtiesInputs: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",

    "& >*": {
      marginRight: "2%",
    },
    "& input, select": {
      width: "13.5vw",
      maxWidth: "13.5vw",
    },
    "& input::placeholder": {
      fontSize: "10px",
    },
  },
  selectSpan: {
    width: "20vw",
  },
  twoInputs: {
    display: "flex",
    // flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "start",

    "& >*": {
      marginRight: "2%",
    },
    "& input, select": {
      width: "20vw",
      maxWidth: "20vw",
    },
    "& input::placeholder": {
      fontSize: "10px",
    },
  },
  itemsTable: {
    margin: "2vh 0",
    width: "100%",
    border: "1px solid #a9a9a9",
    tableLayout: "fixed",
    // "& th, td": {
    //   border: "1px solid black",
    // },
    "& tr": {
      height: "4vh",
    },
    "& th": {
      color: "#a9a9a9",
      fontWeight: 500,
    },
    "& td": {
      textAlign: "center",
    },
  },
  alignRight: {
    textAlign: "right !important",
  },

  "@media (max-width: 1320px)": {
    specialtiesInputs: {
      "& >*": {
        width: "40%",

        "& select": {
          width: "100%",
          maxWidth: "100%",
        },
        "& input": {
          width: "100%",
          maxWidth: "100%",
        },
      },
    },
  },
});

export const usePromptWindowStyles = createUseStyles({
  promptWindow: {
    padding: "0 2vw",
    width: "25vw",
    "& h1": {
      fontSize: "20px",
      fontWeight: 400,
      margin: "0 0 2vh",
      textAlign: "center",
    },
  },
  actionBtns: {
    display: "flex",
    justifyContent: "space-around",
    margin: "2vh 0",
    "& button": {
      minWidth: "8vw",
    },
  },
  errorMsg: {
    color: "red",
    textAlign: "center",
  },
});

export const useCardViewStyles = createUseStyles({
  main: {
    width: "80%",
    "& h1": {
      fontSize: "16px",
      fontWeight: 500,
      margin: "0vh 0 2vh",
    },
    "& h2": {
      fontSize: "14px",
      fontWeight: 500,
    },
    "& >*": {
      marginBottom: "3vh",
    },
    "& table": {
      backgroundColor: "#c9c9c9",

      "& th": {
        fontWeight: 500,
        fontSize: "13px",
      },

      "& td, th": {
        backgroundColor: "white",
        padding: "1vh",
        textAlign: "center",
      },
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  actionBtns: {
    marginRight: "10vw",
    "& button": {
      marginRight: "3vw",
    },
  },
});
