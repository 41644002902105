import React from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTechCardStart,
  deleteTechGroupStart,
} from "../../redux/techCards/techCards.actions";

const DeletePrompt = ({
  confirmDelete,
  cardIdToEdit,
  setCardIdToEdit,
  cardIdToView,
  setCardIdToView,
  groupIdForCard,
  close,
}) => {
  const dispatch = useDispatch();

  const currentAdmin = useSelector((state) => state.admins.currentAdmin);
  const isDeleting = useSelector((state) => state.techCards.isDeleting);
  const errorMessage = useSelector((state) => state.techCards.error);

  const handleDelete = (e) => {
    e.preventDefault();
    const action =
      confirmDelete.type === "group"
        ? deleteTechGroupStart
        : deleteTechCardStart;

    const groupId =
      groupIdForCard && groupIdForCard.current ? groupIdForCard.current : null;

    const callback = () => {
      close();
      if (cardIdToEdit === confirmDelete.data.id) {
        setCardIdToEdit(null);
      }
      if (cardIdToView === confirmDelete.data.id) {
        setCardIdToView(null);
      }
    };

    dispatch(
      action(confirmDelete.data.id, currentAdmin.adminId, callback, groupId)
    );
  };

  return ReactDOM.createPortal(
    <div className="main">
      <div className="under_popup"></div>

      <div className="popup_small">
        <div className="message">
          {`Удалить ${confirmDelete.type === "group" ? "группу" : "карту"} "${
            confirmDelete.data.name
          }" ?`}
        </div>
        <div className="info err">{errorMessage}</div>
        <div className="buttons">
          <button className="red" onClick={handleDelete} disabled={isDeleting}>
            Да
          </button>
          <button className="cancel" onClick={close} disabled={isDeleting}>
            Нет
          </button>
        </div>
      </div>
    </div>,
    document.querySelector("#modal")
  );
  // <form className={classes.promptWindow} onSubmit={handleDelete}>
  //   <h1>
  // {`Удалить ${confirmDelete.type === "group" ? "группу" : "карту"} "${
  //   confirmDelete.data.name
  // }" ?`}
  //   </h1>
  //   <div className={classes.errorMsg}>{errorMessage}</div>
  //   <div className={classes.actionBtns}>
  //     <CustomButton
  //       onClick={handleDelete}
  //       cancelstyle="true"
  //       disabled={isDeleting}
  //     >
  //       Да
  //     </CustomButton>
  //     <CustomButton onClick={close} type="button" disabled={isDeleting}>
  //       Нет
  //     </CustomButton>
  //   </div>
  // </form>
};

export default DeletePrompt;
