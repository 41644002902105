import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signInStart } from "../redux/admins/admins.actions";
import { InputField } from "../../components/input-forms";

const AdminLogin = () => {
  const dispatch = useDispatch();

  // useEffect(() => dispatch(clearErrors()), [dispatch]);

  const loginError = useSelector((state) => state.admins.error);
  const isUserLoggingIn = useSelector((state) => state.admins.isFetching);

  const [userCredentials, setCredentials] = useState({
    company_id: "",
    email: "email_56@tk.ru",
    password: "123456",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signInStart(userCredentials));
  };

  const handleChange = (e) => {
    setCredentials({ ...userCredentials, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className="header_login">
        <h1>Под Контролем</h1>
        <h2>Вход администратора</h2>
      </div>
      <form className="content_login" onSubmit={handleSubmit}>
        <div className="info err">{loginError}</div>
        <InputField
          type="text"
          label="Идентификатор компании"
          value={userCredentials.company_id}
          name="company_id"
          onChange={handleChange}
          loginstyle
        />
        <InputField
          label="Электронная почта"
          type="email"
          name="email"
          value={userCredentials.email}
          onChange={handleChange}
          loginstyle
          validationType="required"
        />
        <InputField
          label="Пароль"
          type="password"
          name="password"
          value={userCredentials.password}
          onChange={handleChange}
          loginstyle
          validationType="required"
        />
        {/* <Link className="forgot" to="/admin/login/restore_by_email">
          Забыли пароль?
        </Link> */}
        <div className="button">
          <button disabled={isUserLoggingIn}>
            {isUserLoggingIn ? "авторизация..." : "Войти"}
          </button>
        </div>
      </form>
    </>
  );
};

export default AdminLogin;
