import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  addSiteStart,
  editSiteStart,
  saveSiteInputs,
} from "../../redux/sites/sites.actions";
import { SelectInput, InputField, TextAreaInput } from "../input-forms";
import Team from "./team";
import Contacts from "./contacts";
import Emails from "./emails";
import { validate } from "../../utils/normalizeData";

const AddSite = ({ siteToEdit, checkInputs, close, checkInputsAndClose }) => {
  const dispatch = useDispatch();

  const status = useSelector((state) => state.statuses.sites);
  const [statusOptions, setStatusOptions] = useState({});

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  const { site_id } = useParams();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [siteDetails, setSiteDetails] = useState({
    name: "",
    address: "",
    status_id: "",
    regulations: "",
    descriptions: "",
    customer_info: "",
    org_id: currentUser.organization || null,
  });

  // set default site status and normalize status selection options
  useEffect(() => {
    if (status) {
      const options = {};
      for (let id in status) {
        if (status[id] === "актуальные") {
          options[id] = "В работе";
          setSiteDetails((s) => ({ ...s, status_id: Number(id) }));
        } else if (status[id] === "завершенные") options[id] = "Завершен";
      }

      setStatusOptions(options);
    }
  }, [status]);

  const [contactsToAdd, setContactsToAdd] = useState([]);
  const [contactInputs, setContactInputs] = useState({
    name: "",
    email: "",
    tel: "",
    other_contacts: "",
  });
  const [emailsToAdd, setEmailsToAdd] = useState([]);
  const [emailInput, setEmailInput] = useState("");
  const [emailIndexToEdit, setEmailIndexToEdit] = useState();

  const [team, setTeam] = useState([currentUser.user]);

  const [errors, setErrors] = useState({
    name: { type: "required", error: null },
    address: { type: "required", error: null },
  });
  const [isDataValid, setIsDataValid] = useState(true);

  const isEmpty = (obj) => {
    for (let i in obj) if (obj[i]) return false;
    return true;
  };

  // if this is not the edit window, then check inputs and prompt the user when he
  // tries to close the window without saving
  checkInputs.current = () => {
    if (
      !site_id &&
      (siteDetails.name ||
        siteDetails.address ||
        siteDetails.regulations ||
        siteDetails.descriptions ||
        siteDetails.customer_info ||
        contactsToAdd.length ||
        !isEmpty(contactInputs) ||
        emailsToAdd.length ||
        emailInput ||
        team.length > 1 ||
        (status && Object.keys(status)[0] !== String(siteDetails.status_id)))
    )
      return true;
    return false;
  };

  //this selector is used to retrieve typed inputs when user goes to another window
  const savedSiteInputs = useSelector(
    (state) => state.sites.savedNewSiteDetails
  );

  // if user returns to this page (not by clicking on "add site" button), all the previous
  // typed and unsaved info is assigned back to the appropriate inputs
  useEffect(() => {
    if (savedSiteInputs) {
      setSiteDetails(savedSiteInputs.siteDetails);
      setContactsToAdd(savedSiteInputs.contactsToAdd);
      setEmailsToAdd(savedSiteInputs.emailsToAdd);
      return setTeam(savedSiteInputs.team);
    }
    if (siteToEdit) {
      setSiteDetails(siteToEdit.siteDetails);
      setContactsToAdd(siteToEdit.contacts);
      setEmailsToAdd(siteToEdit.emails);
      setTeam(siteToEdit.team);
    }
  }, [savedSiteInputs, siteToEdit]);

  // check if all the errors have been corrected
  useEffect(() => {
    if (!isDataValid) {
      for (const i in errors) {
        if (errors[i].error) return;
      }
      setIsDataValid(true);
    }
  }, [isDataValid, errors]);

  //when user goes to another window (for example, to view team members), this useRef and useEffect
  //are used to save typed inputs to redux
  const _saveSiteInputs = useRef(null);
  _saveSiteInputs.current = { siteDetails, contactsToAdd, emailsToAdd, team };

  useEffect(() => {
    return () => dispatch(saveSiteInputs(_saveSiteInputs.current));
  }, [dispatch]);

  const isFetching = useSelector((state) => state.sites.isFetching);
  const errorMessage = useSelector((state) => state.sites.error);

  const handleChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      validate(
        e.target.value,
        () => {},
        () =>
          setErrors({
            ...errors,
            [e.target.name]: { ...errors[e.target.name], error: null },
          }),
        errors[e.target.name].type
      );
    }
    if (e.target.name === "status_id") {
      return setSiteDetails({
        ...siteDetails,
        [e.target.name]: parseInt(e.target.value),
      });
    }
    setSiteDetails({ ...siteDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    if (!siteDetails.name) {
      setErrors((errors) => ({
        ...errors,
        name: { ...errors.name, error: "Название обязательно" },
      }));
      valid = false;
    }
    if (!siteDetails.address) {
      setErrors((errors) => ({
        ...errors,
        address: { ...errors.address, error: "Адрес обязателен" },
      }));
      valid = false;
    }
    if (!valid) return setIsDataValid(false);

    const action = siteToEdit ? editSiteStart : addSiteStart;

    let normalizedEmails = [...emailsToAdd];
    if (emailInput) {
      if (emailIndexToEdit) {
        normalizedEmails[emailIndexToEdit] = emailInput;
      } else {
        normalizedEmails.push(emailInput);
      }
    }

    dispatch(
      action(
        {
          siteDetails,
          contactsToAdd: isEmpty(contactInputs)
            ? contactsToAdd
            : [...contactsToAdd, contactInputs],
          emailsToAdd: normalizedEmails,
          team,
          currentUser,
          obj_id: siteToEdit ? siteToEdit.obj_id : null,
        },
        close
      )
    );
  };

  return (
    <div id="container">
      <div className="caption">
        Объект
        <button className="close" onClick={checkInputsAndClose}></button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="two_col">
          <div className="col">
            <InputField
              label="Название"
              type="text"
              value={siteDetails.name}
              onChange={handleChange}
              name="name"
              validationType="required"
              placeholder="Заливка фундамента"
              err={errors.name.error}
            />

            <SelectInput
              options={statusOptions}
              defaultOption={siteDetails.status_id}
              label="Статус"
              name="status_id"
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <TextAreaInput
              label="Адрес"
              value={siteDetails.address}
              onChange={handleChange}
              name="address"
              validationType="required"
              placeholder="Город, улица, дом"
              err={errors.address.error}
            />
          </div>
        </div>
        <Contacts
          contactsToAdd={contactsToAdd}
          setContactsToAdd={setContactsToAdd}
          contactInputs={contactInputs}
          setContactInputs={setContactInputs}
        />
        <TextAreaInput
          label="Правила попадания на объект"
          height="15vh"
          name="regulations"
          value={siteDetails.regulations}
          onChange={handleChange}
          placeholder="Ремонт окон"
        />
        <TextAreaInput
          label="Общее описание"
          height="15vh"
          onChange={handleChange}
          value={siteDetails.descriptions}
          name="descriptions"
          placeholder="Ремонт окон"
        />
        <Emails
          emailsToAdd={emailsToAdd}
          setEmailsToAdd={setEmailsToAdd}
          emailInput={emailInput}
          setEmailInput={setEmailInput}
          emailIndexToEdit={emailIndexToEdit}
          setEmailIndexToEdit={setEmailIndexToEdit}
        />

        <TextAreaInput
          label="Данные заказчика"
          height="15vh"
          value={siteDetails.customer_info}
          onChange={handleChange}
          name="customer_info"
        />
        <Team team={team} setTeam={setTeam} currentUserId={currentUser.id} />

        <p className="info err">
          {errorMessage || (!isDataValid && "Не все поля заполнены корректно")}
        </p>

        <div className="buttons">
          <button disabled={isFetching} className="green">
            {isFetching ? "Загрузка..." : siteToEdit ? "Сохранить" : "Добавить"}
          </button>
          <button
            className="cancel"
            onClick={checkInputsAndClose}
            deletestyle="true"
            type="button"
          >
            Отмена
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddSite;
