import React, { useState, useEffect } from "react";
import { InputField, TextAreaInput } from "../../input-forms";
import Contacts from "../contacts";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addContractorStart,
  editContractorStart,
  deleteContractorStart,
  getCurrentContractorStart,
} from "../../../redux/contractors/contractors.actions";
import AllContractors from "./all-contractors";
import { isEmpty, isFilled } from "../../../utils/normalizeData";
import ConfirmDeleteModal from "../../confirmActionModal";
import { validate } from "../../../utils/normalizeData";

const AddContractor = ({
  checkInputs,
  closeAfterDelete,
  close,
  checkInputsAndClose,
  contractorIdToEdit,
}) => {
  const { site_id } = useParams();
  const dispatch = useDispatch();
  const currentContractor = useSelector(
    (state) => state.contractors.currentContractor
  );
  const currentUser = useSelector((state) => state.user.currentUser);
  const [contractorDetails, setContractorDetails] = useState({
    no_email: false,
    name: "",
    ogrn: "",
    specialization: "",
    descriptions: "",
    obj_id: parseInt(site_id),
  });
  const [contactsToAdd, setContactsToAdd] = useState([]);
  const [contactInputs, setContactInputs] = useState({
    name: "",
    email: "",
    tel: "",
    other_contacts: "",
  });
  // console.log("CONTACTS TO ADD: ", contactsToAdd);
  const [errors, setErrors] = useState({
    name: { type: "required", error: null },
  });

  // if this is not the edit window, then check inputs and prompt the user when he
  // tries to close the window without saving
  if (!contractorIdToEdit) {
    checkInputs.current = () => {
      if (
        contractorDetails.name ||
        contractorDetails.ogrn ||
        contractorDetails.specialization ||
        contractorDetails.descriptions ||
        contactsToAdd.length ||
        contactInputs.name ||
        contactInputs.email ||
        contactInputs.tel ||
        contactInputs.other_contacts
      )
        return true;
      return false;
    };
  }

  useEffect(() => {
    if (contractorIdToEdit) {
      if (!currentContractor || currentContractor.id !== contractorIdToEdit) {
        dispatch(
          getCurrentContractorStart(contractorIdToEdit, currentUser.user)
        );
      } else {
        setContractorDetails({
          no_email: currentContractor.no_email,
          name: currentContractor.name,
          ogrn: currentContractor.ogrn,
          specialization: currentContractor.specialization,
          descriptions: currentContractor.descriptions,
          obj_id: currentContractor.obj_id,
        });

        if (!isEmpty(currentContractor.contractor_contacts)) {
          const reps = Object.values(currentContractor.contractor_contacts).map(
            (contact) => {
              delete contact.contractor_id;
              delete contact.id;
              return contact;
            }
          );
          setContactsToAdd(reps);
        }
      }
    }
  }, [contractorIdToEdit, currentContractor, currentUser, dispatch]);

  const handleChange = (e) => {
    if (e.target.name === "no_email") {
      return setContractorDetails({
        ...contractorDetails,
        [e.target.name]: e.target.checked,
      });
    }
    if (errors[e.target.name] && errors[e.target.name].error) {
      validate(
        e.target.value,
        () => {},
        () =>
          setErrors((errors) => ({
            ...errors,
            [e.target.name]: { ...errors[e.target.name], error: null },
          })),
        errors[e.target.name].type
      );
    }
    setContractorDetails({
      ...contractorDetails,
      [e.target.name]: e.target.value,
    });
  };

  const isFetching = useSelector((state) => state.contractors.isFetching);
  const errorMessage = useSelector((state) => state.contractors.error);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!contractorDetails.name) {
      return setErrors({
        name: { type: "required", error: "Обязательное поле" },
      });
    }

    const action =
      contractorIdToEdit &&
      currentContractor &&
      currentContractor.id === contractorIdToEdit
        ? editContractorStart
        : addContractorStart;

    dispatch(
      action(
        {
          contractorDetails,
          currentUser,
          currentContractor,
          contacts: isFilled(contactInputs)
            ? [...contactsToAdd, contactInputs]
            : contactsToAdd,
        },
        close
      )
    );
  };

  const [confirmDelete, setConfirmDelete] = useState();

  const handleDelete = () => {
    dispatch(
      deleteContractorStart(
        {
          currentUser,
          id: currentContractor.id,
          object: currentContractor.obj_id,
        },
        closeAfterDelete
      )
    );
  };
  return (
    <>
      <div className="caption">
        Подрядчик
        <button className="close" onClick={checkInputsAndClose} />
      </div>
      {!contractorIdToEdit ||
      (currentContractor && currentContractor.id === contractorIdToEdit) ? (
        <form onSubmit={handleSubmit}>
          <div className="field">
            <input
              id="no_email"
              name="no_email"
              value={1}
              type="checkbox"
              onChange={handleChange}
              checked={contractorDetails.no_email}
            />
            <label htmlFor="no_email">
              не отправлять предписания и акты на почту
            </label>
          </div>

          {!contractorIdToEdit && (
            <AllContractors
              setContractorDetails={setContractorDetails}
              site_id={site_id}
              setReps={setContactsToAdd}
            />
          )}

          <div className="two_col">
            <div className="col">
              <InputField
                type="text"
                label="Название"
                validationType="required"
                value={contractorDetails.name}
                onChange={handleChange}
                name="name"
                err={errors.name.error}
              />
            </div>
            <div className="col">
              <InputField
                type="text"
                label="ОГРН"
                name="ogrn"
                value={contractorDetails.ogrn}
                onChange={handleChange}
              />
            </div>
          </div>
          <Contacts
            contactsToAdd={contactsToAdd}
            setContactsToAdd={setContactsToAdd}
            contactInputs={contactInputs}
            setContactInputs={setContactInputs}
          />

          <TextAreaInput
            label="Специализация"
            height="20vh"
            name="specialization"
            value={contractorDetails.specialization}
            onChange={handleChange}
          />
          <TextAreaInput
            label="Дополнительное описание"
            height="20vh"
            name="descriptions"
            value={contractorDetails.descriptions}
            onChange={handleChange}
          />

          <div className="info err">
            {errorMessage || (errors.name.error && "Не все поля заполнены")}
          </div>
          <div className="buttons">
            <button className="green" disabled={isFetching}>
              {isFetching
                ? "Загрузка..."
                : contractorIdToEdit
                ? "Сохранить"
                : "добавить"}
            </button>
            <button
              className="cancel"
              type="button"
              onClick={checkInputsAndClose}
            >
              Отмена
            </button>
            {contractorIdToEdit && (
              <button
                className="remove"
                type="button"
                onClick={() => setConfirmDelete(true)}
                disabled={!currentContractor.deletable}
              />
            )}
          </div>
        </form>
      ) : (
        ""
      )}

      {confirmDelete && (
        <ConfirmDeleteModal
          message="Удалить подрядчика?"
          confirm={handleDelete}
          cancel={() => setConfirmDelete(false)}
          processing={isFetching}
          errorMsg={errorMessage}
        />
      )}
    </>
  );
};

export default AddContractor;
