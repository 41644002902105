import React, { useState } from "react";
import { useSelector } from "react-redux";
import UserRow from "../manage-users/user-row";
import InlineModal from "../inlineModal";
import AddSite from "./add-site";
import { isEmpty, normalizeSiteData } from "../../utils/normalizeData";
import { useParams } from "react-router";

const SiteDetails = ({ close, currentSite }) => {
  const statuses = useSelector((state) => state.statuses.sites);
  const users = useSelector((state) => state.user.usersList);
  const { site_id, menu } = useParams();

  const monoStatus = { актуальные: "В работе", завершенные: "Завершен" };

  const [editSitePopup, showEditSitePopup] = useState();

  return (
    <>
      <div className="caption">
        Объект
        <button className="close" onClick={close}></button>
      </div>

      <div className="two_col">
        <div className="col">
          <div className="field">
            <label>Название</label>
            <span className="data">{currentSite.name}</span>
          </div>

          <div className="field">
            <label>Статус</label>
            <span className="data">
              {statuses ? monoStatus[statuses[currentSite.status_id]] : ""}
            </span>
          </div>
        </div>

        <div className="col">
          {currentSite.address && (
            <div className="field">
              <label>Адрес</label>
              <span className="data">{currentSite.address}</span>
            </div>
          )}
        </div>
      </div>

      {!isEmpty(currentSite.contacts) && (
        <>
          <div className="hdr">Контакты</div>
          <table className="list">
            <tbody>
              {Object.values(currentSite.obj_contacts).map((contact) => (
                <tr key={contact.id}>
                  <td>{contact.name}</td>

                  <td>
                    <a
                      className="contact_link"
                      href={`mailto: ${contact.email}`}
                    >
                      {contact.email}
                    </a>
                  </td>

                  <td>
                    <a className="contact_link" href={`tel: ${contact.tel}`}>
                      {contact.tel}
                    </a>
                  </td>

                  <td>{contact.other_contacts}</td>
                </tr>
              ))}
            </tbody>
          </table>{" "}
        </>
      )}

      {currentSite.regulations && (
        <div className="field">
          <label>Правила попадания на объект</label>
          <span className="data">{currentSite.regulations}</span>
        </div>
      )}

      {currentSite.descriptions && (
        <div className="field">
          <label>Общее описание</label>
          <span className="data">{currentSite.descriptions}</span>
        </div>
      )}

      {!isEmpty(currentSite.obj_emails) && (
        <>
          <div className="hdr">
            Электронные почты заказчика для отправки уведомлений
          </div>

          <table className="list">
            <tbody>
              {Object.values(currentSite.obj_emails).map((email) => (
                <tr key={email.id}>
                  <td>
                    <a className="contact_link" href={`mailto: ${email.email}`}>
                      {email.email}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {currentSite.customer_info && (
        <div className="field">
          <label>Данные заказчика</label>
          <span className="data">{currentSite.customer_info}</span>
        </div>
      )}

      {!isEmpty(currentSite.obj_teams) && (
        <>
          <div className="hdr">Команда, работающая на объекте</div>

          <table className="list">
            <tbody>
              {Object.values(currentSite.obj_teams).map((member) => {
                const user = users.find((user) => user.id === member.user_id);
                console.log("USER IS: ", user);
                if (user) {
                  return (
                    <tr key={member.id}>
                      <UserRow
                        user={user}
                        basePath={`/main/sites/${site_id}/${menu}/site_info`}
                      />
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </>
      )}

      <div className="buttons">
        <button className="blue" onClick={() => showEditSitePopup(true)}>
          Редактировать
        </button>
      </div>

      {editSitePopup && (
        <InlineModal close={() => showEditSitePopup(false)}>
          <AddSite
            siteToEdit={normalizeSiteData(currentSite)}
            close={() => showEditSitePopup(false)}
          />
        </InlineModal>
      )}
    </>
  );
};

export default SiteDetails;
