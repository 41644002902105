import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { formatDate } from "../../../utils/normalizeData";
import InlineModal from "../../inlineModal";
import ViewPoint from "../view-point";
import AddAct from "./add-act";

const ActsList = ({ currentSubsectionId }) => {
  const acts = useSelector((state) => state.acts.acts);

  const history = useHistory();
  const { site_id, sub_id, menu, info } = useParams();

  let actsToRender = acts;
  if (currentSubsectionId) {
    actsToRender = acts.filter(
      (act) => act.subsection_id === currentSubsectionId
    );
  }
  const subsections = useSelector((state) => state.subsections.subsections);
  const contractors = useSelector((state) => state.contractors.contractors);

  const [addActWindow, showAddActWindow] = useState();

  const path =
    `/main/sites/${site_id}` + (sub_id ? `/subsections/${sub_id}` : "");

  return (
    <div className="content">
      {addActWindow && (
        <InlineModal close={() => showAddActWindow(false)}>
          <AddAct close={() => showAddActWindow(false)} />
        </InlineModal>
      )}

      {menu === "acts" && info && (
        <InlineModal>
          <ViewPoint
            close={() => history.push(`${path}/acts`)}
            act_id={+info}
          />
        </InlineModal>
      )}

      <div className="buttons top">
        <button className="blue" onClick={() => showAddActWindow(true)}>
          выписать акт
        </button>
      </div>
      {actsToRender.length ? (
        <table className="list">
          <thead>
            <tr>
              <th>номер</th>
              <th>дата подписания</th>
              <th>подраздел</th>
              <th>подрядчик</th>
              <th>описание</th>
            </tr>
          </thead>
          <tbody>
            {actsToRender.map((act) => {
              const sub = subsections.find(
                (sub) => sub.id === act.subsection_id
              );
              const contractor = contractors.find(
                (cont) => cont.id === act.contractor_id
              );
              return (
                <tr key={act.id}>
                  <td>
                    <label className="mobile">Номер</label>
                    <Link to={`${path}/acts/${act.id}`}>{act.num}</Link>
                  </td>
                  <td>
                    <label className="mobile">Дата подписания</label>

                    {formatDate(act.date, true)}
                  </td>
                  <td>
                    <label className="mobile">Подраздел</label>

                    {sub && sub.name}
                  </td>
                  <td>
                    <label className="mobile">Подрядчик</label>

                    {contractor && contractor.name}
                  </td>
                  <td>
                    <label className="mobile">Описание</label>

                    {act.descriptions}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}
    </div>
  );
};

export default ActsList;
