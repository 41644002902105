import { all, call } from "redux-saga/effects";
import { userSagas } from "./user/user.sagas";
import { sitesSagas } from "./sites/sites.sagas";
import { subsectionsSagas } from "./subsections/subsections.sagas";
import { revisionsSagas } from "./revisions/revisions.sagas";
import { claimsSagas } from "./claims/claims.sagas";
import { actsSagas } from "./acts/acts.sagas";
import { contractorsSagas } from "./contractors/contractors.sagas";
import { statusesSagas } from "./statuses/statuses.sagas";
import { worksSagas } from "./works/works.sagas";
import { remarksSagas } from "./remarks/remarks.sagas";
import { resourcesSagas } from "./resources/resources.sagas";
import { variablesSagas } from "./variables/variables.sagas";
import { techCardsSagas } from "./techCards/techCards.sagas";
import { reasonsSagas } from "./reasons/reasons.sagas";
import { reportsSagas } from "./reports/reports.sagas";
import { checklistsSagas } from "./checklists/checklists.sagas";

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(sitesSagas),
    call(subsectionsSagas),
    call(revisionsSagas),
    call(claimsSagas),
    call(actsSagas),
    call(contractorsSagas),
    call(statusesSagas),
    call(worksSagas),
    call(resourcesSagas),
    call(remarksSagas),
    call(variablesSagas),
    call(techCardsSagas),
    call(reasonsSagas),
    call(reportsSagas),
    call(checklistsSagas),
  ]);
}
