const SpecialtiesActionTypes = {
  ADD_SPECIALTY_START: "ADD_SPECIALTY_START",
  ADD_SPECIALTY_SUCCESS: "ADD_SPECIALTY_SUCCESS",
  ADD_SPECIALTY_FAILURE: "ADD_SPECIALTY_FAILURE",
  GET_SPECIALTIES_START: "GET_SPECIALTIES_START",
  GET_SPECIALTIES_SUCCESS: "GET_SPECIALTIES_SUCCESS",
  GET_SPECIALTIES_FAILURE: "GET_SPECIALTIES_FAILURE",
  DELETE_SPECIALTY_START: "DELETE_SPECIALTY_START",
  DELETE_SPECIALTY_SUCCESS: "DELETE_SPECIALTY_SUCCESS",
  DELETE_SPECIALTY_FAILURE: "DELETE_SPECIALTY_FAILURE",
  EDIT_SPECIALTY_START: "EDIT_SPECIALTY_START",
  EDIT_SPECIALTY_SUCCESS: "EDIT_SPECIALTY_SUCCESS",
  EDIT_SPECIALTY_FAILURE: "EDIT_SPECIALTY_FAILURE",
  CLEAR_ERRORS: "CLEAR_ERRORS",
};

export default SpecialtiesActionTypes;
