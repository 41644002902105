import { takeLeading, put, all, call, takeLatest } from "redux-saga/effects";
import axios from "axios";
import { handleError } from "../../../utils/normalizeData";

import SpecialtiesActionTypes from "./specialties.types";
import {
  addSpecialtySuccess,
  addSpecialtyFailure,
  getSpecialtiesStart,
  getSpecialtiesSuccess,
  getSpecialtiesFailure,
  editSpecialtySuccess,
  editSpecialtyFailure,
  deleteSpecialtySuccess,
  deleteSpecialtyFailure,
} from "./specialties.actions";

export function* addSpecialty({ payload, callback }) {
  const { newSpecialty, adminId } = payload;
  const postData = {
    row: {
      specialty: newSpecialty,
    },
    who: adminId,
  };
  try {
    yield axios.post(`/api/admin/employees/add`, postData);
    yield put(addSpecialtySuccess());
    yield put(getSpecialtiesStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(addSpecialtyFailure(error));
  }
}

export function* getSpecialties({ payload }) {
  try {
    const response = yield axios.get(`/api/admin/employees/${payload}`);
    yield put(getSpecialtiesSuccess(response.data));
  } catch (e) {
    const error = handleError(e);
    yield put(getSpecialtiesFailure(error));
  }
}

export function* editSpecialty({ payload, callback }) {
  const { specialtyName, adminId, specialtyId } = payload;
  const putSpecialtyData = {
    row: { specialty: specialtyName },
    who: adminId,
    employee: specialtyId,
  };
  try {
    yield axios.put("/api/admin/employees/edit", putSpecialtyData);
    yield put(editSpecialtySuccess());
    yield put(getSpecialtiesStart(adminId));
    yield callback();
  } catch (e) {
    const error = handleError(e);
    yield put(editSpecialtyFailure(error));
  }
}

export function* deleteSpecialty({ payload, callback }) {
  const { specialtyId, adminId } = payload;
  try {
    yield axios.put("/api/admin/employees/mark_as_deleted", {
      who: adminId,
      employee_id: specialtyId,
    });
    yield put(deleteSpecialtySuccess(specialtyId));
    yield put(getSpecialtiesStart(adminId));
    callback();
  } catch (e) {
    const error = handleError(e);
    yield put(deleteSpecialtyFailure(error));
  }
}

export function* onGetSpecialtiesStart() {
  yield takeLeading(
    SpecialtiesActionTypes.GET_SPECIALTIES_START,
    getSpecialties
  );
}

export function* onAddSpecialtyStart() {
  yield takeLatest(SpecialtiesActionTypes.ADD_SPECIALTY_START, addSpecialty);
}

export function* onEditSpecialtyStart() {
  yield takeLatest(SpecialtiesActionTypes.EDIT_SPECIALTY_START, editSpecialty);
}

export function* onDeleteSpecialtyStart() {
  yield takeLeading(
    SpecialtiesActionTypes.DELETE_SPECIALTY_START,
    deleteSpecialty
  );
}

export function* specialtiesSagas(args) {
  yield all([
    call(onGetSpecialtiesStart),
    call(onAddSpecialtyStart),
    call(onEditSpecialtyStart),
    call(onDeleteSpecialtyStart),
  ]);
}
