import React, { useState } from "react";
import { /*useDispatch,*/ useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomButton from "../../components/buttons/custom-button";
import { InputField } from "../../components/input-forms";

const Recover = () => {
  // const dispatch = useDispatch();

  // useEffect(() => dispatch(clearErrors()), [dispatch]);

  const [credentials, setCredentials] = useState({ identifier: "", email: "" });
  const isFetching = useSelector((state) => state.admins.isFetching);
  const recoverSuccess = useSelector((state) => state.admins.recover);
  const errorMessage = useSelector((state) => state.admins.error);

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("recover submit handled");
    // dispatch(recoverPasswordStart(credentials));
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {recoverSuccess ? (
          "Ссылка для восстановления пароля отправлена на указанную электронную почту"
        ) : (
          <>
            <div>
              Для восстановления пароля, укажите идентификатор компании и
              электронную почту, указанную при регистрации. Если что-то из этого
              не помните, свяжитесь с вашим администратором.
            </div>

            <InputField
              type="text"
              name="identifier"
              label="Идентификатор компании"
              loginstyle="true"
              value={credentials.identifier}
              validationType="required"
              onChange={handleChange}
            />
            <InputField
              name="email"
              label="Электронная почта"
              type="email"
              loginstyle="true"
              value={credentials.email}
              validationType="required"
              onChange={handleChange}
            />
          </>
        )}
        <div>
          <div>{errorMessage}</div>
          {!recoverSuccess && (
            <CustomButton loginbutton="true" disabled={isFetching}>
              {isFetching ? "Загрузка..." : "Готово"}
            </CustomButton>
          )}
          <Link to="/admin/login">Вход</Link>
        </div>
      </form>
    </div>
  );
};

export default Recover;
