import React, { useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStatusesStart } from "../../redux/statuses/statuses.actions";
import { getUsersStart, signOut } from "../../redux/user/user.actions";
import { getSitesStart } from "../../redux/sites/sites.actions";

import Header from "../../components/header/header-component";
import Users from "./Users";
import Sites from "./Sites";
import ChangePassword from "../login/change-password";
import SiteRoutes from "./site-routes";
// import Modal from "../../components/modal";
// import AddUser from "../../components/manage-users/add-user";
// import UserInfo from "../../components/manage-users/user-info";
// import AddSite from "../../components/manage-sites/add-site";

const MainRoutes = ({ match: { path } }) => {
  const dispatch = useDispatch();
  const siteStatuses = useSelector((state) => state.statuses.sites);
  const currentUser = useSelector((state) => state.user.currentUser);
  const usersList = useSelector((state) => state.user.usersList);

  useEffect(() => {
    if (!siteStatuses) dispatch(getStatusesStart(currentUser));
    dispatch(getUsersStart(currentUser));
    dispatch(getSitesStart(currentUser));
  }, [dispatch, currentUser, siteStatuses]);

  if (!usersList) return null;

  if (usersList && currentUser) {
    const currentUserDetails = usersList.find(
      (user) => user.id === currentUser.user
    );
    if (!currentUserDetails || currentUserDetails.blocked) {
      dispatch(signOut());
    }
  }

  return (
    <div className="main">
      <Header />
      <Switch>
        <Route
          exact
          path={[`${path}/users`, `${path}/users/user/:user_id/:action?`]}
          render={() =>
            currentUser.admin ? <Users /> : <Redirect to="/main/sites" />
          }
        />

        <Route
          exact
          path={[`${path}/sites`, `${path}/sites/user/:user_id/:action?`]}
          component={Sites}
        />

        <Route path={`${path}/sites/:site_id`} component={SiteRoutes} />

        <Route
          exact
          path={`${path}/change_password`}
          component={ChangePassword}
        />

        {/* <Route
          exact
          path={`${path}/users/add_user`}
          render={() =>
            currentUser.admin ? (
              <Modal child={AddUser} path="/main/users" />
            ) : (
              <Redirect to="/main/sites" />
            )
          }
        /> */}

        {/* <Route
          exact
          path={`${path}/users/edit/:user_id`}
          render={({ match: { params } }) => {
            const userToEdit = usersList.find(
              (user) => user.id === Number(params.user_id)
            );
            return currentUser.admin ? (
              <Modal
                child={AddUser}
                path="/main/users"
                userToEdit={userToEdit}
              />
            ) : (
              <Redirect to="/main/sites" />
            );
          }}
        /> */}

        {/* <Route
          exact
          path={`${path}/users/:user_id`}
          render={() => {
            return <Modal child={UserInfo} path="/main/users" />;
          }}
        /> */}

        {/* <Route
          exact
          path={`${path}/sites/add_site`}
          render={() => {
            return <Modal child={AddSite} path="/main/sites/" />;
          }}
        /> */}
      </Switch>
    </div>
  );
};

export default MainRoutes;
