import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SelectInput } from "../input-forms";

const PickUser = ({ userId, selectUserId, validationType, err, name }) => {
  const users = useSelector((state) => state.user.usersList);
  const [userOptions, setUserOptions] = useState();

  useEffect(() => {
    setUserOptions(
      users.reduce((optionsObj, user) => {
        optionsObj[
          user.id
        ] = `${user.firstname} ${user.lastname} (${user.email})`;
        return optionsObj;
      }, {})
    );
  }, [users]);

  const handleChange = (e) => {
    selectUserId(e.target.value);
  };

  return (
    <SelectInput
      label="Пользователи"
      defaultOption={userId}
      options={userOptions}
      onChange={handleChange}
      description="Выберите ответственного пользователя из выпадающего списка"
      emptyMessage="Пользователи не загружены из сервера"
      validationType={validationType}
      err={err}
      name={name}
    />
  );
};

export default PickUser;
