import TechCardsActionsTypes from "./techCards.types";

export const getTechCardsStart = (payload) => ({
  type: TechCardsActionsTypes.GET_TECH_CARDS_START,
  payload: payload,
});

export const getTechCardsSuccess = (techCards) => ({
  type: TechCardsActionsTypes.GET_TECH_CARDS_SUCCESS,
  payload: techCards,
});

export const getTechCardsFailure = (error) => ({
  type: TechCardsActionsTypes.GET_TECH_CARDS_FAILURE,
  payload: error,
});

export const getTechCardDetailsStart = (cardId, userId) => ({
  type: TechCardsActionsTypes.GET_TECH_CARD_DETAILS_START,
  payload: { cardId, userId },
});

export const getTechCardDetailsSuccess = (details) => ({
  type: TechCardsActionsTypes.GET_TECH_CARD_DETAILS_SUCCESS,
  payload: details,
});

export const getTechCardDetailsFailure = (error) => ({
  type: TechCardsActionsTypes.GET_TECH_CARD_DETAILS_FAILURE,
  payload: error,
});

export const clearTechCardDetails = () => ({
  type: TechCardsActionsTypes.CLEAR_TECH_CARD_DETAILS,
});
