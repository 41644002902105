import React, { useState, useEffect, useCallback } from "react";
import { NumberInput, SelectInput } from "../../../input-forms";
import { useDispatch, useSelector } from "react-redux";
import { getEquipmentStart } from "../../../../redux/resources/resources.actions";
import LinkButton from "../../../buttons/link-button";
import { isEmpty, isEqual } from "../../../../utils/normalizeData";
import {
  calculateFormula,
  hasQtyProperty,
} from "../../../../utils/calculateFormula";

const TechCardEquipment = ({
  cardEquipment,
  cardSpecialties,
  cardEquipmentInputs,
  setCardEquipmentInputs,
  equipmentToSubmit,
  setEquipment,
  equipmentInputs,
  setEquipmentInputs,
  userChangedVariable,
  userEditedCardEquipment,
  variableInputs,
  specialtyVariables,
  dailyWorkHours,
  work_id,
}) => {
  const dispatch = useDispatch();

  const equipment = useSelector((state) => state.resources.equipment);
  const currentUser = useSelector((state) => state.user.currentUser);
  const isFetchingEquipment = useSelector(
    (state) => state.resources.isFetchingEquipment
  );
  const errorMessage = useSelector((state) => state.resources.errorEquipment);

  const [options, setOptions] = useState({});
  const [cardEquipmentToRender, setCardEquipmentToRender] = useState([]);

  const [moreEquipment, addMoreEquipment] = useState();
  const [calculationError, setCalculationError] = useState();

  useEffect(() => {
    if (!isEmpty(cardEquipment)) {
      setCardEquipmentToRender(Object.values(cardEquipment));
    }
    setCalculationError(null);
  }, [cardEquipment]);

  useEffect(() => {
    // return if card specialties object is not empty, but specialty variables have not been set yet
    if (!isEmpty(cardSpecialties) && !specialtyVariables) return;

    if (!isEmpty(cardEquipment)) {
      if (!work_id || userChangedVariable.current) {
        const variablesScope = {
          ...variableInputs,
          ...specialtyVariables,
          DH: dailyWorkHours,
        };
        const updEquipmentInputsState = {};

        for (const i in cardEquipment) {
          if (
            isEmpty(cardEquipmentInputs) ||
            (cardEquipmentInputs[cardEquipment[i].equipment_id] &&
              cardEquipmentInputs[cardEquipment[i].equipment_id].hasOwnProperty(
                "qty"
              ))
          ) {
            let result;
            if (
              userEditedCardEquipment.current.hasOwnProperty([
                cardEquipment[i].equipment_id,
              ])
            ) {
              result =
                userEditedCardEquipment.current[cardEquipment[i].equipment_id];
            } else {
              result = calculateFormula(
                cardEquipment[i].qty,
                variablesScope,
                setCalculationError,
                true
              );
            }

            updEquipmentInputsState[cardEquipment[i].equipment_id] = {
              qty: result,
            };
          }
        }
        if (
          !isEmpty(updEquipmentInputsState) &&
          !isEqual(cardEquipmentInputs, updEquipmentInputsState)
        ) {
          setCardEquipmentInputs(updEquipmentInputsState);
        }
      }
    }
  }, [
    cardEquipment,
    cardSpecialties,
    cardEquipmentInputs,
    variableInputs,
    specialtyVariables,
    userEditedCardEquipment,
    setCardEquipmentInputs,
    dailyWorkHours,
    work_id,
    userChangedVariable,
  ]);

  const checkIfEquipmentAdded = useCallback(
    (id) => {
      return (
        equipmentToSubmit.some((eq) => eq[0] === id) ||
        (cardEquipmentInputs[id] &&
          cardEquipmentInputs[id].hasOwnProperty("qty"))
      );
    },
    [equipmentToSubmit, cardEquipmentInputs]
  );

  useEffect(() => {
    if (!equipment) {
      dispatch(getEquipmentStart(currentUser.user));
    } else {
      setOptions(
        equipment.reduce((options, tool) => {
          if (!checkIfEquipmentAdded(tool.id)) {
            options[tool.id] = tool.name;
          }
          return options;
        }, {})
      );
    }
  }, [equipment, dispatch, currentUser.user, checkIfEquipmentAdded]);

  const handleChange = (e) => {
    setEquipmentInputs({
      ...equipmentInputs,
      [e.target.name]: e.target.value ? Number(e.target.value) : "",
    });
  };

  const addEquipment = () => {
    const equipmentIndex = equipmentToSubmit.findIndex(
      (tool) => tool[0] === equipmentInputs.id
    );
    if (equipmentIndex !== -1) {
      let newEquipmentToSubmit = [...equipmentToSubmit];
      newEquipmentToSubmit[equipmentIndex][1] += equipmentInputs.qty;
      setEquipment(newEquipmentToSubmit);
    } else {
      const eq = equipment.find(
        (equipment) => equipment.id === equipmentInputs.id
      );
      if (eq) {
        setEquipment([
          ...equipmentToSubmit,
          [equipmentInputs.id, equipmentInputs.qty, eq.name],
        ]);
      }
    }
    return setEquipmentInputs({ id: "", qty: "" });
  };

  const deleteItem = (index) => {
    setEquipment(equipmentToSubmit.filter((tool, i) => i !== index));
  };

  const handleQtyChange = (e) => {
    userEditedCardEquipment.current[e.target.name] = e.target.value;
    setCardEquipmentInputs({
      ...cardEquipmentInputs,
      [e.target.name]: { qty: +e.target.value },
    });
  };

  const removeCardEquipment = (equipmentId) => {
    const updatedCardEquipmentInputs = { ...cardEquipmentInputs };
    if (
      updatedCardEquipmentInputs[equipmentId] &&
      updatedCardEquipmentInputs[equipmentId].hasOwnProperty("qty")
    ) {
      delete updatedCardEquipmentInputs[equipmentId].qty;
    }
    setCardEquipmentInputs(updatedCardEquipmentInputs);
    // setCardEquipmentInputs({ ...cardEquipmentInputs, [equipmentId]: "" });
    setCardEquipmentToRender(
      cardEquipmentToRender.filter((eq) => eq.equipment_id !== equipmentId)
    );
  };

  if (!isEmpty(cardSpecialties) && !specialtyVariables) {
    return "";
  }

  return (
    <>
      {hasQtyProperty(cardEquipmentInputs) || equipmentToSubmit.length ? (
        <>
          <div className="hdr">Необходимое оборудование</div>
          {calculationError && (
            <div className="info err">{calculationError}</div>
          )}
        </>
      ) : (
        ""
      )}

      {hasQtyProperty(cardEquipmentInputs) || equipmentToSubmit.length ? (
        <table className="list">
          <thead>
            <tr>
              <th>наименование</th>
              <th>количество</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cardEquipmentToRender.map((equipment) => {
              if (
                cardEquipmentInputs[equipment.equipment_id] &&
                cardEquipmentInputs[equipment.equipment_id].hasOwnProperty(
                  "qty"
                )
              ) {
                return (
                  <tr key={equipment.id}>
                    <td>{equipment.equipment}</td>
                    <td>
                      <NumberInput
                        name={equipment.equipment_id}
                        value={cardEquipmentInputs[equipment.equipment_id].qty}
                        onChange={handleQtyChange}
                      />
                    </td>
                    <td className="control">
                      <button
                        className="remove"
                        type="button"
                        onClick={() =>
                          removeCardEquipment(equipment.equipment_id)
                        }
                      />
                    </td>
                  </tr>
                );
              }
              return null;
            })}

            {equipmentToSubmit.length
              ? equipmentToSubmit.map((tool, i) => (
                  <tr key={tool[0]}>
                    <td>{tool[2]}</td>
                    <td>{tool[1]}</td>
                    <td className="control">
                      <button
                        className="remove"
                        onClick={() => deleteItem(i)}
                        type="button"
                      />
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      ) : (
        ""
      )}

      {!moreEquipment && (
        <LinkButton onClick={() => addMoreEquipment(true)}>
          Нужно дополнительное оборудование
        </LinkButton>
      )}

      {moreEquipment && (
        <>
          <div className="two_col">
            <div className="col">
              <SelectInput
                name="id"
                label="Наименование"
                onChange={handleChange}
                options={options}
                defaultOption={equipmentInputs.id}
                errorMessage={errorMessage}
                description={
                  isFetchingEquipment
                    ? "Загрузка данных..."
                    : "Выбрать оборудование"
                }
                emptyMessage={"Нет оборудования"}
              />
            </div>
            <div className="col">
              <NumberInput
                label="Количество"
                value={equipmentInputs.qty}
                onChange={handleChange}
                name="qty"
              />
            </div>
          </div>
          <div className="buttons in_form">
            <button
              type="button"
              onClick={addEquipment}
              disabled={!equipmentInputs.id || !equipmentInputs.qty}
            >
              добавить
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default TechCardEquipment;
