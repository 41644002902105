import EquipmentActionTypes from "./equipment.types";

const INITIAL_STATE = {
  isAdding: false,
  isFetching: false,
  isDeleting: false,
  error: null,
  equipment: null,
};

const equipmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EquipmentActionTypes.ADD_EQUIPMENT_START:
    case EquipmentActionTypes.EDIT_EQUIPMENT_START:
      return { ...state, isAdding: true, error: null };
    case EquipmentActionTypes.GET_EQUIPMENT_START:
      return { ...state, isFetching: true, error: null };
    case EquipmentActionTypes.DELETE_EQUIPMENT_START:
      return {
        ...state,
        isDeleting: true,
        error: null,
      };

    case EquipmentActionTypes.ADD_EQUIPMENT_SUCCESS:
    case EquipmentActionTypes.EDIT_EQUIPMENT_SUCCESS:
      return { ...state, isAdding: false, error: null };
    case EquipmentActionTypes.DELETE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        error: null,
      };

    case EquipmentActionTypes.GET_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        equipment: action.payload,
      };

    case EquipmentActionTypes.ADD_EQUIPMENT_FAILURE:
    case EquipmentActionTypes.EDIT_EQUIPMENT_FAILURE:
      return {
        ...state,
        isAdding: false,
        error: action.payload,
      };

    case EquipmentActionTypes.GET_EQUIPMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case EquipmentActionTypes.DELETE_EQUIPMENT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: action.payload,
      };

    case EquipmentActionTypes.CLEAR_ERRORS:
      return { ...state, isFetching: false, error: null };

    default:
      return state;
  }
};

export default equipmentReducer;
