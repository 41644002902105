import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  addRevisionStart,
  editRevisionStart,
  clearErrors,
} from "../../../redux/revisions/revisions.actions";
import PreviousActions from "../previousActions/previous-actions";
import DatePicker from "react-date-picker";
import { InputField, TextAreaInput } from "../../input-forms";
import { useParams } from "react-router-dom";
import SetPointsOnPlans from "../pointsOnPlan/set-points-on-plans";
import SelectSubsection from "../subsections/select-subsection";
import SelectContractor from "../contractors/select-contractor";
import {
  validate,
  isEmpty,
  concatDateTime,
  getTime,
} from "../../../utils/normalizeData";

const AddRevision = ({ rev_id, checkInputs, close, checkInputsAndClose }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    clearErrors();
  }, [dispatch]);

  const { site_id } = useParams();
  const [subsection, selectSub] = useState(null);
  const [revisionDetails, setRevisionDetails] = useState({
    title: "",
    descriptions: "",
    contractor_id: null,
    claim_id: null,
    type: "planned",
    user_id: currentUser.user,
  });
  const [revisionDate, setRevisionDate] = useState(new Date());
  const [revisionTime, setRevisionTime] = useState("09:00");
  const [markerCoords, setMarkerCoords] = useState([]);
  const [coordsToDelete, setCoordsToDelete] = useState([]);

  const [errors, setErrors] = useState({
    subsection: { type: "required", error: null },
    title: { type: "required", error: null },
  });
  const [isDataValid, setIsDataValid] = useState(true);

  // if this is not the edit window, then check inputs and prompt the user when he
  // tries to close the window without saving
  const currentSub = useSelector(
    (state) => state.subsections.currentSubsection
  );

  if (!rev_id) {
    checkInputs.current = () => {
      if (
        revisionDetails.title ||
        revisionDetails.descriptions ||
        revisionDetails.contractor_id ||
        revisionDetails.claim_id ||
        revisionDate.getDate() !== new Date().getDate() ||
        (!currentSub && subsection) ||
        (currentSub && subsection.id !== currentSub.id)
      )
        return true;
      return false;
    };
  }

  const revisions = useSelector((state) => state.revisions.revisions);

  // this id is used to find the correct subsection and set the subsection in SelectSubsection component to edit the revision
  const [idToGetSub, setIdToGetSub] = useState(null);

  // reset attached previous claim if user changes subsection
  useEffect(() => {
    if (subsection) {
      if (subsection.id === idToGetSub) return;
      setRevisionDetails((r) => ({ ...r, claim_id: null }));
      if (idToGetSub) setIdToGetSub(null);
    }
  }, [subsection, idToGetSub]);

  const claims = useSelector((state) => state.claims.claims);

  // if the EDIT page is opened, set up all the necessary data
  useEffect(() => {
    if (rev_id && !subsection) {
      const revisionToEdit = revisions.find(
        (revision) => revision.id === rev_id
      );
      if (revisionToEdit) {
        axios
          .get(
            `/api/revision_plan_points_revision/${currentUser.user}/${revisionToEdit.id}`
          )
          .then((response) => {
            setMarkerCoords(response.data);
          })
          .catch((e) => console.log(e));

        setIdToGetSub(revisionToEdit.subsection_id);
        setRevisionDetails({
          title: revisionToEdit.title,
          item: revisionToEdit.item,
          descriptions: revisionToEdit.descriptions
            ? revisionToEdit.descriptions
            : "",
          id: revisionToEdit.id,
          contractor_id: revisionToEdit.contractor_id,
          claim_id: revisionToEdit.claim_id,
          user_id: currentUser.user,
        });
        setRevisionDate(new Date(revisionToEdit.date));
        setRevisionTime(getTime(revisionToEdit.date));

        return;
      }
    }
  }, [rev_id, subsection, revisions, currentUser.user]);
  console.log("REVISION TIME: ", revisionTime);
  // check if all the input data errors have been eliminated
  useEffect(() => {
    if (!isDataValid) {
      for (const i in errors) {
        if (errors[i].error) return;
      }
      setIsDataValid(true);
    }
  }, [errors, isDataValid]);

  const setPreviousPoints = (pointsArr) => {
    setMarkerCoords(
      pointsArr.map((point) => ({
        dot_x: point.dot_x,
        dot_y: point.dot_y,
        plan_id: point.plan_id,
      }))
    );
  };

  // automatically set the subsection, contractor and points, if a previous claim has been selected
  useEffect(() => {
    if (revisionDetails.claim_id) {
      const previousClaim = claims.find(
        (claim) => claim.id === revisionDetails.claim_id
      );
      if (previousClaim) {
        if (!isEmpty(previousClaim.claim_plans)) {
          setPreviousPoints(Object.values(previousClaim.claim_plans));
        }
        if (previousClaim.subsection_id !== idToGetSub) {
          setIdToGetSub(previousClaim.subsection_id);
        }
        setRevisionDetails((revisionDetails) => ({
          ...revisionDetails,
          contractor_id: previousClaim.contractor_id,
        }));
      }
    }
  }, [revisionDetails.claim_id, claims, idToGetSub]);

  const checkValidity = (e) => {
    validate(
      e.target.value,
      () => {},
      () =>
        setErrors({
          ...errors,
          [e.target.name]: { ...errors[e.target.name], error: null },
        }),
      errors[e.target.name].type
    );
  };

  const handleChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      checkValidity(e);
    }

    if (e.target.name === "claim_id" || e.target.name === "revision_id") {
      return setRevisionDetails({
        ...revisionDetails,
        [e.target.name]: Number(e.target.value),
      });
    }
    setRevisionDetails({ ...revisionDetails, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    if (errors[e.target.name] && errors[e.target.name].error) {
      checkValidity(e);
    }
    setRevisionDetails({
      ...revisionDetails,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const isFetching = useSelector((state) => state.revisions.isFetching);
  const errorMessage = useSelector((state) => state.revisions.error);

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    for (const i in errors) {
      if (i === "subsection") {
        if (!subsection) {
          setErrors((errors) => ({
            ...errors,
            subsection: { ...errors.subsection, error: "Выберите подраздел" },
          }));
          valid = false;
          if (isDataValid) setIsDataValid(false);
        }
      } else if (!revisionDetails[i]) {
        setErrors((errors) => ({
          ...errors,
          [i]: { ...errors[i], error: "Обязательное поле" },
        }));
        valid = false;
        if (isDataValid) setIsDataValid(false);
      }
    }
    if (!valid) return;

    const action = rev_id ? editRevisionStart : addRevisionStart;
    dispatch(
      action(
        {
          ...revisionDetails,
          date: concatDateTime(revisionDate, revisionTime),
          subsection_id: subsection.id,
        },
        markerCoords,
        currentUser,
        site_id,
        close,
        rev_id,
        coordsToDelete
      )
    );
  };

  const isPlanned = (e) => {
    setRevisionDetails((r) => ({ ...r, type: e.target.value }));
  };

  // console.log("REVISION TIME: ", revisionTime);
  // console.log("CONCAT DATE TIME: ", concatDateTime(revisionDate, revisionTime));
  return (
    <>
      <div className="caption">
        Проверка
        <button className="close" onClick={checkInputsAndClose} />
      </div>
      <form onSubmit={handleSubmit}>
        <PreviousActions
          claimBtn
          subsectionId={subsection && subsection.id}
          handleChange={handleChange}
          claim_id={revisionDetails.claim_id}
          setRevisionDetails={setRevisionDetails}
          clearData={setRevisionDetails}
        />
        <InputField
          type="text"
          label="Краткое название проверки"
          name="title"
          placeholder="побелка стены, например..."
          value={revisionDetails.title}
          onChange={handleChange}
          validationType="required"
          err={errors.title.error}
        />

        <div className="field in_line" onChange={isPlanned}>
          <input
            type="radio"
            id="planned"
            name="type"
            value="planned"
            defaultChecked={revisionDetails.type === "planned"}
          />
          <label htmlFor="planned">Плановая</label>
          <input
            type="radio"
            id="not planned"
            name="type"
            value="not planned"
            defaultChecked={revisionDetails.type === "not planned"}
          />
          <label htmlFor="not planned">Внеплановая</label>
        </div>

        <SelectSubsection
          subsection={subsection}
          selectSub={selectSub}
          idToGetSub={idToGetSub}
          markerCoords={markerCoords}
          setMarkerCoords={setMarkerCoords}
          setCoordsToDelete={setCoordsToDelete}
          err={errors.subsection.error}
          setErrors={setErrors}
          validationType="required"
        />

        <SelectContractor
          contractorId={revisionDetails.contractor_id}
          selectContractorId={handleSelectChange}
        />
        <TextAreaInput
          label="Пояснение"
          height="20vh"
          name="descriptions"
          value={revisionDetails.descriptions}
          onChange={handleChange}
        />

        <div className="two_col">
          <div className="col">
            <label>Дата проверки</label>
            <div id="date-picker">
              <DatePicker
                onChange={setRevisionDate}
                value={revisionDate}
                clearIcon={null}
                format="dd-MM-yyyy"
                minDate={new Date()}
                validationType="required"
                locale="ru-RU"
              />
            </div>
          </div>
          <div className="col">
            <div className="field">
              <label>Время проверки</label>
              <input
                type="time"
                value={revisionTime}
                onChange={(e) => setRevisionTime(e.target.value)}
              />
            </div>
          </div>
        </div>

        <SetPointsOnPlans
          subsection={subsection}
          markerCoords={markerCoords}
          setMarkerCoords={setMarkerCoords}
          setCoordsToDelete={setCoordsToDelete}
          pinType={revisionDetails.claim_id ? "many" : "revision"}
        />
        <div className="info err">
          {errorMessage || (!isDataValid && "Не все поля заполнены корректно")}
        </div>
        <div className="buttons">
          <button className="green" disabled={isFetching}>
            {isFetching ? "загрузка..." : rev_id ? "сохранить" : "добавить"}
          </button>
          <button
            className="cancel"
            type="button"
            onClick={checkInputsAndClose}
          >
            Отмена
          </button>
        </div>
      </form>
    </>
  );
};

export default AddRevision;
