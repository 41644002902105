import { all, call } from "redux-saga/effects";
import { adminsSagas } from "./admins/admins.sagas";
import { specialtiesSagas } from "./specialties/specialties.sagas";
import { materialUnitsSagas } from "./materialUnits/materialUnits.sagas";
import { materialsSagas } from "./materials/materials.sagas";
import { equipmentSagas } from "./equipment/equipment.sagas";
import { reasonsSagas } from "./reasons/reasons.sagas";
import { variablesSagas } from "./variables/variables.sagas";
import { techCardsSagas } from "./techCards/techCards.sagas";

export default function* rootSaga() {
  yield all([
    call(adminsSagas),
    call(specialtiesSagas),
    call(materialUnitsSagas),
    call(materialsSagas),
    call(equipmentSagas),
    call(reasonsSagas),
    call(variablesSagas),
    call(techCardsSagas),
  ]);
}
