import React, { useState } from "react";
import { validate } from "../utils/normalizeData";
import {
  useFormInputStyles,
  usetextAreaStyles,
  useSelectStyles,
} from "../css/input-forms.styles";

export const FormInput = ({
  handleChange,
  label,
  loginstyle,
  error,
  ...otherProps
}) => {
  const classes = useFormInputStyles({ loginstyle, error });
  return (
    <div>
      {label && <label className={classes.label}>{label}</label>}
      <input
        type="text"
        className={classes.input}
        onChange={handleChange}
        {...otherProps}
      />
    </div>
  );
};

export const InputField = ({
  label,
  onChange,
  err,
  validationType = "",
  info,
  ...otherProps
}) => {
  const [error, setError] = useState();

  const handleChange = (e) => {
    if (error)
      validate(
        e.target.value,
        () => {},
        () => setError(),
        validationType
      );
    onChange(e);
  };

  const setErrorCallback = () => {
    switch (validationType) {
      case "required":
        return setError("Обязательное поле");
      case "email":
      case "required email":
        return setError("Введите корректный e-mail");
      default:
        return;
    }
  };

  return (
    <div className="field">
      {label && (
        <label>
          {label} {validationType.includes("required") && <small>*</small>}
        </label>
      )}
      <input
        {...otherProps}
        onChange={handleChange}
        onBlur={(e) =>
          validate(e.target.value, setErrorCallback, () => {}, validationType)
        }
      />
      <p className="info err">{err || error}</p>
      <p className="info">{info}</p>
    </div>
  );
};

export const PasswordInput = ({
  label,
  onChange,
  err,
  children,
  validationType = "",

  ...otherProps
}) => {
  const [opened, showPassword] = useState();
  const [error, setError] = useState();

  const handleChange = (e) => {
    if (error) {
      validate(
        e.target.value,
        () => {},
        () => setError(),
        validationType
      );
    }
    onChange(e);
  };

  return (
    <div className="field">
      {label && (
        <label>
          {label} {validationType.includes("required") && <small>*</small>}
        </label>
      )}
      <input
        type={`${opened ? "text" : "password"}`}
        {...otherProps}
        onChange={handleChange}
        onBlur={(e) =>
          validate(
            e.target.value,
            () => setError("Обязательное поле"),
            () => {},
            validationType
          )
        }
      />
      <span
        type="button"
        className={`eye ${opened ? "opened" : ""}`}
        onClick={() => showPassword(!opened)}
      />
      <p className="info err">{err || error}</p>
      {children}
    </div>
  );
};

export const NumberInput = ({
  handleChange,
  label,
  loginstyle,
  error,
  validationType = "",
  ...otherProps
}) => {
  return (
    <div className="field">
      {label && (
        <label>
          {label} {validationType.includes("required") && <small>*</small>}
        </label>
      )}
      <input
        type="number"
        min="0"
        step="1"
        onChange={handleChange}
        {...otherProps}
      />
    </div>
  );
};

export const TextArea = ({
  handleChange,
  label,
  height,
  validationType = "",
  ...otherProps
}) => {
  const classes = usetextAreaStyles();
  return (
    <div className={classes.textareaDiv}>
      <div className={classes.label}>
        {label} {validationType.includes("required") && <small>*</small>}
      </div>
      <textarea
        className={classes.textarea}
        onChange={handleChange}
        style={{ height }}
        {...otherProps}
      />
    </div>
  );
};

export const TextAreaInput = ({
  onChange,
  label,
  height,
  validationType = "",
  err,
  ...otherProps
}) => {
  const [error, setError] = useState();

  const handleChange = (e) => {
    if (error)
      validate(
        e.target.value,
        () => {},
        () => setError(),
        validationType
      );
    onChange(e);
  };

  return (
    <div className="field">
      <label>
        {label} {validationType.includes("required") && <small>*</small>}
      </label>
      <textarea
        onChange={handleChange}
        {...otherProps}
        onBlur={(e) =>
          validate(
            e.target.value,
            () => setError("Обязательное поле"),
            () => {},
            validationType
          )
        }
      />
      <p className="info err">{err || error}</p>
    </div>
  );
};

export const SelectForm = ({
  handleChange,
  options,
  defaultOption,
  label,
  description,
  ...otherProps
}) => {
  const classes = useSelectStyles();
  const optionsKeys = options ? Object.keys(options) : [];
  return (
    <div className={classes.select}>
      <div className={classes.label}>{label}</div>
      <select
        onChange={handleChange}
        {...otherProps}
        defaultValue={description ? "" : String(defaultOption)}
      >
        {description && (
          <option value="" disabled hidden>
            {description}
          </option>
        )}
        {optionsKeys.map((optionKey) => (
          <option value={optionKey} key={optionKey}>
            {options[optionKey]}
          </option>
        ))}
      </select>
    </div>
  );
};

export const ControlledSelect = ({
  options,
  defaultOption,
  label,
  description,
  emptyMessage,
  error,
  errorMessage,
  ...otherProps
}) => {
  const classes = useSelectStyles({ error });
  const optionsKeys = options ? Object.keys(options) : [];
  return (
    <div className={classes.select}>
      <div className={classes.label}>{label}</div>

      <select
        {...otherProps}
        value={errorMessage ? "" : defaultOption ? String(defaultOption) : ""}
      >
        {errorMessage && (
          <option value="" disabled hidden>
            {errorMessage}
          </option>
        )}
        {!errorMessage && description && (
          <option value="" disabled hidden>
            {description}
          </option>
        )}
        {optionsKeys.length ? (
          optionsKeys.map((optionKey) => (
            <option value={optionKey} key={optionKey}>
              {options[optionKey]}
            </option>
          ))
        ) : (
          <option value="" disabled>
            {emptyMessage}
          </option>
        )}
      </select>
    </div>
  );
};

export const SelectInput = ({
  options,
  defaultOption,
  label,
  description,
  emptyMessage,
  err,
  errorMessage,
  onChange,
  validationType = "",
  ...otherProps
}) => {
  const optionsKeys = options ? Object.keys(options) : [];
  const [error, setError] = useState();

  const handleChange = (e) => {
    if (error) {
      validate(
        e.target.value,
        () => {},
        () => setError(),
        validationType
      );
    }
    onChange(e);
  };

  return (
    <div className="field">
      <label>
        {label} {validationType.includes("required") && <small>*</small>}
      </label>

      <select
        {...otherProps}
        value={errorMessage ? "" : defaultOption ? String(defaultOption) : ""}
        onChange={handleChange}
        onBlur={(e) =>
          validate(
            e.target.value,
            () => setError("Обязательное поле"),
            () => {},
            validationType
          )
        }
      >
        {errorMessage && (
          <option value="" disabled hidden>
            {errorMessage}
          </option>
        )}
        {!errorMessage && description && (
          <option value="" disabled hidden>
            {description}
          </option>
        )}

        {optionsKeys.length ? (
          optionsKeys.map((optionKey) => (
            <option value={optionKey} key={optionKey}>
              {options[optionKey]}
            </option>
          ))
        ) : (
          <option value="" disabled>
            {emptyMessage}
          </option>
        )}
      </select>

      <span className="select_arrow"></span>

      <p className="info err">{err || error} </p>
    </div>
  );
};

export default FormInput;
