import ResourcesActionTypes from "./resources.types";

const INITIAL_STATE = {
  isFetchingSpecialties: false,
  isFetchingEquipment: false,
  isFetchingMaterials: false,
  errorSpecialty: null,
  errorEquipment: null,
  errorMaterials: null,
  specialties: null,
  equipment: null,
  materials: null,
};

const resourcesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ResourcesActionTypes.ADD_SPECIALTY_START:
    case ResourcesActionTypes.EDIT_SPECIALTY_START:
    case ResourcesActionTypes.GET_SPECIALTIES_START:
    case ResourcesActionTypes.DELETE_SPECIALTY_START:
      return { ...state, isFetchingSpecialties: true, errorSpecialty: null };

    case ResourcesActionTypes.ADD_EQUIPMENT_START:
    case ResourcesActionTypes.EDIT_EQUIPMENT_START:
    case ResourcesActionTypes.GET_EQUIPMENT_START:
    case ResourcesActionTypes.DELETE_EQUIPMENT_START:
      return { ...state, isFetchingEquipment: true, errorEquipment: null };

    case ResourcesActionTypes.ADD_MATERIAL_START:
    case ResourcesActionTypes.EDIT_MATERIAL_START:
    case ResourcesActionTypes.GET_MATERIALS_START:
    case ResourcesActionTypes.DELETE_MATERIAL_START:
      return { ...state, isFetchingMaterials: true, errorMaterials: null };

    case ResourcesActionTypes.ADD_SPECIALTY_SUCCESS:
    case ResourcesActionTypes.EDIT_SPECIALTY_SUCCESS:
    case ResourcesActionTypes.DELETE_SPECIALTY_SUCCESS:
      return { ...state, isFetchingSpecialties: false, errorSpecialty: null };

    case ResourcesActionTypes.ADD_EQUIPMENT_SUCCESS:
    case ResourcesActionTypes.EDIT_EQUIPMENT_SUCCESS:
    case ResourcesActionTypes.DELETE_EQUIPMENT_SUCCESS:
      return { ...state, isFetchingEquipment: false, errorEquipment: null };

    case ResourcesActionTypes.ADD_MATERIAL_SUCCESS:
    case ResourcesActionTypes.EDIT_MATERIAL_SUCCESS:
    case ResourcesActionTypes.DELETE_MATERIAL_SUCCESS:
      return { ...state, isFetchingMaterials: false, errorMaterials: null };

    case ResourcesActionTypes.GET_SPECIALTIES_SUCCESS:
      return {
        ...state,
        isFetchingSpecialties: false,
        errorSpecialty: null,
        specialties: action.payload,
      };

    case ResourcesActionTypes.GET_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isFetchingEquipment: false,
        errorEquipment: null,
        equipment: action.payload,
      };

    case ResourcesActionTypes.GET_MATERIALS_SUCCESS:
      return {
        ...state,
        isFetchingMaterials: false,
        errorMaterials: null,
        materials: action.payload,
      };

    case ResourcesActionTypes.ADD_SPECIALTY_FAILURE:
    case ResourcesActionTypes.EDIT_SPECIALTY_FAILURE:
    case ResourcesActionTypes.GET_SPECIALTIES_FAILURE:
    case ResourcesActionTypes.DELETE_SPECIALTY_FAILURE:
      return {
        ...state,
        isFetchingSpecialties: false,
        errorSpecialty: action.payload,
      };

    case ResourcesActionTypes.ADD_EQUIPMENT_FAILURE:
    case ResourcesActionTypes.EDIT_EQUIPMENT_FAILURE:
    case ResourcesActionTypes.GET_EQUIPMENT_FAILURE:
    case ResourcesActionTypes.DELETE_EQUIPMENT_FAILURE:
      return {
        ...state,
        isFetchingEquipment: false,
        errorEquipment: action.payload,
      };

    case ResourcesActionTypes.ADD_MATERIAL_FAILURE:
    case ResourcesActionTypes.EDIT_MATERIAL_FAILURE:
    case ResourcesActionTypes.GET_MATERIALS_FAILURE:
    case ResourcesActionTypes.DELETE_MATERIAL_FAILURE:
      return {
        ...state,
        isFetchingMaterials: false,
        errorMaterials: action.payload,
      };

    case ResourcesActionTypes.CLEAR_ERRORS:
      return { ...state, isFetchingSpecialties: false, errorSpecialty: null };

    default:
      return state;
  }
};

export default resourcesReducer;
